import { CircularProgress } from '@mui/material';
import { FC, useCallback } from 'react';
import { useNavigate, useOutletContext, useRevalidator } from 'react-router-dom'
import { ContentMessageTemplateContext } from './ContentMessageTemplateContainer';
import { ContentMessageTemplateForm, ContentMessageTemplateFormData } from './ContentMessageTemplateForm';
import { useData } from './data';

export const ContentMessageTemplateEdit: FC = () => {
  const { messageTemplate, inProgress } = useOutletContext() as ContentMessageTemplateContext;
  const { updateContentMessageTemplate } = useData();
  const navigate = useNavigate();
  const { revalidate } = useRevalidator();

  const onSubmit = useCallback( async ( data: ContentMessageTemplateFormData ) => {
    if( !data?.id ) return;
    await updateContentMessageTemplate( `${ data.id }`, data );
    revalidate();
    navigate( '..' );
  }, [ updateContentMessageTemplate ] )

  if( inProgress ) return <CircularProgress />;
  return (
    <ContentMessageTemplateForm
      title='Edit MessageTemplate'
      data={messageTemplate}
      onSubmit={onSubmit}
    />
  )
}
