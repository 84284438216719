import { Autocomplete, Box, Button, Checkbox, Chip, FormControlLabel, Grid, TextField, Toolbar, Typography } from '@mui/material';
import { capitalize } from 'inflection';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ContentLanguage,FormData, FormProps, LanguageTranslator } from './data';

export type ContentLanguageFormData = FormData<ContentLanguage>;

export const ContentLanguageForm: FC<FormProps<ContentLanguageFormData>> = props => {
  const { title, data, onSubmit } = props;
  const isCreate = !data;

  const { control, handleSubmit, formState: { errors, isDirty, isValidating, isValid, isSubmitted, isSubmitting } } = useForm<ContentLanguageFormData>( {
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: data || {
      _id: '',
      name: '',
      nativeName: '',
      flag: '',
      svg: '',
      rtl: false,
      isSupported: false,
      isEnabled: false,
      translators: [],
    },
  } );

  return (
    <Box>
      <Typography gutterBottom fontSize='1.2rem' fontWeight='bold'>
        { title }
      </Typography>

      <form
        style={{
          width: '100%',
          marginBottom: '1rem',
          minHeight: '10rem',
        }}
      >
        <Grid
          container
          spacing={1}
          direction='column'
          sx={{ maxWidth: '80rem' }}
        >

          <Grid item>
            <Controller
              name='id'
              control={control}
              render={( { field } ) => (
                !isCreate
                  ? <TextField
                    {...field}
                    sx={{ minWidth: '12rem' }}
                    margin='dense'
                    label='ID'
                    variant='standard'
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  : <TextField
                    {...field}
                    inputRef={field.ref}
                    margin='dense'
                    label='Native Name'
                    variant='filled'
                    fullWidth
                  />
              )}
            />
          </Grid>

          <Grid item>
            <Controller
              name='name'
              control={control}
              render={( { field } ) => (
                <TextField
                  {...field}
                  inputRef={field.ref}
                  margin='dense'
                  label='Name'
                  variant='filled'
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item>
            <Controller
              name='nativeName'
              control={control}
              render={( { field } ) => (
                <TextField
                  {...field}
                  inputRef={field.ref}
                  margin='dense'
                  label='Native'
                  variant='filled'
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item>
            <Controller
              name='flag'
              control={control}
              render={( { field } ) => (
                <TextField
                  {...field}
                  inputRef={field.ref}
                  margin='dense'
                  label='Flag'
                  variant='filled'
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item>
            <Controller
              name='svg'
              control={control}
              render={( { field } ) => (
                <TextField
                  {...field}
                  inputRef={field.ref}
                  margin='dense'
                  label='SVG'
                  variant='filled'
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item>
            <FormControlLabel
              label='RTL'
              control={
                <Controller
                  name='rtl'
                  control={control}
                  render={( { field } ) => (
                    <Checkbox
                      {...field}
                      inputRef={field.ref}
                      checked={ !!field.value }
                      onChange={( e ) => field.onChange( e.target.checked ) }
                    />
                  )}
                />
              }
            />
          </Grid>

          <Grid item>
            <FormControlLabel
              label='Supported'
              control={
                <Controller
                  name='isSupported'
                  control={control}
                  render={( { field } ) => (
                    <Checkbox
                      {...field}
                      inputRef={field.ref}
                      checked={ !!field.value }
                      onChange={( e ) => field.onChange( e.target.checked ) }
                    />
                  )}
                />
              }
            />
          </Grid>

          <Grid item>
            <FormControlLabel
              label='Enabled'
              control={
                <Controller
                  name='isEnabled'
                  control={control}
                  render={( { field } ) => (
                    <Checkbox
                      {...field}
                      inputRef={field.ref}
                      checked={ !!field.value }
                      onChange={( e ) => field.onChange( e.target.checked ) }
                    />
                  )}
                />
              }
            />
          </Grid>

          <Grid item>
            <Controller
              name='translators'
              control={control}
              render={( { field } ) => (
                <Autocomplete
                  multiple
                  options={ [
                    'aws',
                    'google',
                    'manual',
                    'preset',
                  ] }
                  getOptionLabel={ option => capitalize( option ) }
                  value={ field.value as LanguageTranslator[] }
                  onChange={ ( _e, values ) => field.onChange( values ) }
                  renderTags={ ( value, getTagProps ) => value.map( ( option, index ) => (
                    <Chip label={ capitalize( option ) } {...getTagProps( { index } ) } />
                  ) ) }
                  renderInput={ params => (
                    <TextField
                      {...params}
                      margin='dense'
                      label='Translators'
                      variant='filled'
                      onChange={ field.onChange }
                      fullWidth
                    />
                  ) }
                />
              )}
            />
          </Grid>

        </Grid>
      </form>
      <Toolbar>
        <Button variant='contained'
          disabled={!isDirty || isValidating || isSubmitting || isSubmitted || !isValid}
          onClick={handleSubmit( onSubmit, ( v ) => { console.log( errors, v ) } )}
          sx={{ fontSize: '90%' }}
        // color='success'
        >
          Submit
        </Button>
        <Box sx={{ flexGrow: 1 }} />
      </Toolbar>

    </Box>
  );
}