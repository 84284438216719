import { FC, useCallback, useEffect, useState } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { ContentTextsContext } from './ContentTextsContainer';
import { ContentText, useData } from './data';

export interface ContentTextContext extends ContentTextsContext {
  text?: ContentText;
  setText?: ( t: ContentText ) => void;
  getContentText: ( id: string ) => void;
  inProgress: boolean;
}

export const ContentTextContainer: FC = () => {
  const { textId } = useParams();
  const { getContentText } = useData();
  const [ inProgress, setInProgress ] = useState( false );
  const [ text, setText ] = useState<ContentText>();
  const contentTextsContext = useOutletContext() as ContentTextsContext;

  const loadText = useCallback( async () => {
    setText( await getContentText( textId ) );
  }, [ textId ] );

  useEffect( () => {
    ( async () => {
      setInProgress( true );
      await loadText();
      setInProgress( false );
    } )();
  }, [ textId, setText, getContentText ] );

  const context: ContentTextContext = {
    ...contentTextsContext,
    text, setText,
    getContentText,
    inProgress,
  };

  return (
    <div id='ContentText'>
      <Outlet {...{ context }} />
    </div>
  );
}
