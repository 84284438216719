import { CircularProgress } from '@mui/material';
import { FC, useCallback } from 'react';
import { useNavigate, useOutletContext, useRevalidator } from 'react-router-dom'
import { ContentTwimlContext } from './ContentTwimlContainer';
import { ContentTwimlForm, ContentTwimlFormData } from './ContentTwimlForm';
import { useData } from './data';

export const ContentTwimlEdit: FC = () => {
  const { twiml, inProgress } = useOutletContext() as ContentTwimlContext;
  const { updateContentTwiml } = useData();
  const navigate = useNavigate();
  const { revalidate } = useRevalidator();

  const onSubmit = useCallback( async ( data: ContentTwimlFormData ) => {
    if( !data?.id ) return;
    await updateContentTwiml( `${data.id}`, data );
    revalidate();
    navigate( '..' );
  }, [ updateContentTwiml ] )

  if( inProgress ) return <CircularProgress />;
  return (
    <ContentTwimlForm
      title='Edit Twiml'
      data={ twiml }
      onSubmit={ onSubmit }
    />
  )
}
