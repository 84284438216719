import { Grid, useTheme } from '@mui/material';
import { FC } from 'react';
import { useOutletContext } from 'react-router-dom';
import { TenantContext } from './TenantContainer';

export const TenantMessaging: FC = () => {
  const { tenant } = useOutletContext() as TenantContext;
  // const { environment: { config }, tag, name, description, stackName, status, stackId, tags = {}, createdAt, updatedAt, parameters = {}, outputs = {} } = tenant;

  return (
    <Grid item >
      Messaging: Plivo/Twilio/Customer, plus 10DLC etc.
    </Grid>
  );
}


