import { Box } from '@mui/material';
import { FC } from 'react';
import { useOutletContext, Outlet } from 'react-router-dom';

export const ContentHtmlComponentsContainer: FC = () => {
  const context = useOutletContext();
  return (
    <Box>
      <Outlet
        context={ context }
      />
    </Box>
  );
}
