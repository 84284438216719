import { FC, useCallback } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { BaseContext } from './Base';
import { TenantCreateFormData, useData } from './data';
import { TenantForm } from './TenantForm';

export const TenantCreate: FC = () => {
  const { createTenant } = useData();
  const { base, timeZoneName } = useOutletContext() as BaseContext;
  const navigate = useNavigate();

  const onSubmit = useCallback( async ( data: TenantCreateFormData ) => {
    const { tag } = data;
    await createTenant( data );
    // navigate( `../${ tag }/stack/events` );
    navigate( `..` );
    // navigate( `../${ tag }` );
  }, [ createTenant ] );


  return (
    <TenantForm
      title='Create tenant'
      onSubmit={onSubmit}
    />
  );

}


