import { FC, useCallback, useEffect, useState } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { ContentContext } from './Content';
import { ContentSnippet, useData } from './data';

export interface ContentSnippetContext extends ContentContext {
  snippet?: ContentSnippet;
  setSnippet?: ( s: ContentSnippet ) => void;
  getContentSnippet: ( id: string ) => void;
  inProgress: boolean;
}

export const ContentSnippetContainer: FC = () => {
  const { snippetId } = useParams();
  const { getContentSnippet } = useData();
  const [ inProgress, setInProgress ] = useState( false );
  const [ snippet, setSnippet ] = useState<ContentSnippet>();
  const contentContext = useOutletContext() as ContentContext;

  const loadSnippet = useCallback( async () => {
    setSnippet( await getContentSnippet( snippetId ) );
  }, [ snippetId ] );

  useEffect( () => {
    ( async () => {
      setInProgress( true );
      await loadSnippet();
      setInProgress( false );
    } )();
  }, [ snippetId, setSnippet, getContentSnippet ] );

  const context: ContentSnippetContext = {
    ...contentContext,
    snippet, setSnippet,
    getContentSnippet,
    inProgress,
  };

  return (
    <div id='ContentSnippet'>
      <Outlet {...{ context }} />
    </div>
  );
}
