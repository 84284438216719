import { Edit as EditIcon, FileUpload, OpenInNew } from '@mui/icons-material';
import { Box, Button, Link, Toolbar, Typography } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { get, pickBy, zipObject } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ContentTextContext } from './ContentTextContainer';
import { ContentText as ContentTextType, useData } from './data';
import { LanguageField, LanguageSelect } from './LanguageSelect';
import { PropGrid } from './PropGrid';
import { PropTable, PropTableRecord } from './PropTable';
import { ConfirmDialog } from './components/ConfirmDialog';

export const ContentText: FC = () => {
  const { languages, text, inProgress, setText, setNotify } = useOutletContext() as ContentTextContext;
  const { id, lang, name } = text || {};
  const { getTenants, getContentText, updateContentText } = useData();
  const navigate = useNavigate();
  const [ tenantMods, setTenantMods ] = useState<Record<string, ContentTextType>>();
  const [ openConfirm, setOpenConfirm ] = useState( '' );
  const [ updateInProgress, setUpdateInProgress ] = useState( false );

  useEffect( () => {
    ( async () => {
      if( tenantMods || !text?.id ) return;
      const tags = await getTenants();
      const mods = await Promise.all( tags.map( tag => {
        try {
          return getContentText( text?.id, { tag } );
        }
        catch( e ) {
          return Promise.resolve( undefined );
        }
      }
      ) );
      const map = pickBy( zipObject( tags, mods ) ) as Record<string, ContentTextType>;
      setTenantMods( map );
    } )();
  }, [ text ] );

  const updateContentValue = useCallback( async ( newValue?: string ) => {
    if( !newValue || !text?.id || !setText ) return;
    const { id } = text;
    setText( { ...text, value: newValue } );
    setUpdateInProgress( true );
    const update = await updateContentText( id, { ...text, value: newValue } );
    if( update ) {
      setText( update );
      setNotify( 'Updated text value.' );
    }
  }, [ text ] );


  const record = useMemo<PropTableRecord | undefined>( () => {
    if( !text ) return;
    const { id, lang, name, value, updatedAt } = text;
    return ( {
      id: id.length != id.trim().length ? `"${ id }"` : id,
      language: <LanguageField lang={lang} languages={languages} />,
      name: name.length != name.trim().length ? `"${ name }"` : name,
      value,
      updated: updatedAt,
    } );
  }, [ text ] );

  const modRecords = useMemo<PropTableRecord[] | undefined>( () => {
    if( !tenantMods ) return;
    return Object.entries( tenantMods ).map( ( [ tenant, mod ] ) => {
      const { id, value, updatedAt: updated } = mod || {};
      return {
        tenant,
        // changed: value != text?.value ? <Check color='primary' /> : <></>,
        updated: updated ? formatDistanceToNow( new Date( updated ) ) : '',
        value: <Typography
          sx={{
            fontWeight: value != text?.value ? 'bold' : undefined,
          }}
        >
          {value}
        </Typography>,
        actions: (
          <>
            <Button
              startIcon={<FileUpload />}
              onClick={() => setOpenConfirm( tenant )}
              disabled={value == text?.value}
            >
              make default
            </Button>
            <Link href={`https://admin.${ tenant }.analoginformation.com/?#/texts/${ encodeURIComponent( id ) }`} target="_blank" rel="noopener" color="inherit" >
              <Button
                endIcon={<OpenInNew />}
              >
                edit
              </Button>
            </Link>

          </>
        ),
      };
    } );
  }, [ tenantMods, text ] );

  const onChangeLang = useCallback( ( lang: string ) => {
    if( !lang || !name ) return;
    setTenantMods( undefined );
    navigate( `../${ lang }:${ name }` );
  }, [ name ] );

  return (
    <Box>
      <Toolbar>
        <LanguageSelect languages={languages} lang={lang || ''} onChange={( e ) => onChangeLang( e.target.value )} />
        <Box sx={{ flexGrow: 1 }} />
        <Button
          startIcon={<EditIcon />}
          onClick={() => navigate( 'edit' )}
          disabled={!!inProgress}
        >
          edit
        </Button>
      </Toolbar>

      <PropTable label='Content Central' record={record} inProgress={inProgress} />

      <Box mt='2rem'>
        <PropGrid label='Tenant modifications' records={modRecords} unsort emptyLabel='None found' />
      </Box>

      <ConfirmDialog
        open={!!openConfirm}
        title={`Update "${ text?.name }" default for all tenants`}
        message={`Are you sure you want to update the default value to "${ get( tenantMods, [ openConfirm, 'value' ] ) }" for all tenants?`}
        confirmButton='Update'
        onClose={( confirmed ) => {
          if( confirmed ) {
            openConfirm ? updateContentValue( get( tenantMods, [ openConfirm, 'value' ] ) ) : undefined;
          }
          setOpenConfirm( '' );
        }}
      // fullScreen={isXSmall}
      />


    </Box>
  );
}
