import { Add as AddIcon, Delete, Edit as EditIcon } from '@mui/icons-material';
import { Box, Button, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { useLoaderData, useNavigate, useOutletContext, useParams, useRevalidator } from 'react-router-dom';
import { ConfirmDialog } from './components/ConfirmDialog';
import { ConfigTable } from './ConfigTable';
import { TenantEnvironment, useData } from './data';
import { TenantConfigContext } from './TenantConfigContainer';
import { TenantContext } from './TenantContainer';

export const TenantConfig: FC = () => {
  const { id: tag } = useParams();
  const { envConfig } = useOutletContext() as TenantConfigContext;
  const [ openConfirm, setOpenConfirm ] = useState( '' );

  const navigate = useNavigate();
  const { revalidate } = useRevalidator();
  const { deleteTenantEnvConfig } = useData();

  const onDelete = useCallback( async ( key: string ) => {
    if( !tag ) return;
    await deleteTenantEnvConfig( tag, key );
    revalidate();
  }, [ deleteTenantEnvConfig, tag ] );

  return (
    <Box >
      <Toolbar>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          startIcon={<AddIcon />}
          onClick={() => navigate( 'create' )}
        >
          Create
        </Button>
        <Button
          startIcon={<EditIcon />}
          onClick={() => navigate( 'edit' )}
        >
          edit
        </Button>
      </Toolbar>

      {envConfig &&
        <ConfigTable label='Configs' record={envConfig}

          addOn={( key ) => (
            <Tooltip
              title={`Delete ${ key }`}
            >
              <IconButton
                onClick={() => setOpenConfirm( key )}
              >
                <Delete />
              </IconButton>
            </Tooltip>

          )
          }

        />
      }
      <ConfirmDialog
        open={!!openConfirm}
        title={`Delete ${ openConfirm }`}
        message={`Are you sure you want to delete the ${ openConfirm } prop?`}
        confirmButton='Delete'
        onClose={( confirmed ) => {
          if( confirmed ) {
            onDelete( openConfirm );
          }
          setOpenConfirm( '' );
        }}
      // fullScreen={isXSmall}
      />

    </Box >
  );
}


