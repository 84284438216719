import { Edit as EditIcon, FileUpload, OpenInNew } from '@mui/icons-material';
import { Box, Button, Link, TextField, Toolbar, Typography } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { get, pickBy, zipObject } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ConfirmDialog } from './components/ConfirmDialog';
import { ContentSnippetContext } from './ContentSnippetContainer';
import { useData, ContentSnippet as ContentSnippetType } from './data';
import { PropGrid } from './PropGrid';
import { PropTable, PropTableRecord } from './PropTable';

export const ContentSnippet: FC = () => {
  const { snippet, inProgress, setSnippet, setNotify } = useOutletContext() as ContentSnippetContext;
  const { getTenants, getContentSnippet, updateContentSnippet } = useData();
  const navigate = useNavigate();
  const [ tenantMods, setTenantMods ] = useState<Record<string, ContentSnippetType>>();
  const [ openConfirm, setOpenConfirm ] = useState( '' );
  const [ updateInProgress, setUpdateInProgress ] = useState( false );

  useEffect( () => {
    ( async () => {
      if( tenantMods || !snippet?.id ) return;
      const tags = await getTenants();
      const mods = await Promise.all( tags.map( tag => {
        try {
          return getContentSnippet( snippet?.id, { tag } );
        }
        catch( e ) {
          return Promise.resolve( undefined );
        }
      }
      ) );
      const map = pickBy( zipObject( tags, mods ) ) as Record<string, ContentSnippetType>;
      setTenantMods( map );
    } )();
  }, [ snippet ] );


  const record: PropTableRecord | undefined = useMemo( () => {
    if( !snippet ) return undefined;
    const { id, value, comment, updatedAt } = snippet;
    return ( {
      id: id.length != id.trim().length ? `"${ id }"` : id,
      value: <TextField
        margin='none'
        variant='outlined'
        multiline
        fullWidth
        value={value}
      />,
      comment: <TextField
        margin='none'
        variant='outlined'
        multiline
        fullWidth
        value={comment}
      />,
      updated: updatedAt,
    } );
  }, [ snippet ] );

  const updateContentValue = useCallback( async ( newValue?: string ) => {
    if( !newValue || !snippet?.id || !setSnippet ) return;
    const { id } = snippet;
    setSnippet( { ...snippet, value: newValue } );
    setUpdateInProgress( true );
    const update = await updateContentSnippet( id, { ...snippet, value: newValue } );
    if( update ) {
      setSnippet( update );
      setNotify( 'Updated snippet value.' );
    }
  }, [ snippet ] );


  const modRecords = useMemo<PropTableRecord[] | undefined>( () => {
    if( !tenantMods ) return;
    return Object.entries( tenantMods ).map( ( [ tenant, mod ] ) => {
      const { id, value, updatedAt: updated } = mod || {};
      return {
        tenant,
        // changed: value != snippet?.value ? <Check color='primary' /> : <></>,
        updated: updated ? formatDistanceToNow( new Date( updated ) ) : '',
        value: <Typography
          sx={{
            fontWeight: value != snippet?.value ? 'bold' : undefined,
          }}
        >
          {value}
        </Typography>,
        actions: (
          <>
            <Button
              startIcon={<FileUpload />}
              onClick={() => setOpenConfirm( tenant )}
              disabled={value == snippet?.value}
            >
              make default
            </Button>
            <Link href={`https://admin.${ tenant }.analoginformation.com/?#/snippets/${ encodeURIComponent( id ) }`} target="_blank" rel="noopener" color="inherit" >
              <Button
                endIcon={<OpenInNew />}
              >
                edit
              </Button>
            </Link>

          </>
        ),
      };
    } );
  }, [ tenantMods, snippet ] );


  return (
    <Box>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          startIcon={<EditIcon />}
          onClick={() => navigate( 'edit' )}
          disabled={!!inProgress}
        >
          edit
        </Button>
      </Toolbar>
      <PropTable label='Content Central' record={record} inProgress={inProgress} unsort />

      <Box mt='2rem'>
        <PropGrid label='Tenant modifcations' records={modRecords} unsort emptyLabel='None found' />
      </Box>

      <ConfirmDialog
        open={!!openConfirm}
        title={`Update "${ snippet?.name }" default for all tenants`}
        message={`Are you sure you want to update the default value to "${ get( tenantMods, [ openConfirm, 'value' ] ) }" for all tenants?`}
        confirmButton='Update'
        onClose={( confirmed ) => {
          if( confirmed ) {
            openConfirm ? updateContentValue( get( tenantMods, [ openConfirm, 'value' ] ) ) : undefined;
          }
          setOpenConfirm( '' );
        }}
      // fullScreen={isXSmall}
      />


    </Box>
  );
}
