import { FC, useCallback, useEffect, useState } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { ContentContext } from './Content';
import { ContentHtmlComponent, useData } from './data';

export interface ContentHtmlComponentContext extends ContentContext {
  htmlComponent?: ContentHtmlComponent;
  setHtmlComponent: ( htmlComponent: ContentHtmlComponent ) => void;
  getContentHtmlComponent: ( id: string ) => void;
  inProgress: boolean;
}

export const ContentHtmlComponentContainer: FC = () => {
  const { htmlComponentId } = useParams();
  const { getContentHtmlComponent } = useData();
  const [ inProgress, setInProgress ] = useState( false );
  const [ htmlComponent, setHtmlComponent ] = useState<ContentHtmlComponent>();
  const contentContext = useOutletContext() as ContentContext;

  const loadHtmlComponent = useCallback( async () => {
    setHtmlComponent( await getContentHtmlComponent( htmlComponentId ) );
  }, [ htmlComponentId ] );

  useEffect( () => {
    ( async () => {
      setInProgress( true );
      await loadHtmlComponent();
      setInProgress( false );
    } )();
  }, [ htmlComponentId, setHtmlComponent, getContentHtmlComponent ] );

  const context: ContentHtmlComponentContext = {
    ...contentContext,
    htmlComponent: htmlComponent,
    setHtmlComponent,
    getContentHtmlComponent,
    inProgress,
  };

  return (
    <div id='ContentHtmlComponent'>
      <Outlet {...{ context }} />
    </div>
  );
}
