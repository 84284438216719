import { Box } from '@mui/material';
import { FC } from 'react';
import { useOutletContext } from 'react-router-dom';
import { PropTable } from './PropTable';
import { TenantStackContext } from './TenantStack';

export const TenantStackTags: FC = () => {
  const { stack } = useOutletContext() as TenantStackContext;
  const { tags = {} } = stack || {};

  return (
    <Box >
      <PropTable label='Tags' record={tags} />
    </Box>

  );
}


