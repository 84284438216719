import styled from '@mui/material/styles/styled';
import MuiSvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const SvgIcon = styled( MuiSvgIcon, {
  name: 'LogoOpenEHR',
  shouldForwardProp: ( prop ) => prop !== 'fill',
} )<SvgIconProps>( () => ( {
  // fill: '#f00',
  // stroke: 'currentColor',
  // strokeLinecap: 'round',
  // strokeLinejoin: 'round',
  // strokeWidth: '2.25px',
} ) );

SvgIcon.defaultProps = {
  viewBox: '24 26 140 140',
  focusable: 'false',
  'aria-hidden': 'true',
};

export const LogoOpenEHR: FC<SvgIconProps> = ( props ) => {

  const { fill = 'rgb(248, 153, 29)', ...rest } = props;

  return (
    <SvgIcon {...rest}>

      <g><path style={{ opacity: 1 }} fill="#fdfefd" d="M -0.5,-0.5 C 66.1667,-0.5 132.833,-0.5 199.5,-0.5C 199.5,66.1667 199.5,132.833 199.5,199.5C 132.833,199.5 66.1667,199.5 -0.5,199.5C -0.5,132.833 -0.5,66.1667 -0.5,-0.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#f7c897" d="M 133.5,39.5 C 134.449,38.8476 135.615,38.5143 137,38.5C 139.442,38.4823 141.608,39.149 143.5,40.5C 141.756,43.1003 140.423,42.9336 139.5,40C 137.527,39.5045 135.527,39.3379 133.5,39.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#f3a75e" d="M 47.5,40.5 C 42.1592,39.2598 36.8259,39.2598 31.5,40.5C 36.8333,37.8333 42.1667,37.8333 47.5,40.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#fdf8ef" d="M 103.5,41.5 C 94.2247,52.8169 94.2247,64.1502 103.5,75.5C 100.928,78.4443 97.5942,79.7776 93.5,79.5C 93.2407,75.9319 91.2407,74.5985 87.5,75.5C 93.6618,67.0602 95.1618,57.7269 92,47.5C 88.1597,40.663 82.3264,37.663 74.5,38.5C 72.1959,33.6631 73.0292,33.3298 77,37.5C 80.4506,37.2654 83.9506,37.2654 87.5,37.5C 88.8333,36.8333 90.1667,36.1667 91.5,35.5C 92.5174,38.028 93.6841,38.028 95,35.5C 98.0169,37.4087 100.85,39.4087 103.5,41.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#fa8826" d="M 47.5,40.5 C 55.1398,46.2399 57.9731,53.9065 56,63.5C 53.3587,75.1528 46.192,79.9862 34.5,78C 29.4939,75.1617 26.1606,70.995 24.5,65.5C 24.3748,59.7969 24.7081,54.1302 25.5,48.5C 26.5512,45.0393 28.5512,42.3727 31.5,40.5C 36.8259,39.2598 42.1592,39.2598 47.5,40.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#fdf8f0" d="M 34.5,47.5 C 46.5749,46.3069 51.2415,51.6402 48.5,63.5C 43.8678,71.8569 38.3678,72.5236 32,65.5C 31.1711,60.467 31.3377,55.467 32.5,50.5C 33.9185,49.9953 34.5852,48.9953 34.5,47.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#f9bc88" d="M 133.5,39.5 C 133.5,52.1667 133.5,64.8333 133.5,77.5C 135.833,77.5 138.167,77.5 140.5,77.5C 140.158,78.3382 139.492,78.6716 138.5,78.5C 136.265,78.7947 134.265,78.4614 132.5,77.5C 132.5,67.5 132.5,57.5 132.5,47.5C 132.194,44.6146 132.527,41.9479 133.5,39.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#fef8ee" d="M 155.5,77.5 C 154.833,69.9896 154.167,62.3229 153.5,54.5C 152.643,55.7357 151.977,57.069 151.5,58.5C 152.428,59.7678 152.595,61.1011 152,62.5C 151.333,61.1667 150.667,61.1667 150,62.5C 148.908,65.0444 148.241,67.7111 148,70.5C 147.73,67.7759 147.23,65.1092 146.5,62.5C 146.167,62.1667 145.833,61.8333 145.5,61.5C 144.713,62.2421 144.047,63.0754 143.5,64C 142.594,66.7048 142.594,71.5381 143.5,78.5C 141.701,79.7879 140.035,79.7879 138.5,78.5C 139.492,78.6716 140.158,78.3382 140.5,77.5C 140.334,69.16 140.5,60.8267 141,52.5C 145.667,45.8333 150.333,45.8333 155,52.5C 155.5,60.8267 155.666,69.16 155.5,77.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#ea974d" d="M 70.5,41.5 C 69.791,42.404 68.791,42.7373 67.5,42.5C 67.5,41.5 67.5,40.5 67.5,39.5C 65.5328,39.2606 63.6995,39.5939 62,40.5C 61.5001,56.8299 61.3334,73.1633 61.5,89.5C 63.5,89.5 65.5,89.5 67.5,89.5C 67.5,89.8333 67.5,90.1667 67.5,90.5C 65.1667,90.5 62.8333,90.5 60.5,90.5C 60.5,73.1667 60.5,55.8333 60.5,38.5C 63.1667,38.5 65.8333,38.5 68.5,38.5C 68.2852,40.1788 68.9519,41.1788 70.5,41.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#fdbf86" d="M 25.5,48.5 C 24.7081,54.1302 24.3748,59.7969 24.5,65.5C 23.3503,60.6842 23.1837,55.6842 24,50.5C 24.2784,49.5842 24.7784,48.9175 25.5,48.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#fafaf6" d="M 123.5,63.5 C 121.416,65.0767 119.416,66.91 117.5,69C 111.111,72.1229 106.777,70.2895 104.5,63.5C 112.65,62.5073 120.983,62.1739 129.5,62.5C 129.678,59.7835 130.178,57.1168 131,54.5C 131.499,58.1516 131.665,61.8183 131.5,65.5C 128.549,65.7128 125.882,65.0461 123.5,63.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#fef1db" d="M 132.5,47.5 C 132.5,57.5 132.5,67.5 132.5,77.5C 131.677,74.023 131.177,70.3563 131,66.5C 130.517,67.448 130.351,68.448 130.5,69.5C 129.5,69.5 128.5,69.5 127.5,69.5C 126.797,67.1274 125.464,65.1274 123.5,63.5C 125.882,65.0461 128.549,65.7128 131.5,65.5C 131.665,61.8183 131.499,58.1516 131,54.5C 130.178,57.1168 129.678,59.7835 129.5,62.5C 120.983,62.1739 112.65,62.5073 104.5,63.5C 103.957,63.44 103.624,63.1067 103.5,62.5C 111.807,61.5021 120.14,61.1688 128.5,61.5C 128.354,57.4809 128.021,53.4809 127.5,49.5C 129.113,49.9443 130.28,50.9443 131,52.5C 131.198,50.6183 131.698,48.9516 132.5,47.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#fa8826" d="M 155.5,77.5 C 155.666,69.16 155.5,60.8267 155,52.5C 150.333,45.8333 145.667,45.8333 141,52.5C 140.5,60.8267 140.334,69.16 140.5,77.5C 138.167,77.5 135.833,77.5 133.5,77.5C 133.5,64.8333 133.5,52.1667 133.5,39.5C 135.527,39.3379 137.527,39.5045 139.5,40C 140.423,42.9336 141.756,43.1003 143.5,40.5C 153.961,36.6544 160.461,40.3211 163,51.5C 163.5,60.1603 163.666,68.8269 163.5,77.5C 160.833,77.5 158.167,77.5 155.5,77.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#fa8623" d="M 74.5,38.5 C 82.3264,37.663 88.1597,40.663 92,47.5C 95.1618,57.7269 93.6618,67.0602 87.5,75.5C 85.8333,76.1667 84.1667,76.8333 82.5,77.5C 78.7657,77.6773 75.0991,77.3439 71.5,76.5C 71.5,75.8333 71.1667,75.5 70.5,75.5C 70.189,74.5222 69.5223,73.8555 68.5,73.5C 67.5052,78.7921 67.1718,84.1254 67.5,89.5C 65.5,89.5 63.5,89.5 61.5,89.5C 61.3334,73.1633 61.5001,56.8299 62,40.5C 63.6995,39.5939 65.5328,39.2606 67.5,39.5C 67.5,40.5 67.5,41.5 67.5,42.5C 68.791,42.7373 69.791,42.404 70.5,41.5C 71.5903,40.1429 72.9236,39.1429 74.5,38.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#fdf8f0" d="M 74.5,46.5 C 85.1859,47.1497 89.0192,52.8164 86,63.5C 83.5493,69.0891 79.3826,70.9224 73.5,69C 71.3421,67.543 69.5088,65.7097 68,63.5C 66.3968,56.1526 68.5635,50.4859 74.5,46.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#fa8927" d="M 127.5,49.5 C 128.021,53.4809 128.354,57.4809 128.5,61.5C 120.14,61.1688 111.807,61.5021 103.5,62.5C 103.624,63.1067 103.957,63.44 104.5,63.5C 106.777,70.2895 111.111,72.1229 117.5,69C 119.416,66.91 121.416,65.0767 123.5,63.5C 125.464,65.1274 126.797,67.1274 127.5,69.5C 127.5,69.8333 127.5,70.1667 127.5,70.5C 122.87,76.0509 116.87,78.7175 109.5,78.5C 107.412,77.7992 105.412,76.7992 103.5,75.5C 94.2247,64.1502 94.2247,52.8169 103.5,41.5C 114.357,35.8762 122.357,38.5429 127.5,49.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#fedda9" d="M 105.5,50.5 C 105.5,51.5 105.5,52.5 105.5,53.5C 110.944,54.1083 115.944,53.4417 120.5,51.5C 121.404,52.209 121.737,53.209 121.5,54.5C 115.833,54.5 110.167,54.5 104.5,54.5C 104.232,52.901 104.566,51.5676 105.5,50.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#fbf9f1" d="M 120.5,51.5 C 115.944,53.4417 110.944,54.1083 105.5,53.5C 105.5,52.5 105.5,51.5 105.5,50.5C 109.111,46.4114 113.445,45.578 118.5,48C 119.305,49.1127 119.972,50.2794 120.5,51.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#ffdeba" d="M 127.5,70.5 C 125.667,74.4975 122.667,77.3309 118.5,79C 115.304,79.8045 112.304,79.6378 109.5,78.5C 116.87,78.7175 122.87,76.0509 127.5,70.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#f7b378" d="M 71.5,76.5 C 75.0991,77.3439 78.7657,77.6773 82.5,77.5C 79.3601,78.6408 76.0267,78.8075 72.5,78C 71.944,77.6174 71.6107,77.1174 71.5,76.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#f6b680" d="M 70.5,75.5 C 68.8587,80.2514 67.8587,85.2514 67.5,90.5C 67.5,90.1667 67.5,89.8333 67.5,89.5C 67.1718,84.1254 67.5052,78.7921 68.5,73.5C 69.5223,73.8555 70.189,74.5222 70.5,75.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#b3dbe8" d="M 68.5,107.5 C 59.0144,108.494 49.3478,108.827 39.5,108.5C 39.5,111.833 39.5,115.167 39.5,118.5C 48.3494,118.174 57.0161,118.507 65.5,119.5C 56.5,119.5 47.5,119.5 38.5,119.5C 38.5,115.5 38.5,111.5 38.5,107.5C 48.5,107.5 58.5,107.5 68.5,107.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#579eb8" d="M 38.5,119.5 C 47.5,119.5 56.5,119.5 65.5,119.5C 65.5,122.5 65.5,125.5 65.5,128.5C 56.5,128.5 47.5,128.5 38.5,128.5C 46.9839,127.507 55.6506,127.174 64.5,127.5C 64.5,125.167 64.5,122.833 64.5,120.5C 55.6506,120.826 46.9839,120.493 38.5,119.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#2a8aad" d="M 73.5,98.5 C 75.8333,98.5 78.1667,98.5 80.5,98.5C 80.5,105.5 80.5,112.5 80.5,119.5C 89.0168,119.826 97.3501,119.493 105.5,118.5C 105.5,111.833 105.5,105.167 105.5,98.5C 108.167,98.5 110.833,98.5 113.5,98.5C 113.5,116.167 113.5,133.833 113.5,151.5C 110.833,151.5 108.167,151.5 105.5,151.5C 105.5,144.167 105.5,136.833 105.5,129.5C 97.3501,128.507 89.0168,128.174 80.5,128.5C 80.5,135.833 80.5,143.167 80.5,150.5C 78.1667,150.5 75.8333,150.5 73.5,150.5C 73.5,133.167 73.5,115.833 73.5,98.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#a5d1e1" d="M 80.5,98.5 C 81.4907,104.979 81.824,111.646 81.5,118.5C 89.5,118.5 97.5,118.5 105.5,118.5C 97.3501,119.493 89.0168,119.826 80.5,119.5C 80.5,112.5 80.5,105.5 80.5,98.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#2188b6" d="M 111.5,99.5 C 112.78,116.359 112.78,128.692 111.5,136.5C 110.5,136.5 109.5,136.5 108.5,136.5C 108.411,139.709 108.744,142.875 109.5,146C 108.833,149.333 108.167,149.333 107.5,146C 106.606,139.869 106.273,133.702 106.5,127.5C 109.998,127.522 111.331,126.022 110.5,123C 109.207,122.51 107.873,122.343 106.5,122.5C 106.281,121.325 106.614,120.325 107.5,119.5C 108.736,120.246 110.07,120.579 111.5,120.5C 111.956,116.889 111.289,113.556 109.5,110.5C 110.168,106.803 110.835,103.137 111.5,99.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#278aae" d="M 31.5,98.5 C 43.8333,98.5 56.1667,98.5 68.5,98.5C 68.5,101.5 68.5,104.5 68.5,107.5C 58.5,107.5 48.5,107.5 38.5,107.5C 38.5,111.5 38.5,115.5 38.5,119.5C 46.9839,120.493 55.6506,120.826 64.5,120.5C 64.5,122.833 64.5,125.167 64.5,127.5C 55.6506,127.174 46.9839,127.507 38.5,128.5C 38.5,132.833 38.5,137.167 38.5,141.5C 47.9856,142.494 57.6522,142.827 67.5,142.5C 67.5,145.167 67.5,147.833 67.5,150.5C 55.5,150.5 43.5,150.5 31.5,150.5C 31.5,133.167 31.5,115.833 31.5,98.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#308caf" d="M 120.5,98.5 C 130.172,98.3335 139.839,98.5002 149.5,99C 157.637,103.371 160.804,110.204 159,119.5C 157.972,124.744 155.139,128.577 150.5,131C 153.931,137.69 156.931,144.523 159.5,151.5C 156.5,151.5 153.5,151.5 150.5,151.5C 148.128,145.084 145.461,138.75 142.5,132.5C 137.88,131.507 133.214,131.173 128.5,131.5C 128.5,138.167 128.5,144.833 128.5,151.5C 125.833,151.5 123.167,151.5 120.5,151.5C 120.5,133.833 120.5,116.167 120.5,98.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#f9fcfc" d="M 147.5,108.5 C 151.744,111.267 152.578,114.934 150,119.5C 149.184,120.823 148.017,121.489 146.5,121.5C 140.5,121.5 134.5,121.5 128.5,121.5C 128.5,117.167 128.5,112.833 128.5,108.5C 134.833,108.5 141.167,108.5 147.5,108.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#b0d7e1" d="M 147.5,108.5 C 141.167,108.5 134.833,108.5 128.5,108.5C 128.5,112.833 128.5,117.167 128.5,121.5C 134.5,121.5 140.5,121.5 146.5,121.5C 140.355,122.49 134.022,122.823 127.5,122.5C 127.5,117.5 127.5,112.5 127.5,107.5C 134.354,107.176 141.021,107.509 147.5,108.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#eefefe" d="M 68.5,98.5 C 56.1667,98.5 43.8333,98.5 31.5,98.5C 30.5033,115.992 30.1699,133.659 30.5,151.5C 43.1667,151.5 55.8333,151.5 68.5,151.5C 68.5,148.167 68.5,144.833 68.5,141.5C 58.8333,141.5 49.1667,141.5 39.5,141.5C 39.5,137.5 39.5,133.5 39.5,129.5C 41.1667,129.5 42.8333,129.5 44.5,129.5C 43.4324,130.434 42.099,130.768 40.5,130.5C 40.5,133.833 40.5,137.167 40.5,140.5C 50.5056,140.666 60.5056,140.5 70.5,140C 71.1378,136.065 71.8045,132.231 72.5,128.5C 72.5,135.833 72.5,143.167 72.5,150.5C 71.2438,150.461 70.2438,150.961 69.5,152C 56.5914,152.177 43.758,152.677 31,153.5C 28.9372,151.283 27.7705,148.617 27.5,145.5C 29.7771,132.604 30.1105,119.604 28.5,106.5C 28.6282,102.73 29.2949,99.0634 30.5,95.5C 43.1643,97.1293 55.831,98.1293 68.5,98.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#afdced" d="M 38.5,128.5 C 47.5,128.5 56.5,128.5 65.5,128.5C 58.6866,129.491 51.6866,129.825 44.5,129.5C 42.8333,129.5 41.1667,129.5 39.5,129.5C 39.5,133.5 39.5,137.5 39.5,141.5C 39.1667,141.5 38.8333,141.5 38.5,141.5C 38.5,137.167 38.5,132.833 38.5,128.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#8bc1d5" d="M 73.5,98.5 C 73.5,115.833 73.5,133.167 73.5,150.5C 75.8333,150.5 78.1667,150.5 80.5,150.5C 80.5,143.167 80.5,135.833 80.5,128.5C 89.0168,128.174 97.3501,128.507 105.5,129.5C 97.5,129.5 89.5,129.5 81.5,129.5C 81.5,136.833 81.5,144.167 81.5,151.5C 78.2869,151.81 75.2869,151.477 72.5,150.5C 72.5,143.167 72.5,135.833 72.5,128.5C 72.1726,118.319 72.506,108.319 73.5,98.5 Z" /></g>
      <g><path style={{ opacity: 1 }} fill="#75aabf" d="M 31.5,98.5 C 31.5,115.833 31.5,133.167 31.5,150.5C 43.5,150.5 55.5,150.5 67.5,150.5C 67.5,147.833 67.5,145.167 67.5,142.5C 57.6522,142.827 47.9856,142.494 38.5,141.5C 38.8333,141.5 39.1667,141.5 39.5,141.5C 49.1667,141.5 58.8333,141.5 68.5,141.5C 68.5,144.833 68.5,148.167 68.5,151.5C 55.8333,151.5 43.1667,151.5 30.5,151.5C 30.1699,133.659 30.5033,115.992 31.5,98.5 Z" /></g>


    </SvgIcon >
  );
};

export default LogoOpenEHR;

