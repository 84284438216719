import { Paper, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { LicenseInfo } from '@mui/x-license';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import * as reactRouterDom from 'react-router-dom';
import { createBrowserRouter, Outlet, redirect, RouterProvider } from 'react-router-dom';
import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react';
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui.js';
import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui';
import { Account } from './Account';
import { AccountCertificates } from './AccountCertificates';
import { AccountRedirectDomains } from './AccountRedirectDomains';
import { App } from './App';
import { AuthPage } from './AuthPage';
import { AwsLogo } from './AwsLogo';
import { Base } from './Base';
import { BaseIndex } from './BaseIndex';
import { BaseOutputs } from './BaseOutputs';
import { BaseParameters } from './BaseParameters';
import { BaseTags } from './BaseTags';
import { LocMatch } from './Breadcrumb';
import { Content, ContentLanguages } from './Content';
import { ContentBackup, ContentBackupCollection } from './ContentBackup';
import { ContentBackupContainer } from './ContentBackupContainer';
import { ContentBackups, ContentBackupsContainer } from './ContentBackups';
import { ContentHtmlComponent } from './ContentHtmlComponent';
import { ContentHtmlComponentContainer } from './ContentHtmlComponentContainer';
import { ContentHtmlComponents } from './ContentHtmlComponents';
import { ContentHtmlComponentsContainer } from './ContentHtmlComponentsContainer';
import { ContentHtmlComponentEdit } from './ContentHtmlComponentsEdit';
import { ContentLanguage } from './ContentLanguage';
import { ContentLanguageContainer } from './ContentLanguageContainer';
import { ContentLanguageEdit } from './ContentLanguageEdit';
import { ContentLanguagesContainer } from './ContentLanguages';
import { ContentMessageTemplate } from './ContentMessageTemplate';
import { ContentMessageTemplateContainer } from './ContentMessageTemplateContainer';
import { ContentMessageTemplateEdit } from './ContentMessageTemplateEdit';
import { ContentMessageTemplates } from './ContentMessageTemplates';
import { ContentMessageTemplatesContainer } from './ContentMessageTemplatesContainer';
import { ContentSnippet } from './ContentSnippet';
import { ContentSnippetContainer } from './ContentSnippetContainer';
import { ContentSnippetEdit } from './ContentSnippetEdit';
import { ContentSnippets } from './ContentSnippets';
import { ContentSnippetsContainer } from './ContentSnippetsContainer';
import { ContentText } from './ContentText';
import { ContentTextContainer } from './ContentTextContainer';
import { ContentTextCreate } from './ContentTextCreate';
import { ContentTextEdit } from './ContentTextEdit';
import { ContentTexts } from './ContentTexts';
import { ContentTextsContainer } from './ContentTextsContainer';
import { ContentTwiml } from './ContentTwiml';
import { ContentTwimlContainer } from './ContentTwimlContainer';
import { ContentTwimlEdit } from './ContentTwimlEdit';
import { ContentTwimls } from './ContentTwimls';
import { ContentTwimlsContainer } from './ContentTwimlsContainer';
import { Dashboard } from './Dashboard';
import { DataProvider, getBase } from './data';
import { ErrorPage } from './ErrorPage';
import { FetchProvider } from './fetch';
import './index.css';
import { LogoAthena, LogoCerner, LogoEpic, LogoEyeMD, LogoModMed, LogoNextech, LogoNextgen, LogoOpenEHR, LogoOpenEMR, LogoPointClickCare, LogoSupertokens } from './Logos';
import { superTokensConfig } from './supertokens-config';
import { TenantAuth, TenantCertificate, TenantConfig, TenantConfigContainer, TenantConfigCreate, TenantConfigEdit, TenantContainer, TenantCreate, TenantDatabase, TenantDns, TenantEdit, TenantIndex, TenantLambda, TenantLaunch, TenantManage, TenantMessaging, Tenants, TenantSecret, TenantSecretContainer, TenantSecretCreate, TenantSecretEdit, TenantSetup, TenantStack, TenantStackEvents, TenantStackOutputs, TenantStackParameters, TenantStackResources, TenantStackTags, TenantSummary, TenantTimers } from './Tenant';
import { TenantEmr } from './TenantEmr';
import { TenantEmrContainer } from './TenantEmrContainer';
import { TenantEmrCreate } from './TenantEmrCreate';
import { TenantEmrEdit } from './TenantEmrEdit';
import { TenantEmrs } from './TenantEmrs';
import { TenantEmrsContainer } from './TenantEmrsContainer';
import { TenantFunctionCode } from './TenantFunctionCode';
import { TenantInit } from './TenantInit';
import { TenantsTabs } from './Tenants';
import { TenantSitesCode } from './TenantSitesCode';
import { TenantStackTemplates } from './TenantStackTemplates';
import { TenantWaf } from './TenantWaf';
import { ThemeProviderWithColorMode } from './ThemeProviderWithColorMode';
import { Users } from './Users';
import { UsersStaff } from './UsersStaff';
import { UsersStaffCreate } from './UsersStaffCreate';
import { UsersStaffEdit } from './UsersStaffEdit';
import { UsersStaffUser } from './UsersStaffUser';
import { UsersOps } from './UsersOps';
import { UsersOpsCreate } from './UsersOpsCreate';
import { UsersOpsEdit } from './UsersOpsEdit';
import { UsersOpsUser } from './UsersOpsUser';
import { TenantsCosts } from './TenantsCosts';

LicenseInfo.setLicenseKey( 'ac76026faf68c469b19b64a8a0d412e3Tz04MDMyNyxFPTE3MzM3OTUxMTYwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y' );
SuperTokens.init( superTokensConfig );

const router = createBrowserRouter( [

  // https://github.com/supertokens/supertokens-auth-react/issues/581
  ...getSuperTokensRoutesForReactRouterDom( reactRouterDom, [ EmailPasswordPreBuiltUI ] ).map( ( r ) => r.props ),

  {
    path: '/auth',
    element: <AuthPage />,
  },
  {
    loader: async () => getBase(),
    id: 'app',
    element: <App />,
    errorElement: <ErrorPage />,
    handle: {
      breadcrumb: () => 'Home',
    },
    children: [
      {
        path: '/logos',
        children: [
          {
            path: 'supertokens',
            element: <LogoSupertokens sx={{ width: 300, height: 300, border: '1px gray solid' }} />,
          },
          {
            path: 'athena',
            element: <LogoAthena sx={{ width: 300, height: 300, border: '1px gray solid' }} />,
          },
          {
            path: 'cerner',
            element: <LogoCerner sx={{ width: 300, height: 300, border: '1px gray solid' }} />,
          },
          {
            path: 'epic',
            element: <LogoEpic sx={{ width: 300, height: 300, border: '1px gray solid' }} />,
          },
          {
            path: 'eyemd',
            element: <LogoEyeMD sx={{ width: 300, height: 300, border: '1px gray solid' }} />,
          },
          {
            path: 'modmed',
            element: <LogoModMed sx={{ width: 300, height: 300, border: '1px gray solid' }} />,
          },
          {
            path: 'openemr',
            element: <LogoOpenEMR sx={{ width: 300, height: 300, border: '1px gray solid' }} />,
          },
          {
            path: 'openehr',
            element: <LogoOpenEHR sx={{ width: 300, height: 300, border: '1px gray solid' }} />,
          },
          {
            path: 'nextgen',
            element: <LogoNextgen sx={{ width: 300, height: 300, border: '1px gray solid' }} />,
          },
          {
            path: 'nextech',
            element: <LogoNextech sx={{ width: 300, height: 300, border: '1px gray solid' }} />,
          },
          {
            path: 'pointclickcare',
            element: <LogoPointClickCare sx={{ width: 300, height: 300, border: '1px gray solid' }} />,
          },
        ]
      },
      {
        path: '/',
        element: <Dashboard />,
      },
      {
        path: '/account',
        element: <Account />,
        handle: {
          breadcrumb: () => 'Account',
        },
        children: [
          {
            index: true,
            loader: async () => redirect( 'info' ),
          },
          {
            path: 'info',
            element: <Paper elevation={0} ><AwsLogo sx={{ width: '6rem', height: '6rem' }} /><Typography>Account</Typography><Typography>(move certificates here)</Typography></Paper>,
          },
          {
            path: 'certificates',
            element: <AccountCertificates />,
            handle: {
              breadcrumb: () => 'Certificates',
            },
          },
          {
            path: 'redirect-domains',
            element: <AccountRedirectDomains />,
            handle: {
              breadcrumb: () => 'Redirect Domains',
            },
          },
        ]

      },
      {
        path: '/users',
        element: <Users />,
        handle: {
          breadcrumb: 'Users',
        },
        children: [
          {
            index: true,
            loader: async () => redirect( 'staff' ),
          },
          {
            path: 'staff',
            handle: {
              breadcrumb: 'Staff',
            },
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <UsersStaff />,
              },
              {
                path: 'create',
                element: <UsersStaffCreate />,
                handle: {
                  breadcrumb: 'Create',
                },
              },
              {
                path: ':id',
                element: <div><Outlet /></div>,
                handle: {
                  // @ts-ignore:2344
                  breadcrumb: ( { params } ) => params.id,
                },
                children: [
                  {
                    index: true,
                    element: <UsersStaffUser />,
                  },
                  {
                    path: 'edit',
                    element: <UsersStaffEdit />,
                    handle: {
                      breadcrumb: 'Edit',
                    },
                  },
                ],
              },
            ]
          },
          {
            path: 'ops',
            handle: {
              breadcrumb: 'Ops',
            },
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <UsersOps />,
              },
              {
                path: 'create',
                element: <UsersOpsCreate />,
                handle: {
                  breadcrumb: 'Create',
                },
              },
              {
                path: ':id',
                element: <div><Outlet /></div>,
                handle: {
                  // @ts-ignore:2344
                  breadcrumb: ( { params } ) => params.id,
                },
                children: [
                  {
                    index: true,
                    element: <UsersOpsUser />,
                  },
                  {
                    path: 'edit',
                    element: <UsersOpsEdit />,
                    handle: {
                      breadcrumb: 'Edit',
                    },
                  },
                ],
              },
            ]
          }
        ]

      },
      {
        path: '/content',
        element: <Content />,
        handle: {
          breadcrumb: () => 'Content',
        },
        children: [
          {
            index: true,
            loader: async () => redirect( 'languages' ),
          },
          {
            path: 'backups',
            element: <ContentBackupsContainer />,
            handle: {
              breadcrumb: () => 'Backups',
            },
            children: [
              {
                index: true,
                element: <ContentBackups />,
              },
              {
                path: ':backupId',
                element: <ContentBackupContainer />,
                handle: {
                  // @ts-ignore:2344
                  breadcrumb: ( { params } ) => params.backupId,
                },
                children: [
                  {
                    index: true,
                    element: <ContentBackup />,

                  },
                  {
                    path: ':collection',
                    element: <ContentBackupCollection />,
                    handle: {
                      // @ts-ignore:2344
                      breadcrumb: ( { params } ) => params.collection,
                    },
                  },
                ]
              },
            ],
          },
          {
            path: 'languages',
            element: <ContentLanguagesContainer />,
            handle: {
              breadcrumb: () => 'Languages',
            },
            children: [
              {
                index: true,
                element: <ContentLanguages />,
              },
              {
                path: ':languageId',
                element: <ContentLanguageContainer />,
                handle: {
                  // @ts-ignore:2344
                  breadcrumb: ( { params } ) => params.languageId,
                },
                children: [
                  {
                    index: true,
                    element: <ContentLanguage />,
                  },
                  {
                    path: 'edit',
                    element: <ContentLanguageEdit />,
                    handle: {
                      breadcrumb: () => 'Edit',
                    },
                  },
                ]
              },
            ],
          },
          {
            path: 'texts',
            element: <ContentTextsContainer />,
            handle: {
              breadcrumb: () => 'Texts',
            },
            children: [
              {
                index: true,
                element: <ContentTexts />,
              },
              {
                path: 'create',
                element: <ContentTextCreate />,
                handle: {
                  breadcrumb: 'Create',
                }
              },
              {
                path: ':textId',
                element: <ContentTextContainer />,
                handle: {
                  // @ts-ignore:2344
                  breadcrumb: ( { params } ) => params.textId,
                },
                children: [
                  {
                    index: true,
                    element: <ContentText />,
                  },
                  {
                    path: 'edit',
                    element: <ContentTextEdit />,
                    handle: {
                      breadcrumb: () => 'Edit',
                    },
                  }
                ]
              }
            ]
          },
          {
            path: 'snippets',
            element: <ContentSnippetsContainer />,
            handle: {
              breadcrumb: () => 'Snippets',
            },
            children: [
              {
                index: true,
                element: <ContentSnippets />,
              },
              {
                path: ':snippetId',
                element: <ContentSnippetContainer />,
                handle: {
                  // @ts-ignore:2344
                  breadcrumb: ( { params } ) => params.snippetId,
                },
                children: [
                  {
                    index: true,
                    element: <ContentSnippet />,
                  },
                  {
                    path: 'edit',
                    element: <ContentSnippetEdit />,
                    handle: {
                      breadcrumb: () => 'Edit',
                    },
                  }
                ]
              }
            ]
          },
          {
            path: 'htmlComponents',
            element: <ContentHtmlComponentsContainer />,
            handle: {
              breadcrumb: () => 'HtmlComponents',
            },
            children: [
              {
                index: true,
                element: <ContentHtmlComponents />
              },
              {
                path: ':htmlComponentId',
                element: <ContentHtmlComponentContainer />,
                handle: {
                  // @ts-ignore:2344
                  breadcrumb: ( { params } ) => params.htmlComponentId,
                },
                children: [
                  {
                    index: true,
                    element: <ContentHtmlComponent />,
                  },
                  {
                    path: 'edit',
                    element: <ContentHtmlComponentEdit />,
                    handle: {
                      breadcrumb: () => 'Edit',
                    },
                  }
                ],
              },
            ]
          },
          {
            path: 'messageTemplates',
            element: <ContentMessageTemplatesContainer />,
            handle: {
              breadcrumb: () => 'MessageTemplates',
            },
            children: [
              {
                index: true,
                element: <ContentMessageTemplates />,
              },
              {
                path: ':messageTemplateId',
                element: <ContentMessageTemplateContainer />,
                handle: {
                  // @ts-ignore:2344
                  breadcrumb: ( { params } ) => params.messageTemplateId,
                },
                children: [
                  {
                    index: true,
                    element: <ContentMessageTemplate />,
                  },
                  {
                    path: 'edit',
                    element: <ContentMessageTemplateEdit />,
                    handle: {
                      breadcrumb: () => 'Edit',
                    },
                  }
                ]
              }
            ]
          },
          {
            path: 'twimls',
            element: <ContentTwimlsContainer />,
            handle: {
              breadcrumb: () => 'Twimls',
            },
            children: [
              {
                index: true,
                element: <ContentTwimls />,
              },
              {
                path: ':twimlId',
                element: <ContentTwimlContainer />,
                handle: {
                  // @ts-ignore:2344
                  breadcrumb: ( { params } ) => params.twimlId,
                },
                children: [
                  {
                    index: true,
                    element: <ContentTwiml />,
                  },
                  {
                    path: 'edit',
                    element: <ContentTwimlEdit />,
                    handle: {
                      breadcrumb: () => 'Edit',
                    },
                  }
                ]
              }
            ]
          },
        ]

      },
      {
        path: '/services',
        element: <Paper elevation={0}><Typography>Services</Typography></Paper>,
        handle: {
          breadcrumb: () => 'Services',
        },
      },
      {
        path: '/base',
        element: <Base />,
        handle: {
          breadcrumb: () => 'Base',
        },
        children: [
          {
            index: true,
            loader: async () => redirect( 'info' ),
          },
          {
            path: 'info',
            element: <BaseIndex />,
          },
          {
            path: 'tags',
            element: <BaseTags />,
            handle: {
              breadcrumb: () => 'Tags',
            },
          },
          {
            path: 'outputs',
            element: <BaseOutputs />,
            handle: {
              breadcrumb: () => 'Outputs',
            },
          },
          {
            path: 'parameters',
            element: <BaseParameters />,
            handle: {
              breadcrumb: () => 'Parameters',
            },
          },
        ]

      },

      {
        path: 'tenants',
        element: <TenantsTabs />,
        handle: {
          breadcrumb: () => 'Tenants',
        },
        children: [
          {
            index: true,
            loader: async () => redirect( 'summary' ),
          },
          {
            path: 'summary',
            element: <Tenants />,
            handle: {
              breadcrumb: () => 'Summary',
            },
          },
          {
            path: 'costs',
            element: <TenantsCosts />,
            handle: {
              breadcrumb: () => 'Costs',
            },
          },
          {
            path: 'code',
            element: <TenantFunctionCode />,
            handle: {
              breadcrumb: () => 'Function Code',
            },
          },
          {
            path: 'sites',
            element: <TenantSitesCode />,
            handle: {
              breadcrumb: () => 'Sites Code',
            },
          },
          {
            path: 'templates',
            element: <TenantStackTemplates />,
            handle: {
              breadcrumb: () => 'Function Code',
            },
          },
          {
            path: 'create',
            element: <TenantCreate />,
            handle: {
              breadcrumb: () => 'Create',
            },
          },
          {
            path: ':id',
            // loader: async ( { params } ) => getTenant( params.id ),
            element: <TenantContainer />,
            handle: {
              // breadcrumb: ( { data, ...rest }: LocMatch<Record<string, unknown>> ) => data?.tag,
              breadcrumb: ( { params }: LocMatch<Record<string, unknown>> ) => params.id,
            },
            children: [
              {
                index: true,
                loader: async () => redirect( 'create' ),
              },
              {
                path: 'create',
                children: [
                  {
                    index: true,
                    element: <TenantIndex />,
                  },
                  {
                    path: 'edit',
                    element: <TenantEdit />,
                    handle: {
                      breadcrumb: () => 'Edit',
                    },
                  },
                ],
              },
              {
                path: 'setup',
                // loader: async ( { params } ) => getTenantDatabaseUser( params.id ),
                element: <TenantSetup />,
                handle: {
                  breadcrumb: () => 'Setup',
                },
                children: [
                  {
                    index: true,
                    loader: async () => redirect( 'summary' ),
                  },
                  {
                    path: 'summary',
                    element: <p>Placeholder</p>,
                  },
                  {
                    path: 'database',
                    // loader: async ( { params } ) => getTenantDatabaseUser( params.id ),
                    element: <TenantDatabase />,
                    handle: {
                      breadcrumb: () => 'Database',
                    },
                  },
                  {
                    path: 'auth',
                    // loader: async ( { params } ) => getTenantAuth( params.id ),
                    element: <TenantAuth />,
                    handle: {
                      breadcrumb: () => 'Auth',
                    },
                  },
                  {
                    path: 'certificate',
                    // loader: async ( { params } ) => ( {
                    //   certificate: await getTenantCertificate( params.id )
                    // } ),
                    element: <TenantCertificate />,
                    handle: {
                      breadcrumb: () => 'Certificate',
                    },
                  },
                  {
                    path: 'messaging',
                    element: <TenantMessaging />,
                    handle: {
                      breadcrumb: () => 'Messaging',
                    },
                  },
                  {
                    path: 'config',
                    // loader: async ( { params } ) => getTenantEnvConfig( params.id ),
                    element: <TenantConfigContainer />,
                    handle: {
                      breadcrumb: () => 'Config',
                    },
                    children: [
                      {
                        index: true,
                        element: <TenantConfig />
                      },
                      {
                        path: 'edit',
                        element: <TenantConfigEdit />,
                        handle: {
                          breadcrumb: () => 'Edit',
                        },
                      },
                      {
                        path: 'create',
                        element: <TenantConfigCreate />,
                        handle: {
                          breadcrumb: () => 'Create',
                        },
                      },
                    ],
                  },

                ],
              },
              {
                path: 'launch',
                element: <TenantLaunch />,
                handle: {
                  breadcrumb: () => 'Launch',
                },
                children: [
                  {
                    index: true,
                    loader: async () => redirect( 'summary' ),
                  },
                  {
                    path: 'summary',
                    element: <p>Placeholder</p>,
                  },
                  {
                    path: 'secret',
                    // loader: async ( { params } ) => getTenantEnvSecret( params.id ),
                    element: <TenantSecretContainer />,
                    handle: {
                      breadcrumb: () => 'Secret',
                    },
                    children: [
                      {
                        index: true,
                        element: <TenantSecret />
                      },
                      {
                        path: ':fieldKey',
                        handle: {
                          // @ts-ignore:2344
                          breadcrumb: ( { params } ) => params.fieldKey,
                        },
                        children: [
                          {
                            path: 'edit',
                            element: <TenantSecretEdit />,
                            handle: {
                              breadcrumb: () => 'Edit',
                            },
                          },
                        ],
                      },
                      {
                        path: 'create',
                        element: <TenantSecretCreate />,
                        handle: {
                          breadcrumb: () => 'Create',
                        },
                      },
                    ],
                  },
                  {
                    path: 'dns',
                    // loader: async ( { params } ) => await getTenantDns( params.id ),
                    element: <TenantDns />,
                    handle: {
                      breadcrumb: () => 'DNS',
                    },
                  },
                  {
                    path: 'init',
                    element: <TenantInit />,
                    handle: {
                      breadcrumb: () => 'Init',
                    },
                  },
                  {
                    path: 'emr',
                    // loader: async ( { params } ) => await getTenantEmrs( params.id ),
                    element: <TenantEmrsContainer />,
                    handle: {
                      breadcrumb: () => 'EMR',
                    },
                    children: [
                      {
                        index: true,
                        element: <TenantEmrs />,
                      },
                      {
                        path: 'create',
                        element: <TenantEmrCreate />,
                        handle: {
                          breadcrumb: () => 'Create',
                        },
                      },
                      {
                        path: ':emr',
                        // loader: async ( { params } ) => await getTenantEmr( params.id, params.emr ),
                        element: <TenantEmrContainer />,
                        handle: {
                          // @ts-ignore:2344
                          breadcrumb: ( { params } ) => params.emr,
                        },
                        children: [
                          {
                            index: true,
                            // element: <Typography>Emr detail</Typography>,
                            element: <TenantEmr />,
                          },
                          {
                            path: 'edit',
                            element: <TenantEmrEdit />,
                            handle: {
                              breadcrumb: () => 'Edit',
                            },
                          },

                        ]
                      },

                    ],
                  },
                  {
                    path: 'test',
                    element: <p>Placeholder</p>,
                    handle: {
                      breadcrumb: () => 'Test',
                    },
                  },
                ],
              },
              {
                path: 'stack',
                // loader: async ( { params } ) => getTenantStack( params.id ),
                element: <TenantStack />,
                handle: {
                  breadcrumb: () => 'Stack',
                },
                children: [
                  {
                    index: true,
                    loader: async () => redirect( 'events' ),
                  },
                  {
                    path: 'events',
                    element: <TenantStackEvents />,
                  },
                  {
                    path: 'resources',
                    element: <TenantStackResources />,
                    handle: {
                      breadcrumb: () => 'Resources',
                    },
                  },
                  {
                    path: 'tags',
                    element: <TenantStackTags />,
                    handle: {
                      breadcrumb: () => 'Tags',
                    },
                  },
                  {
                    path: 'outputs',
                    element: <TenantStackOutputs />,
                    handle: {
                      breadcrumb: () => 'Outputs',
                    },
                  },
                  {
                    path: 'parameters',
                    element: <TenantStackParameters />,
                    handle: {
                      breadcrumb: () => 'Parameters',
                    },
                  },
                ],
              },
              {
                path: 'summary',
                // loader: async ( { params } ) => ( {
                //   auth: await getTenantAuth( params.id ),
                //   databaseUser: await getTenantDatabaseUser( params.id ),
                //   certificate: await getTenantCertificate( params.id ),
                //   stack: await getTenantStack( params.id ),
                // } ),
                element: <TenantSummary />,
              },
              {
                path: 'manage',
                element: <TenantManage />,
                handle: {
                  breadcrumb: () => 'Manage',
                },
                children: [
                  {
                    index: true,
                    // loader: async () => redirect( 'summary' ),
                  },
                  {
                    path: 'summary',
                    element: <p>Placeholder</p>,
                  },
                  {
                    path: 'waf',
                    element: <TenantWaf />,
                    handle: {
                      breadcrumb: () => 'WAF',
                    },
                  },
                  {
                    path: 'timers',
                    // loader: async ( { params } ) => getTenantStackTimers( params.id ),
                    element: <TenantTimers />,
                    handle: {
                      breadcrumb: () => 'Timers',
                    },
                  },
                  {
                    path: 'server',
                    // loader: async ( { params } ) => getTenantStackFunction( params.id ),
                    element: <TenantLambda />,
                    handle: {
                      breadcrumb: () => 'Server',
                    },
                  },
                  {
                    path: 'admin',
                    element: <p>Placeholder</p>,

                    handle: {
                      breadcrumb: () => 'Admin',
                    },
                  },
                  {
                    path: 'app',
                    element: <p>Placeholder</p>,
                    handle: {
                      breadcrumb: () => 'App',
                    },
                  },
                ],
              },
            ],
          },

        ]
      },

    ]
  },
] );


ReactDOM.createRoot( document.getElementById( 'root' ) as HTMLElement ).render(
  <StrictMode>
    <>
      <CssBaseline />
      <HelmetProvider>
        <SuperTokensWrapper>
          <FetchProvider>
            <DataProvider>
              <ThemeProviderWithColorMode>
                <RouterProvider router={router} />
              </ThemeProviderWithColorMode>
            </DataProvider>
          </FetchProvider>
        </SuperTokensWrapper>
      </HelmetProvider>
    </>
  </StrictMode >
)


// Solves CSP unsafe-eval error
// https://github.com/exceljs/exceljs/issues/1411#issuecomment-977578401
// https://github.com/facebook/regenerator/issues/378
// @ts-ignore: 7017
globalThis.regeneratorRuntime = undefined
