import { CircularProgress } from '@mui/material';
import { FC, useCallback } from 'react';
import { useNavigate, useOutletContext, useRevalidator } from 'react-router-dom'
import { ContentLanguageContext } from './ContentLanguageContainer';
import { ContentLanguageForm, ContentLanguageFormData } from './ContentLanguageForm';
import { useData } from './data';

export const ContentLanguageEdit: FC = () => {
  const { language, inProgress, setLanguage } = useOutletContext() as ContentLanguageContext;
  const { updateContentLanguage } = useData();
  const navigate = useNavigate();
  const { revalidate } = useRevalidator();

  const onSubmit = useCallback( async ( data: ContentLanguageFormData ) => {
    if( !data?.id ) return;
    const update = await updateContentLanguage( `${ data.id }`, data );
    if( update && setLanguage ) setLanguage( update );
    navigate( '..' );
  }, [ updateContentLanguage ] )

  if( inProgress ) return <CircularProgress />;
  return (
    <ContentLanguageForm
      title='Edit Language'
      data={language}
      onSubmit={onSubmit}
    />
  )

}
