import styled from '@mui/material/styles/styled';
import MuiSvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const SvgIcon = styled( MuiSvgIcon, {
  name: 'AwsCloudFormation',
  shouldForwardProp: ( prop ) => prop !== 'fill',
} )<SvgIconProps>( () => ( {
  // fill: '#f00',
  // stroke: 'currentColor',
  // strokeLinecap: 'round',
  // strokeLinejoin: 'round',
  // strokeWidth: '2.25px',
} ) );

SvgIcon.defaultProps = {
  viewBox: '0 0 40 40',
  focusable: 'false',
  'aria-hidden': 'true',
};

export const AwsCloudFormation: FC<SvgIconProps> = ( props ) => {

  return (
    <SvgIcon {...props}>
      <title>Icon-Architecture/32/Arch_AWS-CloudFormation_32</title>
      <g id="Icon-Architecture/32/Arch_AWS-CloudFormation_32" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icon-Architecture-BG/32/Management-Governance" fill="#E7157B">
          <rect id="Rectangle" x="0" y="0" width="40" height="40"></rect>
        </g>
        <path d="M27,19.9819545 L30,19.9819545 L30,18.9804507 L27,18.9804507 L27,19.9819545 Z M14,25.9909772 L17,25.9909772 L17,24.9894735 L14,24.9894735 L14,25.9909772 Z M9,25.9909772 L12,25.9909772 L12,24.9894735 L9,24.9894735 L9,25.9909772 Z M9,22.9864659 L15,22.9864659 L15,21.9849621 L9,21.9849621 L9,22.9864659 Z M9,16.9774431 L14,16.9774431 L14,15.9759393 L9,15.9759393 L9,16.9774431 Z M9,19.9819545 L25,19.9819545 L25,18.9804507 L9,18.9804507 L9,19.9819545 Z M18.968,30.9984962 L7,30.9984962 L7,13.9729317 L18.968,13.9729317 L18.968,16.9944687 L19.968,16.9944687 L19.968,13.4721798 C19.968,13.1957648 19.745,12.9714279 19.468,12.9714279 L6.5,12.9714279 C6.223,12.9714279 6,13.1957648 6,13.4721798 L6,31.4992481 C6,31.7756632 6.223,32 6.5,32 L19.468,32 C19.745,32 19.968,31.7756632 19.968,31.4992481 L19.968,22.0019876 L18.968,22.0019876 L18.968,30.9984962 Z M34,18.4796988 C34,22.9353892 30.04,22.9864659 30,22.9864659 L22,22.9864659 L22,21.9849621 L30,21.9849621 C30.305,21.9819576 33,21.8627786 33,18.4796988 C33,15.6324235 30.431,15.0545558 29.917,14.967425 C29.66,14.9243603 29.48,14.69101 29.502,14.4316205 L29.505,14.4015754 C29.476,12.7951633 28.49,12.2924084 28.063,12.1511963 C27.264,11.8837948 26.376,12.1401798 25.905,12.7701257 C25.796,12.9173467 25.61,12.9924595 25.43,12.9664204 C25.248,12.9383783 25.095,12.8131903 25.034,12.6389287 C24.723,11.7686219 24.272,11.2047753 23.646,10.5768324 C22.077,9.01849248 19.955,8.58984886 17.963,9.42710603 C16.919,9.86676619 16.007,10.8652655 15.461,12.1642159 L14.539,11.7756324 C15.185,10.2373226 16.292,9.04453158 17.575,8.50371953 C19.954,7.50221574 22.486,8.01298267 24.352,9.8677677 C24.886,10.4035722 25.33,10.9253557 25.675,11.6023723 C26.424,11.0495422 27.427,10.8852955 28.379,11.1997677 C29.595,11.6053768 30.364,12.6609618 30.488,14.0650701 C32.19,14.4947152 34,15.8567604 34,18.4796988 L34,18.4796988 Z" id="AWS-CloudFormation_Icon_32_Squid" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="1"></path>
      </g>
    </SvgIcon >
  );
};

export default AwsCloudFormation;

