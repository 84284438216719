
export const colorPalettes = {
  discretePrimary: [ '#FF8A00', '#00BEE6', '#F6484D', '#00BBC1', '#2973EF', '#9600E5', '#18B74E' ],
  spectrumGreenRed: [ '#009933', '#00BBC1', '#F7E71E', '#FF8A00', '#E22D2D' ],
  spectrumPrimary: [ '#9600E5', '#2973EF', '#00C78E', '#FF8A00', '#F7E71E' ],
  spectrumTealRed: [ '#005763', '#00C78E', '#F7E71E', '#FF8A00', '#E22D2D' ],
};


export default colorPalettes;
