const aws = require( './aws.svg' ) as string;
import { Cloud, Dashboard as DashboardIcon, Store, Business, MedicalServices, TextSnippet, Hub } from '@mui/icons-material';
import { Box, MenuItem, MenuList, Paper, Typography, useTheme } from '@mui/material';
import { cloneElement, FC, ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { AwsLogo } from './AwsLogo';
import { LogoSupertokens } from './Logos';

export interface AppMenuItemProps {
  label: string;
  icon: ReactElement;
  to: string;
  open: boolean;
}

export const AppMenuItem: FC<AppMenuItemProps> = props => {
  const { label, icon, to, open } = props;
  const theme = useTheme();
  const { grey, mode, text } = theme.palette;

  return (
    <NavLink
      to={to}
      style={( { isActive, isPending } ) => {
        return {
          color: text.primary,
          // fontWeight: isActive ? 'bold' : '',
          backgroundColor: isActive ? grey[ mode == 'dark' ? 800 : 200 ] : undefined,
          textDecorationLine: 'none',
        };
      }}
    >
      <MenuItem
        sx={{
          flexDirection: 'column',
          color: 'inherit',
          backgroundColor: 'inherit',
        }}
      >
        <Box>
          {cloneElement( icon, { fontSize: 'large', color: 'inherit' } )}
        </Box>
        {open &&
          <Typography component='div'
            sx={{
              fontSize: '90%',
              color: 'inherit',
              overflowWrap: 'normal',
              whiteSpace: 'normal',
              wordBreak: 'normal',
              textAlign: 'center',
            }}
          >
            {label}
          </Typography>
        }
      </MenuItem>
    </NavLink >
  );
}

export interface AppMenuProps {
  open?: boolean;
  //  drawerRef?: RefObject<HTMLDivElement>;
}

export const AppMenu: FC<AppMenuProps> = props => {
  const { open = true } = props;

  return (
    <Paper
      sx={{
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
        },
      }}
    >
      <MenuList
        sx={{
          display: 'inline-block',
          maxWidth: '120px',
        }}
      >
        <AppMenuItem {...{ open }} label='Dashboard' icon={<DashboardIcon />} to='/' />
        <AppMenuItem {...{ open }} label='Content Central' icon={<Hub />} to='/content' />
        <AppMenuItem {...{ open }} label='Tenants' icon={<MedicalServices />} to='/tenants' />
        <AppMenuItem {...{ open }} label='Users' icon={<LogoSupertokens />} to='/users' />
        <AppMenuItem {...{ open }} label='Services' icon={<Store />} to='/services' />
        <AppMenuItem {...{ open }} label='Base' icon={<Cloud />} to='/base' />
        <AppMenuItem {...{ open }} label='Account' icon={<AwsLogo />} to='/account' />
      </MenuList >
    </Paper>
  );
}


