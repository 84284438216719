import { Add } from '@mui/icons-material';
import { Box, Button, CircularProgress, Paper, Toolbar, useTheme } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import { BaseContext } from './Base';
import { TenantsCosts as TenantsCostsType, useData } from './data';
import { EmrWithLogo } from './EmrWithLogo';
import { NavTabs } from './NavTabs';
import { PropGrid } from './PropGrid';
import { PropTableRecord } from './PropTable';
import { map, mapValues } from 'lodash';

export const TenantsCosts: FC = () => {
  const navigate = useNavigate();
  const [ inProgress, setInProgress ] = useState( false );
  const [ tenantsCosts, setTenantsCosts ] = useState<TenantsCostsType | undefined>();
  const { getTenants, getTenantsCosts } = useData();
  const theme = useTheme();
  const primary = theme.palette.primary.main;

  useEffect( () => {
    ( async () => {
      if( tenantsCosts ) return;
      setInProgress( true );
      // const tags = await getTenants();
      // setTenants( tags.map( tag => ( { tag } ) ) );
      const costs = await getTenantsCosts();
      setTenantsCosts( costs );
      setInProgress( false );
    } )();
  }, [ getTenants, getTenantsCosts ] );

  const records = useMemo( () => {
    if( !tenantsCosts ) return;
    return map( tenantsCosts, ( costs, tag ) => {
      return {
        _tag: tag,
        tag: (
          <Link
            // color="inherit"
            to={`../${ tag }`}
            key={tag}
            style={{
              color: primary,
              textDecorationLine: 'none',
            }}
          >
            {tag}
          </Link>
        ),
        ...mapValues( costs, v => v.toFixed( 4 ) ),
      } as PropTableRecord;
    } );
  }, [ tenantsCosts ] );

  const rowClick = useCallback( ( record: PropTableRecord ) => {
    if( !record._tag ) return;
    navigate( `../${ record._tag }` );
  }, [] );

  //   if( !tenantsCosts ) return <CircularProgress />;

  return (

    <Paper
      sx={{
        padding: '1rem',
      }}
    >

      <PropGrid label='' records={records} inProgress={inProgress} rowClick={rowClick}
        sx={{
          '& .MuiTableRow-root .MuiTableCell-root:not(:first-of-type)': {
            textAlign: 'right',
          },
          '& .MuiTableRow-root .MuiTableCell-head:not(:first-of-type) .MuiBox-root': {
            justifyContent: 'flex-end',
          },
        }}
      />

    </Paper >
  );
}


