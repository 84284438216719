import { Add as AddIcon, Delete as DeleteIcon, RestartAlt as ResetIcon } from '@mui/icons-material';
import { Box, Button, CircularProgress, Toolbar, Typography } from '@mui/material';
import { titleize } from 'inflection';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useOutletContext, useParams, useRevalidator } from 'react-router-dom';
import { ConfirmDialog } from './components/ConfirmDialog';
import { AuthConfig, AuthUser, useData } from './data';
import { PropGrid } from './PropGrid';
import { formatKey, PropTable, PropTableRecord } from './PropTable';
import { TenantContext } from './TenantContainer';


export const TenantAuth: FC = () => {
  const { id } = useParams();
  // const auth = useLoaderData() as AuthConfig;
  const { getTenantAuth } = useData();
  const [ auth, setAuth ] = useState<AuthConfig | undefined>();
  const { tenant, setNotify } = useOutletContext() as TenantContext;
  const { revalidate } = useRevalidator();
  const { tag } = tenant;
  const [ openConfirm, setOpenConfirm ] = useState<'reset' | 'delete' | ''>( '' );
  const [ inProgress, setInProgress ] = useState<'load' | 'create' | 'reset' | 'delete' | ''>( '' );
  const { createTenantAuth, resetTenantAuth, deleteTenantAuth } = useData();

  const loadAuth = useCallback( async () => {
    setAuth( undefined );
    const a = await getTenantAuth( id );
    if( !a ) return;
    setAuth( a );
  }, [ id ] );

  useEffect( () => {
    ( async () => {
      setInProgress( 'load' );
      await loadAuth();
      setInProgress( '' );
    } )();
  }, [ id, setAuth, getTenantAuth ] );

  const onCreate = useCallback( async () => {
    setInProgress( 'create' );
    await createTenantAuth( tag );
    setNotify( `Created auth config for "${ tag }"` )
    await loadAuth();
    setInProgress( '' );
  }, [ tag, createTenantAuth ] )

  const onDelete = useCallback( async () => {
    setInProgress( 'delete' );
    await deleteTenantAuth( tag );
    setNotify( `Deleted auth config for "${ tag }"` )
    await loadAuth();
    setInProgress( '' );
  }, [ tag, deleteTenantAuth ] )

  const onReset = useCallback( async () => {
    setInProgress( 'reset' );
    await resetTenantAuth( tag );
    setNotify( `Reset auth config for "${ tag }"` )
    await loadAuth();
    setInProgress( '' );
  }, [ tag, resetTenantAuth ] )

  useEffect( () => { setInProgress( '' ); }, [ auth ] ); // fixes wrong data flash before revalidate kicks in

  const { summary, roles } = useMemo( () => {
    if( !auth ) return {};
    const { roles } = auth;
    // const { scopes: rawScopes = [], ...api } = rawApi || {};
    // const scopes = Object.fromEntries( ( rawScopes as { value: string; description: string }[] ).map( s => [ s.description, s.value ] ) );
    const { providerStaff } = roles || {};
    const providerStaffRole = providerStaff?.id && providerStaff?.name ? `${ providerStaff.id } (${ providerStaff.name })` : '';
    const { users = [] } = providerStaff || {};
    const providerUsers = users.map( u => u.email ).filter( a => a ).join( ', ' );
    return {
      summary: {
        providerStaffRole,
        providerUsers,
      },
      roles,
    };
  }, [ auth ] );



  return (
    <Box>

      <PropTable label='Auth Summary' record={summary} inProgress={!!inProgress} />

      <Toolbar>
        {( !summary && !inProgress ) &&
          <Button
            startIcon={<AddIcon />}
            onClick={onCreate}
            disabled={!!inProgress}
          >
            Create auth
          </Button>
        }
        <Box sx={{ flexGrow: 1 }} />
        {summary &&
          <>
            <Button
              startIcon={<DeleteIcon />}
              color='error'
              onClick={() => setOpenConfirm( 'delete' )}
              disabled={!!inProgress}
            >
              Delete auth
            </Button>

            <Button
              startIcon={<ResetIcon />}
              //color='error'
              onClick={() => setOpenConfirm( 'reset' )}
              disabled={!!inProgress}
            >
              Reset auth
            </Button>
          </>
        }
      </Toolbar>
      <ConfirmDialog
        open={!!openConfirm}
        title={`${ titleize( openConfirm ) } Auth`}
        message={`Are you sure you want to ${ openConfirm } the auth config?`}
        confirmButton={titleize( openConfirm )}
        onClose={( confirmed ) => {
          if( confirmed ) {
            openConfirm == 'delete' ? onDelete() : onReset();
          }
          setOpenConfirm( '' );
        }}
      // fullScreen={isXSmall}
      />

      {!inProgress &&
        <>



          {Object.entries( roles || [] ).map( ( [ name, role ] ) => {
            const { users, ...rest } = role;
            return (
              <Box key={name} >
                <PropTable label={`${ formatKey( name ) } Role`} record={rest as unknown as Record<string, string>} inProgress={!!inProgress} />

                <Box sx={{ marginLeft: '2rem' }}>
                  <PropGrid label={`${ formatKey( name ) } Role Users`} records={users as PropTableRecord[]} />
                </Box>
              </Box>
            );
          } )}

          {/* <PropTable label='Auth Scopes' record={[]} inProgress={!!inProgress} /> */}

        </>
      }

      {/* inProgress &&
        <Box sx={{ textAlign: 'center', padding: '3rem' }}>
          <CircularProgress />
        </Box>
      */ }

    </Box>
  );
}


