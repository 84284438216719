import { FC } from 'react';
import { isRouteErrorResponse, useNavigate, useRouteError } from 'react-router-dom';
import { AuthPage } from './AuthPage';

type NormalError = { status: number; statusText?: string, message?: string };

export const ErrorPage: FC = () => {
  const rawError = useRouteError();
  const navigate = useNavigate();
  const error = rawError as NormalError;

  if( isRouteErrorResponse( error ) ) {
    if( error.status === 404 ) {
      return <div>This page doesn't exist!</div>;
    }

    if( error.status === 401 ) {
      // navigate( '/auth', { replace: true, state: { redirectTo: location.pathname } } );
      return <AuthPage />;
      // return <div>Redirecting...</div>;
    }

    if( error.status === 503 ) {
      return <div>Looks like our API is down</div>;
    }

    if( error.status === 429 ) {
      return <div>Our API is reporting too many requests. Try again shortly.</div>;
    }

    if( error.status === 418 ) {
      return <div>🫖</div>;
    }
  }

  return <div>Something went wrong</div>;

}
