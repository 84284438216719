import { FC, useCallback } from 'react';
import { useNavigate, useOutletContext, useRevalidator } from 'react-router-dom';
import { TenantEmrCreateFormData, useData } from './data';
import { TenantEmrContext } from './TenantEmrContainer';
import { TenantEmrForm } from './TenantEmrForm';

export const TenantEmrEdit: FC = () => {
  const { emr, tenant } = useOutletContext() as TenantEmrContext;
  const { updateTenantEmr } = useData();
  const navigate = useNavigate();
  const { revalidate } = useRevalidator();

  const onSubmit = useCallback( async ( data: TenantEmrCreateFormData ) => {
    if( !emr?.brand ) return;
    const { tag } = tenant;
    await updateTenantEmr( tag, emr.brand, data );
    revalidate();
    navigate( `..` );
  }, [ updateTenantEmr ] );

  return (
    <TenantEmrForm
      title='Edit EMR'
      data={emr}
      onSubmit={onSubmit}
    />
  );

}


