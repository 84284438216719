import { Add as CreateIcon, Clear } from '@mui/icons-material';
import { Autocomplete, Box, Button, ButtonProps, Chip, CircularProgress, IconButton, InputAdornment, LinearProgress, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { default as _, get } from 'lodash';
import { FC, MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ContentTextsContext } from './ContentTextsContainer';
import { ContentText, ContentTextTranslations, useData } from './data';
import { useCachedState } from './components/use-cached-state';
import { LanguageField, LanguageSelect } from './LanguageSelect';
import { promisedSleep } from './promised-sleep';
import { PropGridWithPaging } from './PropGrid';
import { PropTableRecord } from './PropTable';

export const ContentTexts: FC = () => {
  const { languages, setNotify } = useOutletContext<ContentTextsContext>();
  const { getContentTexts, getContentTenantsTexts, getContentTextsTranslations, translateContentText } = useData();
  const navigate = useNavigate();
  const [ lang, setLang ] = useState<string>( 'en' );
  const [ variableName, setVariableName ] = useCachedState<string>( 'variableName', '' );
  const [ tenantMods, setTenantMods ] = useState<Record<string, { tag: string, value: string }[]> | undefined>();
  const [ query, setQuery ] = useCachedState<string>( 'q', '' );
  const [ queryInput, setQueryInput ] = useState<string>( query );

  useEffect( () => {
    ( async () => {
      const mods = await getContentTenantsTexts( {
        'filter[lang]': lang,
        'page[size]': '300',
      } );
      const mods2 = _( mods )
        .toPairs()
        .flatMap( ( [ tag, v ] ) => v.map( v => ( { tag, id: v.id, value: v.value } ) ) )
        .groupBy( v => v.id )
        .mapValues( v => v.map( v => ( { tag: v.tag, value: v.value } ) ) )
        .value();
      setTenantMods( mods2 );
    } )();
  }, [ lang ] );

  const onTranslateClick = async ( id: string ) => {
    setNotify( `Translating ${ id.split( ':' )[ 1 ] || '' }` );
    await translateContentText( id );
  };

  const getSortValue = ( key: string ) => {
    const replacements: Record<string, string> = {
      updated: 'updatedAt',
    };
    const direction = key.startsWith( '-' ) ? '-' : '';
    const value = key.replace( /^-/, '' );
    return direction + ( replacements[ value ] || value );
  }
  const debouncedSetSearch = useCallback( _.debounce( setQuery, 500 ), [] );
  useEffect( () => debouncedSetSearch( queryInput ), [ queryInput ] );

  interface TranslateButtonProps {
    record: ContentText;
    allTranslations: ContentTextTranslations[];
  }

  const TranslateButton: FC<TranslateButtonProps> = props => {
    const { record, allTranslations } = props;
    const { id } = record;

    const [ isBusy, setIsBusy ] = useState( false );
    //use state for stats in order to clear them wo a page reload, assuming that a translate finished
    const [ stats, setStats ] = useState<ContentTextTranslations | undefined>();
    useEffect( () => { if( !id ) return; setStats( allTranslations.find( t => t?.id == id ) ); }, [ id ] );

    const { translationsText, translationsColor } = useMemo( () => {
      const { total = 0, available = 0, current = 0 } = stats || {};
      return {
        translationsText: available < total ? `${ total - available } missing` : current < available ? `${ available - current } stale` : '',
        translationsColor: available < total ? 'error' : current < available ? 'info' : undefined as ButtonProps[ 'color' ],
      };
    }, [ stats ] );

    const handleClick: MouseEventHandler<HTMLButtonElement> = ( e ) => {
      e.stopPropagation();
      setIsBusy( true );
      setStats( undefined );
      ( async () => {
        await onTranslateClick( id )
        setIsBusy( false );
      } )();
    }

    // Let a busy display until the stats are cleared
    if( isBusy ) return <CircularProgress size='1.2rem' />;
    if( !translationsText ) return null;

    return (
      <Tooltip title={`Click to translate ${ translationsText }`}>
        <Button
          color={translationsColor}
          onClick={handleClick}
          variant='contained'
          size='small'
          fullWidth
          disabled={isBusy}
        >
          {translationsText}
        </Button>
      </Tooltip>
    );
  }

  const fetchRecords = useCallback( async ( page: number, pageSize: number, sort?: string ) => {
    if( !languages.length ) return { records: [] };
    const params: Record<string, string> = {
      'filter[lang]': lang,
      'page[number]': `${ page + 1 }`, // 1-indexed pagination
      'page[size]': `${ pageSize }`,
    };
    if( sort ) params[ 'sort' ] = getSortValue( sort );
    if( query ) params[ 'filter[q]' ] = query;
    if( variableName ) params[ 'filter[name]' ] = variableName;
    const [ { total, data }, allTranslations ] = await Promise.all( [
      getContentTexts( params ),
      lang == 'en' ? getContentTextsTranslations() : Promise.resolve( [] ),
    ] )
    const records = data.map( record => {
      const { id, lang: language, name, value, updatedAt: updated } = record;
      const mods = get( tenantMods, id, [] ).length;
      const diffs = get( tenantMods, id, [] ).filter( v => v.value != value );

      return ( {
        ...{
          _id: id,
          language: <LanguageField lang={language} languages={languages} />,
        },
        ...{
          mods: mods
            ? (
              <Tooltip title={get( tenantMods, id, [] ).map( v => v.tag ).join( ', ' )}>
                <Typography>
                  {mods}
                </Typography>
              </Tooltip>
            )
            : mods === undefined
              ? <LinearProgress />
              : <Typography>-</Typography>,
          diffs: diffs.length
            ?
            <Typography>
              {diffs.length}
            </Typography>
            : diffs === undefined
              ? <LinearProgress />
              : <Typography>-</Typography>,
          tags: <>{diffs.map( v => <Chip key={v.tag} label={v.tag} /> )}</>,
          name: name.length != name.trim().length ? `"${ name }"` : name,
          value: <Typography sx={{ maxWidth: '20rem' }}>{value}</Typography>,
          updated: updated ? formatDistanceToNow( new Date( updated ) ) : '',
        },

        ...( lang == 'en'
          ? {
            translations: (
              <TranslateButton record={record} allTranslations={allTranslations} />
            ),
          }
          : {} ),


      } )
    } );
    return { records, total };
  }, [ getContentTexts, languages, lang, tenantMods, query, variableName ] );

  const rowClick = useCallback( ( record: PropTableRecord ) => {
    if( !record._id ) return;
    navigate( `./${ encodeURIComponent( record._id as string ) }` );
  }, [] );

  const onChangeLang = useCallback( ( lang: string ) => {
    if( !lang ) return;
    setLang( lang );
  }, [ setLang ] );

  return (
    <Box>
      <Toolbar>
        <LanguageSelect languages={languages} lang={lang || ''} onChange={( e ) => onChangeLang( e.target.value )} />
        <Box width='1em' />
        <VariableNameField value={variableName} onChange={setVariableName} />
        <Box width='1em' />
        <TextField
          value={queryInput}
          onChange={( e ) => setQueryInput( e.target.value )}
          label='Search'
          size='small'
          sx={{
            minWidth: '30rem',
            maxHeight: '80%',
          }}
          InputProps={{
            endAdornment: queryInput && (
              <InputAdornment position='end'>
                <IconButton onClick={() => setQueryInput( '' )}>
                  <Clear />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Box sx={{ flexGrow: 1 }} />
        <Button
          startIcon={<CreateIcon />}
          onClick={() => navigate( 'create' )}
        // disabled={true}
        >
          create
        </Button>
      </Toolbar>

      <PropGridWithPaging
        label={''}
        fetchRecords={fetchRecords}
        rowClick={rowClick}
        sx={{
          '& .column-mods': {
            textAlign: 'end',
          },
          '& .column-diffs': {
            textAlign: 'end',
          },
        }}
      />
    </Box>
  );

}

export interface VariableNameFieldProps {
  value: string;
  onChange: ( value: string ) => void;
}
export const VariableNameField: FC<VariableNameFieldProps> = props => {
  const { value, onChange } = props;
  const { getContentTexts } = useData();
  const [ options, setOptions ] = useState<string[]>( [] );

  useEffect( () => {
    ( async () => {
      const { data } = await getContentTexts( {
        'filter[lang]': 'en',
        'page[size]': '500',
        'sort': 'name',
      } );
      setOptions( data.map( v => v.name ) );
    } )();
  }, [] );

  const handleChange = useCallback( ( _e: unknown, newValue: string | null ) => {
    onChange( newValue || '' );
  }, [ onChange ] );
  return (
    <Autocomplete
      value={value || null}
      onChange={handleChange}
      options={options}
      renderInput={params => <TextField {...params} label='Variable Name' />}
      size='small'
      sx={{
        minWidth: '20rem',
        maxHeight: '80%',
      }}
    />
  );
}
