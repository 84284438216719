import styled from '@mui/material/styles/styled';
import MuiSvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const SvgIcon = styled( MuiSvgIcon, {
  name: 'AwsLambda',
  shouldForwardProp: ( prop ) => prop !== 'fill',
} )<SvgIconProps>( () => ( {
  // fill: '#f00',
  // stroke: 'currentColor',
  // strokeLinecap: 'round',
  // strokeLinejoin: 'round',
  // strokeWidth: '2.25px',
} ) );

SvgIcon.defaultProps = {
  viewBox: '0 0 40 40',
  focusable: 'false',
  'aria-hidden': 'true',
};

export const AwsLambda: FC<SvgIconProps> = ( props ) => {

  return (
    <SvgIcon {...props}>
      <g id="Icon-Architecture/32/Arch_AWS-Lambda_32" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icon-Architecture-BG/32/Compute" fill="#ED7100">
          <rect id="Rectangle" x="0" y="0" width="40" height="40"></rect>
        </g>
        <path d="M14.3863762,33 L8.26957323,33 L15.032628,18.574 L18.0969223,25.014 L14.3863762,33 Z M15.4706649,17.202 C15.3891468,17.03 15.2172714,16.92 15.0286994,16.92 L15.0267351,16.92 C14.8391453,16.921 14.6672698,17.032 14.5857517,17.205 L7.04778408,33.285 C6.9751053,33.439 6.9859089,33.622 7.0762663,33.767 C7.16564155,33.912 7.32278485,34 7.49073175,34 L14.6967342,34 C14.8872704,34 15.0601281,33.889 15.1416462,33.714 L19.085943,25.225 C19.1497824,25.088 19.1488003,24.929 19.0839787,24.793 L15.4706649,17.202 Z M32.0178544,33 L26.1357842,33 L16.6669183,12.289 C16.5863824,12.113 16.4125426,12 16.2220063,12 L12.3700312,12 L12.3749419,7 L19.9237132,7 L29.3483826,27.71 C29.4289185,27.887 29.6037404,28 29.7942767,28 L32.0178544,28 L32.0178544,33 Z M32.5089272,27 L30.1085633,27 L20.6829118,6.29 C20.6023758,6.113 20.4275539,6 20.2370176,6 L11.8838691,6 C11.6127969,6 11.3927963,6.224 11.3927963,6.5 L11.3869034,12.5 C11.3869034,12.632 11.4389572,12.759 11.5312788,12.854 C11.6236005,12.947 11.7473509,13 11.8779762,13 L15.909684,13 L25.3775678,33.711 C25.4581038,33.887 25.6319435,34 25.8234619,34 L32.5089272,34 C32.7809815,34 33,33.776 33,33.5 L33,27.5 C33,27.224 32.7809815,27 32.5089272,27 L32.5089272,27 Z" id="AWS-Lambda_Icon_32_Squid" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="1"></path>
      </g>

    </SvgIcon >
  );
};

export default AwsLambda;

