import { Clear } from '@mui/icons-material';
import { Box, Chip, IconButton, InputAdornment, LinearProgress, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import _, { get } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useData } from './data';
import { useCachedState } from './components/use-cached-state';
import { PropGridWithPaging } from './PropGrid';
import { PropTableRecord } from './PropTable';

export const ContentTwimls: FC = () => {
  const { getContentTwimls, getContentTenantsTwimls } = useData();
  const navigate = useNavigate();
  const [ tenantMods, setTenantMods ] = useState<Record<string, { tag: string, value: string }[]> | undefined>();
  const [ query, setQuery ] = useCachedState<string>( 'q', '' );
  const [ queryInput, setQueryInput ] = useState<string>( query );

  useEffect( () => {
    ( async () => {
      const mods = await getContentTenantsTwimls( {
        'page[size]': '300',
      } );
      const mods2 = _( mods )
        .toPairs()
        .flatMap( ( [ tag, v ] ) => v.map( v => ( { tag, id: v.id, value: v.value } ) ) )
        .groupBy( v => v.id )
        .mapValues( v => v.map( v => ( { tag: v.tag, value: v.value } ) ) )
        .value();
      setTenantMods( mods2 );
    } )();
  }, [] );

  const debouncedSetSearch = useCallback( _.debounce( setQuery, 500 ), [] );
  useEffect( () => debouncedSetSearch( queryInput ), [ queryInput ] );

  const fetchRecords = useCallback( async ( page: number, pageSize: number, sort?: string ) => {
    const params: Record<string,string> = {
      'page[number]': `${page + 1}`, // 1-indexed pagination
      'page[size]': `${pageSize}`,
    };
    if( sort ) params[ 'sort' ] = sort;
    if( query ) params[ 'filter[q]'] = query;
    const { total, data: contentTwimls } = await getContentTwimls( params );
    const records = contentTwimls.map( record => {
      const { id, value, comment, updatedAt } = record;
      const mods = get( tenantMods, id, [] ).length;
      const diffs = get( tenantMods, id, [] ).filter( v => v.value != value );
      return ( {
        _tag: id,
        name: id,
        mods: mods
          ? (
            <Tooltip title={get( tenantMods, id, [] ).map( v => v.tag ).join( ', ' )}>
              <Typography>
                {mods}
              </Typography>
            </Tooltip>
          )
          : mods === undefined
            ? <LinearProgress />
            : <Typography>-</Typography>,
        diffs: diffs.length
          ?
          <Typography>
            {diffs.length}
          </Typography>
          : diffs === undefined
            ? <LinearProgress />
            : <Typography>-</Typography>,
        tags: <>{diffs.map( v => <Chip key={v.tag} label={v.tag} /> )}</>,
        comment,
        updated: updatedAt,
      } );
    } );
    return { records, total };
  }, [ tenantMods, query ] );

  const rowClick = useCallback( ( record: PropTableRecord ) => {
    if( !record._tag ) return;
    navigate( `./${ record._tag }` );
  }, [] );

  return (
    <Box>
      <Toolbar>
        <TextField
          value={queryInput}
          onChange={ ( e ) => setQueryInput( e.target.value ) }
          label='Search'
          size='small'
          sx={{
            minWidth: '30rem',
            maxHeight: '80%',
          }}
          InputProps={{
            endAdornment: queryInput && (
              <InputAdornment position='end'>
                <IconButton onClick={() => setQueryInput( '' )}>
                  <Clear />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Box sx={{ flexGrow: 1 }} />
      </Toolbar>
      <PropGridWithPaging
        label={''}
        fetchRecords={fetchRecords}
        rowClick={rowClick}
        sx={{
          '& .column-mods': {
            textAlign: 'end',
          },
          '& .column-diffs': {
            textAlign: 'end',
          },
        }}
      />
    </Box>
  );

}
