import { Box, CircularProgress, SxProps, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { sortBy } from 'lodash';
import { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { formatKey, formatValue, PropTableRecord } from './PropTable';
import { useCachedState } from './components/use-cached-state';

export interface PropGridProps {
  records?: PropTableRecord[];
  label: string;
  emptyLabel?: string;
  unsort?: boolean;
  inProgress?: boolean;
  rowClick?: ( record: PropTableRecord ) => void;
  verticalAlign?: 'top' | 'middle' | 'bottom';
  sx?: SxProps;
  onSortChange?: ( sortKey: string ) => void;
  sortBy?: string;
  footer?: ReactElement;
}

export const PropGrid: FC<PropGridProps> = props => {
  const { records, sx, label, emptyLabel, unsort, inProgress, rowClick, verticalAlign = 'top', footer } = props;
  const allKeys = ( records || [] ).flatMap( r => Object.keys( r ) )
    .filter( k => k[ 0 ] != '_' ); // use leading underscore on key to pass record info wo displaying, eg  "_tag"
  const keys = Array.from( new Set( allKeys ) );
  const unsortableKeys = [ 'mods', 'language' ];

  const sorted = useMemo( () => unsort || !records ? records : sortBy( records || [], r => r[ keys[ 0 ] ] ), [ records, unsort, inProgress ] );

  return (
    <Box sx={sx}>
      {label &&
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: '110%',
            marginBottom: '1rem',
          }}
        >
          {label}
        </Typography>
      }
      <Box
        sx={{
          position: 'relative',
          minHeight: inProgress ? '12rem' : undefined,
        }}
      >
        {inProgress &&
          <Box sx={{
            textAlign: 'center', padding: '3rem',
            position: 'absolute', zIndex: 10,
            top: '10%',
            left: '30%',
          }}>
            <CircularProgress size='2rem' />
          </Box>
        }


        <Table size='small' padding='normal'
          sx={{
            '& .MuiTableCell-head': {
              fontSize: '1rem',
              fontWeight: 'bold',
            },
            '& .MuiTableCell-body': {
              fontSize: '1rem',
              opacity: inProgress ? 0.2 : undefined,
              borderBottomWidth: 0,
              paddingBottom: '1rem',
              paddingRight: '1rem',
              // fontWeight: 'bold',
              verticalAlign,
              // '&:first-of-type': {
              //     fontWeight: 'inherit',
              //     width: '6rem',
              //     // color: 'red',
              //   },
            },
          }}
        >
          <TableHead>
            <TableRow >
              {keys.map( key => (
                unsortableKeys.includes( key )
                  ? <TableCell
                    key={key}
                    className={`column-${ key }`}
                  >
                    {formatKey( key )}
                  </TableCell>
                  : <TableCell
                    key={key}
                    className={`column-${ key }`}
                    onClick={() => !unsortableKeys.includes( key ) && props.onSortChange && props.onSortChange( key )}
                  >
                    <Tooltip key={key} title={`Sort by ${ formatKey( key ) }`} placement='top-start'>
                      <Box display='flex' flexDirection='row' alignItems='center'>
                        {formatKey( key )}
                        {props.sortBy?.replace( /^-/, '' ) === key && ( props.sortBy.startsWith( '-' ) ? <ArrowDownward /> : <ArrowUpward /> )}
                      </Box>
                    </Tooltip>
                  </TableCell>
              ) )}
            </TableRow >
          </TableHead>
          <TableBody
            sx={{
              '& .MuiTableRow-root:first-of-type .MuiTableCell-root': {
                paddingTop: '1rem',
              }
            }}
          >
            {sorted
              ? (
                sorted.length === 0
                  ? ( emptyLabel ? <TableCell>{emptyLabel}</TableCell> : null )
                  : sorted.map( ( record, idx ) => (
                    <TableRow
                      key={idx}
                      onClick={() => rowClick && rowClick( record )}
                    >
                      {keys.map( key => (
                        <TableCell key={key} className={`column-${ key }`}  >{formatValue( record[ key ] )}</TableCell>
                      ) )}
                    </TableRow>
                  ) )
              )
              : !inProgress
                ? (
                  <TableRow>
                    <TableCell
                    >
                      <CircularProgress size='2rem' />
                    </TableCell>
                  </TableRow>
                )
                : null
            }
          </TableBody>
          {footer &&
            <TableFooter>
              <TableRow>
                {footer}
              </TableRow>
            </TableFooter>
          }
        </Table>
      </Box >
    </Box >
  )
}

export interface PropGridWithPagingProps extends Omit<PropGridProps, 'records' | 'inProgress'> {
  fetchRecords: ( page: number, pageSize: number, sort?: string ) => Promise<{ records: PropTableRecord[], total?: number }>;
}
export const PropGridWithPaging: FC<PropGridWithPagingProps> = props => {
  const { fetchRecords, ...rest } = props;
  const [ records, setRecords ] = useState<PropTableRecord[]>( [] );
  const [ count, setCount ] = useState<number>( -1 );
  const [ page, setPage ] = useCachedState<number>( 'page', 0 );
  const [ pageSize, setPageSize ] = useCachedState<number>( 'pageSize', 10 );
  const [ inProgress, setInProgress ] = useState<boolean>( false );
  const [ sortBy, setSortBy ] = useCachedState<string>( 'sortBy', 'id' );

  const updatePage = useCallback( ( newPage: number, newPageSize?: number ) => {
    setPage( newPage );
    if( newPageSize ) setPageSize( newPageSize );
  }, [ pageSize ] )

  const onPageChange = useCallback( async ( _event: unknown, newPage: number ) => {
    updatePage( newPage );
  }, [ fetchRecords, pageSize ] );
  const onRowsPerPageChange = useCallback( ( event: React.ChangeEvent ) => {
    const target = event.target as unknown as { value: number };
    updatePage( 0, target.value );
  }, [ onPageChange ] );

  useEffect( () => {
    ( async () => {
      setInProgress( true );
      const { records: newRecords, total } = await fetchRecords( page, pageSize, sortBy );
      setRecords( newRecords );
      if( total !== undefined ) setCount( total );
      setInProgress( false );
      if( newRecords.length < pageSize ) {
        setCount( page * pageSize + newRecords.length );
        // if the real final page is full, nextPage will be empty, so go back one page
        if( page > 0 && newRecords.length == 0 ) setPage( page - 1 );
      }
    } )();
  }, [ fetchRecords, page, pageSize, sortBy ] );

  return (
    <Box>
      <PropGrid
        {...rest}
        records={records}
        inProgress={inProgress}
        unsort
        onSortChange={sortBy => {
          setSortBy( prev => sortBy === prev ? `-${ sortBy }` : sortBy );
        }}
        sortBy={sortBy}
        footer={(
          <TablePagination
            count={count}
            onPageChange={onPageChange}
            page={page}
            rowsPerPage={pageSize}
            onRowsPerPageChange={onRowsPerPageChange}
            sx={{ marginRight: 0, marginLeft: 'auto' }}
          />
        )
        }
      />
    </Box>
  );
}
