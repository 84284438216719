import { AppBar as MuiAppBar, Box, Breadcrumbs, CssBaseline, Drawer, Grid, IconButton, MenuItem, MenuList, Paper, SvgIcon, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Toolbar, Typography, useTheme } from '@mui/material';
import { get } from 'lodash';
import { Dashboard as DashboardIcon, Email, Settings as SettingsIcon, Person, Store, Cloud, Menu as MenuIcon } from '@mui/icons-material';
import { FC, ReactElement, useMemo, cloneElement, useState, RefObject, useRef, Dispatch, SetStateAction } from 'react';
import { Link, NavLink, Outlet, useLoaderData, useMatches, useOutletContext } from 'react-router-dom';
import { LocBreadcrumbs } from './Breadcrumb';
import { BaseInterface } from './data';
import { prettyDate } from './lib/pretty';
import { NavTabs } from './NavTabs';


export interface BaseContext {
  base: BaseInterface;
  timeZoneName: string;
  setNotify: Dispatch<SetStateAction<string>>;
  setError: Dispatch<SetStateAction<string>>;
}

export const Account: FC = () => {
  const context = {}; // useOutletContext() as BaseContext;

  const tabs = [
    { label: 'Info', to: 'info' },
    { label: 'Certificates', to: 'certificates' },
    { label: 'Redirect Domains', to: 'redirect-domains' },
  ];

  return (
    <Box>
      <NavTabs tabs={tabs} />

      <Paper
        sx={{
          padding: '1rem',
        }}
      >
        <Outlet
          context={context}
        />
      </Paper>
    </Box>
  );
}


