import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthUserCreate, useData } from './data';
import { UsersStaffForm } from './UsersStaffForm';

export const UsersStaffCreate: FC = () => {
  const { createUser } = useData();
  const navigate = useNavigate();

  const onSubmit = useCallback( async ( data: AuthUserCreate ) => {
    await createUser( data );
    navigate( `..` );
  }, [ createUser ] );


  return (
    <UsersStaffForm
      title='Create user'
      onSubmit={onSubmit}
    />
  );

}


