import { Check, Close } from '@mui/icons-material';
import { Box } from '@mui/material';
import { pick } from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { CertificateStatus } from './CertificateStatus';
import { useData } from './data';
import { PropGrid } from './PropGrid';
import { PropTableRecord } from './PropTable';


export const AccountCertificates: FC = () => {
  const { getCertificates } = useData();
  const [ certificates, setCertificates ] = useState<PropTableRecord[] | undefined>();

  useEffect( () => {
    ( async () => {
      const c = await getCertificates();
      if( !c ) return;
      setCertificates( c as PropTableRecord[] );
    } )()
  }, [ getCertificates, setCertificates ] );

  // if( !certificates ) return null;
  const fields = 'InUse DomainName Status  CreatedAt'.split( / +/ );
  const records = useMemo( () => {
    return certificates?.map( c => pick( c, fields ) )
      .map( c => {
        c.InUse = c.InUse ? <Check color='primary' /> : <Close color='error' />;
        c.Status = <CertificateStatus Status={c.Status as string | undefined} />;
        return c;
      } )
  }, [ certificates ] );

  return (
    <Box>
      <PropGrid label='Certificates' records={records} />
    </Box>

  );
}


