import { Box } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { TenantEmr, useData } from './data';
import { TenantContext } from './TenantContainer';

export interface TenantEmrsContext extends TenantContext {
  emrs?: TenantEmr[];
  loadEmrs: () => void;
  inProgress: boolean;
  setInProgress: ( b: boolean ) => void;
}

export const TenantEmrsContainer: FC = () => {
  const { id } = useParams();
  const { getTenantEmrs } = useData();
  const [ inProgress, setInProgress ] = useState( false );
  const [ emrs, setEmrs ] = useState<TenantEmr[] | undefined>();

  const tenantContext = useOutletContext() as TenantContext;

  const loadEmrs = useCallback( async () => {
    setEmrs( await getTenantEmrs( id ) );
  }, [ id ] );

  useEffect( () => {
    ( async () => {
      setInProgress( true );
      await loadEmrs();
      setInProgress( false );
    } )();
  }, [ id, setEmrs ] );

  // if( !emrs ) return null;

  const context: TenantEmrsContext = {
    ...tenantContext,
    emrs, loadEmrs, inProgress, setInProgress,
  };

  return (
    <Box>
      <Outlet
        context={context}
      />
    </Box>
  );

}


