import { CertificateStatus as CertificateStatusType } from '@aws-sdk/client-acm';
import { CheckCircleOutline, HighlightOff, InfoOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { FC } from 'react';

//     readonly EXPIRED: "EXPIRED";
//     readonly FAILED: "FAILED";
//     readonly INACTIVE: "INACTIVE";
//     readonly ISSUED: "ISSUED";
//     readonly PENDING_VALIDATION: "PENDING_VALIDATION";
//     readonly REVOKED: "REVOKED";
// readonly VALIDATION_TIMED_OUT: "VALIDATION_TIMED_OUT";

export const CertificateStatus: FC<{ Status?: string | CertificateStatusType }> = ( { Status = '' } ) => {
  const color = Status.match( /EXPIRED|FAILED|INACTIVE|REVOKED|VALIDATION_TIMED_OUT/ ) ? 'error' : Status.includes( 'ISSUED' ) ? 'green' : 'blue';
  const icon = Status.match( /EXPIRED|FAILED|INACTIVE|REVOKED|VALIDATION_TIMED_OUT/ ) ? <HighlightOff /> : Status.includes( 'ISSUED' ) ? <CheckCircleOutline /> : <InfoOutlined />;
  return (
    <Typography
      color={color}
      fontSize='90%'
      sx={{ ' .MuiSvgIcon-root': { verticalAlign: 'bottom' } }}
    >
      {icon}&nbsp;{Status}
    </Typography>
  )
}

