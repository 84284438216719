import { Box, Typography, alpha, useTheme, SelectProps, MenuItem, Select, BoxProps, BaseSelectProps } from '@mui/material';
import { FC, forwardRef, RefAttributes, useCallback, useEffect, useMemo, useState } from 'react';
import { ContentLanguage, useData } from './data';

export const LanguageBox: FC<{ lang: ContentLanguage, i?: number }> = ( { lang, i } ) => {
  const theme = useTheme();
  return (
    <Box>
      <Box pr={1} component='span' >
        {lang.svg
          ? <img src={lang.svg} height='14' style={{ border: `1px solid ${ theme.palette.divider }` }} />
          : <Box sx={{ height: 14, width: 14 * 4 / 3, border: `1px solid ${ theme.palette.divider }`, display: 'inline-block' }} />
        }
      </Box>
      {lang.nativeName} {lang.name.toLowerCase() == lang.nativeName?.toLowerCase() ? '' : `/ ${ lang.name }`}
      <Box sx={{ flexGrow: 1 }} component='span' />
      <Typography pl={1} component='span' sx={{ color: alpha( theme.palette.text.primary, 0.5 ), fontSize: '80%' }}>{lang.id}</Typography>
      {i !== undefined &&
        <Typography pl={1} component='span' sx={{ color: alpha( theme.palette.text.primary, 0.5 ), fontSize: '80%' }}>{i}</Typography>
      }
    </Box>
  );
}

export interface LanguageSelectProps {
  onChange?: SelectProps<string>[ 'onChange' ];
  lang: string;
  languages: ContentLanguage[];
}

export const LanguageSelect: FC<LanguageSelectProps> = props => {
  const { onChange, lang, languages } = props;

  const langs = useMemo( () => {
    if( !languages ) return;
    const langs = [ ...languages ];
    const i = langs.findIndex( l => l.id == 'en' );
    if( i < 0 ) return langs;
    const en = langs.splice( i, 1 );
    return [ ...en, ...langs ];
  }, [ languages ] );

  const makeOption = useCallback( ( l: ContentLanguage, i?: number ) => (
    <MenuItem key={l.id} value={l.id} disabled={!l.isEnabled}>
      <LanguageBox lang={l} i={i} />
    </MenuItem >
  ), [] );

  const options = useMemo( () => ( langs || [] ).map( ( l, i ) => makeOption( l, i + 1 ) ), [ makeOption, langs ] );

  if( !langs?.length ) return null;

  return (
    <Select
      labelId="select-label"
      id="select"
      defaultValue={lang || 'en'}
      value={lang}
      label="Language"
      onChange={( e, c ) => onChange && onChange( e, c )}
      size='small'
      sx={{
        minWidth: '20rem',
      }}
      MenuProps={{
        sx: {
          maxHeight: '80%',
        }
      }}
    >
      {options}
    </Select>
  );
}

export interface LanguageInputProps extends BaseSelectProps {
  languages: ContentLanguage[];
}

export const LanguageInput = forwardRef<HTMLSelectElement, LanguageInputProps>( ( props, ref ) => {
  const { languages } = props;

  const choices = useMemo( () => {
    if( !languages ) return;
    const langs = [ ...languages ];
    const i = langs.findIndex( l => l.id == 'en' );
    const en = langs.splice( i, 1 );
    if( i >= 0 ) {
      langs.unshift( ...en )
    }

    return langs.map( l => ( {
      id: l.id,
      name: l.name,
      lang: l,
    } ) );
  }, [ languages ] );

  if( !choices?.length ) return null;

  return (
    <Select
      {...props}
      ref={ref}
      sx={{
        minWidth: '20rem',
      }}
    >
      {choices.map( choice => (
        <MenuItem value={choice.id} key={choice.id} >
          <LanguageBox lang={choice.lang} />
        </MenuItem>
      ) )}
    </Select>
  );
} );

export interface LanguageFieldProps extends BoxProps {
  lang: string;
  languages: ContentLanguage[];
}
export const LanguageField: FC<LanguageFieldProps> = props => {
  const { lang, languages, ...rest } = props;
  const language = languages.find( l => l.id == lang );
  return (
    <Box {...rest}>
      {language ? <LanguageBox lang={language} /> : <Typography>{lang}</Typography>}
    </Box>
  );
}

