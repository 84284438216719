import { FC, useCallback } from 'react';
import { useLoaderData, useNavigate, useOutletContext, useRouteLoaderData, useParams, useRevalidator } from 'react-router-dom';
import { BaseContext } from './Base';
import { ConfigForm } from './ConfigForm';
import { ConfigProp, ConfigPropForm } from './ConfigPropForm';
import { TenantCore, TenantCreateFormData, TenantEnvironment, useData } from './data';
import { TenantSecretContext } from './TenantSecretContainer';
import { TenantContext } from './TenantContainer';


const possibleKeys = 'EMR_PASSWORD'.split( / +/ );

export const TenantSecretCreate: FC = () => {
  const { id: tag } = useParams();
  const { envSecret } = useOutletContext() as TenantSecretContext;
  const { updateTenantEnvSecret } = useData();
  const navigate = useNavigate();
  const { revalidate } = useRevalidator();

  const onCancel = useCallback( () => {
    navigate( '..' );
  }, [ navigate ] );

  const onSubmit = useCallback( async ( data: ConfigProp ) => {
    if( !tag ) return;
    await updateTenantEnvSecret( tag, { [ data.key ]: data.value } );
    revalidate();
    navigate( '..' );
  }, [ tag, updateTenantEnvSecret ] );

  if( !envSecret ) return null;

  return (

    <ConfigPropForm
      title='Create secret prop'
      data={envSecret}
      possibleKeys={possibleKeys}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );

}


