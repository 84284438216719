import styled from '@mui/material/styles/styled';
import MuiSvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const SvgIcon = styled( MuiSvgIcon, {
  name: 'LogoModMed',
  shouldForwardProp: ( prop ) => prop !== 'fill',
} )<SvgIconProps>( () => ( {
  // fill: '#f00',
  // stroke: 'currentColor',
  // strokeLinecap: 'round',
  // strokeLinejoin: 'round',
  // strokeWidth: '2.25px',
} ) );

SvgIcon.defaultProps = {
  viewBox: '62 236 110 180',
  focusable: 'false',
  'aria-hidden': 'true',
};
//       <style type="text/css">
//         .st0{fill:#FFFFFF;}
//         .st1{fill:#582D82;}
//         .st2{fill:#FFC425;}
//         .st3{fill:#C1C5C8;}
//         .st4{fill:#A3AAAD;}
// </style>

export const LogoModMed: FC<SvgIconProps> = ( props ) => {

  const { fill = 'rgb(248, 153, 29)', ...rest } = props;

  return (
    <SvgIcon {...rest}>
      <path fill="#FFFFFF" d="M111.1,390.4c-0.1,0-0.2,0-0.3,0c-7-0.3-8.7-6.2-12.1-18c-2-6.7-4.4-15.9-7.3-27.5c-1.2-4.9-2.4-9.8-3.6-14.5
	c-1.2,6.2-2.4,12.2-3.2,17c-0.8,4-1.5,7.8-2.1,10.9c-1.6,8.1-10.6,8.8-14,9.1c-4.6,0.4-11,0.3-20.7,0.3c-3.7,0,0.4,0-4.2,0l-7.3-16
	c4.7,0,7.9-0.1,11.6-0.1c8,0,15.2,0.1,19.2-0.2c0.4-2.1,0.8-4.7,1.3-7c1.9-10.3,4.4-23.2,6.5-33.2c1.2-5.6,2.2-9.8,3-12.8
	c1.1-4.1,3-10.9,10-10.9c3.6,0,7.3,2.4,8.6,7.5c2,7.3,8.1,35.3,13.8,56.9c3.8-16,8.5-36.1,12.2-51.8c3.8-16.1,6.8-28.8,7.8-32.9
	c2.1-8.5,6.7-10.2,10.2-10.1c8,0.2,10.1,9,12,16.8c1.6,6.8,3.4,16.2,5.6,27.2c0.7,3.8,1.5,7.9,2.3,12.1c2.5,12.5,3.7,20.5,4.5,25.7
	c0.1,0.7,0.2,1.4,0.3,2.1H200l-4.8,16.5h-34.8c-9.5,0-10.6-7.1-12-16c-0.8-5.1-2-12.9-4.4-25.1c-0.8-4.2-1.6-8.3-2.4-12.1
	c-0.5-2.5-1-5.2-1.5-7.7c-0.6,2.4-1.2,4.9-1.8,7.5c-9.7,41.3-17.9,75.9-19.1,80.3C118.3,387.8,114.9,390.4,111.1,390.4"/>
      <path fill="#582D82" d="M160.4,357.2c-9.5,0-10.6-7.1-12-16c-0.8-5.1-2-12.9-4.4-25.1c-0.8-4.2-1.6-8.3-2.4-12.1
	c-0.5-2.5-1-5.2-1.5-7.7c-0.6,2.4-1.2,4.9-1.8,7.5c-9.7,41.3-17.9,75.9-19.1,80.3c-1.1,3.8-4.4,6.3-8.2,6.3c-0.1,0-0.2,0-0.3,0
	c-7-0.3-8.7-6.2-12.1-18c-2-6.7-4.4-15.9-7.3-27.5c-1.2-4.9-2.4-9.8-3.6-14.5c-1.2,6.2-2.4,12.2-3.2,17c-0.8,4-1.5,7.8-2.1,10.9
	c-1.6,8.1-10.6,8.8-14,9.1c-4.6,0.4-11,0.3-20.7,0.3c-3.7,0,0.4,0-4.2,0l-4.9-10.8c-0.2,0.4-0.1,1.1,0.4,2.2c2.1,5,4.7,9.8,7.8,14.3
	c3,4.5,6.5,8.6,10.3,12.5c3.8,3.8,8,7.3,12.5,10.3c4.5,3,9.3,5.6,14.3,7.8c5,2.1,10.3,3.8,15.8,4.9c5.5,1.1,11.2,1.7,17,1.7
	c5.8,0,11.5-0.6,17-1.7c5.5-1.1,10.8-2.8,15.8-4.9c5-2.1,9.8-4.7,14.3-7.8c4.5-3,8.6-6.5,12.5-10.3c3.8-3.8,7.3-8,10.3-12.5
	c3-4.5,5.6-9.3,7.8-14.3c0.3-0.6,0.5-1.3,0.8-1.9H160.4z"/>
      <path fill="#FFC425" d="M47.8,351.6c8,0,15.2,0.1,19.2-0.2c0.4-2.1,0.8-4.7,1.3-7c1.9-10.3,4.4-23.2,6.5-33.2c1.2-5.6,2.2-9.8,3-12.8
	c1.1-4.1,3-10.9,10-10.9c3.6,0,7.2,2.4,8.6,7.5c2,7.3,8.1,35.3,13.8,56.9c3.8-16,8.5-36.1,12.2-51.8c3.8-16.1,6.8-28.8,7.8-32.9
	c2.1-8.5,6.7-10.2,10.2-10.1c8,0.2,10.1,9,12,16.8c1.6,6.8,3.4,16.2,5.6,27.2c0.7,3.8,1.5,7.9,2.3,12.1c2.5,12.5,3.7,20.5,4.5,25.7
	c0.1,0.7,0.2,1.4,0.3,2H200c0.8-4.8,1.3-9.7,1.3-14.7c0-46.5-37.7-84.2-84.2-84.2c-46.5,0-84.2,37.7-84.2,84.2c0,9,1.4,17.6,4,25.7
	C41.2,351.7,44.3,351.6,47.8,351.6"/>
      <path fill="#C1C5C8" d="M117,242.2c-46.1,0-83.6,37.5-83.6,83.6s37.5,83.6,83.6,83.6c46.1,0,83.6-37.5,83.6-83.6S163.1,242.2,117,242.2
	 M117,416.3c-49.9,0-90.5-40.6-90.5-90.5s40.6-90.5,90.5-90.5c49.9,0,90.5,40.6,90.5,90.5S166.9,416.3,117,416.3"/>
      {/*
        <path fill="#582D82" d="M250.1,270.2l17.7,53.6l17.7-53.6h21.4V340h-14.3l1.2-54.4L275.9,340h-15.5l-18.4-55l1.5,55h-14.9v-69.9H250.1z
	M335.8,329.2c-2.9-1.1-5-3-6.3-5.7c-1.3-2.7-2-5.6-2.1-8.6c-0.1-1.2,0-2.4,0.1-3.6c0.2-1.2,0.4-2.4,0.7-3.5
	c0.9-2.9,2.6-5.2,5.2-6.8c2.6-1.6,5.4-2.3,8.3-2c0.6,0.1,1.2,0.2,1.8,0.3c0.6,0.1,1.3,0.3,1.8,0.6c2.7,1.2,4.7,3,5.9,5.6
	c1.2,2.6,1.9,5.3,2,8.1c0,1.3-0.1,2.6-0.2,3.8c-0.2,1.3-0.4,2.4-0.7,3.6c-0.9,3.1-2.7,5.5-5.4,7.1c-2.7,1.6-5.6,2.2-8.7,1.8
	c-0.4-0.1-0.8-0.2-1.2-0.3C336.7,329.5,336.3,329.3,335.8,329.2L335.8,329.2z M359.9,334c2.5-2.5,4.4-5.4,5.7-8.9
	c1.3-3.4,1.9-7,1.9-10.6s-0.6-7.2-1.9-10.6c-1.3-3.4-3.1-6.4-5.7-8.9c-2.5-2.4-5.4-4.2-8.9-5.4c-3.5-1.2-7-1.8-10.6-1.8
	c-3.7,0-7.2,0.6-10.7,1.8c-3.5,1.2-6.4,3-8.9,5.4c-2.5,2.5-4.4,5.4-5.7,8.9c-1.3,3.4-1.9,7-1.9,10.6s0.6,7.2,1.9,10.6
	c1.3,3.4,3.1,6.4,5.7,8.9c2.5,2.4,5.4,4.2,8.8,5.4c3.4,1.2,7,1.8,10.6,1.8c3.7,0,7.2-0.6,10.7-1.8
	C354.5,338.2,357.5,336.4,359.9,334L359.9,334z M410.4,329.1h-2c-0.7,0-1.3,0-2,0.1c-5.2,0.1-9.6-0.4-13.3-1.5
	c-3.7-1-5.7-5-5.9-11.9v-1.4v-1.5c0.1-1.8,0.5-3.6,1-5.4c0.5-1.8,1.5-3.4,2.9-4.7c0.8-0.7,1.6-1.3,2.6-1.8c0.9-0.5,1.9-0.8,3-0.9
	c1.2-0.1,2.3-0.2,3.4-0.2c1.1,0,2.3,0,3.4,0h1.9h1.9h1.5c0.5,0,1,0,1.5-0.1V329.1L410.4,329.1z M423.8,340v-69.9h-13.5v18.6
	l-11.5,0.1c-3.9,0-7.6,0.5-11.1,1.5c-3.5,1-6.5,3.1-9.1,6.4c-2.1,2.7-3.6,5.7-4.5,9c-0.8,3.3-1.2,6.6-1.1,10.1
	c0.4,7.5,2.7,13.4,7,17.8c4.3,4.3,10.2,6.5,17.9,6.5H423.8z M436,270.2V340h14.9l-1.5-55l18.4,55h15.5l17.8-54.4L500,340h14.3v-69.9
	H493l-17.7,53.6l-17.7-53.6H436z M537.2,338.7c3.4,0.9,6.9,1.3,10.5,1.3h19.7v-10.7l-17.9,0.1c-3.9,0-7-0.5-9.1-1.5
	c-2.1-1-3.3-3.7-3.4-8.2h30.4v-10.7l-30.4,0.1c0.1-4.3,1.3-7,3.6-8c2.3-1.1,5.3-1.6,9-1.7h17.8v-10.7l-19.7,0.1
	c-3.6,0-7.1,0.4-10.5,1.3c-3.4,0.9-6.4,2.7-9,5.6c-2,2.2-3.5,4.7-4.4,7.5c-0.9,2.8-1.5,5.7-1.7,8.6c-0.1,0.5-0.1,0.9-0.1,1.3v1.3v1
	v1c0.2,3,0.8,6,1.7,9c0.9,2.9,2.4,5.5,4.5,7.8C530.9,336,533.8,337.9,537.2,338.7 M611.8,329.1h-2c-0.7,0-1.3,0-2,0.1
	c-5.2,0.1-9.6-0.4-13.3-1.5c-3.7-1-5.7-5-5.9-11.9v-1.4v-1.5c0.1-1.8,0.5-3.6,1-5.4c0.5-1.8,1.5-3.4,2.9-4.7
	c0.8-0.7,1.6-1.3,2.6-1.8c0.9-0.5,1.9-0.8,3-0.9c1.2-0.1,2.3-0.2,3.4-0.2c1.1,0,2.3,0,3.4,0h1.9h1.9h1.5c0.5,0,1,0,1.5-0.1V329.1
	L611.8,329.1z M625.3,340v-69.9h-13.5v18.6l-11.5,0.1c-3.9,0-7.6,0.5-11.1,1.5c-3.5,1-6.5,3.1-9.1,6.4c-2.1,2.7-3.6,5.7-4.5,9
	c-0.8,3.3-1.2,6.6-1.1,10.1c0.4,7.5,2.7,13.4,7,17.8c4.3,4.3,10.2,6.5,17.9,6.5H625.3z"/>
        <path fill="#A3AAAD" d="M234.1,357l5.7,17.3l5.7-17.3h6.9v22.5h-4.6l0.4-17.5l-5.7,17.5h-5l-5.9-17.7l0.5,17.7h-4.8V357H234.1z
	M260.8,364.9c0.2-1,0.6-1.8,1.2-2.5c0.5-0.7,1.3-1.2,2.2-1.6c0.4-0.1,0.7-0.2,1.1-0.3c0.4-0.1,0.8-0.1,1.2-0.1c0.4,0,0.8,0,1.2,0.1
	c0.4,0.1,0.8,0.2,1.1,0.3c1,0.3,1.7,0.8,2.2,1.6c0.5,0.7,0.9,1.6,1.1,2.5c0.1,0.5,0.2,1.1,0.3,1.7c0.1,0.6,0.1,1.1,0.1,1.7
	c0,0.6,0,1.2-0.1,1.7c-0.1,0.6-0.2,1.1-0.3,1.7c-0.2,1-0.6,1.8-1.1,2.5c-0.5,0.7-1.3,1.2-2.2,1.6c-0.4,0.1-0.7,0.2-1.1,0.3
	c-0.4,0.1-0.8,0.1-1.2,0.1c-0.4,0-0.8,0-1.2-0.1c-0.4-0.1-0.8-0.2-1.1-0.3c-1-0.3-1.7-0.9-2.2-1.6c-0.5-0.7-0.9-1.5-1.1-2.5
	c-0.1-0.5-0.2-1.1-0.3-1.7c-0.1-0.6-0.1-1.2-0.1-1.7c0-0.6,0-1.1,0.1-1.7S260.7,365.4,260.8,364.9L260.8,364.9z M256,363.3
	c-0.2,0.7-0.4,1.5-0.5,2.4c-0.1,0.9-0.2,1.7-0.2,2.6c0,0.9,0.1,1.7,0.2,2.6c0.1,0.9,0.3,1.7,0.5,2.4c0.5,1.7,1.3,3.1,2.4,4.1
	c1.1,1,2.5,1.8,4.2,2.2c0.6,0.1,1.3,0.3,1.9,0.3c0.6,0.1,1.3,0.1,1.9,0.1c0.7,0,1.4,0,2.2-0.1c0.7-0.1,1.4-0.2,2.1-0.4
	c1.7-0.4,3.1-1.1,4.2-2.2c1.1-1,1.9-2.4,2.4-4.1c0.2-0.8,0.4-1.6,0.5-2.4c0.1-0.9,0.2-1.7,0.2-2.6c0-0.8-0.1-1.7-0.2-2.5
	c-0.1-0.8-0.3-1.6-0.5-2.4c-0.5-1.6-1.3-3-2.3-4c-1.1-1-2.4-1.8-4.1-2.2c-0.7-0.2-1.4-0.3-2.1-0.4c-0.7-0.1-1.4-0.1-2.2-0.1
	c-0.7,0-1.3,0-2,0.1c-0.7,0.1-1.3,0.2-1.9,0.3c-1.7,0.4-3.1,1.2-4.1,2.2C257.3,360.3,256.5,361.6,256,363.3L256,363.3z M290.8,361
	h0.1h0.1c0.7,0,1.4,0.1,2,0.2c0.7,0.1,1.2,0.5,1.7,1.1c0.5,0.5,0.8,1.2,1,1.9c0.2,0.7,0.3,1.4,0.4,2.2c0,0.3,0.1,0.7,0.1,1v1v0.9
	c0,0.3,0,0.6,0,0.9c-0.1,0.8-0.2,1.5-0.4,2.2c-0.2,0.7-0.5,1.4-1,2c-0.5,0.6-1.1,0.9-1.7,1.1c-0.7,0.1-1.3,0.2-2,0.2h-0.1
	c0,0-0.1,0-0.1,0h-5.5v-14.7L290.8,361L290.8,361z M280.6,357v22.5H291c1.3,0,2.5-0.1,3.7-0.3c1.2-0.2,2.3-0.8,3.3-1.6
	c1.2-1,2.1-2.3,2.6-3.7c0.5-1.5,0.8-3,0.8-4.5c0-0.3,0-0.7,0-1c0-0.3,0-0.6,0-0.9c-0.2-3.6-1.2-6.2-2.9-7.8
	c-1.7-1.7-4.2-2.5-7.6-2.5H280.6z M310.4,362.3c0.9-0.9,2.1-1.4,3.9-1.4h7.7V357l-7.8,0c-3.4,0-5.9,0.8-7.6,2.5
	c-1.7,1.7-2.7,4.3-2.9,7.8c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6,0,1c0,1.6,0.3,3.1,0.8,4.5c0.5,1.5,1.4,2.7,2.6,3.7c1,0.8,2.2,1.4,3.3,1.6
	c1.2,0.2,2.4,0.3,3.7,0.3h8v-3.9l-7.7,0h-0.1H314c-0.7,0-1.4-0.1-2-0.2c-0.7-0.1-1.2-0.5-1.7-1c-0.5-0.6-0.9-1.3-1.1-2
	c-0.2-0.7-0.3-1.5-0.3-2.3H322v-3.9l-13,0C309.1,364.5,309.6,363.2,310.4,362.3 M336.3,361.1c1.1,0,2,0.1,2.9,0.4
	c0.9,0.3,1.3,1.1,1.3,2.4c0,1.3-0.4,2.1-1.2,2.4c-0.8,0.3-1.7,0.5-2.7,0.5h-0.1c0,0-0.1,0-0.1,0h-5.8V361L336.3,361.1L336.3,361.1z
	M325.9,357v22.5h4.7v-8.8h5.6l4.9,8.8h5.3l-5.6-9.4c1.6-0.4,2.7-1.1,3.4-2.3c0.7-1.1,1.1-2.5,1.1-4.1c0-0.1,0-0.3,0-0.4
	c0-0.1,0-0.3,0-0.4c-0.1-0.8-0.2-1.5-0.5-2.3c-0.3-0.7-0.7-1.4-1.3-1.9c-0.6-0.6-1.3-1.1-2.2-1.3c-0.8-0.2-1.7-0.4-2.5-0.4
	c-0.2,0-0.5,0-0.7,0c-0.2,0-0.4,0-0.7,0H325.9z M349,357v22.5h4.8l-0.2-17l9.6,17h5.5V357h-4.8l0.2,16.2l-9.2-16.2H349z M373,379.5
	h4.9V357H373V379.5z M400.7,361v-4h-18.9v4h12.8l-12.8,14.5v4h18.9v-4h-12.6L400.7,361z M404.9,379.5h4.9V357h-4.9V379.5z
	M414.2,357v22.5h4.8l-0.2-17l9.6,17h5.5V357h-4.8l0.2,16.2l-9.2-16.2H414.2z M446.4,370.1h6.3v5.3l-5.3,0c-2.2,0-3.7-0.7-4.3-2.1
	c-0.7-1.4-1-3-1-4.9v0v0c0-1.9,0.3-3.6,1-5.1c0.7-1.6,2.1-2.3,4.3-2.4h10.1V357l-10.1,0c-3.4,0-5.9,0.8-7.6,2.5
	c-1.7,1.7-2.7,4.3-2.9,7.8c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6,0,1c0,1.5,0.3,3.1,0.8,4.5c0.5,1.5,1.4,2.7,2.6,3.7c1,0.9,2.2,1.4,3.4,1.6
	s2.5,0.3,3.7,0.3h10.1v-13.4h-11.1V370.1z M472.9,357v22.5h4.8l-0.5-17.7l5.9,17.7h5l5.7-17.5l-0.4,17.5h4.6V357h-6.9l-5.7,17.3
	l-5.7-17.3H472.9z M507.8,362.3c0.8-0.9,2.1-1.4,3.9-1.4h7.7V357l-7.8,0c-3.4,0-5.9,0.8-7.6,2.5c-1.7,1.7-2.7,4.3-2.9,7.8
	c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6,0,1c0,1.6,0.3,3.1,0.8,4.5c0.5,1.5,1.4,2.7,2.6,3.7c1,0.8,2.2,1.4,3.3,1.6c1.2,0.2,2.4,0.3,3.7,0.3
	h8v-3.9l-7.7,0h-0.1h-0.1c-0.7,0-1.4-0.1-2-0.2c-0.7-0.1-1.2-0.5-1.7-1c-0.5-0.6-0.9-1.3-1.1-2c-0.2-0.7-0.3-1.5-0.3-2.3h13.1v-3.9
	l-13,0C506.5,364.5,507,363.2,507.8,362.3 M533.5,361h0.1h0.1c0.7,0,1.4,0.1,2,0.2c0.7,0.1,1.2,0.5,1.7,1.1c0.5,0.5,0.8,1.2,1,1.9
	c0.2,0.7,0.3,1.4,0.4,2.2c0,0.3,0.1,0.7,0.1,1v1v0.9c0,0.3,0,0.6,0,0.9c-0.1,0.8-0.2,1.5-0.4,2.2c-0.2,0.7-0.5,1.4-1,2
	c-0.5,0.6-1.1,0.9-1.7,1.1c-0.7,0.1-1.3,0.2-2,0.2h-0.1c0,0-0.1,0-0.1,0H528v-14.7L533.5,361L533.5,361z M523.3,357v22.5h10.4
	c1.3,0,2.5-0.1,3.7-0.3c1.2-0.2,2.3-0.8,3.3-1.6c1.2-1,2.1-2.3,2.6-3.7c0.5-1.5,0.8-3,0.8-4.5c0-0.3,0-0.7,0-1c0-0.3,0-0.6,0-0.9
	c-0.2-3.6-1.2-6.2-2.9-7.8c-1.7-1.7-4.2-2.5-7.6-2.5H523.3z M547.1,379.5h4.9V357h-4.9V379.5z M561.2,363.1c0.7-1.5,2.1-2.2,4.4-2.3
	h7.7V357l-7.8,0c-3.4,0-5.9,0.8-7.6,2.5c-1.7,1.7-2.7,4.3-2.9,7.8c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6,0,1c0,1.6,0.3,3.1,0.8,4.5
	c0.5,1.5,1.4,2.7,2.6,3.7c1,0.8,2.1,1.4,3.3,1.6c1.2,0.2,2.4,0.3,3.7,0.3h8v-3.9l-7.7,0h-0.1h-0.1c-0.7,0-1.4-0.1-2-0.2
	c-0.7-0.1-1.2-0.5-1.7-1c-0.5-0.5-0.8-1.2-1-1.9c-0.2-0.7-0.3-1.4-0.4-2.1c0-0.4,0-0.7,0-1v-1C560.2,366.3,560.6,364.6,561.2,363.1
	M576.6,379.5h4.9V357h-4.9V379.5z M585.5,357v22.5h4.8l-0.2-17l9.6,17h5.5V357h-4.8l0.2,16.2l-9.2-16.2H585.5z M614.9,362.3
	c0.9-0.9,2.1-1.4,3.9-1.4h7.7V357l-7.8,0c-3.4,0-5.9,0.8-7.6,2.5c-1.7,1.7-2.7,4.3-2.9,7.8c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6,0,1
	c0,1.6,0.3,3.1,0.8,4.5c0.5,1.5,1.4,2.7,2.6,3.7c1,0.8,2.2,1.4,3.3,1.6c1.2,0.2,2.4,0.3,3.7,0.3h8v-3.9l-7.7,0h-0.1h-0.1
	c-0.7,0-1.4-0.1-2-0.2c-0.7-0.1-1.2-0.5-1.7-1c-0.5-0.6-0.9-1.3-1.1-2c-0.2-0.7-0.3-1.5-0.3-2.3h13.1v-3.9l-13,0
	C613.6,364.5,614,363.2,614.9,362.3"/>
      */}
    </SvgIcon >
  );
};

export default LogoModMed;

