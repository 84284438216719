import { CertificateDetail } from '@aws-sdk/client-acm';
import { Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { CertificateStatus } from './CertificateStatus';
import { AuthConfig, Stack, TenantDatabaseUser, useData } from './data';
import { PropTable, PropTableRecord } from './PropTable';
import { StackEventStatus } from './StackEventStatus';
import { TenantContext } from './TenantContainer';

//  loader: async ( { params } ) => ( {
//    auth: await getTenantAuth( params.id ),
//    databaseUser: await getTenantDatabaseUser( params.id ),
//    certificate: await getTenantCertificate( params.id ),
//    stack: await getTenantStack( params.id ),
//  } ),

export interface TenantSummaryData {
  auth?: AuthConfig;
  databaseUser?: TenantDatabaseUser;
  certificate?: CertificateDetail;
  stack?: Stack;
  // tenant?: Tenant;
}

export const TenantSummary: FC = () => {
  const { id } = useParams();
  const { getTenantAuth, getTenantDatabaseUser, getTenantCertificate, getTenantStack } = useData();
  const { tenant } = useOutletContext() as TenantContext;
  const { tag, name, description } = tenant || {};
  // const data = useLoaderData();
  const [ data, setData ] = useState<TenantSummaryData | undefined>();
  useEffect( () => {
    ( async () => {
      const [ auth, databaseUser, certificate, stack ] = await Promise.all( [
        getTenantAuth( id ),
        getTenantDatabaseUser( id ),
        getTenantCertificate( id ),
        getTenantStack( id ),
      ] );
      setData( { auth, databaseUser, certificate, stack } );
    } )();
  }, [ id, setData ] );

  const { auth, databaseUser, certificate, stack } = data || {};

  const { status: stackStatus, stackId, outputs = {} } = stack || {};
  const { roles: authRoles } = auth || {};
  const { username: databaseUsername } = databaseUser || {};
  const { Status: certificateStatus, DomainName: certificateDomain } = certificate || {};
  const { providerStaff } = authRoles || {};
  const { name: authStaffRole, users = [] } = providerStaff || {};

  const authUsers = users.map( u => u.emails[ 0 ] );

  const record: PropTableRecord | undefined = !data
    ? data
    : {
      '   tag': tag,
      '  name': name,
      ' description': description,
      authStaffRole,
      authUsers,
      certificateDomain,
      certificateStatus: <CertificateStatus Status={certificateStatus} />,
      databaseUsername,
      stackId,
      stackStatus: <StackEventStatus Status={stackStatus} />,
      stackAdminDomain: outputs.AdminDomainName,
      stackAdminDistribution: outputs.AdminDomainTarget,
      stackAppDomain: outputs.PrimaryDomainName,
      stackAppDistribution: outputs.PrimaryDomainTarget,
    };

  return (
    <Box>

      <PropTable label='' record={record} unsort />

    </Box>
  );
}


