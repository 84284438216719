import styled from '@mui/material/styles/styled';
import MuiSvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const SvgIcon = styled( MuiSvgIcon, {
  name: 'LogoAthena',
  shouldForwardProp: ( prop ) => prop !== 'fill',
} )<SvgIconProps>( () => ( {
  // fill: '#f00',
  // stroke: 'currentColor',
  // strokeLinecap: 'round',
  // strokeLinejoin: 'round',
  // strokeWidth: '2.25px',
} ) );

SvgIcon.defaultProps = {
  viewBox: '22 9 30 56',
  focusable: 'false',
  'aria-hidden': 'true',
};
//       <style type="text/css">
//           .st0{fill:#FFFFFF;}   fill="#FFFFFF"
//           .st1{fill:#B4C327;}   fill="#B4C327"
//           .st2{fill:#73A233;}   fill="#73A233"
//             .st3{fill:none;stroke:#FFFFFF;stroke-width:1.42;stroke-miterlimit:10;} fill="none" stroke="#FFFFFF" stroke-width="1.42" stroke-miterlimit="10" 
// </style>

export const LogoAthena: FC<SvgIconProps> = ( props ) => {

  const { fill = 'rgb(248, 153, 29)', ...rest } = props;

  return (
    <SvgIcon {...rest}>
      <path fill="#FFFFFF" d="M74.3,51.6c0,5.2,3.4,9.5,9,9.5c5.4,0,9-4.1,9-9.5s-3.6-9.5-9-9.5C77.8,42.1,74.3,46.4,74.3,51.6 M98.4,66.5       h-6.5v-3.6c-2.2,2.9-5.7,4.5-9.3,4.4c-8.3,0-14.9-6.6-14.9-15.7S74.2,36,82.6,36c3.6-0.1,7.1,1.5,9.3,4.4v-3.6h6.5V66.5z" />
      <path fill="#FFFFFF" d="M125.1,42.6h-11.4v13c0,4,2.1,5.6,4.7,5.6c2.1-0.1,4.2-0.8,6-2l2.6,5.2c-2.7,1.9-5.9,3-9.1,3       c-6.7,0-10.7-3.6-10.7-11.6V42.6h-5.9v-5.9h5.9v-9h6.5v9h11.4V42.6z" />
      <path fill="#FFFFFF" d="M136.1,40.3c2.1-2.9,5.5-4.5,9.1-4.3c7.1,0,12.1,4.9,12.1,11.8v18.7h-6.5V49.9c0-5-2.6-7.8-6.9-7.8       c-4.2,0-7.7,2.8-7.7,7.9v16.5h-6.5V21.8h6.5v18.5H136.1z" />
      <path fill="#FFFFFF" d="M168.5,49.1h15.4c-0.7-4.6-3.4-7.3-7.6-7.3C171.8,41.7,169.2,44.7,168.5,49.1 M190.5,51.6       c0,0.8-0.1,1.7-0.1,2.4h-22c0.9,5.3,4.6,7.6,8.9,7.6c3.1-0.1,6.2-1.2,8.6-3.1l3.2,4.6c-3.4,2.9-7.8,4.4-12.2,4.2       c-8.9,0-15.2-6.2-15.2-15.7s6-15.6,14.8-15.6C184.8,36,190.5,42.3,190.5,51.6" />
      <path fill="#FFFFFF" d="M222.8,47.8v18.7h-6.5V49.9c0-5-2.6-7.8-6.9-7.8c-4.2,0-7.7,2.8-7.7,7.9v16.5h-6.5V36.7h6.5v3.6       c2.1-2.9,5.5-4.6,9.1-4.4C217.8,36,222.8,40.8,222.8,47.8" />
      <path fill="#FFFFFF" d="M233.7,51.6c0,5.2,3.4,9.5,9,9.5c5.4,0,9-4.1,9-9.5s-3.6-9.5-9-9.5C237.2,42.1,233.7,46.4,233.7,51.6       M257.7,66.5h-6.5v-3.6c-2.2,2.9-5.7,4.5-9.4,4.4c-8.3,0-14.9-6.6-14.9-15.7s6.7-15.6,15-15.6c3.6-0.1,7.1,1.5,9.4,4.4v-3.6h6.5       L257.7,66.5z" />
      <path fill="#FFFFFF" d="M268,42.3c2.2-3.6,6.2-5.7,10.4-5.5c7.3,0,12.3,4.5,12.3,11.8v17.9h-3.5V49.7c0-5.9-3.4-9.5-9.4-9.5       c-5.2-0.2-9.6,3.9-9.8,9.1c0,0.2,0,0.4,0,0.6v16.6h-3.5V21.8h3.5V42.3z" />
      <path fill="#FFFFFF" d="M299.4,50.1h20.9c-0.5-6.1-4.7-10.2-10.1-10.2C304.2,39.9,300,44.2,299.4,50.1 M323.9,51.7c0,0.5,0,1-0.1,1.5       h-24.5c0.5,6.8,5.3,10.7,11.3,10.7c3.7,0,7.3-1.5,9.9-4.2l1.9,2.4c-3.1,3.2-7.5,5-12,4.8c-8.5,0-14.7-6.2-14.7-15.1       s6.2-15.1,14.5-15.1C317.9,36.8,323.7,43,323.9,51.7" />
      <path fill="#FFFFFF" d="M330.7,51.9c0,6.6,4.8,11.7,11.4,11.7s11.4-5,11.4-11.7s-4.8-11.8-11.4-11.8S330.7,45.3,330.7,51.9       M356.7,66.5h-3.5v-5.6c-2.5,3.9-6.9,6.3-11.6,6.1c-8.2,0-14.6-6.4-14.6-15.1s6.4-15.1,14.6-15.1c4.7-0.2,9.1,2.2,11.6,6.1v-5.6       h3.5V66.5z" />
      <rect x="364" y="21.8" fill="#FFFFFF" width="3.5" height="44.7" />
      <path fill="#FFFFFF" d="M394.7,40.5h-13.5v15.9c0,4.9,2.6,7.2,6.5,7.2c2.4,0,4.7-0.8,6.5-2.2l1.7,2.6c-2.4,1.9-5.4,3-8.5,2.9       c-6.1,0-9.8-3.4-9.8-10.5v-16H371v-3.2h6.7v-9.5h3.5v9.5h13.5V40.5z" />
      <path fill="#FFFFFF" d="M402.8,42.3c2.2-3.6,6.2-5.7,10.4-5.5c7.3,0,12.3,4.5,12.3,11.8v17.9H422V49.7c0-5.9-3.4-9.5-9.4-9.5       c-5.2-0.2-9.6,3.9-9.8,9.1c0,0.2,0,0.4,0,0.6v16.6h-3.5V21.8h3.5L402.8,42.3z" />
      <path fill="#B4C327" d="M32.8,36.7c0,0-5.7-7-5.8-14.6s5.4-13,5.4-13s5.7,6.9,5.9,14.6S32.8,36.7,32.8,36.7" />
      <path fill="#73A233" d="M42.1,26.2c0,2,1.6,3.7,3.6,3.7s3.7-1.6,3.7-3.6s-1.6-3.7-3.6-3.7c0,0,0,0-0.1,0       C43.7,22.6,42.1,24.2,42.1,26.2 M63.4,28.1c0,0,0.7,15.4-7,25.9S35.6,66.2,35.6,66.2s-0.7-15.4,7-25.9S63.4,28.1,63.4,28.1" />
      <path fill="#B4C327" d="M32.6,52.5L32.6,52.5L32.6,52.5c0-3.1-0.5-12.3-5.4-19c-5.9-8.1-15.9-9.4-15.9-9.4S10.8,35.9,16.7,44       c4.2,5.8,10.5,8.1,13.7,9v12.6c0.1,0.6,0.6,1,1.2,1c0.5-0.1,0.9-0.5,1-1V52.9C32.6,52.8,32.6,52.7,32.6,52.5" />
      <line fill="none" stroke="#FFFFFF" strokeWidth="1.42" strokeMiterlimit="10" x1="471.9" y1="17" x2="472.1" y2="74.5" />
      <path fill="#FFFFFF" d="M517.1,22.3h14.8c12.9,0,21.3,8.6,21.3,22s-8.4,22-21.3,22h-14.8V22.3z M531.6,59.6c9.2,0,14.2-5.9,14.2-15.3       s-4.9-15.4-14.2-15.4h-7.1v30.7H531.6z" />
      <path fill="#FFFFFF" d="M564.5,51.9c0.5,6.2,4.7,8.9,9.8,8.9c3.3,0.1,6.5-1,9.1-2.9v6.3c-2.7,2-6.1,2.8-10.1,2.8       c-9.3,0-15.7-6.3-15.7-16.3s6.1-16.9,14.6-16.9c8.8,0,13.4,6.3,13.4,15.6V52L564.5,51.9z M564.9,46.5h13.8       c-0.2-3.9-2.4-6.7-6.5-6.7C569.1,39.8,566,41.6,564.9,46.5L564.9,46.5z" />
      <path fill="#FFFFFF" d="M599.8,66.2l-12-31.7h7.7l8.2,23.2l8.2-23.2h7.4l-12.1,31.7H599.8z" />
      <path fill="#FFFFFF" d="M628.2,51.9c0.5,6.2,4.7,8.9,9.8,8.9c3.3,0.1,6.5-1,9.1-2.9v6.3c-2.7,2-6.1,2.8-10.1,2.8       c-9.3,0-15.7-6.3-15.7-16.3s6.1-16.9,14.6-16.9c8.8,0,13.4,6.3,13.4,15.6V52L628.2,51.9z M628.7,46.5h13.8       c-0.2-3.9-2.4-6.7-6.5-6.7C632.8,39.8,629.7,41.6,628.7,46.5z" />
      <path fill="#FFFFFF" d="M662.5,66.2h-7.2V19.7h7.2V66.2z" />
      <path fill="#FFFFFF" d="M683.5,66.9c-8.9,0-15.1-6.9-15.1-16.6s6.2-16.6,15.1-16.6s15.1,6.9,15.1,16.6S692.5,66.9,683.5,66.9z       M683.5,39.9c-5.2,0-7.9,4.4-7.9,10.4s2.8,10.4,7.9,10.4s7.9-4.4,7.9-10.4S688.7,39.9,683.5,39.9L683.5,39.9z" />
      <path fill="#FFFFFF" d="M711.7,78.4h-7.2V34.5h7.2v2.7c2.1-2.2,5-3.5,8.1-3.4c9.3,0,14.7,7.7,14.7,16.6S729.1,67,719.8,67       c-3.1,0.1-6-1.2-8.1-3.4V78.4z M711.7,56.4c1.3,2.6,3.9,4.2,6.8,4.2c5.5,0,8.8-4.4,8.8-10.2s-3.2-10.3-8.8-10.3       c-2.9,0-5.6,1.6-6.9,4.2L711.7,56.4z" />
      <path fill="#FFFFFF" d="M745.3,51.9c0.5,6.2,4.7,8.9,9.8,8.9c3.3,0.1,6.5-1,9.1-2.9v6.3c-2.7,2-6.1,2.8-10.1,2.8       c-9.3,0-15.7-6.3-15.7-16.3s6.1-16.9,14.6-16.9c8.8,0,13.4,6.3,13.4,15.6V52h-21.1V51.9z M745.8,46.5h13.8       c-0.2-3.9-2.4-6.7-6.5-6.7C749.9,39.8,746.8,41.6,745.8,46.5L745.8,46.5z" />
      <path fill="#FFFFFF" d="M790.2,41.7c-1.3-0.7-2.7-1.1-4.2-1.1c-3.1-0.1-5.8,2-6.4,5.1v20.5h-7.1V34.5h7.2v3.3c1.5-2.5,4.2-4.1,7.1-4       c1.2,0,2.4,0.2,3.5,0.6L790.2,41.7z" />
    </SvgIcon >
  );
};

export default LogoAthena;

