import { Box } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { useOutletContext, Outlet } from 'react-router-dom';
import { ContentContext } from './Content';
import { ContentLanguage, useData } from './data';

export interface ContentTextsContext extends ContentContext {
  languages: ContentLanguage[];
}

export const ContentTextsContainer: FC = () => {
  const contentContext = useOutletContext() as ContentContext;
  const { getContentLanguages } = useData();
  const [ languages, setLanguages ] = useState<ContentLanguage[]>( [] );

  const loadLanguages = useCallback( async () => {
    const { data } = await getContentLanguages();
    setLanguages( data );
  }, [] );

  useEffect( () => {
    ( async () => {
      await loadLanguages();
    } )();
  }, [ getContentLanguages, setLanguages ] );

  const context: ContentTextsContext = {
    ...contentContext,
    languages,
  };

  return (
    <Box>
      <Outlet
        context={context}
      />
    </Box>
  );
}
