import { FC, useCallback } from 'react';
import { useLoaderData, useNavigate, useOutletContext, useRouteLoaderData, useParams, useRevalidator } from 'react-router-dom';
import { BaseContext } from './Base';
import { ConfigForm } from './ConfigForm';
import { TenantCore, TenantCreateFormData, TenantEnvironment, useData } from './data';
import { TenantConfigContext } from './TenantConfigContainer';
import { TenantContext } from './TenantContainer';


export const TenantConfigEdit: FC = () => {
  const { id: tag } = useParams();
  const { envConfig } = useOutletContext() as TenantConfigContext;
  const { updateTenantEnvConfig } = useData();
  const navigate = useNavigate();
  const { revalidate } = useRevalidator();

  const onCancel = useCallback( () => {
    navigate( '..' );
  }, [ navigate ] );

  const onSubmit = useCallback( async ( data: Record<string, string> ) => {
    if( !tag ) return;
    await updateTenantEnvConfig( tag, data );
    revalidate();
    navigate( '..' );
  }, [ tag, updateTenantEnvConfig ] );

  if( !envConfig ) return null;

  return (

    <ConfigForm
      title='Edit config'
      data={envConfig}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );

}


