import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Theme } from '@mui/system';
import { createContext, FC, useContext, useMemo, useState } from 'react';
import './index.css';

export type ThemeProviderProps<T extends Theme = Theme> = Parameters<typeof ThemeProvider<T>>[ 0 ];

// eslint-disable-next-line
const ColorModeContext = createContext( { toggleColorMode: () => { } } );

export const useColorMode = () => useContext( ColorModeContext );

export const ThemeProviderWithColorMode: FC<Omit<ThemeProviderProps, 'theme'>> = props => {
  const { children } = props;
  const [ mode, setMode ] = useState<'light' | 'dark'>( 'light' );
  const colorMode = useMemo(
    () => ( {
      toggleColorMode: () => {
        setMode( ( prevMode ) => ( prevMode === 'light' ? 'dark' : 'light' ) );
      },
    } ),
    [],
  );

  const theme = useMemo(
    () =>
      createTheme( {
        palette: {
          mode,
        },
      } ),
    [ mode ],
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

