import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthUser, AuthUserCreate, useData } from './data';
import { UsersStaffForm } from './UsersStaffForm';

export const UsersStaffEdit: FC = () => {
  const { id } = useParams();
  const { getUser } = useData();
  const [ user, setUser ] = useState<AuthUser | undefined>();
  const { updateUser } = useData();
  const navigate = useNavigate();

  useEffect( () => {
    ( async () => {
      if( !id || user ) return;
      const u = await getUser( id );
      setUser( u );
    } )();
  }, [ id, setUser ] );


  const onSubmit = useCallback( async ( data: AuthUserCreate ) => {
    if( !id ) return;
    await updateUser( id, data );
    navigate( '..' );
  }, [ updateUser ] );

  if( !user ) return null;

  const { emails, metadata } = user || {};
  const { name = '' } = metadata || {};
  const [ email ] = emails || [];
  const data = { name, email };

  return (
    <UsersStaffForm
      title='Edit user'
      data={data}
      onSubmit={onSubmit}
    />
  );

}


