import styled from '@mui/material/styles/styled';
import MuiSvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const SvgIcon = styled( MuiSvgIcon, {
  name: 'LogoEpic',
  shouldForwardProp: ( prop ) => prop !== 'fill',
} )<SvgIconProps>( () => ( {
  // fill: '#f00',
  // stroke: 'currentColor',
  // strokeLinecap: 'round',
  // strokeLinejoin: 'round',
  // strokeWidth: '2.25px',
} ) );

SvgIcon.defaultProps = {
  viewBox: '0 -1 39.14 14.84',
  focusable: 'false',
  'aria-hidden': 'true',
};
//       <style type="text/css">
//         .st0{fill:#FFFFFF;}
//         .st1{fill:#582D82;}
//         .st2{fill:#FFC425;}
//         .st3{fill:#C1C5C8;}
//         .st4{fill:#A3AAAD;}
// </style>

export const LogoEpic: FC<SvgIconProps> = ( props ) => {

  const { fill = 'rgb(248, 153, 29)', ...rest } = props;

  return (
    <SvgIcon {...rest}>
      <path d="M28.69 0H25.3l-.43 2.05h3.39L28.69 0z" fill="#cd1543"></path>
      <path d="M33.64 4.92c1.29 0 1.9.44 1.98 1.21h3.51c.12-2.54-1.94-3.45-5.06-3.45s-5.67 1.16-6.7 3.51l.67-3.16h-3.39l-.62 2.94c-.15-2.04-1.7-3.16-4.19-3.16-1.37 0-2.68.5-3.59 1.37h-.03l.24-1.15h-3.29l-1.32 6.2H4.32l.36-1.94h6.72l.5-2.37H5.18l.36-1.91h7.63l.52-2.46H2.27L0 11.7h11.33l-.67 3.13h3.39l.83-3.92h.03c.44.64 1.49 1.13 2.99 1.13 2.57 0 4.56-1.33 5.5-3.16l-.6 2.81h3.39l.66-3.12c.02 2.36 1.9 3.47 5.21 3.47 3.73 0 5.71-1.36 6.76-3.75h-3.58C34.76 9.3 33.83 9.8 32.6 9.8c-1.63 0-2.37-.88-2.04-2.45.33-1.57 1.48-2.45 3.06-2.45ZM20.45 7.38c-.31 1.44-1.32 2.21-2.87 2.21s-2.23-.86-1.95-2.21c.29-1.34 1.3-2.21 2.88-2.21s2.23.77 1.93 2.21Z" fill="#cd1543"></path>
    </SvgIcon >
  );
};

export default LogoEpic;

