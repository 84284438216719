import { Box, Tooltip, Typography } from '@mui/material';
import { FC, ReactElement } from 'react';
import AwsApiGateway from './AwsApiGateway';
import AwsBlank from './AwsBlank';
import AwsCloudFormation from './AwsCloudFormation';
import { AwsCloudFront } from './AwsCloudFront';
import AwsCloudWatch from './AwsCloudWatch';
import AwsEC2 from './AwsEC2';
import { AwsEventBridge } from './AwsEventBridge';
import { AwsIAM } from './AwsIAM';
import AwsLambda from './AwsLambda';
import AwsRDS from './AwsRDS';
import AwsS3 from './AwsS3';
import { AwsSecretsManager } from './AwsSecretsManager';
import AwsVPC from './AwsVPC';
import { AwsWAF } from './AwsWAF';

export const resourceTypeImage: Record<string, ReactElement> = {
  CloudFormation: <AwsCloudFormation />,
  Lambda: <AwsLambda />,
  ApiGatewayV2: <AwsApiGateway />,
  CloudFront: <AwsCloudFront />,
  CloudWatch: <AwsCloudWatch />,
  EC2: <AwsEC2 />,
  RDS: <AwsRDS />,
  S3: <AwsS3 />,
  VPC: <AwsVPC />,
  IAM: <AwsIAM />,
  Events: <AwsEventBridge />,
  SecretsManager: <AwsSecretsManager />,
  WAFv2: <AwsWAF />,
};


export interface StackResourceTypeProps {
  Type?: string;
}

export const StackResourceType: FC<StackResourceTypeProps> = ( { Type = '' } ) => {
  const r = Type.split( '::' ).slice( 1 )[ 0 ];
  const svg = resourceTypeImage[ r ];

  return svg || <AwsBlank />;
}

export const LabelledStackResource: FC<{ type?: string; label?: string }> = ( { label = '', type = '' } ) => (
  <Tooltip
    title={type}
    placement='left-end'
  // sx={{
  //   fontSize: '110%',
  // }}
  >
    <Box
      sx={{
        display: 'flex',
        '& .MuiSvgIcon-root': {
          marginRight: '0.5rem',
        },
      }} >
      <StackResourceType Type={type} />
      <Typography>
        {label}
      </Typography>
    </Box>
  </Tooltip>
)



