import { useState, useMemo, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export type CacheableValue = string | number | Record<string, unknown> | Array<unknown>;

export const useCachedState = <T extends CacheableValue>( key: string, defaultValue: T ): [ T, React.Dispatch<React.SetStateAction<T>> ] => {
  const [ searchParams, setSearchParams ] = useSearchParams();

  const { pathname } = window.location;
  const localStorageKey = useMemo( () => {
    return `store${ pathname.replaceAll( '/', '.' ).toLowerCase() }.${key}`
  }, [ key, pathname ] );

  const initialValue = useMemo<T>( () => {
    const value = searchParams.get( key ) || localStorage.getItem( localStorageKey );
    if( value ) return typeof defaultValue == 'string' ? value : JSON.parse( value );
    return defaultValue;
  }, [ defaultValue ] );

  const [ state, setState ] = useState<T>( initialValue );

  useEffect( () => {
    const stringifiedState = typeof state == 'string' ? state : JSON.stringify( state );
    setSearchParams( params => {
      if( !state ) {
        params.delete( key );
        return params;
      }
      params.set( key, stringifiedState );
      return params;
    } );
    state ? localStorage.setItem( localStorageKey, stringifiedState ) : localStorage.removeItem( localStorageKey );
  }, [ state ] );
  return [ state, setState ];
};
