import { Grid } from '@mui/material';
import { FC } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ConfigTable } from './ConfigTable';
import { PropTable } from './PropTable';
import { TenantContext } from './TenantContainer';
import { TenantStackContext } from './TenantStack';

export const TenantStackOutputs: FC = () => {
  const { tenant, stack } = useOutletContext() as TenantStackContext;
  const { outputs = {} } = stack || {};

  return (
    <Grid item >
      <ConfigTable label='Outputs' record={outputs} />
    </Grid>
  );
}


