import { Box, Button, Grid, InputLabel, MenuItem, Select, Stack, TextField, Toolbar, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { BaseContext } from './Base';
import { tagRe, TenantCreateFormData, useData } from './data';

export interface TenantFormProps {
  title: string;
  data?: TenantCreateFormData;
  onSubmit: ( data: TenantCreateFormData ) => Promise<void>;
}

export const TenantForm: FC<TenantFormProps> = props => {
  const { title, data, onSubmit } = props;
  const isCreate = !data;

  const { control, handleSubmit, formState: { errors, isDirty, isValidating, isValid, isSubmitted, isSubmitting } } = useForm<TenantCreateFormData>( {
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: data || {
      tag: '',
      name: '',
      description: '',
    } // fixes react (un)controlled error
  } );

  return (
    <Box>
      <Typography gutterBottom fontSize='1.2rem' fontWeight='bold'
      >
        {title}
      </Typography>

      <form
        style={{
          width: '100%',
          marginBottom: '1rem',
        }}
      >
        <Grid container spacing={1} direction='column'
          sx={{ maxWidth: '40rem' }}

        >

          {isCreate &&
            <Grid item>
              <Controller
                name='tag'
                control={control}
                rules={{
                  required: true,
                  minLength: {
                    value: 4,
                    message: 'Minimum of 4 characters'
                  },
                  maxLength: {
                    value: 32,
                    message: 'Maximum of 32 characters'
                  },
                  pattern: {
                    value: tagRe,
                    message: 'Must be lowercase alphanumeric, letter first.'
                  },
                }}
                render={( { field } ) => (
                  <TextField
                    {...field}
                    inputRef={field.ref}
                    required
                    // InputProps={{
                    //   readOnly: !!data?.tag,
                    // }}
                    margin='dense'
                    label='Tag'
                    variant='filled'
                    error={!!errors?.tag?.message}
                    helperText={errors?.tag?.message}

                  />
                )}
              />
            </Grid>
          }

          <Grid item >
            <Controller
              name='name'
              control={control}
              rules={{
                minLength: {
                  value: 4,
                  message: 'Minimum of 4 characters'
                },
                maxLength: {
                  value: 32,
                  message: 'Maximum of 32 characters'
                },
              }}
              render={( { field } ) => (
                <TextField
                  {...field}
                  inputRef={field.ref}
                  required
                  margin='dense'
                  // sx={{ width: '40rem' }}
                  fullWidth
                  label='Name'
                  variant='filled'
                  error={!!errors?.tag?.message}
                  helperText={errors?.tag?.message}
                />
              )}
            />

          </Grid>
          <Grid item>
            <Controller
              name='description'
              control={control}
              render={( { field } ) => (
                <TextField
                  {...field}
                  inputRef={field.ref}
                  margin='dense'
                  label='Description'
                  fullWidth
                  multiline
                  minRows={4}
                  variant='filled'
                  error={!!errors?.tag?.message}
                  helperText={errors?.tag?.message}
                />
              )}
            />

          </Grid>

        </Grid>
      </form>
      <Toolbar>
        <Button variant='contained'
          disabled={!isDirty || isValidating || isSubmitting || isSubmitted || !isValid}
          onClick={handleSubmit( onSubmit, ( v ) => { console.log( errors, v ) } )}
          sx={{ fontSize: '90%' }}
        // color='success'
        >
          Submit
        </Button>
        <Box sx={{ flexGrow: 1 }} />
      </Toolbar>

    </Box >
  );
}


