import { Menu as MenuIcon, Person, Brightness4, Brightness7 } from '@mui/icons-material';
import { AppBar as MuiAppBar, Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Tooltip, Typography, useTheme } from '@mui/material';
import { Dispatch, FC, MouseEventHandler, SetStateAction, useEffect, useState } from 'react';
import { Brightness1, Brightness4TwoTone } from '@mui/icons-material';
import { LogoutMenuItem } from './LoginButton';
import { useColorMode } from './ThemeProviderWithColorMode';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { signOut, getUserId, getAccessTokenPayloadSecurely } from 'supertokens-auth-react/recipe/session';
import { SessionInfo, useData } from './data';

export interface AppBarProps {
  environment?: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const AppBar: FC<AppBarProps> = props => {
  const { environment, open, setOpen } = props;
  const { getSessionInfo } = useData();
  const [ metadata, setMetadata ] = useState<SessionInfo[ 'metadata' ] | undefined>();
  // @ts-ignore: 2339
  const { doesSessionExist, loading } = useSessionContext();
  const colorMode = useColorMode();
  const theme = useTheme();
  const backgroundColor = theme.palette.primary.main;

  useEffect( () => {
    ( async () => {
      if( metadata || !doesSessionExist ) return;
      const { metadata: data } = await getSessionInfo() || {};
      if( data ) setMetadata( data );
    } )();
  }, [ getSessionInfo, metadata, doesSessionExist ] );
  const { name, pictureUrl } = ( metadata || {} ) as { name?: string, pictureUrl?: string };

  const ProfileButton = () => {
    const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>( null );
    const open = Boolean( anchorEl );
    const handleClick: MouseEventHandler<HTMLButtonElement> = ( event ) => {
      setAnchorEl( event.currentTarget );
    };
    const handleClose = () => {
      setAnchorEl( null );
    };
    return (
      <>
        <Tooltip title={name} >
          <IconButton
            color='inherit'
            onClick={handleClick}
          >
            {pictureUrl
              ? (
                <Box sx={{ display: 'flex', verticalAlign: 'bottom', border: '0px yellow solid' }}>
                  <Box sx={{ borderRadius: '50%', overflow: 'hidden', display: 'inline-block', border: '0px red solid' }} >
                    <img src={pictureUrl} width='36' height='36' style={{ verticalAlign: 'bottom' }} />
                  </Box>
                  <Box sx={{ display: 'inline-block', border: '0px red solid', marginLeft: '0.5rem' }} >
                    <Typography variant='h6' sx={{ border: '0px blue solid', fontSize: '80%' }}>{name}</Typography>
                  </Box>
                </Box>

              )
              : <Person />
            }
          </IconButton>

        </Tooltip>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <LogoutMenuItem onClick={handleClose} />
        </Menu>
      </>
    )
  }

  return (
    <MuiAppBar
      position='static'
      sx={{
        backgroundColor: theme.palette.grey[ 900 ],
        zIndex: 4, // ( theme ) => theme.zIndex.drawer + 2,
      }}
    >
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          className={open ? 'opened' : 'closed'}
          sx={{
            // mr: 2,
            '&.closed': {
              transition: theme.transitions.create( [ 'transform' ], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              } ),
              transform: 'rotate(0deg)',
            },
            '&.opened': {
              transition: theme.transitions.create( [ 'transform' ], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              } ),
              transform: 'rotate(180deg)',
            },
          }}
          onClick={() => setOpen( !open )}
          disabled={!doesSessionExist}
        >
          <MenuIcon />
        </IconButton>

        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}
          color='inherit'
        >
          Tenant Operations
        </Typography>

        {doesSessionExist &&
          <>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}
              fontWeight='bold'
              color='inherit'
            >
              {environment}
            </Typography>
            <IconButton
              color='inherit'
              onClick={() => colorMode.toggleColorMode()}
            >
              {theme.palette.mode == 'dark' ? <Brightness7 /> : <Brightness4 />}
            </IconButton>
            <ProfileButton />
          </>
        }
      </Toolbar>
    </MuiAppBar >
  );
}

