import { Box, Paper } from '@mui/material';
import { FC } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { NavTabs } from './NavTabs';
import { TenantContext } from './TenantContainer';

export interface TenantLaunchContext extends TenantContext {
  // stack?: Stack;
  maybe?: string;
}

export const TenantLaunch: FC = () => {
  // const tenant = useLoaderData() as Tenant;
  const tenantContext = useOutletContext() as TenantContext;


  const tabs = [
    { label: 'Summary', to: 'summary' },
    { label: 'Secret', to: 'secret' },
    { label: 'DNS', to: 'dns' },
    { label: 'Init', to: 'init' },
    { label: 'EMR', to: 'emr' },
    { label: 'Test', to: 'test' },
  ];

  const context: TenantLaunchContext = { ...tenantContext };

  return (
    <Box>
      <NavTabs tabs={tabs} />

      <Outlet
        context={context}
      />

    </Box>
  );

}


