import { FC, useCallback, useEffect, useState } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { ContentContext } from './Content';
import { ContentLanguage, useData } from './data';

export interface ContentLanguageContext extends ContentContext {
  language?: ContentLanguage;
  setLanguage?: ( l: ContentLanguage ) => void;
  getContentLanguage: ( id: string ) => void;
  inProgress: boolean;
}

export const ContentLanguageContainer: FC = () => {
  const { languageId } = useParams();
  const { getContentLanguage } = useData();
  const [ inProgress, setInProgress ] = useState( false );
  const [ language, setLanguage ] = useState<ContentLanguage>();
  const contentContext = useOutletContext() as ContentContext;

  const loadLanguage = useCallback( async () => {
    setLanguage( await getContentLanguage( languageId ) );
  }, [ languageId ] );

  useEffect( () => {
    ( async () => {
      setInProgress( true );
      await loadLanguage();
      setInProgress( false );
    } )();
  }, [ languageId, setLanguage, getContentLanguage ] );

  const context: ContentLanguageContext = {
    ...contentContext,
    language, setLanguage,
    getContentLanguage,
    inProgress,
  };

  return (
    <div id='ContentLanguage'>
      <Outlet {...{ context }} />
    </div>
  );
}
