import { Edit as EditIcon, FileUpload, OpenInNew } from '@mui/icons-material';
import { Box, Button, Link, TextField, Toolbar, Typography } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { get, pickBy, zipObject } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ConfirmDialog } from './components/ConfirmDialog';
import { ContentHtmlComponentContext } from './ContentHtmlComponentContainer';
import { ContentHtmlComponent as ContentHtmlComponentType, useData } from './data';
import { PropGrid } from './PropGrid';
import { PropTable, PropTableRecord } from './PropTable';

export const ContentHtmlComponent: FC = () => {
  const { htmlComponent, inProgress, setHtmlComponent, setNotify } = useOutletContext() as ContentHtmlComponentContext;
  const { getTenants, getContentHtmlComponent, updateContentHtmlComponent } = useData();
  const navigate = useNavigate();
  const [ tenantMods, setTenantMods ] = useState<Record<string, ContentHtmlComponentType>>();
  const [ openConfirm, setOpenConfirm ] = useState( '' );
  const [ _updateInProgress, setUpdateInProgress ] = useState( false );

  useEffect( () => {
    ( async () => {
      if( tenantMods || !htmlComponent?.id ) return;
      const tags = await getTenants();
      const mods = await Promise.all( tags.map( tag => {
        try {
          return getContentHtmlComponent( htmlComponent?.id, { tag } );
        }
        catch( e ) {
          return Promise.resolve( undefined );
        }
      }
      ) );
      const map = pickBy( zipObject( tags, mods ) ) as Record<string, ContentHtmlComponentType>;
      setTenantMods( map );
    } )();
  }, [ htmlComponent ] );
  const updateContentValue = useCallback( async ( newValue?: string ) => {
    if( !newValue || !htmlComponent?.id || !setHtmlComponent ) return;
    const { id } = htmlComponent;
    setHtmlComponent( { ...htmlComponent, value: newValue } );
    setUpdateInProgress( true );
    const update = await updateContentHtmlComponent( id, { ...htmlComponent, value: newValue } );
    if( update ) {
      setHtmlComponent( update );
      setNotify( 'Updated htmlComponent value.' );
    }
  }, [ htmlComponent ] );

  const record: PropTableRecord | undefined = useMemo( () => {
    if( !htmlComponent ) return undefined;
    const { id, value, comment, updatedAt } = htmlComponent;
    return ( {
      id: id.length != id.trim().length ? `"${ id }"` : id,
      value: <TextField
        margin='none'
        variant='outlined'
        multiline
        fullWidth
        value={value}
      />,
      comment: <TextField
        margin='none'
        variant='outlined'
        multiline
        fullWidth
        value={comment}
      />,
      updated: updatedAt,
    } );
  }, [ htmlComponent ] );

  const modRecords = useMemo<PropTableRecord[] | undefined>( () => {
    if( !tenantMods ) return;
    return Object.entries( tenantMods ).map( ( [ tenant, mod ] ) => {
      const { id, value, updatedAt: updated } = mod || {};
      return {
        tenant,
        updated: updated ? formatDistanceToNow( new Date( updated ) ) : '',
        value: <Typography
          sx={{
            fontWeight: value != htmlComponent?.value ? 'bold' : undefined,
          }}
        >
          {value}
        </Typography>,
        actions: (
          <>
            <Button
              startIcon={<FileUpload />}
              onClick={() => setOpenConfirm( tenant )}
              disabled={value == htmlComponent?.value}
            >
              make default
            </Button>
            <Link href={`https://admin.${ tenant }.analoginformation.com/?#/htmlComponents/${ encodeURIComponent( id ) }`} target="_blank" rel="noopener" color="inherit" >
              <Button
                endIcon={<OpenInNew />}
              >
                edit
              </Button>
            </Link>

          </>
        ),
      };
    } );
  }, [ tenantMods, htmlComponent ] );

  return (
    <Box>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          startIcon={<EditIcon />}
          onClick={() => navigate( 'edit' )}
          disabled={!!inProgress}
        >
          edit
        </Button>
      </Toolbar>
      <PropTable label='Content Central' record={record} inProgress={inProgress} unsort />

      <Box mt='2rem'>
        <PropGrid label='Tenant modifications' records={modRecords} unsort emptyLabel='None found' />
      </Box>

      <ConfirmDialog
        open={!!openConfirm}
        title={`Update "${ htmlComponent?.name }" default for all tenants`}
        message={`Are you sure you want to update the default value to "${ get( tenantMods, [ openConfirm, 'value' ] ) }" for all tenants?`}
        confirmButton='Update'
        onClose={( confirmed ) => {
          if( confirmed ) {
            openConfirm ? updateContentValue( get( tenantMods, [ openConfirm, 'value' ] ) ) : undefined;
          }
          setOpenConfirm( '' );
        }}
      />

    </Box>
  );
}
