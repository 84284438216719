import { Check, Close } from '@mui/icons-material';
import { Box, Chip, useTheme } from '@mui/material';
import { capitalize } from 'lodash';
import { FC, useCallback } from 'react';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import { useData } from './data';
import { PropGridWithPaging } from './PropGrid';
import { PropTableRecord } from './PropTable.js';

export const ContentLanguagesContainer: FC = () => {
  const context = useOutletContext();
  return (
    <Box>
      <Outlet
        context={context}
      />
    </Box>
  );
}

export const ContentLanguages: FC = () => {
  const { getContentLanguages } = useData();
  const navigate = useNavigate();
  const theme = useTheme();

  const getSortValue = ( key: string ) => {
    const replacements: Record<string,string> = {
      name: '_id',
      native: 'nativeName',
      flag: 'flag',
      RTL: 'rtl',
      supported: 'isSupported',
      enabled: 'isEnabled',
      updated: 'updatedAt',
    };
    const direction = key.startsWith( '-' ) ? '-' : '';
    const value = key.replace( /^-/, '' );
    return direction + ( replacements[ value ] || value );
  }

  const fetchRecords = useCallback( async ( page: number, pageSize: number, sort?: string ) => {
    const params: Record<string,string> = {
      'page[number]': `${page + 1}`, // 1-indexed pagination
      'page[size]': `${pageSize}`,
    };
    if( sort ) params[ 'sort' ] = getSortValue( sort );
    const { total, data } = await getContentLanguages( params );
    const records = data.map( record => ( {
      _tag: record.id,
      name: record.name,
      native: record.nativeName,
      flag: <span style={{ fontSize: '2.5rem' }}>{ record.flag }</span>,
      SVG: record.svg ?
        <img
          src={ record.svg }
          style={ { height: '2rem', border: `1px solid ${ theme.palette.divider }` } }
          alt="missing"
        /> :
        undefined,
      RTL: record.rtl ? <Check color='primary' /> : <></>,
      supported: record.isSupported ? <Check color='primary' /> : <Close color='error' />,
      enabled: record.isEnabled ? <Check color='primary' /> : <Close color='error' />,
      translators: <>{record.translators?.map( t => <Chip key={ t } label={ capitalize( t ) } style={{ margin: '2px' }} /> )}</>,
      updated: record.updatedAt,
    } ) );
    return { records, total };
  }, [ getContentLanguages ] );

  const rowClick = useCallback( ( record: PropTableRecord ) => {
    if( !record._tag ) return;
    navigate( `./${ record._tag }` );
  }, [] );

  return (
    <Box>
      <PropGridWithPaging
        label={ '' }
        fetchRecords={ fetchRecords }
        rowClick={ rowClick }
      />
    </Box>
  );

}


