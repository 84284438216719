import { CheckCircle as DoneIcon, OpenInNew, PowerSettingsNew as InitIcon } from '@mui/icons-material';
import { Box, Button, Link as MuiLink, Toolbar, Tooltip, Typography } from '@mui/material';
import { omit } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useData } from './data';
import { PropTable, PropTableRecord } from './PropTable';
import { TenantContext } from './TenantContainer';

export const TenantInit: FC = () => {
  const { tenant, setNotify } = useOutletContext() as TenantContext;
  const { tag } = tenant;
  const [ inProgress, setInProgress ] = useState<'load' | 'init' | ''>( '' );
  const { initTenantDatabase, getTenantDatabaseOrg, getTenantDatabaseTags } = useData();
  const [ org, setOrg ] = useState<PropTableRecord | undefined>();
  const [ tags, setTags ] = useState<PropTableRecord[]>( [] );

  const loadData = useCallback( async () => {
    setOrg( await getTenantDatabaseOrg( tag ) );
    setTags( await getTenantDatabaseTags( tag ) );
  }, [ tag ] );

  useEffect( () => {
    ( async () => {
      setInProgress( 'load' );
      await loadData();
      setInProgress( '' );
    } )();
  }, [ tag, loadData ] );

  const onInit = useCallback( async () => {
    setInProgress( 'init' );
    await initTenantDatabase( tag );
    setNotify( `Initialized database for "${ tag }"` )
    await loadData();
    setInProgress( '' );
  }, [ tag, initTenantDatabase ] )

  const orgRecord = useMemo( () => !org ? org : omit( org, [ 'root', 'parent', 'children' ] ), [ org ] );

  return (
    <Box>
      {org && !inProgress &&
        <Typography
          color='success'
          fontSize='110%'
          fontWeight='bold'
          sx={{
            marginBottom: '2rem',
            '& .MuiSvgIcon-root': { verticalAlign: 'top' },
          }}
        >
          <DoneIcon color='success' />&nbsp;Database initialized
        </Typography>
      }

      <PropTable label='Parent organization' record={orgRecord} inProgress={!!inProgress} />

      {org && !inProgress &&
        <Typography
          color='success'
          fontSize='110%'
          fontWeight='bold'
          sx={{
            marginBottom: '2rem',
            '& .MuiSvgIcon-root': { verticalAlign: 'top' },
            '& img': { verticalAlign: 'top' },
            '& .MuiButton-root': { marginLeft: '1rem', textTransform: 'inherit' },
          }}
        >
          <DoneIcon color='success' />&nbsp;Site code initialized:
          <Tooltip title={`https://admin.${ tag }.analoginformation.com/logo192.png`}>
            <MuiLink
              href={`https://admin.${ tag }.analoginformation.com`}
              target='_blank'
              rel='noopener'
              underline='none'
            >
              <Button
                variant='outlined'
              >
                <img width={32} src={`https://admin.${ tag }.analoginformation.com/logo192.png`} /> Admin
              </Button>
            </MuiLink>
          </Tooltip>
          <Tooltip title={`https://${ tag }.analoginformation.com/logo192.png`}>
            <MuiLink
              href={`https://${ tag }.analoginformation.com`}
              target='_blank'
              rel='noopener'
              underline='none'
            >
              <Button
                variant='outlined'

              >
                <img width={32} src={`https://${ tag }.analoginformation.com/logo192.png`} /> App
              </Button>
            </MuiLink>
          </Tooltip>
        </Typography>
      }


      <Toolbar>
        {!org && !inProgress &&
          <Button
            startIcon={<InitIcon />}
            onClick={onInit}
            disabled={!!inProgress}
            variant='contained'
          >
            Initialize parent organization
          </Button>
        }
        {org && !inProgress && !tags.length &&
          <MuiLink
            href={`https://admin.${ tag }.analoginformation.com/#/admin/system/initDatabase`}
            target='_blank'
            rel='noopener'
            underline='none'
          >
            <Button
              startIcon={<InitIcon />}
              variant='contained'
              disabled={!!inProgress}
            >
              Initialize database
            </Button>
          </MuiLink>
        }
        <Box sx={{ flexGrow: 1 }} />
        {org && !inProgress && !!tags.length &&
          <MuiLink
            href={`https://admin.${ tag }.analoginformation.com/#/admin/system/initDatabase`}
            target='_blank'
            rel='noopener'
            underline='none'
          >
            <Button
              disabled={!!inProgress}
              endIcon={<OpenInNew />}
            >
              Reinitialize database
            </Button>
          </MuiLink>
        }
      </Toolbar>

    </Box>
  );
}


