import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui';
import { AuthPage as OgAuthPage } from 'supertokens-auth-react/ui';


export const AuthPage: FC = () => {
  const navigate = useNavigate();

  return (
    <OgAuthPage
      // factors={ [] }
      preBuiltUIList={[ EmailPasswordPreBuiltUI ]}
      navigate={navigate}
      redirectOnSessionExists
      // useSignUpStateFromQueryString
      isSignUp={false}
      userContext={{
      }}
    />
  );
}


