import { Add as AddIcon, Delete as DeleteIcon, RestartAlt as ResetIcon } from '@mui/icons-material';
import { Box, Button, Toolbar } from '@mui/material';
import { titleize } from 'inflection';
import { FC, useCallback, useEffect, useState } from 'react';
import { useOutletContext, useParams, useRevalidator } from 'react-router-dom';
import { ConfirmDialog } from './components/ConfirmDialog';
import { TenantDatabaseUser, useData } from './data';
import { PropTable } from './PropTable';
import { TenantContext } from './TenantContainer';

export const TenantDatabase: FC = () => {
  const { id } = useParams();
  // const databaseUser = useLoaderData() as TenantDatabaseUser | null;
  const { getTenantDatabaseUser } = useData();
  const [ inProgress, setInProgress ] = useState( false );
  const [ databaseUser, setDatabaseUser ] = useState<TenantDatabaseUser | undefined>();
  const { tenant, setNotify } = useOutletContext() as TenantContext;
  const { revalidate } = useRevalidator();
  const { tag } = tenant;
  const [ openConfirm, setOpenConfirm ] = useState<'reset' | 'delete' | ''>( '' );
  const { createTenantDatabaseUser, resetTenantDatabaseUser, deleteTenantDatabaseUser } = useData();

  const loadUser = useCallback( async () => {
    setDatabaseUser( undefined );
    const d = await getTenantDatabaseUser( id );
    if( !d ) return;
    setDatabaseUser( d );
  }, [ id ] );

  useEffect( () => {
    ( async () => {
      setInProgress( true );
      await loadUser();
      setInProgress( false );
    } )();
  }, [ id, setDatabaseUser, getTenantDatabaseUser ] );


  const onCreate = useCallback( async () => {
    setInProgress( true );
    await createTenantDatabaseUser( tag );
    setNotify( `Created database user "${ tag }"` )
    await loadUser();
    setInProgress( false );
  }, [ tag, createTenantDatabaseUser ] )

  const onDelete = useCallback( async () => {
    setInProgress( true );
    await deleteTenantDatabaseUser( tag );
    setNotify( `Deleted database user "${ tag }"` )
    await loadUser();
    setInProgress( false );
  }, [ tag, deleteTenantDatabaseUser ] )

  const onReset = useCallback( async () => {
    setInProgress( true );
    await resetTenantDatabaseUser( tag );
    setNotify( `Reset database user "${ tag }"` )
    await loadUser();
    setInProgress( false );
  }, [ tag, resetTenantDatabaseUser ] )

  // const { stats = {}, collections = {} } = database || {};
  const { username, roles: rolesList = [], scopes: scopesList = [], databaseName } = databaseUser || {};
  const user = databaseUser
    ? {
      username,
      databaseName,
      roles: rolesList.map( ( { roleName, databaseName } ) => `${ roleName }@${ databaseName }` ).join( ', ' ),
      scopes: scopesList.map( ( { name, type } ) => `${ type } ${ name }` ).join( ', ' ),
    }
    : undefined;

  // const colls = Object.entries( collections ).map( ( [ name, c ] ) => {
  //   const { size, count: records, storageSize, totalSize, totalIndexSize, nindexes: indexCount } = c;
  //   return { name, size, records, storageSize, totalSize, totalIndexSize, indexCount } as unknown as Record<string, number | boolean | string>;
  // } );
  // const sorted = sortBy( colls, c => c.name );

  return (
    <Box>

      <PropTable label='Database User' record={user || {}} inProgress={inProgress} />

      <Toolbar>
        {!databaseUser && !inProgress &&
          <Button
            startIcon={<AddIcon />}
            onClick={onCreate}
          >
            Create user
          </Button>
        }
        <Box sx={{ flexGrow: 1 }} />
        {databaseUser &&
          <>
            <Button
              startIcon={<DeleteIcon />}
              color='error'
              onClick={() => setOpenConfirm( 'delete' )}
            >
              Delete user
            </Button>

            <Button
              startIcon={<ResetIcon />}
              //color='error'
              onClick={() => setOpenConfirm( 'reset' )}
            >
              Reset user
            </Button>
          </>
        }
      </Toolbar>
      <ConfirmDialog
        open={!!openConfirm}
        title={`${ titleize( openConfirm ) } User`}
        message={`Are you sure you want to ${ openConfirm } the user, including password?`}
        confirmButton={titleize( openConfirm )}
        onClose={( confirmed ) => {
          if( confirmed ) {
            openConfirm == 'delete' ? onDelete() : onReset();
          }
          setOpenConfirm( '' );
        }}
      // fullScreen={isXSmall}
      />

      { /*
      {stats.length &&
        <PropTable label='Stats' record={stats} />
      }
      {sorted &&
        <PropGrid records={sorted} label='Collections' />
      }
*/ }
    </Box>
  );
}


