import EmailVerification from 'supertokens-auth-react/recipe/emailverification';
import { init } from 'supertokens-auth-react';
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword/index.js';
import Passwordless from 'supertokens-auth-react/recipe/passwordless/index.js';
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui.js';
import { PasswordlessPreBuiltUI } from 'supertokens-auth-react/recipe/passwordless/prebuiltui.js';
import Session from 'supertokens-auth-react/recipe/session/index.js';
import { SuperTokensConfig } from 'supertokens-auth-react/lib/build/types.js';
import Multitenancy from 'supertokens-auth-react/recipe/multitenancy/index.js';
import { RecipeImplementation } from 'supertokens-auth-react/lib/build/recipe/passwordless/types';

export function getApiDomain() {
  // const apiPort = process.env.REACT_APP_API_PORT || 3400; // 3001;
  const apiUrl = process.env.REACT_APP_API_SERVER || location.origin; // `http://localhost:3500`;
  return apiUrl;
}

export function getApiBasePath() {
  return '/api/v1/auth';
}

export function getWebsiteDomain() {
  // const websitePort = location.port; //  process.env.REACT_APP_WEBSITE_PORT || 3000;
  const websiteUrl = location.origin; //  process.env.REACT_APP_WEBSITE_URL || `http://localhost:${ websitePort }`;
  return websiteUrl;
}

export const superTokensConfig: SuperTokensConfig = {
  appInfo: {
    appName: 'Analog Concierge',
    apiDomain: getApiDomain(),
    apiBasePath: getApiBasePath(),
    websiteDomain: getWebsiteDomain(),
  },

  usesDynamicLoginMethods: true,

  disableAuthRoute: true,

  getRedirectionURL: async ( context ) => {
    if( context.action === 'SUCCESS' && context.newSessionCreated ) {
      if( context.redirectToPath !== undefined ) {
        // we are navigating back to where the user was before they authenticated
        return context.redirectToPath;
      }
      const params = new URLSearchParams( location.search );
      const redirectToPath = params.get( 'redirectToPath' );
      if( redirectToPath ) {
        return redirectToPath;
      }
      if( context.createdNewUser ) {
        // user signed up
      } else {
        // user signed in
      }
      // return '/app/profiles';
    }
    return undefined;
  },


  recipeList: [
    EmailPassword.init(),

    Session.init( {
      // exposeAccessTokenToFrontendInCookieBasedAuth: true,
    } ),

    Multitenancy.init( {
      override: {
        functions: ( oI ) => {
          return {
            ...oI,
            getTenantId: () => {
              return 'ops';
            }
          }
        }
      }
    } ),

    EmailVerification.init( {
      mode: 'REQUIRED',
    } ),

  ],

  clientType: 'web',
  // cookieHandler?: CookieHandlerInput;
  // windowHandler?: WindowHandlerInput;
  // dateProvider?: DateProviderInput;
  // usesDynamicLoginMethods?: boolean;
  // languageTranslations?: {
  //   defaultLanguage?: string;
  //   currentLanguageCookieScope?: string;
  //   translations?: TranslationStore;
  //   translationFunc?: TranslationFunc;
  // };
  // enableDebugLogs?: boolean;
  // getRedirectionURL?: (
  //   context: GetRedirectionURLContext,
  //   userContext: UserContext
  // ) => Promise<string | undefined | null>;
  // style?: string;
  // useShadowDom?: boolean;
  // disableAuthRoute?: boolean;
  // defaultToSignUp?: boolean;
  privacyPolicyLink: 'https://analoginformatics.com/privacy',
  termsOfServiceLink: 'https://analoginformatics.com/terms',
  style: `
        [data-supertokens~=authPage] [data-supertokens~=headerSubtitle] {
            display: none;
        }
        [data-supertokens~=superTokensBranding] {
            display: none;
        }
        [data-supertokens~=resendCodeBtn] strong {
            display: none;
        }
        [data-supertokens~=container] .MuiCardHeader-root {
            padding: 1rem 0;
        }
        [data-supertokens~=container] .MuiCardHeader-content .MuiTypography-root {
            font-size: 1.2rem;
        }
       [data-supertokens~=container] {
             --palette-primary: 46, 71, 102;
             --palette-primaryBorder: 146, 171, 202;
            // --palette-background: 51, 51, 51;
            // --palette-inputBackground: 41, 41, 41;
            // --palette-inputBorder: 41, 41, 41;
            // --palette-textTitle: 255, 255, 255;
            // --palette-textLabel: 255, 255, 255;
            // --palette-textPrimary: 255, 255, 255;
            // --palette-error: 173, 46, 46;
            // --palette-textInput: 169, 169, 169;
            // --palette-textLink: 169, 169, 169;
            --palette-superTokensBrandingBackground: 200,  0, 0, 0.001;
            --palette-superTokensBrandingText: 200,  0, 0, 0.001;
        }
      [data-supertokens~=secondaryLinkWithLeftArrow] {
         display: none;
      }
  `,
};

export const recipeDetails = {
  docsLink: 'https://supertokens.com/docs/emailpassword/introduction',
};

export const PreBuiltUIList = [ PasswordlessPreBuiltUI, EmailPasswordPreBuiltUI ]; // [EmailPasswordPreBuiltUI];

export const ComponentWrapper = ( props: { children: JSX.Element } ): JSX.Element => {
  return props.children;
};
