import { Add as AddIcon, ClearAll, Delete as DeleteIcon, RestartAlt } from '@mui/icons-material';
import { Box, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Toolbar, Tooltip, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { capitalize } from 'inflection';
import { mapKeys, mapValues, get } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { PickOptionDialog } from './components/PickOptionDialog';
import { UpdateCodeButton, UpdateCodeButtonOption } from './components/UpdateCodeButton';
import { ConfigTable } from './ConfigTable';
import { BaseInterface, TenantSiteCode, useData } from './data';
import { PropGrid } from './PropGrid';
import { PropTableRecord } from './PropTable';
import { useRouteLoaderData } from 'react-router-dom';
import { ConfirmDialog } from './components/ConfirmDialog';

export interface TenantSitesCodeContext {
  admin?: TenantSiteCode;
  app?: TenantSiteCode;
  ops?: TenantSiteCode;
}

export const TenantSitesCode: FC = () => {
  const base = useRouteLoaderData( 'app' ) as BaseInterface;
  const { environment } = base;
  const [ inProgress, setInProgress ] = useState( false );
  const [ inUpdate, setInUpdate ] = useState( '' );
  const [ open, setOpen ] = useState<string>( '' );
  const [ openConfirmDeploy, setOpenConfirmDeploy ] = useState<string>( '' );
  const [ sitesVersions, setSitesVersions ] = useState<TenantSitesCodeContext | undefined>();
  const [ sitesDeployeds, setSitesDeployeds ] = useState<TenantSitesCodeContext | undefined>();
  const [ tags, setTags ] = useState<string[]>( [] );
  const { getTenants, getTenantSitesAdminCode, getTenantSitesAppCode, getTenantSitesOpsCode, setTenantSitesAdminCode, setTenantSitesAppCode, updateSharedSitesCode, invalidateSitesCaches, deploySite } = useData();
  const theme = useTheme();
  const primary = theme.palette.primary.main;
  const deployPrefix = 'prod'; // 'dorp';

  const loadData = useCallback( async () => {
    const [ admin, app, ops, tags ] = await Promise.all( [
      getTenantSitesAdminCode(),
      getTenantSitesAppCode(),
      getTenantSitesOpsCode(),
      getTenants(),
    ] );
    setSitesVersions( { admin, app, ops } );
    setTags( tags );
    if( environment == 'aic-dev' ) {
      const params = { overridePrefix: deployPrefix };
      const [ admin, app, ops ] = await Promise.all( [
        getTenantSitesAdminCode( params ),
        getTenantSitesAppCode( params ),
        getTenantSitesOpsCode( params ),
      ] );
      setSitesDeployeds( { admin, app, ops } );
    }
  }, [] );

  useEffect( () => {
    ( async () => {
      if( sitesVersions ) return;
      setInProgress( true );
      await loadData();
      setInProgress( false );
    } )();
  }, [ loadData ] );

  const setSitesCodePath = useCallback( async ( id: string, originId: string, path?: string ): Promise<void> => {
    setInProgress( true );
    if( originId == 'admin' ) {
      await setTenantSitesAdminCode( id, { path } );
    } else {
      await setTenantSitesAppCode( id, { path } );
    }
    console.log( id, originId, path );
    await loadData();
    setInProgress( false );
  }, [] );

  const updateSite = useCallback( async ( originId: string, versionId: string ): Promise<void> => {
    setInUpdate( originId );
    await updateSharedSitesCode( originId, versionId );
    await loadData();
    setInUpdate( '' );
  }, [] );

  const deploy = useCallback( async ( originId: string, versionId?: string ): Promise<void> => {
    setInUpdate( `deploy:${ originId }` );
    await deploySite( originId, versionId );
    await loadData();
    setInUpdate( '' );
  }, [] );

  const invalidateCaches = useCallback( async (): Promise<void> => {
    setInUpdate( 'cache' );
    await invalidateSitesCaches();
    setInUpdate( '' );
  }, [] );

  const versionRecordsMap = useMemo<Partial<Record<keyof TenantSitesCodeContext, ( PropTableRecord[] )>>>( () => {
    if( !sitesVersions ) return { admin: [], app: [], ops: [] };
    return mapValues( sitesVersions, siteCode => ( siteCode?.versions || [] ).slice( 0, 8 ).map( v => {
      const { IsLatest, LastModified, Key, ChecksumSHA256: codeSha256 } = v;
      return {
        codeSha256: (
          <Typography
            sx={{
              color: IsLatest ? primary : undefined,
              fontWeight: IsLatest ? 'bold' : undefined,
            }}
          >
            {codeSha256}
          </Typography >
        ),
        Key, LastModified
      };
    } ) );
  }, [ sitesVersions ] );

  return (
    <Box>
      <Toolbar
        variant='dense'
      // disableGutters
      >
        <Box sx={{ flexGrow: 1 }} />
        <Button
          startIcon={inUpdate == 'cache' ? <CircularProgress size='1rem' /> : <ClearAll />}
          onClick={() => invalidateCaches()}
          disabled={inProgress || inUpdate == 'cache'}
        >
          Invalidate all caches
        </Button>
        <Box sx={{ flexGrow: 0.05 }} />
      </Toolbar>

      {Object.keys( versionRecordsMap ).map( key => {

        const siteContext = ( sitesVersions ? sitesVersions[ key as keyof TenantSitesCodeContext ] : undefined ) as TenantSiteCode | undefined;
        const siteVersions = siteContext?.versions;
        const prodDeployeds = !sitesDeployeds ? undefined : sitesDeployeds[ key as keyof TenantSitesCodeContext ]?.versions;
        const [ latest ] = siteVersions || [];
        const options = siteVersions?.map( v => ( {
          value: v.VersionId,
          label: <Typography>{v.ChecksumSHA256 || ''}</Typography>,
          disabled: v.ChecksumSHA256 == siteContext?.deployed || false,
        } ) ) as UpdateCodeButtonOption[];

        const records = versionRecordsMap[ key as keyof TenantSitesCodeContext ] || [];
        const deployed = siteContext?.deployed || 'Unknown';
        const overrides = siteContext?.overrides;
        const isLatest = deployed == latest?.ChecksumSHA256;
        const isLatestInProd = !!( prodDeployeds || [] ).find( d => d.ChecksumSHA256 == deployed );
        return (
          <Grid key={key} item xs={'auto'} mb={3}>
            <Toolbar
              disableGutters
              sx={{
                '& > .MuiButton-root,& > .MuiButtonGroup-root': {
                  marginLeft: '1.5rem',
                }
              }}
            >
              <Typography variant='h5'>{capitalize( key )} </Typography>
              <Box sx={{ flexGrow: 1 }} />
              {[ 'admin', 'app', 'ops' ].includes( key ) &&
                <>
                  {key != 'ops' &&
                    <Button
                      startIcon={<AddIcon />}
                      onClick={() => setOpen( key )}
                      disabled={inProgress}
                    >
                      Create override
                    </Button>
                  }
                  {environment == 'aic-dev' &&
                    <Button
                      onClick={() => setOpenConfirmDeploy( key )}
                      disabled={inProgress || !isLatest || isLatestInProd}
                      color='error'
                    >
                      Deploy to prod
                    </Button>
                  }
                  <UpdateCodeButton
                    startIcon={inUpdate == key ? <CircularProgress size='1rem' /> : undefined}
                    onClick={( versionId ) => {
                      updateSite( key, versionId );
                    }}
                    options={options || []}
                    disabled={isLatest}
                    disabledFully={!!inUpdate || inProgress || !siteContext?.deployed || key == 'ops'}
                  >
                    Update site
                  </UpdateCodeButton>
                </>
              }
              <Box sx={{ flexGrow: 0.1 }} />
            </Toolbar>

            <ConfigTable
              record={{
                Deployed: (
                  <Typography
                    sx={{
                      color: isLatest ? primary : undefined,
                      fontWeight: isLatest ? 'bold' : undefined,
                    }}
                  >
                    {deployed}
                  </Typography >
                ),
                ...( mapKeys( overrides || {}, ( _v, k ) => `Override: ${ k }` ) ),
              }}
              addOn={( k, _v ) => {
                if( !k.match( /^Override/ ) ) return null;
                const tag = k.split( /: +/ )[ 1 ];
                return (
                  <Tooltip title={`Delete ${ key } override for ${ tag }`} >
                    <IconButton
                      onClick={() => setSitesCodePath( tag, key, '' )}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                );
              }}
              label={''}
              inProgress={inProgress}
            />

            <PropGrid label='' records={records} unsort inProgress={inProgress} />

          </Grid>


        );
      }
      )}
      <PickOptionDialog
        open={!!open}
        title={`Pick a tenant to override for ${ open }`}
        onClose={( id ) => {
          console.log( 'override', open, id );
          if( id ) {
            setSitesCodePath( id, open, `/${ open }-test` );
          }
          setOpen( '' );
        }}
        choices={tags.filter( t => !Object.keys( get( sitesVersions, [ open, 'overrides' ], {} ) ).includes( t ) ).map( t => ( { value: t, label: t } ) )}
        message='message'
        label='Tenant'
        confirmButton='Add override'
      />
      <ConfirmDialog
        open={!!openConfirmDeploy}
        title={`Deploy "${ openConfirmDeploy }" to Prod`}
        message={`Are you sure you want to deploy "${ openConfirmDeploy }" to production?`}
        confirmButton='Deploy to Prod'
        onClose={( confirmed ) => {
          if( confirmed ) {
            deploy( openConfirmDeploy );
          }
          setOpenConfirmDeploy( '' );
        }}
      // fullScreen={isXSmall}
      />

    </Box >
  );

}


