import { CertificateDetail } from '@aws-sdk/client-acm';
import { Add as AddIcon, CheckCircleOutline, Delete as DeleteIcon } from '@mui/icons-material';
import { Box, Button, CircularProgress, Toolbar } from '@mui/material';
import { titleize } from 'inflection';
import { pick } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams, useRevalidator } from 'react-router-dom';
import { ConfirmDialog } from './components/ConfirmDialog';
import { useData } from './data';
import { promisedSleep } from './promised-sleep';
import { PropGrid } from './PropGrid';
import { PropTable, PropTableRecord } from './PropTable';
import { TenantContext } from './TenantContainer';

export const TenantCertificate: FC = () => {
  const { id } = useParams();
  // const { certificate } = useLoaderData() as { certificate?: CertificateDetail };
  const { getTenantCertificate } = useData();
  const [ certificate, setCertificate ] = useState<CertificateDetail | undefined>();
  const { tenant, setNotify } = useOutletContext() as TenantContext;
  const { tag } = tenant;
  const { DomainValidationOptions = [] } = certificate || {};
  const { revalidate } = useRevalidator();
  const navigate = useNavigate();

  const [ openConfirm, setOpenConfirm ] = useState<'delete' | ''>( '' );
  const [ inProgress, setInProgress ] = useState<'init' | 'load' | 'create' | 'validate' | 'delete' | ''>( 'init' );
  const { createTenantCertificate, deleteTenantCertificate, validateTenantCertificate } = useData();
  const [ reloads, setReloads ] = useState( 0 );
  const [ reloadCause, setReloadCause ] = useState<'create' | 'validate' | 'delete' | ''>( '' )

  const loadCert = useCallback( async () => {
    setCertificate( undefined );
    const c = await getTenantCertificate( id );
    setCertificate( c );
  }, [ id ] );

  useEffect( () => {
    ( async () => {
      setInProgress( 'load' );
      await loadCert();
      setInProgress( '' );
    } )();
  }, [ loadCert, setCertificate, getTenantCertificate ] );

  const onCreate = useCallback( async () => {
    setInProgress( 'create' );
    await createTenantCertificate( tag );
    setNotify( `Created certificate config for "${ tag }"` )
    revalidate();
    setReloads( 20 );
    setReloadCause( 'create' );
  }, [ tag, createTenantCertificate ] )

  const onValidate = useCallback( async () => {
    setInProgress( 'validate' );
    await validateTenantCertificate( tag );
    setNotify( `Validating certificate config for "${ tag }"` )
    revalidate();
    setReloads( 20 );
    setReloadCause( 'validate' );
  }, [ tag, validateTenantCertificate ] )

  const onDelete = useCallback( async () => {
    setInProgress( 'delete' );
    await deleteTenantCertificate( tag );
    setNotify( `Deleted certificate config for "${ tag }"` )
    revalidate();
    setReloads( 5 );
    setReloadCause( 'delete' );
  }, [ tag, deleteTenantCertificate ] )

  useEffect( () => {
    if( reloads <= 0 || !reloadCause ) return;
    if( reloadCause == 'delete' && !certificate
      || [ 'create', 'validate' ].includes( reloadCause ) && certificate?.Status == 'ISSUED'
    ) {
      setReloads( 0 );
      setReloadCause( '' );
      return;
    }
    ( async () => {
      // console.log( 'sleep' );
      await promisedSleep( 5000 );
      setReloads( reloads - 1 );
      navigate( '.' );
    } )()
  }, [ reloads, reloadCause, certificate ] );


  const certFields = 'DomainName Status CreatedAt SubjectAlternativeNames'.split( / +/ );
  const certRecord = !certificate ? {} : pick( certificate, certFields ) as PropTableRecord;

  const validationFields = 'ValidationDomain ValidationMethod ValidationStatus'.split( / +/ );
  const validations = DomainValidationOptions.map( v => pick( v, validationFields ) ) as PropTableRecord[];

  return (
    <Box>

      <PropTable label='Certificate' record={certRecord} inProgress={!!inProgress} />

      <Toolbar>
        {!inProgress && !certificate &&
          <Button
            startIcon={<AddIcon />}
            onClick={onCreate}
            disabled={!!inProgress}
          >
            Create certificate {inProgress}
          </Button>
        }
        <Box sx={{ flexGrow: 1 }} />
        {certificate &&
          <>
            <Button
              startIcon={<DeleteIcon />}
              color='error'
              onClick={() => setOpenConfirm( 'delete' )}
              disabled={!!inProgress}
            >
              Delete certificate
            </Button>

            {DomainValidationOptions[ 0 ]?.ValidationStatus == 'PENDING_VALIDATION' &&

              <Button
                startIcon={<CheckCircleOutline />}
                onClick={onValidate}
                disabled={!!inProgress}
              >
                Validate
              </Button>
            }
          </>
        }
      </Toolbar>
      <ConfirmDialog
        open={!!openConfirm}
        title={`${ titleize( openConfirm ) } Certificate`}
        message={`Are you sure you want to ${ openConfirm } the certificate?`}
        confirmButton={titleize( openConfirm )}
        onClose={( confirmed ) => {
          if( confirmed ) {
            openConfirm == 'delete' ? onDelete() : undefined;
          }
          setOpenConfirm( '' );
        }}
      // fullScreen={isXSmall}
      />

      <PropGrid label='Certificate Validations' records={validations || []} inProgress={!!inProgress} />


    </Box >
  );
}


