import { Box, Paper } from '@mui/material';
import { Dispatch, FC, SetStateAction } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { BaseContext } from './Base';
// import { ContentInterface } from './data';
import { NavTabs } from './NavTabs';

export { ContentLanguages } from './ContentLanguages';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ContentContext extends BaseContext {
}

export const Content: FC = () => {
  const context = useOutletContext() as BaseContext;

  const tabs = [
    { label: 'Languages', to: 'languages' },
    { label: 'Texts', to: 'texts' },
    { label: 'Snippets', to: 'snippets' },
    { label: 'EmailComponents', to: 'htmlComponents' },
    { label: 'MessageTemplates', to: 'messageTemplates' },
    { label: 'Voice', to: 'twimls' },
    { label: 'Backups', to: 'backups' },
  ];

  return (
    <Box
      sx={{
        '& .MuiTab-root': {
          fontSize: '140%',
          fontVariant: 'small-caps',
          textTransform: 'none',
        }
      }}
    >
      <NavTabs tabs={tabs} />

      <Paper
        sx={{
          padding: '1rem',
        }}
      >
        <Outlet
          context={context}
        />
      </Paper>
    </Box>
  );
}


