import { Box, CircularProgress, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { sortBy } from 'lodash';
import { FC, ReactNode, useMemo } from 'react';
import { formatValue, PropTableRecord, PropTableValue } from './PropTable';

export interface ConfigTableProps {
  record?: PropTableRecord;
  label: string;
  addOn?: ( key: string, value: PropTableValue ) => ReactNode;
  unsort?: boolean;
  inProgress?: boolean;
}

export const ConfigTable: FC<ConfigTableProps> = props => {
  const { record, label, addOn, unsort, inProgress } = props;

  const entries = useMemo( () => !record ? record : unsort ? Object.entries( record ) : sortBy( Object.entries( record ), p => p[ 0 ] ), [ record, unsort ] );

  return (
    <Box>
      <Typography
        key='label'
        sx={{
          fontWeight: 'bold',
          fontSize: '110%',
          marginBottom: '1rem',
        }}
      >
        {label}
      </Typography>

      <Box
        key='box'
        sx={{
          position: 'relative',
          minHeight: inProgress ? '12rem' : undefined,
        }}
      >
        {inProgress &&
          <Box
            key='progress'
            sx={{
              textAlign: 'center', padding: '3rem',
              position: 'absolute', zIndex: 10,
              top: '10%',
              left: '30%',
            }}>
            <CircularProgress />
          </Box>
        }
        <Table
          key='table'
          size='small' padding='none'
          sx={{
            '& .MuiTableCell-body': {
              fontSize: '1rem',
              opacity: inProgress ? 0.2 : undefined,
              borderBottomWidth: 0,
              paddingBottom: '1rem',
              paddingRight: '1rem',
              fontWeight: 'bold',
              verticalAlign: 'top',
              overflowX: 'hidden',
              '&.value': {
                paddingLeft: '2rem',
                fontWeight: 'bold',
              },
              '&.key': {
                fontWeight: 'inherit',
                paddingBottom: '0',
                // maxWidth: '10rem',
                // width: '6rem',
                // color: 'red',
              },
            },
          }}
        >
          <TableBody>
            {!entries && inProgress === undefined
              ? <TableRow><TableCell><CircularProgress /></TableCell></TableRow>
              : ( entries || [] ).map( ( [ key, value ] ) => (
                <>
                  <TableRow key={`${ key }-key`} >
                    <TableCell key='key' className='key'>{key}</TableCell>
                  </TableRow>
                  <TableRow key={`${ key }-value`} >
                    <TableCell className='value' key='value'>{formatValue( value )}</TableCell>
                    {addOn &&
                      <TableCell className='value' key='addon'>{addOn( key, value )}</TableCell>
                    }
                  </TableRow>

                </>
              ) )}
          </TableBody>
        </Table>

      </Box>
    </Box>
  )
}
