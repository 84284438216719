import { Edit as EditIcon, FileUpload, OpenInNew } from '@mui/icons-material';
import { Box, Button, Link, TextField, Toolbar, Typography } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { get, pick, pickBy, zipObject } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ConfirmDialog } from './components/ConfirmDialog';
import { ContentMessageTemplateContext } from './ContentMessageTemplateContainer';
import { useData, ContentMessageTemplate as ContentMessageTemplateType } from './data';
import { PropGrid } from './PropGrid';
import { PropTable, PropTableRecord } from './PropTable';

export const ContentMessageTemplate: FC = () => {
  const { messageTemplate, inProgress, setMessageTemplate, setNotify } = useOutletContext() as ContentMessageTemplateContext;
  const { getTenants, getContentMessageTemplate, updateContentMessageTemplate } = useData();
  const navigate = useNavigate();
  const [ tenantMods, setTenantMods ] = useState<Record<string, ContentMessageTemplateType>>();
  const [ openConfirm, setOpenConfirm ] = useState( '' );
  const [ _updateInProgress, setUpdateInProgress ] = useState( false );

  useEffect( () => {
    ( async () => {
      if( tenantMods || !messageTemplate?.id ) return;
      const tags = await getTenants();
      const mods = await Promise.all( tags.map( tag => {
        try {
          return getContentMessageTemplate( messageTemplate?.id, { tag } );
        }
        catch( e ) {
          return Promise.resolve( undefined );
        }
      }
      ) );
      const map = pickBy( zipObject( tags, mods ) ) as Record<string, ContentMessageTemplateType>;
      setTenantMods( map );
    } )();
  }, [ messageTemplate ] );

  const updateContentValue = useCallback( async ( newValue?: ContentMessageTemplateType ) => {
    const updateValue = pick( newValue, [ 'smsMessage', 'smsShortMessage', 'emailSubjectLine' ] );
    if( !newValue || !messageTemplate?.id || !setMessageTemplate ) return;
    const { id } = messageTemplate;
    setMessageTemplate( { ...messageTemplate, ...updateValue } );
    setUpdateInProgress( true );
    const update = await updateContentMessageTemplate( id, { ...messageTemplate, ...updateValue } );
    if( update ) {
      setMessageTemplate( update );
      setNotify( 'Updated messageTemplate value.' );
    }
  }, [ messageTemplate ] );

  const record: PropTableRecord | undefined = useMemo( () => {
    if( !messageTemplate ) return undefined;
    const { name, smsMessage, smsShortMessage, emailSubjectLine, updatedAt } = messageTemplate;
    return ( {
      name,
      smsMessage: <TextField
        margin='none'
        variant='outlined'
        multiline
        fullWidth
        value={smsMessage}
      />,
      smsShortMessage: <TextField
        margin='none'
        variant='outlined'
        multiline
        fullWidth
        value={smsShortMessage}
      />,
      emailSubjectLine: <TextField
        margin='none'
        variant='outlined'
        multiline
        fullWidth
        value={emailSubjectLine}
      />,
      updated: updatedAt,
    } );
  }, [ messageTemplate ] );

  const modRecords = useMemo<PropTableRecord[] | undefined>( () => {
    if( !tenantMods ) return;
    return Object.entries( tenantMods ).map( ( [ tenant, mod ] ) => {
      const { id, smsMessage, smsShortMessage, emailSubjectLine, updatedAt: updated } = mod || {};
      return {
        tenant,
        updated: updated ? formatDistanceToNow( new Date( updated ) ) : '',
        smsMessage: <Typography
          sx={{
            fontWeight: smsMessage != messageTemplate?.smsMessage ? 'bold' : undefined,
          }}
        >
          {smsMessage}
        </Typography>,
        smsShortMessage: <Typography
          sx={{
            fontWeight: smsShortMessage != messageTemplate?.smsShortMessage ? 'bold' : undefined,
          }}
        >
          {smsShortMessage}
        </Typography>,
        emailSubjectLine: <Typography
          sx={{
            fontWeight: emailSubjectLine != messageTemplate?.emailSubjectLine ? 'bold' : undefined,
          }}
        >{emailSubjectLine}</Typography>,
        actions: (
          <>
            <Button
              startIcon={<FileUpload />}
              onClick={() => setOpenConfirm( tenant )}
              disabled={smsMessage == messageTemplate?.smsMessage && smsShortMessage == messageTemplate?.smsShortMessage && emailSubjectLine == messageTemplate?.emailSubjectLine}
            >
              make default
            </Button>
            <Link href={`https://admin.${ tenant }.analoginformation.com/?#/messagetemplates/${ encodeURIComponent( id ) }`} target="_blank" rel="noopener" color="inherit" >
              <Button
                endIcon={<OpenInNew />}
              >
                edit
              </Button>
            </Link>

          </>
        ),
      };
    } );
  }, [ tenantMods, messageTemplate ] );

  return (
    <Box>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          startIcon={<EditIcon />}
          onClick={() => navigate( 'edit' )}
          disabled={!!inProgress}
        >
          edit
        </Button>
      </Toolbar>
      <PropTable label={messageTemplate?.id || ''} record={record} inProgress={inProgress} unsort />

      <Box mt='2rem'>
        <PropGrid label='Tenant modifications' records={modRecords} unsort emptyLabel='None found' />
      </Box>

      <ConfirmDialog
        open={!!openConfirm}
        title={`Update "${ messageTemplate?.name }" default for all tenants`}
        message={`Are you sure you want to update the default value for all tenants from "${ openConfirm }"?`}
        confirmButton='Update'
        onClose={( confirmed ) => {
          if( confirmed ) {
            openConfirm ? updateContentValue( get( tenantMods, openConfirm ) ) : undefined;
          }
          setOpenConfirm( '' );
        }}
      />

    </Box>
  );
}
