import styled from '@mui/material/styles/styled';
import MuiSvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const SvgIcon = styled( MuiSvgIcon, {
  name: 'LogoBlank',
  shouldForwardProp: ( prop ) => prop !== 'fill',
} )<SvgIconProps>( () => ( {
  // fill: '#f00',
  // stroke: 'currentColor',
  // strokeLinecap: 'round',
  // strokeLinejoin: 'round',
  // strokeWidth: '2.25px',
} ) );

SvgIcon.defaultProps = {
  viewBox: '0 0 180 180',
  focusable: 'false',
  'aria-hidden': 'true',
};


// https://png2svg.com

// <?xml version="1.0" encoding="UTF-8"?>
// <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
// <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="180px" height="180px" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" xmlns:xlink="http://www.w3.org/1999/xlink">
// <g><path style="opacity:0.999" fill="#97cc05" d="M 6.5,-0.5 C 61.8333,-0.5 117.167,-0.5 172.5,-0.5C 175.167,1.5 177.5,3.83333 179.5,6.5C 179.5,61.8333 179.5,117.167 179.5,172.5C 177.5,175.167 175.167,177.5 172.5,179.5C 117.167,179.5 61.8333,179.5 6.5,179.5C 3.83333,177.5 1.5,175.167 -0.5,172.5C -0.5,117.167 -0.5,61.8333 -0.5,6.5C 1.5,3.83333 3.83333,1.5 6.5,-0.5 Z"/></g>
// <g><path style="opacity:1" fill="#fdfefc" d="M 64.5,49.5 C 78.8372,49.3334 93.1705,49.5001 107.5,50C 127.982,56.7964 135.482,70.6297 130,91.5C 124.821,103.691 115.654,109.858 102.5,110C 100.294,107.791 99.9602,105.291 101.5,102.5C 105.965,100.621 110.298,98.4545 114.5,96C 124.136,84.9973 123.803,74.3307 113.5,64C 110.785,62.0922 107.785,60.7589 104.5,60C 95.1667,59.3333 85.8333,59.3333 76.5,60C 76,60.5 75.5,61 75,61.5C 74.6667,65.5 74.3333,69.5 74,73.5C 71,76.1667 68,76.1667 65,73.5C 64.5003,65.5069 64.3336,57.5069 64.5,49.5 Z"/></g>
// <g><path style="opacity:1" fill="#fcfdf9" d="M 67.5,83.5 C 70.368,83.085 72.5347,84.085 74,86.5C 74.4993,91.4889 74.6659,96.4889 74.5,101.5C 79.5111,101.334 84.5111,101.501 89.5,102C 92.9661,104.661 93.2994,107.661 90.5,111C 85.5111,111.499 80.5111,111.666 75.5,111.5C 75.6659,116.511 75.4993,121.511 75,126.5C 71.952,130.01 68.6187,130.343 65,127.5C 64.5007,122.511 64.3341,117.511 64.5,112.5C 59.4889,112.666 54.4889,112.499 49.5,112C 45.564,108.492 45.564,105.159 49.5,102C 54.1548,101.501 58.8215,101.334 63.5,101.5C 63.3342,96.8215 63.5008,92.1548 64,87.5C 64.6897,85.6498 65.8564,84.3164 67.5,83.5 Z"/></g>
// </svg>

export const LogoPointClickCare: FC<SvgIconProps> = ( props ) => {

  const { fill = 'rgb(248, 153, 29)', ...rest } = props;

  return (
    <SvgIcon {...rest}>
      <g><path fill="#97cc05" d="M 6.5,-0.5 C 61.8333,-0.5 117.167,-0.5 172.5,-0.5C 175.167,1.5 177.5,3.83333 179.5,6.5C 179.5,61.8333 179.5,117.167 179.5,172.5C 177.5,175.167 175.167,177.5 172.5,179.5C 117.167,179.5 61.8333,179.5 6.5,179.5C 3.83333,177.5 1.5,175.167 -0.5,172.5C -0.5,117.167 -0.5,61.8333 -0.5,6.5C 1.5,3.83333 3.83333,1.5 6.5,-0.5 Z" /></g>
      <g><path fill="#fdfefc" d="M 64.5,49.5 C 78.8372,49.3334 93.1705,49.5001 107.5,50C 127.982,56.7964 135.482,70.6297 130,91.5C 124.821,103.691 115.654,109.858 102.5,110C 100.294,107.791 99.9602,105.291 101.5,102.5C 105.965,100.621 110.298,98.4545 114.5,96C 124.136,84.9973 123.803,74.3307 113.5,64C 110.785,62.0922 107.785,60.7589 104.5,60C 95.1667,59.3333 85.8333,59.3333 76.5,60C 76,60.5 75.5,61 75,61.5C 74.6667,65.5 74.3333,69.5 74,73.5C 71,76.1667 68,76.1667 65,73.5C 64.5003,65.5069 64.3336,57.5069 64.5,49.5 Z" /></g>
      <g><path fill="#fcfdf9" d="M 67.5,83.5 C 70.368,83.085 72.5347,84.085 74,86.5C 74.4993,91.4889 74.6659,96.4889 74.5,101.5C 79.5111,101.334 84.5111,101.501 89.5,102C 92.9661,104.661 93.2994,107.661 90.5,111C 85.5111,111.499 80.5111,111.666 75.5,111.5C 75.6659,116.511 75.4993,121.511 75,126.5C 71.952,130.01 68.6187,130.343 65,127.5C 64.5007,122.511 64.3341,117.511 64.5,112.5C 59.4889,112.666 54.4889,112.499 49.5,112C 45.564,108.492 45.564,105.159 49.5,102C 54.1548,101.501 58.8215,101.334 63.5,101.5C 63.3342,96.8215 63.5008,92.1548 64,87.5C 64.6897,85.6498 65.8564,84.3164 67.5,83.5 Z" /></g>
    </SvgIcon >
  );
};

export default LogoPointClickCare;

