import { FunctionConfiguration } from '@aws-sdk/client-lambda';
import { RestartAlt as ReloadIcon, SystemUpdateAlt as UpdateIconAlt } from '@mui/icons-material';
import { Box, Button, CircularProgress, Toolbar } from '@mui/material';
import { titleize } from 'inflection';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useOutletContext, useParams, useRevalidator } from 'react-router-dom';
import { ConfirmDialog } from './components/ConfirmDialog';
import { ConfigTable } from './ConfigTable';
import { useData } from './data';
import { PropTable } from './PropTable';
import { TenantContext } from './TenantContainer';

export type { FunctionConfiguration };

export const TenantLambda: FC = () => {
  const { id } = useParams();
  const { getTenantStackFunction } = useData();
  const [ stackFunction, setStackFunction ] = useState<FunctionConfiguration | undefined>();
  const { tenant, setNotify } = useOutletContext() as TenantContext;
  const { tag } = tenant;
  const { revalidate } = useRevalidator();
  const [ openConfirm, setOpenConfirm ] = useState<'reload' | 'update' | ''>( '' );
  const [ inProgress, setInProgress ] = useState<'load' | 'reload' | 'update' | ''>( '' );
  const { reloadTenantStackFunction, updateTenantStackFunction } = useData();

  const loadData = useCallback( async () => {
    setStackFunction( await getTenantStackFunction( id ) );
  }, [ id ] );

  useEffect( () => {
    ( async () => {
      setInProgress( 'load' );
      await loadData();
      setInProgress( '' );
    } )();
  }, [ id, getTenantStackFunction, setStackFunction ] );

  const onReload = useCallback( async () => {
    setInProgress( 'reload' );
    await reloadTenantStackFunction( tag );
    setNotify( `Reloaded enviroment for function` )
    await loadData();
    setInProgress( '' );
  }, [ tag, reloadTenantStackFunction ] )

  const onUpdate = useCallback( async () => {
    setInProgress( 'update' );
    await updateTenantStackFunction( tag );
    setNotify( `Updated function code` )
    await loadData();
    setInProgress( '' );
  }, [ tag, updateTenantStackFunction ] )

  const { record, environment } = useMemo( () => {
    if( !stackFunction ) return {};
    const { Environment, FunctionName, LastModified, CodeSha256, CodeSize, RevisionId, Role, State, Runtime } = stackFunction;
    const { Variables: environment = {} } = Environment || {};
    const record = { State, FunctionName, LastModified, CodeSize, CodeSha256, RevisionId, Role, Runtime };
    return {
      record,
      environment,
    };
  }, [ stackFunction ] );

  return (
    <Box>
      <PropTable label='Lambda' unsort record={record} inProgress={[ 'load', 'update' ].includes( inProgress )} />

      <Toolbar>

        <Box sx={{ flexGrow: 1 }} />
        {stackFunction &&
          <>
            <Button
              startIcon={<UpdateIconAlt />}
              color='error'
              onClick={() => setOpenConfirm( 'update' )}
              disabled={!!inProgress}
            >
              Update code
            </Button>
            <Button
              startIcon={<ReloadIcon />}
              onClick={onReload}
              disabled={!!inProgress}
            >
              Reload env
            </Button>

          </>
        }
      </Toolbar>
      <ConfirmDialog
        open={!!openConfirm}
        title={`${ titleize( openConfirm ) } function`}
        message={`Are you sure you want to ${ openConfirm } the function?`}
        confirmButton={titleize( openConfirm )}
        onClose={( confirmed ) => {
          if( confirmed ) {
            openConfirm == 'update' ? onUpdate() : onReload();
          }
          setOpenConfirm( '' );
        }}
      // fullScreen={isXSmall}
      />

      <ConfigTable label='Environment' record={environment} inProgress={[ 'load', 'reload' ].includes( inProgress )} />

    </Box>
  );
}


