import { Box, Breadcrumbs, Typography } from '@mui/material';
import { get } from 'lodash';
import { FC, ReactElement, useMemo } from 'react';
import { Link, useMatches } from 'react-router-dom';


export const useLocTitle = ( root = '' ) => {
  const matches = useMatches() as LocMatch[];

  return useMemo<string>( () => {
    const breadcrumbs = matches
      // first get rid of any matches that don't have handle and crumb
      .filter( ( match ) => !!match && !!get( match, 'handle.breadcrumb' ) )
      // now map them into an array of elements, passing the loader
      // data to each one
      .map( ( match, idx, matches ) => {
        const { pathname: to, handle: { breadcrumb } = {} } = match;
        const label = typeof breadcrumb == 'function' ? breadcrumb( { ...match, parent: idx > 0 ? matches[ idx - 1 ] : undefined } ) : breadcrumb;
        return { to, label };
      } );

    return [ ...breadcrumbs.reverse().map( b => b.label ), root ].filter( a => a ).join( ' - ' );
  }, [ matches ] );

}

// export type Match = ReturnType<typeof useMatches>[ number ];

export interface LocMatch<T extends Record<string, unknown> = Record<string, unknown>> extends Omit<ReturnType<typeof useMatches>[ number ], 'handle'> {
  data: T;
  parent?: LocMatch;
  handle?: {
    breadcrumb?: ReactElement | string | ( ( match: LocMatch & { parent?: LocMatch } ) => string );
  };
  // params, pathname from Match 
}

export interface LocBreadcrumbsProps {
  middleElement?: ReactElement;
  endElement?: ReactElement;
}

export const LocBreadcrumbs: FC<LocBreadcrumbsProps> = props => {
  const { middleElement, endElement } = props;
  const matches = useMatches() as LocMatch[];
  const breadcrumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter( ( match ) => !!match && !!get( match, 'handle.breadcrumb' ) )
    // now map them into an array of elements, passing the loader
    // data to each one
    .map( ( match, idx, matches ) => {
      const { pathname: to, handle: { breadcrumb } = {} } = match;
      const label = typeof breadcrumb == 'function' ? breadcrumb( { ...match, parent: idx > 0 ? matches[ idx - 1 ] : undefined } ) : breadcrumb;
      return { to, label };
    } );

  // if( !matches[ 0 ].pathname.match( /^\/app/ ) ) {
  //   breadcrumbs.unshift( { label: 'Home', to: '/' } );
  // }

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Breadcrumbs aria-label="breadcrumb"
        separator='•'
        sx={{
          marginBottom: '1rem',
          display: 'flex',
        }}
      >
        {breadcrumbs.map( ( breadcrumb, idx, breadcrumbs ) => {
          const { label, to } = breadcrumb;
          const last = idx === breadcrumbs.length - 1;


          return last
            ? (
              <Typography color="text.primary" key={to}>
                {label}
              </Typography>
            )
            : (
              <Link
                color="inherit"
                to={to}
                key={to}
                style={{
                  color: 'inherit',
                  textDecorationLine: 'none',
                }}
              >
                {label}
              </Link>
            );
        } )}
      </Breadcrumbs>
      <Box sx={{ flexGrow: 1 }} />
      {middleElement}
      <Box sx={{ flexGrow: 1 }} />
      {endElement}
    </Box>
  );
}


