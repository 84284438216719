import { FC, useCallback } from 'react';
import { useLoaderData, useNavigate, useOutletContext, useRouteLoaderData, useParams, useRevalidator } from 'react-router-dom';
import { BaseContext } from './Base';
import { ConfigForm } from './ConfigForm';
import { TenantCore, TenantCreateFormData, TenantEnvironment, useData } from './data';
import { TenantSecretContext } from './TenantSecretContainer';
import { TenantContext } from './TenantContainer';
import { ConfigProp, ConfigPropForm } from './ConfigPropForm';


export const TenantSecretEdit: FC = () => {
  const { id: tag, fieldKey } = useParams();
  const { envSecret } = useOutletContext() as TenantSecretContext;
  const { updateTenantEnvSecret } = useData();
  const navigate = useNavigate();
  const { revalidate } = useRevalidator();

  const onCancel = useCallback( () => {
    navigate( '../..' );
  }, [ navigate ] );

  const onSubmit = useCallback( async ( data: ConfigProp ) => {
    if( !tag ) return;
    const { key, value } = data;
    await updateTenantEnvSecret( tag, { [ key ]: value } );
    revalidate();
    navigate( '../..' );
  }, [ tag, updateTenantEnvSecret ] );

  if( !envSecret || !fieldKey ) return null;

  return (

    <ConfigPropForm
      title='Edit secret'
      data={envSecret}
      fieldKey={fieldKey}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );

}


