import { Logout as LogoutIcon } from '@mui/icons-material';
import { IconButton, ListItemIcon, ListItemText, MenuItem, MenuItemProps, Tooltip } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'supertokens-auth-react/recipe/session';

export const LogoutButton: FC = () => {
  const navigate = useNavigate();

  return (
    <Tooltip title='Logout'>
      <IconButton
        onClick={() => {
          signOut( {
            userContext: {
              returnToPath: window.location.origin
            }
          } );
          navigate( '/auth' );
        }
        }
        color='inherit'
      >
        <LogoutIcon />
      </IconButton>
    </Tooltip >
  );
};

export const LogoutMenuItem: FC<Omit<MenuItemProps, 'children'>> = props => {
  const { onClick: handleClose } = props;
  const navigate = useNavigate();

  return (
    <MenuItem
      onClick={( e ) => {
        signOut( {
          userContext: {
            returnToPath: window.location.origin
          }
        } );
        handleClose && handleClose( e );
        navigate( '/auth' );
      }}
    >
      <ListItemIcon>
        <LogoutIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>
        Logout
      </ListItemText>
    </MenuItem >
  );
};


