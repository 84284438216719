import { Add } from '@mui/icons-material';
import { Box, Button, CircularProgress, Paper, Toolbar, useTheme } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import { BaseContext } from './Base';
import { Tenant, useData } from './data';
import { EmrWithLogo } from './EmrWithLogo';
import { NavTabs } from './NavTabs';
import { PropGrid } from './PropGrid';
import { PropTableRecord } from './PropTable';

export const TenantsTabs: FC = () => {
  const context = useOutletContext() as BaseContext;

  const tabs = [
    { label: 'Summary', to: 'summary' },
    { label: 'Costs', to: 'costs' },
    { label: 'Code', to: 'code' },
    { label: 'Sites', to: 'sites' },
    { label: 'Templates', to: 'templates' },
  ];


  return (
    <Box>
      <NavTabs tabs={tabs} />
      <Paper
        sx={{
          padding: '1rem',
        }}
      >
        <Outlet
          context={context}
        />
      </Paper>
    </Box>
  );
}

export const Tenants: FC = () => {
  const navigate = useNavigate();
  // const { base } = useOutletContext() as BaseContext;
  // const { environment, stackName, status, stackId, createdAt, updatedAt } = base;
  const [ inProgress, setInProgress ] = useState( false );
  const [ tenants, setTenants ] = useState<Partial<Tenant>[] | undefined>();
  const { getTenants, getTenant } = useData();
  const theme = useTheme();
  const primary = theme.palette.primary.main;

  useEffect( () => {
    ( async () => {
      if( tenants ) return;
      setInProgress( true );
      const tags = await getTenants();
      setTenants( tags.map( tag => ( { tag } ) ) );
      const all = ( await Promise.all( tags.map( tag => getTenant( tag ) ) ) )
        .filter( ( t ): t is Tenant => !!t );
      setTenants( all );
      setInProgress( false );
    } )();
  }, [ getTenants, getTenant, tenants ] );

  const tenantRecords = useMemo( () => {
    return ( tenants || [] ).map( tenant => {
      const { tag, name = '', description = '', emrBrand, updatedAt } = tenant;
      return {
        _tag: tag,
        tag: (
          <Link
            // color="inherit"
            to={`../${ tag }`}
            key={tag}
            style={{
              color: primary,
              textDecorationLine: 'none',
            }}
          >
            {tag}
          </Link>
        ),
        name,
        description,
        EMR: (
          <>
            {emrBrand && emrBrand.split( ',' ).filter( s => s ).map( brand => (
              <Link
                to={`../${ tag }/launch/emr/${ brand }`}
                key={brand}
                style={{
                  color: primary,
                  textDecorationLine: 'none',
                }}
              >
                <EmrWithLogo brand={brand} />
              </Link>
            ) )
            }
          </>
        ),
        updatedAt,
      };
    } );
  }, [ tenants ] );

  const rowClick = useCallback( ( record: PropTableRecord ) => {
    if( !record._tag ) return;
    navigate( `../${ record._tag }` );
  }, [] );

  if( !tenants ) return <CircularProgress />;

  return (
    <Box>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }} />
        <Link
          color={primary}
          to='../create'
          style={{
            color: primary,
            textDecorationLine: 'none',
            textAlign: 'right',
          }}
        >
          <Button startIcon={<Add />} >
            Create
          </Button>
        </Link>
      </Toolbar>

      <Paper
        sx={{
          padding: '1rem',
        }}
      >

        <PropGrid label='' records={tenantRecords} inProgress={inProgress} rowClick={rowClick} />

      </Paper >
    </Box >
  );
}


