import { Box, Button, Grid, InputLabel, MenuItem, Select, Stack, TextField, Toolbar, Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { BaseContext } from './Base';
import { tagRe, TenantCreateFormData, useData } from './data';


export interface ConfigFormProps {
  title: string;
  data: Record<string, string>;
  onSubmit: ( data: Record<string, string> ) => Promise<void>;
  onCancel: () => void;
}

export const ConfigForm: FC<ConfigFormProps> = props => {
  const { title, data, onSubmit, onCancel } = props;

  const keys = useMemo( () => Object.keys( data || {} ), [ data ] ).sort();
  const { control, handleSubmit, watch, formState: { errors, isDirty, isValidating, isValid, isSubmitted, isSubmitting } } = useForm<Record<string, string>>( {
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    delayError: 100,
    defaultValues: data,
  } );

  if( !keys.length ) return null;

  return (
    <Box>
      <Typography gutterBottom fontSize='1.2rem' fontWeight='bold'
      >
        {title}
      </Typography>

      <form
        style={{
          width: '100%',
          marginBottom: '1rem',
        }}
      >
        <Grid container spacing={1} direction='column'
          sx={{ maxWidth: '40rem' }}

        >

          {Object.keys( data ).map( key => (
            <Grid item key={key}>
              <Controller
                name={key}
                control={control}
                render={( { field } ) => (
                  <TextField
                    {...field}
                    inputRef={field.ref}
                    required
                    margin='dense'
                    // sx={{ width: '40rem' }}
                    fullWidth
                    label={key}
                    variant='filled'
                    error={!!( errors || {} )[ key ]?.message}
                    helperText={( errors || {} )[ key ]?.message}
                  />
                )}
              />
            </Grid>
          ) )}

        </Grid>
      </form>
      <Toolbar>
        <Button
          variant='contained'
          disabled={!isDirty || isValidating || isSubmitting || isSubmitted || !isValid}
          onClick={handleSubmit( onSubmit, ( v ) => { console.log( errors, v ) } )}
          sx={{ fontSize: '90%' }}
        >
          Save
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          variant='outlined'
          onClick={onCancel}
          sx={{ fontSize: '90%' }}

        >
          Cancel
        </Button>
      </Toolbar>

    </Box >
  );
}


