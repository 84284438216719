import { Grid } from '@mui/material';
import { FC } from 'react';
import { useOutletContext } from 'react-router-dom';
import { BaseContext } from './Base';
import { ConfigTable } from './ConfigTable';


export const BaseOutputs: FC = () => {
  const { base } = useOutletContext() as BaseContext;
  const { outputs = {} } = base;

  return (
    <Grid item xs={'auto'}>
      <ConfigTable label='Outputs' record={outputs} />
    </Grid>

  );
}


