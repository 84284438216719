import { FC, useCallback, useEffect, useState } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { ContentContext } from './Content';
import { ContentTwiml, useData } from './data';

export interface ContentTwimlContext extends ContentContext {
  twiml?: ContentTwiml;
  setTwiml: ( twiml: ContentTwiml ) => void;
  getContentTwiml: ( id: string ) => void;
  inProgress: boolean;
}

export const ContentTwimlContainer: FC = () => {
  const { twimlId } = useParams();
  const { getContentTwiml } = useData();
  const [ inProgress, setInProgress ] = useState( false );
  const [ twiml, setTwiml ] = useState<ContentTwiml>();
  const contentContext = useOutletContext() as ContentContext;

  const loadTwiml = useCallback( async () => {
    setTwiml( await getContentTwiml( twimlId ) );
  }, [ twimlId ] );

  useEffect( () => {
    ( async () => {
      setInProgress( true );
      await loadTwiml();
      setInProgress( false );
    } )();
  }, [ twimlId, setTwiml, getContentTwiml ] );

  const context: ContentTwimlContext = {
    ...contentContext,
    twiml,
    setTwiml,
    getContentTwiml,
    inProgress,
  };

  return (
    <div id='ContentTwiml'>
      <Outlet {...{ context }} />
    </div>
  );
}
