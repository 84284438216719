import { Box, Divider, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import { sortBy } from 'lodash';
import { FC } from 'react';
import { useOutletContext } from 'react-router-dom';
import { BaseContext } from './Base';
import { prettyDate } from './lib/pretty';


export const BaseIndex: FC = () => {
  const { base, timeZoneName } = useOutletContext() as BaseContext;
  const { environment, stackName, status, stackId, tags = {}, createdAt, updatedAt, outputs = {}, parameters = {} } = base;
  const theme = useTheme();

  return (

    <Grid container alignItems='flex-start' columnSpacing={10} >
      <Grid item xs={'auto'}>
        <Table size='small' padding='none'
          sx={{
            '& .MuiTableCell-body': {
              fontSize: '1rem',
              borderBottomWidth: 0,
              paddingBottom: '1rem',
              paddingRight: '1rem',
              // fontWeight: 'bold',
              verticalAlign: 'top',
            },
            '& .MuiTableCell-head': {
              fontSize: '1rem',
              borderBottomWidth: 0,
              paddingBottom: '1rem',
              paddingRight: '1rem',
              fontWeight: 'bold',
              verticalAlign: 'top',
              width: '6rem',
              // color: 'red',
            },
          }}
        >
          <TableHead>
            <TableRow >
              <TableCell>Name</TableCell>
              <TableCell>Updated </TableCell>
              <TableCell>Status </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow >
              <TableCell>{stackName}</TableCell>
              <TableCell  >{prettyDate( updatedAt, timeZoneName )}</TableCell>
              <TableCell>{status}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>

      <Divider />
      <Grid item xs={'auto'}>
        <Table size='small' padding='none'
          sx={{
            '& .MuiTableCell-body': {
              fontSize: '1rem',
              borderBottomWidth: 0,
              paddingBottom: '1rem',
              paddingRight: '1rem',
              fontWeight: 'bold',
              verticalAlign: 'top',
              '&:first-of-type': {
                fontWeight: 'inherit',
                width: '6rem',
                // color: 'red',
              },
            },
          }}
        >
          <TableBody>
            <TableRow >
              <TableCell>Name</TableCell>
              <TableCell>{stackName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Created </TableCell>
              <TableCell  >{prettyDate( createdAt, timeZoneName )}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Updated </TableCell>
              <TableCell  >{prettyDate( updatedAt, timeZoneName )}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={'auto'}>
        <Table size='small' padding='none'
          sx={{
            '& .MuiTableCell-body': {
              fontSize: '1rem',
              borderBottomWidth: 0,
              paddingBottom: '1rem',
              paddingRight: '1rem',
              fontWeight: 'bold',
              verticalAlign: 'top',
              '&:first-of-type': {
                fontWeight: 'inherit',
                width: '6rem',
                // color: 'red',
              },
            },
          }}
        >
          <TableBody>
            <TableRow >
              <TableCell>Id </TableCell>
              <TableCell>{stackId}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell>Status</TableCell>
              <TableCell>{status}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>

    </Grid>
  );
}


