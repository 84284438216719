import { WebACL } from '@aws-sdk/client-wafv2';
import { Box, Typography, TypographyProps } from '@mui/material';
import { dump } from 'js-yaml';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ConfigTable } from './ConfigTable';
import { useData } from './data';
import { PropTable, PropTableRecord } from './PropTable';
import { get } from 'lodash';
import { PropGrid } from './PropGrid';

export const Pre: FC<TypographyProps> = props => <Typography component='pre' {...props} />;


export const TenantWaf: FC = () => {
  const { id } = useParams();
  const { getTenantStackWaf } = useData();
  const [ inProgress, setInProgress ] = useState( false );
  const [ waf, setWaf ] = useState<WebACL | undefined>();

  const loadData = useCallback( async () => {
    setWaf( await getTenantStackWaf( id ) );
  }, [ id ] );

  useEffect( () => {
    ( async () => {
      setInProgress( true );
      await loadData();
      setInProgress( false );
    } )();
  }, [ id, getTenantStackWaf, setWaf ] );

  const { record, rules } = useMemo( () => {
    const { DefaultAction = {}, Rules, Name, Description } = waf || {};
    const rules = Rules?.map( r => {
      const { Action, Name, Priority, Statement, VisibilityConfig } = r;
      const [ [ action, response ] ] = Object.entries( Action || {} );
      const [ [ type, params ] ] = Object.entries( Statement || {} );

      return {
        Priority: ( Priority ?? 0 ) + 1,
        Name,
        type,
        ...params, // : <Pre>{dump( params )}</Pre>,
        action,
        responseCode: get( response, 'CustomResponse.ResponseCode' ),
        visibility: <Pre>{dump( VisibilityConfig )}</Pre>,
      };
    } );
    const record = {
      Name,
      Description,
      DefaultAction: Object.keys( DefaultAction )[ 0 ],
    };
    return { record, rules };
  }, [ id, waf ] );


  return (
    <Box>
      <PropTable label='WAF' record={record} unsort inProgress={inProgress} />
      <PropGrid label='Rules' records={rules} unsort inProgress={inProgress} />
    </Box>
  );
}


