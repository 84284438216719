import styled from '@mui/material/styles/styled';
import MuiSvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const SvgIcon = styled( MuiSvgIcon, {
  name: 'LogoEyeMD',
  shouldForwardProp: ( prop ) => prop !== 'fill',
} )<SvgIconProps>( () => ( {
  // fill: '#f00',
  // stroke: 'currentColor',
  // strokeLinecap: 'round',
  // strokeLinejoin: 'round',
  // strokeWidth: '2.25px',
} ) );

SvgIcon.defaultProps = {
  viewBox: '20 16 65 65',
  focusable: 'false',
  'aria-hidden': 'true',
};

export const LogoEyeMD: FC<SvgIconProps> = ( props ) => {

  const { fill = 'rgb(248, 153, 29)', ...rest } = props;

  return (
    <SvgIcon {...rest}>
      <g><path fill="#fcfcfc" d="M -0.5,-0.5 C 32.8333,-0.5 66.1667,-0.5 99.5,-0.5C 99.5,32.8333 99.5,66.1667 99.5,99.5C 66.1667,99.5 32.8333,99.5 -0.5,99.5C -0.5,66.1667 -0.5,32.8333 -0.5,-0.5 Z" /></g>
      <g><path fill="#191918" d="M 56.5,20.5 C 57.4972,20.4701 58.1639,20.9701 58.5,22C 56.1205,27.3035 54.4539,32.8035 53.5,38.5C 52.1667,38.5 50.8333,38.5 49.5,38.5C 51.2721,32.2879 53.6055,26.2879 56.5,20.5 Z" /></g>
      <g><path fill="#29292a" d="M 37.5,23.5 C 38.8117,25.7432 39.4783,28.4098 39.5,31.5C 39.3333,34.1667 39.1667,36.8333 39,39.5C 37.9887,40.3366 36.8221,40.67 35.5,40.5C 35.2101,34.6755 35.8768,29.0088 37.5,23.5 Z" /></g>
      <g><path fill="#151515" d="M 73.5,32.5 C 74.675,32.281 75.675,32.6143 76.5,33.5C 72.9809,36.8535 69.3142,40.0202 65.5,43C 64.0347,43.6013 63.0347,43.1013 62.5,41.5C 66.0532,38.242 69.7199,35.242 73.5,32.5 Z" /></g>
      <g><path fill="#252525" d="M 22.5,33.5 C 23.7479,34.3339 24.4146,35.6672 24.5,37.5C 24.9657,40.4922 25.2991,43.4922 25.5,46.5C 24.5,46.5 23.5,46.5 22.5,46.5C 21.4027,42.1099 21.4027,37.7766 22.5,33.5 Z" /></g>
      <g><path fill="#17181c" d="M 59.5,47.5 C 53.7242,44.6117 47.7242,43.945 41.5,45.5C 35.1004,46.9502 29.267,49.6168 24,53.5C 22.9412,53.3699 22.1079,52.8699 21.5,52C 40.5488,35.5819 57.8822,37.0819 73.5,56.5C 73.1667,56.8333 72.8333,57.1667 72.5,57.5C 68.7235,53.3836 64.3901,50.0503 59.5,47.5 Z" /></g>
      <g><path fill="#3e457a" d="M 41.5,45.5 C 47.7242,43.945 53.7242,44.6117 59.5,47.5C 65.7319,56.0361 64.3986,63.2027 55.5,69C 42.8687,71.3604 36.702,66.1937 37,53.5C 38.3604,50.2648 40.527,47.7648 43.5,46C 42.9056,45.5357 42.2389,45.369 41.5,45.5 Z" /></g>
      <g><path fill="#1b1b1b" d="M 79.5,46.5 C 81.288,46.2148 82.9547,46.5481 84.5,47.5C 81.1267,49.1251 77.6267,50.4584 74,51.5C 72.7202,51.3865 71.8869,50.7199 71.5,49.5C 74.1836,48.247 76.8503,47.247 79.5,46.5 Z" /></g>
      <g><path fill="#eaf2fc" d="M 57.5,53.5 C 57.5,54.1667 57.5,54.8333 57.5,55.5C 56.1417,56.6256 54.8084,56.6256 53.5,55.5C 52.6258,53.2534 52.2924,50.9201 52.5,48.5C 56.5,47.8333 58.1667,49.5 57.5,53.5 Z" /></g>
      <g><path fill="#2b398e" d="M 57.5,55.5 C 57.5,54.8333 57.5,54.1667 57.5,53.5C 58.212,52.2613 59.212,51.7613 60.5,52C 62.9731,55.346 63.1398,58.846 61,62.5C 59.9887,63.3366 58.8221,63.67 57.5,63.5C 57.719,64.675 57.3857,65.675 56.5,66.5C 48.7442,70.2658 42.9109,68.2658 39,60.5C 37.4568,50.8739 41.6235,46.5406 51.5,47.5C 50.7369,50.1411 50.5702,52.8078 51,55.5C 52.4229,57.3451 53.5896,59.3451 54.5,61.5C 56.0383,59.7567 57.0383,57.7567 57.5,55.5 Z" /></g>
      <g><path fill="#131414" d="M 71.5,59.5 C 73.6456,59.6964 73.8122,60.6964 72,62.5C 55.7373,79.2388 38.904,79.7388 21.5,64C 22.5929,62.6346 23.9262,62.3013 25.5,63C 38.1438,73.0536 51.4771,74.0536 65.5,66C 67.7059,63.9633 69.7059,61.7966 71.5,59.5 Z" /></g>

    </SvgIcon >
  );
};

export default LogoEyeMD;

