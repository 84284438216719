import { FC, useCallback, useEffect, useState } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { ContentContext } from './Content';
import { ContentMessageTemplate, useData } from './data';

export interface ContentMessageTemplateContext extends ContentContext {
  messageTemplate?: ContentMessageTemplate;
  setMessageTemplate: ( mt: ContentMessageTemplate ) => void;
  getContentMessageTemplate: ( id: string ) => void;
  inProgress: boolean;
}

export const ContentMessageTemplateContainer: FC = () => {
  const { messageTemplateId } = useParams();
  const { getContentMessageTemplate } = useData();
  const [ inProgress, setInProgress ] = useState( false );
  const [ messageTemplate, setMessageTemplate ] = useState<ContentMessageTemplate>();
  const contentContext = useOutletContext() as ContentContext;

  const loadMessageTemplate = useCallback( async () => {
    setMessageTemplate( await getContentMessageTemplate( messageTemplateId ) );
  }, [ messageTemplateId ] );

  useEffect( () => {
    ( async () => {
      setInProgress( true );
      await loadMessageTemplate();
      setInProgress( false );
    } )();
  }, [ messageTemplateId, setMessageTemplate, getContentMessageTemplate ] );

  const context: ContentMessageTemplateContext = {
    ...contentContext,
    messageTemplate,
    setMessageTemplate,
    getContentMessageTemplate,
    inProgress,
  };

  return (
    <div id='ContentMessageTemplate'>
      <Outlet {...{ context }} />
    </div>
  );
}
