


export const prettyDate = ( date: Date | string | undefined, timeZone: string ): string => {
  if( !date ) return '';
  const d = typeof date == 'string' ? new Date( date ) : date;
  return d.toLocaleDateString( 'en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone } );
}

export const parseableDateTz = ( date: Date | string | undefined, timeZone: string ): string => {
  if( !date ) return '';
  const d = typeof date == 'string' ? new Date( date ) : date;
  return d.toLocaleDateString( 'en-US', { year: 'numeric', month: 'numeric', day: 'numeric', timeZone, timeZoneName: 'short' } );
}

export const prettyShortDate = ( date: Date | string | undefined, timeZone: string ): string => {
  if( !date ) return '';
  const d = typeof date == 'string' ? new Date( date ) : date;
  return d.toLocaleDateString( 'en-US', { weekday: 'short', year: undefined, month: 'short', day: 'numeric', timeZone } );
}

export const prettyTime = ( date: string | undefined, timeZone: string ) => {
  if( !date ) return '';
  const d = typeof date == 'string' ? new Date( date ) : date;
  return d.toLocaleTimeString( 'en-US', { timeStyle: 'short', timeZone } );
}

export const prettyTimeTz = ( date: string | undefined, timeZone: string ) => {
  if( !date ) return '';
  const d = typeof date == 'string' ? new Date( date ) : date;
  return d.toLocaleTimeString( 'en-US', { hour: 'numeric', minute: 'numeric', timeZoneName: 'short', timeZone } );
}

export const prettyDateTime = ( date: string | undefined, timeZone: string ) => {
  if( !date ) return '';
  const d = typeof date == 'string' ? new Date( date ) : date;
  return [
    d.toLocaleDateString( 'en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone } ),
    d.toLocaleTimeString( 'en-US', { timeStyle: 'short', timeZone } )
  ].join( ' ' );
}

export const prettyShortDateTime = ( date: string | undefined, timeZone: string ) => {
  if( !date ) return '';
  const d = typeof date == 'string' ? new Date( date ) : date;
  return [
    d.toLocaleDateString( 'en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', timeZone } ),
    d.toLocaleTimeString( 'en-US', { timeStyle: 'short', timeZone } )
  ].join( ' ' );
}

export const prettyTimezone = ( date: Date | string | undefined = new Date(), timeZone?: string ): string => {
  const d = typeof date == 'string' ? new Date( date ) : date;
  return d.toLocaleTimeString( 'en-US', { hour: '2-digit', hour12: false, timeZoneName: 'long', timeZone } ).slice( 3 );
}

export const shortDateTime = ( date: string | undefined, timeZone: string ) => {
  if( !date ) return '';
  const d = typeof date == 'string' ? new Date( date ) : date;
  return [
    d.toLocaleDateString( 'en-US', { weekday: undefined, year: undefined, month: 'short', day: 'numeric', timeZone } ),
    d.toLocaleTimeString( 'en-US', { timeStyle: 'medium', timeZone } )
  ].join( ' ' );
}

