import { Box, Paper } from '@mui/material';
import { Dispatch, FC, SetStateAction } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { BaseInterface } from './data';
import { NavTabs } from './NavTabs';


export interface BaseContext {
  base: BaseInterface;
  timeZoneName: string;
  setNotify: Dispatch<SetStateAction<string>>;
  setError: Dispatch<SetStateAction<string>>;
}

export const Base: FC = () => {
  const context = useOutletContext() as BaseContext;

  const tabs = [
    { label: 'Info', to: 'info' },
    { label: 'Tags', to: 'tags' },
    { label: 'Outputs', to: 'outputs' },
    { label: 'Parameters', to: 'parameters' },
  ];

  return (
    <Box>
      <NavTabs tabs={tabs} />

      <Paper
        sx={{
          padding: '1rem',
        }}
      >
        <Outlet
          context={context}
        />
      </Paper>
    </Box>
  );
}


