import { Add as AddIcon, Delete, Edit as EditIcon, PowerSettingsNew as InitIcon } from '@mui/icons-material';
import { Box, Button, IconButton, Toolbar, Tooltip } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { ConfirmDialog } from './components/ConfirmDialog';
import { ConfigTable } from './ConfigTable';
import { useData } from './data';
import { TenantSecretContext } from './TenantSecretContainer';

export const TenantSecret: FC = () => {
  const { id: tag } = useParams();
  const { envSecret, loadSecrets, inProgress, setInProgress } = useOutletContext() as TenantSecretContext;
  const [ openConfirm, setOpenConfirm ] = useState( '' );
  const navigate = useNavigate();
  const { deleteTenantEnvSecret, initTenantEnvSecret, resetTenantDatabaseUser } = useData();

  const needsInit = useMemo( () => {
    return !envSecret || !Object.keys( envSecret ).includes( 'SUPERTOKENS_API_KEY' );
  }, [ envSecret ] );

  const onInit = useCallback( async () => {
    if( !tag ) return;
    setInProgress( true );
    await initTenantEnvSecret( tag );
    await resetTenantDatabaseUser( tag ); // reset/save new MONGODB_PASSWORD in secrets
    await loadSecrets();
    setInProgress( false );
  }, [ initTenantEnvSecret, tag ] );

  const onDelete = useCallback( async ( key: string ) => {
    setInProgress( true );
    if( !tag ) return;
    await deleteTenantEnvSecret( tag, key );
    await loadSecrets();
    setInProgress( false );
  }, [ deleteTenantEnvSecret, tag ] );

  return (
    <Box >
      <Toolbar>
        {needsInit &&
          <Button
            startIcon={<InitIcon />}
            onClick={onInit}
            variant='contained'
          >
            Init
          </Button>
        }
        <Box sx={{ flexGrow: 1 }} />
        {!needsInit &&
          <Button
            startIcon={<InitIcon />}
            onClick={onInit}
          >
            Reinit
          </Button>
        }

        <Button
          startIcon={<AddIcon />}
          onClick={() => navigate( 'create' )}
        >
          Create
        </Button>
        {/* envSecret && !!Object.keys( envSecret ).length &&
          <Button
          startIcon={<EditIcon />}
          onClick={() => navigate( 'edit' )}
          >
          edit
          </Button>
        */}
      </Toolbar>

      <ConfigTable label='Secrets' record={envSecret} inProgress={inProgress}
        addOn={( key ) => (
          <>
            <Tooltip
              key={`${ key } delete`}
              title={`Delete ${ key }`}
            >
              <IconButton
                onClick={() => setOpenConfirm( key )}
              >
                <Delete />
              </IconButton>
            </Tooltip>
            <Tooltip
              key={`${ key } edit`}
              title={`Edit ${ key }`}
            >
              <IconButton
                onClick={() => navigate( `${ key }/edit` )}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        )
        }

      />

      <ConfirmDialog
        open={!!openConfirm}
        title={`Delete ${ openConfirm }`}
        message={`Are you sure you want to delete the ${ openConfirm } prop?`}
        confirmButton='Delete'
        onClose={( confirmed ) => {
          if( confirmed ) {
            onDelete( openConfirm );
          }
          setOpenConfirm( '' );
        }}
      // fullScreen={isXSmall}
      />

    </Box >
  );
}


