import { Box, Paper } from '@mui/material';
import { FC } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { NavTabs } from './NavTabs';
import { TenantContext } from './TenantContainer';

export interface TenantManageContext extends TenantContext {
  // stack?: Stack;
  maybe?: string;
}

export const TenantManage: FC = () => {
  // const tenant = useLoaderData() as Tenant;
  const tenantContext = useOutletContext() as TenantContext;

  const tabs = [
    { label: 'Summary', to: 'summary' },
    { label: 'App Site', to: 'app' },
    { label: 'Admin Site', to: 'admin' },
    { label: 'WAF', to: 'waf' },
    { label: 'Timers', to: 'timers' },
    { label: 'Server', to: 'server' },
  ];

  const context: TenantManageContext = { ...tenantContext };

  return (
    <Box>
      <NavTabs tabs={tabs} />

      <Outlet
        context={context}
      />

    </Box>
  );

}


