import { CircularProgress } from '@mui/material';
import { FC, useCallback } from 'react';
import { useNavigate, useOutletContext, useRevalidator } from 'react-router-dom'
import { ContentHtmlComponentContext } from './ContentHtmlComponentContainer';
import { ContentHtmlComponentForm, ContentHtmlComponentFormData } from './ContentHtmlComponentForm';
import { useData } from './data';

export const ContentHtmlComponentEdit: FC = () => {
  const { htmlComponent, inProgress } = useOutletContext() as ContentHtmlComponentContext;
  const { updateContentHtmlComponent } = useData();
  const navigate = useNavigate();
  const { revalidate } = useRevalidator();

  const onSubmit = useCallback( async ( data: ContentHtmlComponentFormData ) => {
    if( !data?.id ) return;
    await updateContentHtmlComponent( `${data.id}`, data );
    revalidate();
    navigate( '..' );
  }, [ updateContentHtmlComponent ] )

  if( inProgress ) return <CircularProgress />;
  return (
    <ContentHtmlComponentForm
      title='Edit HtmlComponent'
      data={ htmlComponent }
      onSubmit={ onSubmit }
    />
  )
}
