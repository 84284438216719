import { Box, Tab, Tabs, Toolbar } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { ContentContext } from './Content';
import { ContentBackup, ContentLanguage, useData } from './data';
import { PropTable, PropTableRecord } from './PropTable';

export interface ContentBackupContext extends ContentContext {
  backup?: ContentBackup;
  setBackup?: ( l: ContentBackup ) => void;
  getContentBackup: ( id: string ) => void;
  inProgress: boolean;
  languages?: ContentLanguage[];
}

export const ContentBackupContainer: FC = () => {
  const { backupId } = useParams();
  const { getContentBackup, getContentLanguages } = useData();
  const [ inProgress, setInProgress ] = useState( false );
  const [ backup, setBackup ] = useState<ContentBackup>();
  const [ languages, setLanguages ] = useState<ContentLanguage[] | undefined>();
  const contentContext = useOutletContext() as ContentContext;

  const loadBackup = useCallback( async () => {
    setBackup( await getContentBackup( backupId ) );
  }, [ backupId ] );

  useEffect( () => {
    ( async () => {
      setInProgress( true );
      await loadBackup();
      setInProgress( false );
    } )();
  }, [ backupId, setBackup, getContentBackup ] );

  useEffect( () => {
    ( async () => {
      if( languages ) return;
      const { data: langs } = await getContentLanguages();
      setLanguages( langs.filter( l => l.isActive ) );
    } )();
  }, [ setLanguages ] );


  const context: ContentBackupContext = {
    ...contentContext,
    backup, setBackup,
    getContentBackup,
    inProgress,
  };

  const record: PropTableRecord | undefined = useMemo( () => {
    if( !backup ) return undefined;
    const { id, updatedAt: created, size, url, versionId, metadata, preview = '' } = backup;
    const contents = (
      <Box component='pre'>
        {preview.trim()}
      </Box>

    );
    return ( { id, created, size, URL: url, versionId, metadata, contents } );

  }, [ backup ] );

  return (
    <Box id='ContentBackup'>
      <PropTable label={''} record={record} inProgress={inProgress} unsort />

      <Outlet {...{ context }} />

    </Box>
  );
}
