import { Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { TenantEnvironment, useData } from './data';

export interface TenantConfigContext {
  envConfig?: TenantEnvironment[ 'config' ];
}

export const TenantConfigContainer: FC = () => {
  const { id } = useParams();
  // const envConfig = useLoaderData() as TenantEnvironment[ 'config' ];
  const { getTenantEnvConfig } = useData();
  const [ envConfig, setEnvConfig ] = useState<TenantEnvironment[ 'config' ] | undefined>();

  useEffect( () => {
    ( async () => {
      setEnvConfig( undefined );
      const e = await getTenantEnvConfig( id );
      if( !e ) return;
      setEnvConfig( e );
    } )();
  }, [ id, setEnvConfig, getTenantEnvConfig ] );

  return (
    <Box>
      <Outlet
        context={{ envConfig }}
      />
    </Box>
  );

}


