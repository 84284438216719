import { Box, Button, Grid, TextField, Toolbar, Typography } from '@mui/material';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AuthUserCreate } from './data';

export interface UsersStaffFormProps {
  title: string;
  data?: AuthUserCreate;
  onSubmit: ( data: AuthUserCreate ) => Promise<void>;
}

export const UsersStaffForm: FC<UsersStaffFormProps> = props => {
  const { title, data, onSubmit } = props;
  const isCreate = !data;

  const { control, handleSubmit, formState: { errors, isDirty, isValidating, isValid, isSubmitted, isSubmitting } } = useForm<AuthUserCreate>( {
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: data || {
      name: '',
      email: '',
    } // fixes react (un)controlled error
  } );

  return (
    <Box>
      <Typography gutterBottom fontSize='1.2rem' fontWeight='bold'
      >
        {title}
      </Typography>

      <form
        style={{
          width: '100%',
          marginBottom: '1rem',
        }}
      >
        <Grid container spacing={1} direction='column'
          sx={{ maxWidth: '40rem' }}

        >


          <Grid item >
            <Controller
              name='name'
              control={control}
              rules={{
                minLength: {
                  value: 4,
                  message: 'Minimum of 4 characters'
                },
                maxLength: {
                  value: 32,
                  message: 'Maximum of 32 characters'
                },
              }}
              render={( { field } ) => (
                <TextField
                  {...field}
                  inputRef={field.ref}
                  required
                  margin='dense'
                  // sx={{ width: '40rem' }}
                  fullWidth
                  label='Name'
                  variant='filled'
                  error={!!errors?.name?.message}
                  helperText={errors?.name?.message}
                />
              )}
            />

          </Grid>

          {isCreate &&
            <Grid item>
              <Controller
                name='email'
                control={control}
                render={( { field } ) => (
                  <TextField
                    {...field}
                    inputRef={field.ref}
                    required
                    margin='dense'
                    label='Email'
                    fullWidth
                    variant='filled'
                    error={!!errors?.email?.message}
                    helperText={errors?.email?.message}
                  />
                )}
              />

            </Grid>
          }

        </Grid>
      </form>
      <Toolbar>
        <Button variant='contained'
          disabled={!isDirty || isValidating || isSubmitting || isSubmitted || !isValid}
          onClick={handleSubmit( onSubmit, ( v ) => { console.log( errors, v ) } )}
          sx={{ fontSize: '90%' }}
        // color='success'
        >
          Submit
        </Button>
        <Box sx={{ flexGrow: 1 }} />
      </Toolbar>

    </Box >
  );
}


