import { Add as CreateIcon } from '@mui/icons-material';
import { Box, Button, CircularProgress, Toolbar, useTheme } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import { ContentTextsContext } from './ContentTextsContainer';
import { useData } from './data';
import { PropGrid } from './PropGrid';
import { PropTableRecord } from './PropTable.js';

export const ContentBackupsContainer: FC = () => {
  const context = useOutletContext();
  return (
    <Box>
      <Outlet
        context={context}
      />
    </Box>
  );
}

export const ContentBackups: FC = () => {
  const { setNotify } = useOutletContext<ContentTextsContext>();
  const { getContentBackups, createContentBackup } = useData();
  const [ records, setRecords ] = useState<PropTableRecord[] | undefined>();
  const [ isCreating, setIsCreating ] = useState( false );
  const navigate = useNavigate();

  const fetchRecords = useCallback( async (): Promise<PropTableRecord[]> => {
    const { data: files } = await getContentBackups();
    return files.map( f => {
      const { id, updatedAt: created, size } = f;
      return { _id: id, created, backup: id, size }
    } ) as PropTableRecord[];
  }, [] );

  useEffect( () => {
    ( async () => {
      const records = await fetchRecords();
      setRecords( records );
    } )();
  }, [ getContentBackups ] );

  const handleCreate = useCallback( async () => {
    setIsCreating( true );
    setNotify( 'Creating backup' );
    await createContentBackup();
    setNotify( 'Finished creating backup' );
    setIsCreating( false );
    const records = await fetchRecords();
    setRecords( records );
  }, [ createContentBackup ] );

  const rowClick = useCallback( ( record: PropTableRecord ) => {
    if( !record._id ) return;
    navigate( `./${ record._id }` );
  }, [] );

  return (
    <Box>
      <Toolbar >
        <Box sx={{ flexGrow: 1 }} />
        <Button
          startIcon={<CreateIcon />}
          onClick={() => handleCreate()}
          disabled={isCreating}
        >
          create
        </Button>

      </Toolbar>
      <PropGrid
        label={''}
        records={records}
        rowClick={rowClick}
        unsort
      />
    </Box >
  );

}


