import { RestartAlt } from '@mui/icons-material';
import { Box, CircularProgress, Grid, Typography, useTheme } from '@mui/material';
import _, { without } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import { UpdateCodeButton } from './components/UpdateCodeButton';
import { S3HashVersion, Stack, useData } from './data';
import { PropGrid } from './PropGrid';
import { formatValue } from './PropTable';
import { StackEventStatus } from './StackEventStatus';
import { colorPalettes } from './color-palettes';
import { TenantContext } from './TenantContainer';

export const TenantStackTemplates: FC = () => {
  const [ tags, setTags ] = useState<string[] | undefined>();
  const [ versions, setVersions ] = useState<S3HashVersion[] | undefined>();
  const [ stacks, setStacks ] = useState<Partial<Stack>[] | undefined>();
  const [ isLoading, setIsLoading ] = useState( false );
  const [ inProgress, setInProgress ] = useState<string[]>( [] );
  // const [ latest, setLatest ] = useState<number>( 0 );
  const { getTenants, getTenantStackTemplates, getTenantStack, createTenantStack } = useData();
  const { setNotify } = useOutletContext() as TenantContext;
  const theme = useTheme();
  const primary = theme.palette.primary.main;

  const loadStack = useCallback( async ( tag: string ) => {
    const stack = await getTenantStack( tag );
    if( !stack?.stackName ) return;
    setStacks( prev => {
      if( !prev ) return [ stack ];
      const index = prev.findIndex( s => s.stackName == tag );
      return index == -1 ? [ ...prev, stack ] : [ ...prev.slice( 0, index ), stack, ...prev.slice( index + 1 ) ];
    } );
  }, [] );

  const fetchStacks = useCallback( async () => {
    if( isLoading || !tags || stacks?.length || !setStacks || !getTenantStack ) return;
    // Note, too many tags, no concurrency limit yet
    // const stacks = ( await Promise.all( tags.map( tag => getTenantStack( tag ) ) ) )
    //   .filter( ( s ): s is Stack => !!s?.stackName );
    setStacks( tags.map( tag => ( { stackName: tag } ) ) );
    for( const tag of tags ) {
      loadStack( tag );
    }
  }, [ isLoading, stacks, tags, setStacks, getTenantStack ] );

  useEffect( () => {
    ( async () => {
      if( tags ) return;
      setIsLoading( true );
      setTags( await getTenants() );
      setIsLoading( false );
    } )();
  }, [] );

  useEffect( () => {
    ( async () => {
      if( versions ) return;
      setVersions( ( await getTenantStackTemplates() ) || [] );
    } )();
  }, [ versions ] );

  useEffect( () => {
    if( !versions ) return;
    fetchStacks();
  }, [ versions ] );

  useEffect( () => {
    // periodically re-fetch any stacks that are not UPDATE_COMPLETE
    const interval = setInterval( () => {
      if( !stacks ) return;
      const stacksToFetch = stacks.filter( s => s.status != 'UPDATE_COMPLETE' )
        .map( s => s.stackName )
        .filter( name => name && !inProgress.includes( name ) );
      for( const tag of stacksToFetch ) {
        if( !tag ) continue;
        loadStack( tag );
      }
    }, 5_000 );
    return () => clearInterval( interval );
  }, [ stacks ] );

  const latestVersion = useMemo<string | undefined>( () => {
    if( !versions ) return;
    return ( versions.find( v => v.IsLatest ) || {} ).ChecksumSHA256;
  }, [ versions ] );

  const hashColors = useMemo( () => {
    if( !stacks ) return {};
    const topHashes = _( stacks )
      .countBy( f => f.templateSha256 )
      .entries()
      .sortBy( pair => pair[ 1 ] )
      .reverse()
      .map( pair => pair[ 0 ] )
      .value();
    const { discretePrimary: palette } = colorPalettes;
    const hashColors: Record<string, string | undefined> = {};
    const available = [ ...palette ];
    for( const hash of topHashes ) {
      if( hash == latestVersion ) continue;
      hashColors[ hash ] = available.shift();
    }
    for( const version of( versions || [] ) ) {
      const { ChecksumSHA256: hash } = version;
      if( hash == latestVersion ) continue;
      if( !hash || hashColors[ hash ] ) continue;
      hashColors[ hash ] = available.shift();
    }

    return hashColors;
  }, [ stacks, versions, latestVersion ] )

  const versionRecords = useMemo( () => {
    if( !versions ) return;
    return versions.slice( 0, 8 ).map( v => {
      const { IsLatest, LastModified, Key, ChecksumSHA256: templateSha256 } = v;
      return { templateSha256: < Typography sx={{ color: IsLatest ? primary : ( templateSha256 && hashColors[ templateSha256 ] ) }}> {templateSha256}</Typography >, Key, LastModified };
    } );
  }, [ versions, hashColors ] );

  const versionChoices = useMemo( () => {
    return ( versions || [] )
      .map( v => {
        const { IsLatest, LastModified, Key, ChecksumSHA256: CodeSha256 = '', VersionId = '' } = v;
        return {
          IsLatest, LastModified, Key, CodeSha256, VersionId,
          value: VersionId,
          label: (
            <Box>
              <Typography> {formatValue( LastModified )}</Typography>
              <Typography
                sx={{
                  color: IsLatest ? primary : ( CodeSha256 && hashColors[ CodeSha256 ] || undefined ),
                  fontWeight: IsLatest ? 'bold' : undefined,
                }}
              >{formatValue( CodeSha256 )}</Typography>
            </Box>
          )
        };
      } )
  }, [ versionRecords, hashColors ] );

  // TODO should this be made async?
  const finishUpdate = useCallback( ( tag: string ) => {
    ( async () => {
      setInProgress( without( inProgress, tag ) );
      setNotify( `Started stack update for "${ tag }"` );
      await loadStack( tag );
    } )();
  }, [ inProgress ] );

  const onUpdateStack = useCallback( async ( tag: string, versionId?: string ) => {
    console.log( 'onUpdateStack called', inProgress, tag, versionId );
    setInProgress( [ ...inProgress, tag ] );
    await createTenantStack( tag, versionId );                      // TODO if nothing changed, this ends silently
    console.log( 'onUpdateStack update started', inProgress, tag, versionId );
    finishUpdate( tag );
  }, [ inProgress, createTenantStack ] );

  const stackRecords = useMemo( () => {
    if( !stacks ) return;
    return stacks.map( stack => {
      const { stackName = '', updatedAt: updated, templateSha256, status } = stack || {};
      const isLatest = templateSha256 == latestVersion;
      const isCustomVersion = !versionChoices.find( v => v.CodeSha256 == templateSha256 );
      const options = versionChoices.map( v => ( { ...v, disabled: v.CodeSha256 == templateSha256 } ) );
      const action = (
        <UpdateCodeButton
          startIcon={inProgress.includes( stackName ) ? <CircularProgress size='1rem' /> : <RestartAlt />}
          onClick={( versionId ) => onUpdateStack( stackName, versionId )}
          options={options}
          disabled={isLatest}
          disabledFully={inProgress.includes( stackName ) || isCustomVersion || !templateSha256}
        >
          Update stack
        </UpdateCodeButton>
      );
      return {
        templateSha256: (
          <Typography
            sx={{
              color: isLatest ? primary : ( templateSha256 && hashColors[ templateSha256 ] ),
              fontWeight: isLatest ? 'bold' : undefined,
            }}
          >{templateSha256}</Typography>
        ),
        stackName: (
          <Link
            to={`../${ stackName }/stack`}
            style={{
              color: primary,
              textDecorationLine: 'none',
            }}
          >
            {stackName}
          </Link>
        ),
        status: <StackEventStatus Status={status} />,
        updated: updated || new Date( 0 ),
        action
      };
    } );
  }, [ stacks, latestVersion, inProgress ] );

  // useEffect( () => {
  //   const diff = Date.now() - latest;
  //   if( isLoading || diff > 600000 ) return;
  //   ( async () => {
  //     setIsLoading( true );
  //     await promisedSleep( 10000 );
  //     await fetchStacks();
  //   } )()
  // }, [ latest, isLoading, inProgress ] );


  // if( !tags ) return <CircularProgress size='2rem' />;

  return (
    <Box>
      <Grid item xs={'auto'} mb={3}>
        <PropGrid label='Stacks' records={stackRecords} inProgress={isLoading} unsort />
      </Grid>
      <Grid item xs={'auto'} mb={3}>
        <PropGrid label='Template code versions' records={versionRecords} unsort />
      </Grid>
    </Box>
  );

}


