import { Box } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { TenantEnvironment, useData } from './data';

export interface TenantSecretContext {
  loadSecrets: () => Promise<void>;
  envSecret?: TenantEnvironment[ 'secret' ];
  inProgress: boolean;
  setInProgress: ( b: boolean ) => void;
}

export const TenantSecretContainer: FC = () => {
  const { id } = useParams();
  const { getTenantEnvSecret } = useData();
  const [ inProgress, setInProgress ] = useState( true );
  const [ envSecret, setEnvSecret ] = useState<TenantEnvironment[ 'secret' ] | undefined>();

  const loadSecrets = useCallback( async () => {
    const s = await getTenantEnvSecret( id );
    setEnvSecret( s );
  }, [ id ] );

  useEffect( () => {
    ( async () => {
      setInProgress( true );
      await loadSecrets();
      setInProgress( false );
    } )();
  }, [ id, setEnvSecret, getTenantEnvSecret ] );

  return (
    <Box>
      <Outlet
        context={{ envSecret, loadSecrets, inProgress, setInProgress }}
      />
    </Box>
  );

}


