import { Tab, Tabs, useTheme } from '@mui/material';
import { FC, useEffect } from 'react';
import { NavLink, useHref, useMatch, useMatches } from 'react-router-dom';

interface LinkTabProps {
  label: string;
  to: string;
}

const LinkTab: FC<LinkTabProps> = ( { label, to } ) => {
  const theme = useTheme();
  return (
    <NavLink
      // end
      to={to}
      style={( { isActive, isPending } ) => {
        return {
          color: isActive ? theme.palette.primary.main : 'inherit',
          // color: theme.palette.text.primary,
          // fontWeight: isActive ? 'bold' : '',
          // backgroundColor: isActive ? theme.palette.grey[ 200 ] : undefined,
          textDecorationLine: 'none',
          borderBottom: isActive ? `2px solid ${ theme.palette.primary.main }` : undefined,
        };
      }}
    >
      <Tab
        value={label}
        label={label}
        sx={{
          fontSize: '1rem',
          opacity: 1,
        }}
      />
    </NavLink>
  );
}

export interface NavTabsProps {
  tabs: { label: string; to: string }[];
}

export const NavTabs: FC<NavTabsProps> = props => {
  const { tabs } = props;

  // const matches = useMatches();
  // useEffect( () => { if( !matches ) return; console.log( matches ); }, [ matches ] );

  return (
    <Tabs
      value={false}
      sx={{
        // marginBottom: '1rem',
      }}
    >
      {tabs.map( ( { label, to } ) => (
        <LinkTab {...{ label, to }} key={label} />
      ) )
      }
    </Tabs>

  );
}
