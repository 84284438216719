import styled from '@mui/material/styles/styled';
import MuiSvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const SvgIcon = styled( MuiSvgIcon, {
  name: 'AwsApiGateway',
  shouldForwardProp: ( prop ) => prop !== 'fill',
} )<SvgIconProps>( () => ( {
  // fill: '#f00',
  // stroke: 'currentColor',
  // strokeLinecap: 'round',
  // strokeLinejoin: 'round',
  // strokeWidth: '2.25px',
} ) );

SvgIcon.defaultProps = {
  viewBox: '0 0 40 40',
  focusable: 'false',
  'aria-hidden': 'true',
};

export const AwsApiGateway: FC<SvgIconProps> = ( props ) => {

  return (
    <SvgIcon {...props}>
      <g id="Icon-Architecture/32/Arch_Amazon-API-Gateway_32" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icon-Architecture-BG/32/Application-Integration" fill="#E7157B">
          <rect id="Rectangle" x="0" y="0" width="40" height="40"></rect>
        </g>
        <g id="Icon-Service/32/Amazon-API-Gateway_32" transform="translate(6.000000, 6.000000)" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="1">
          <path d="M13,22.0000333 L15,22.0000333 L15,21.0000333 L13,21.0000333 L13,22.0000333 Z M8,1.26212734 L1,4.30211286 L1,24.3570173 L8,26.7960057 L8,1.26212734 Z M9,7.00010001 L9,21.0000333 L11,21.0000333 L11,22.0000286 L9,22.0000286 L9,27.5000024 C9,27.6620016 8.922,27.8140009 8.79,27.9080004 C8.704,27.9680002 8.603,28 8.5,28 C8.444,28 8.389,27.991 8.336,27.9720001 L0.336,25.1850134 C0.135,25.1150137 0,24.9260146 0,24.7130157 L0,3.97411442 C0,3.77511537 0.118,3.59511623 0.301,3.5161166 L8.301,0.0421331489 C8.455,-0.024866532 8.634,-0.00986660341 8.774,0.0821329584 C8.915,0.17413252 9,0.332131768 9,0.500130968 L9,6.00010477 L11,6.00010477 L11,7.00010001 L9,7.00010001 Z M27,4.30211286 L20,1.26212734 L20,6.00010477 L20,7.00010001 L20,21.0000333 L20,22.0000286 L20,26.7960057 L27,24.3570173 L27,4.30211286 Z M28,3.97411442 L28,24.7130157 C28,24.9260146 27.865,25.1150137 27.664,25.1850134 L19.664,27.9720001 C19.611,27.991 19.556,28 19.5,28 C19.397,28 19.296,27.9680002 19.21,27.9080004 C19.078,27.8140009 19,27.6620016 19,27.5 L19,22.0000286 L17,22.0000286 L17,21.0000333 L19,21.0000333 L19,7.00010001 L17,7.00010001 L17,6.00010477 L19,6.00010477 L19,0.500130968 C19,0.332131768 19.085,0.17413252 19.226,0.0821329584 C19.367,-0.0108665986 19.546,-0.0258665272 19.699,0.0421331489 L27.699,3.5161166 C27.882,3.59511623 28,3.77511537 28,3.97411442 L28,3.97411442 Z M13,7.00010477 L15,7.00010477 L15,6.00010477 L13,6.00010477 L13,7.00010477 Z M17.975,9.65808735 L17.025,9.34208886 L14.025,18.342046 L14.975,18.6580445 L17.975,9.65808735 Z M13.146,16.8540531 L10.146,13.8540674 C9.951,13.6580683 9.951,13.3420698 10.146,13.1460707 L13.146,10.146085 L13.854,10.8540817 L11.207,13.5000691 L13.854,16.1460565 L13.146,16.8540531 Z" id="Amazon-API-Gateway_Icon_32_Squid"></path>
        </g>
      </g>
    </SvgIcon >
  );
};

export default AwsApiGateway;

