import { Clear } from '@mui/icons-material';
import { Box, Chip, IconButton, InputAdornment, LinearProgress, MenuItem, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import _, { get } from 'lodash';
import { ChangeEventHandler, FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useData } from './data';
import { useCachedState } from './components/use-cached-state';
import { PropGridWithPaging } from './PropGrid';
import { PropTableRecord } from './PropTable';

interface ContentMessageTemplateMod {
  tag: string;
  smsMessage?: string;
  smsShortMessage?: string;
  emailSubjectLine?: string;
}

export const ContentMessageTemplates: FC = () => {
  const { getContentMessageTemplates, getContentTenantsMessageTemplates } = useData();
  const navigate = useNavigate();
  const [ tenantMods, setTenantMods ] = useState<Record<string, ContentMessageTemplateMod[]> | undefined>();
  const [ , setSearchParams ] = useSearchParams();
  const [ query, setQuery ] = useCachedState<string>( 'q', '' );
  const [ queryInput, setQueryInput ] = useState<string>( query );
  const [ recipientType, setRecipientType ] = useCachedState<string>( 'recipientType', '' );

  useEffect( () => {
    ( async () => {
      const mods = await getContentTenantsMessageTemplates( {
        'page[size]': '300',
      } );
      const mods2 = _( mods )
        .toPairs()
        .flatMap( ( [ tag, v ] ) => v.map( v => ( {
          tag,
          id: v.id,
          smsMessage: v.smsMessage,
          smsShortMessage: v.smsShortMessage,
          emailSubjectLine: v.emailSubjectLine,
        } ) ) )
        .groupBy( v => v.id )
        .mapValues( v => v.map( v => ( {
          tag: v.tag,
          smsMessage: v.smsMessage,
          smsShortMessage: v.smsShortMessage,
          emailSubjectLine: v.emailSubjectLine,
        } ) ) )
        .value();
      setTenantMods( mods2 );
    } )();
  }, [] );

  const getSortValue = ( key: string ) => {
    const replacements: Record<string,string> = {
      name: '_id',
      updated: 'updatedAt',
    };
    const direction = key.startsWith( '-' ) ? '-' : '';
    const value = key.replace( /^-/, '' );
    return direction + ( replacements[ value ] || value );
  }

  const debouncedSetSearch = useCallback( _.debounce( setQuery, 500 ), [] );
  useEffect( () => debouncedSetSearch( queryInput ), [ queryInput ] );

  const fetchRecords = useCallback( async ( page: number, pageSize: number, sort?: string ) => {
    const params: Record<string,string> = {
      'page[number]': `${page + 1}`, // 1-indexed pagination
      'page[size]': `${pageSize}`,
    };
    if( sort ) params[ 'sort' ] = getSortValue( sort );
    if( query ) params[ 'filter[q]'] = query;
    if( recipientType ) params[ 'filter[recipientType]'] = recipientType;
    const { total, data: contentMessageTemplates } = await getContentMessageTemplates( params );
    const records = contentMessageTemplates.map( record => {
      const { id, comment, updatedAt, smsMessage, smsShortMessage, emailSubjectLine } = record;
      const mods = get( tenantMods, id, [] ).length;
      const diffs = get( tenantMods, id, [] ).filter( v => v.smsMessage != smsMessage || v.smsShortMessage != smsShortMessage || v.emailSubjectLine != emailSubjectLine );
      return ( {
        _tag: id,
        name: id,
        mods: mods
          ? (
            <Tooltip title={get( tenantMods, id, [] ).map( v => v.tag ).join( ', ' )}>
              <Typography>
                {mods}
              </Typography>
            </Tooltip>
          )
          : mods === undefined
            ? <LinearProgress />
            : <Typography>-</Typography>,
        diffs: diffs.length
          ?
          <Typography>
            {diffs.length}
          </Typography>
          : diffs === undefined
            ? <LinearProgress />
            : <Typography>-</Typography>,
        tags: <>{diffs.map( v => <Chip key={v.tag} label={v.tag} /> )}</>,
        comment,
        updated: updatedAt,
      } );
    } ) ;
    return { records, total };
  }, [ tenantMods, recipientType, query ] );

  const handleRecipientTypeChange: ChangeEventHandler<HTMLInputElement|HTMLTextAreaElement> = useCallback( ( e ) => {
    const value: string = e.target.value;
    setRecipientType( value );
    setSearchParams( params => {
      params.set( 'page', '0' );
      return params;
    } );
  }, [] );

  const rowClick = useCallback( ( record: PropTableRecord ) => {
    if( !record._tag ) return;
    navigate( `./${ record._tag }` );
  }, [] );

  return (
    <Box>
      <Toolbar>
        <TextField
          select
          value={recipientType}
          onChange={ handleRecipientTypeChange }
          label='Recipient Type'
          size='small'
          sx={{
            minWidth: '10rem',
            maxHeight: '80%',
          }}
        >
          <MenuItem value=''>&nbsp;</MenuItem>
          <MenuItem value='Patient'>Patient</MenuItem>
          <MenuItem value='Non-Patient'>Non-Patient</MenuItem>
          <MenuItem value='Practitioner'>Practitioner</MenuItem>
        </TextField>
        <Box width='1em'/>
        <TextField
          value={queryInput}
          onChange={ ( e ) => setQueryInput( e.target.value ) }
          label='Search'
          size='small'
          sx={{
            minWidth: '30rem',
            maxHeight: '80%',
          }}
          InputProps={{
            endAdornment: queryInput && (
              <InputAdornment position='end'>
                <IconButton onClick={() => setQueryInput( '' )}>
                  <Clear />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Box sx={{ flexGrow: 1 }} />
      </Toolbar>
      <PropGridWithPaging
        label={''}
        fetchRecords={fetchRecords}
        rowClick={rowClick}
        sx={{
          '& .column-mods': {
            textAlign: 'end',
          },
          '& .column-diffs': {
            textAlign: 'end',
          },
        }}
      />
    </Box>
  );

}
