import { ResourceRecordSet } from '@aws-sdk/client-route-53';
import { Add as AddIcon, Delete as DeleteIcon, RestartAlt as ReloadIcon } from '@mui/icons-material';
import { Box, Button, CircularProgress, Toolbar } from '@mui/material';
import { titleize } from 'inflection';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useOutletContext, useParams, useRevalidator } from 'react-router-dom';
import { ConfirmDialog } from './components/ConfirmDialog';
import { Stack, useData } from './data';
import { PropGrid } from './PropGrid';
import { TenantContext } from './TenantContainer';

export const TenantDns: FC = () => {
  const { id } = useParams();
  const { getTenantDns } = useData();
  // const dnsRecords = useLoaderData() as ResourceRecordSet[] | undefined;
  const [ dnsRecords, setDnsRecords ] = useState<ResourceRecordSet[] | undefined>();
  const { tenant, setNotify } = useOutletContext() as TenantContext;
  const { tag } = tenant;
  const { revalidate } = useRevalidator();

  const [ openConfirm, setOpenConfirm ] = useState<'delete' | ''>( '' );
  const [ inProgress, setInProgress ] = useState<'load' | 'create' | 'delete' | ''>( '' );
  const { createTenantDns, deleteTenantDns, getTenantStackOutputs } = useData();
  const [ stackOutput, setStackOutput ] = useState<Stack[ 'outputs' ] | undefined>();

  const loadRecs = useCallback( async () => {
    const d = await getTenantDns( id );
    setDnsRecords( d );
  }, [ id ] );

  useEffect( () => {
    ( async () => {
      setInProgress( 'load' );
      await loadRecs();
      setInProgress( '' );
    } )();
  }, [ id, setDnsRecords, getTenantDns ] );

  const onCreate = useCallback( async () => {
    setInProgress( 'create' );
    await createTenantDns( tag );
    setNotify( `Created DNS records for "${ tag }"` )
    await loadRecs();
    setInProgress( '' );
  }, [ tag, createTenantDns ] )

  const onDelete = useCallback( async () => {
    setInProgress( 'delete' );
    await deleteTenantDns( tag );
    setNotify( `Deleted certificate config for "${ tag }"` )
    await loadRecs();
    setInProgress( '' );
  }, [ tag, deleteTenantDns ] )

  const onReload = useCallback( async () => {
    setInProgress( 'create' );
    await createTenantDns( tag );
    setNotify( `Updated DNS records for "${ tag }"` )
    await loadRecs();
    setInProgress( '' );
  }, [ tag, createTenantDns ] )

  useEffect( () => {
    ( async () => {
      const outputs = await getTenantStackOutputs( tag );
      setStackOutput( outputs );
    } )();
  }, [ getTenantStackOutputs, tag, setStackOutput ] );

  const isOutdated = useMemo( () => {
    if( !dnsRecords || !stackOutput ) return false;
    const { PrimaryDomainTarget, AdminDomainTarget } = stackOutput;
    const targets = [ PrimaryDomainTarget, AdminDomainTarget ];
    return !!dnsRecords.find( r => r.AliasTarget?.DNSName && !targets.includes( r.AliasTarget.DNSName.slice( 0, -1 ) ) );
  }, [ dnsRecords, stackOutput ] );

  const records = useMemo( () => {
    return !dnsRecords
      ? dnsRecords
      : dnsRecords.map( r => {
        const { Name: name = '', Type: type = '', AliasTarget } = r;
        // const color = State == 'DISABLED' ? 'error' : 'success';
        // const state = <Typography color={color}>{State}</Typography>;
        return { type, name, target: AliasTarget?.DNSName };
      } );
  }, [ dnsRecords ] );

  return (
    <Box>

      <PropGrid label='DNS' records={records} inProgress={!!inProgress} />

      <Toolbar>
        {!dnsRecords?.length &&
          <Button
            startIcon={<AddIcon />}
            onClick={onCreate}
            disabled={!!inProgress}
          >
            Create records
          </Button>
        }
        <Box sx={{ flexGrow: 1 }} />
        {!!dnsRecords?.length &&
          <>
            <Button
              startIcon={<DeleteIcon />}
              color='error'
              onClick={() => setOpenConfirm( 'delete' )}
              disabled={!!inProgress}

            >
              Delete records
            </Button>

          </>
        }
        {!!dnsRecords?.length && isOutdated &&
          <>
            <Button
              startIcon={<ReloadIcon />}
              variant='contained'
              onClick={onReload}
              disabled={!!inProgress}
            >
              Update records
            </Button>

          </>
        }
      </Toolbar>
      <ConfirmDialog
        open={!!openConfirm}
        title={`${ titleize( openConfirm ) } DNS`}
        message={`Are you sure you want to ${ openConfirm } the DNS records?`}
        confirmButton={titleize( openConfirm )}
        onClose={( confirmed ) => {
          if( confirmed ) {
            openConfirm == 'delete' ? onDelete() : undefined;
          }
          setOpenConfirm( '' );
        }}
      // fullScreen={isXSmall}
      />

    </Box>
  );
}


