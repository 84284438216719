import { CheckCircleOutline, HighlightOff, InfoOutlined } from '@mui/icons-material';
import { Typography, useTheme } from '@mui/material';
import { FC } from 'react';

export const StackEventStatus: FC<{ Status?: string }> = ( { Status = '' } ) => {
  const theme = useTheme();
  const color = Status.match( /ERROR|FAILED|ROLLBACK/ ) ? 'error' : Status.includes( 'COMPLETE' ) ? 'success' : 'primary';
  const icon = Status.match( /ERROR|FAILED|ROLLBACK/ ) ? <HighlightOff /> : Status.includes( 'COMPLETE' ) ? <CheckCircleOutline /> : <InfoOutlined />;
  if( !Status ) return null;
  return (
    <Typography
      color={theme.palette[ color ].main}
      fontSize='90%'
      sx={{ ' .MuiSvgIcon-root': { verticalAlign: 'bottom' } }}
    >
      {icon}&nbsp;{Status}
    </Typography>
  )
}

