import { Box, Button, Grid, TextField, Toolbar, Typography } from '@mui/material';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ContentLanguage, ContentText, FormData, FormProps } from './data';
import { LanguageInput } from './LanguageSelect';

export type ContentTextFormData = ContentText; // FormData<ContentText>;

export interface ContentTextFormProps extends FormProps<ContentTextFormData> {
  languages: ContentLanguage[];
}

export const ContentTextForm: FC<ContentTextFormProps> = props => {
  const { title, data, onSubmit, languages } = props;
  const isCreate = !data;

  const { control, getValues, watch, handleSubmit, setError, clearErrors, formState: { errors, isDirty, isValidating, isValid, isSubmitted, isSubmitting } } = useForm<ContentTextFormData>( {
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: data || {
      name: '',
      lang: 'en',
      value: '',
      locked: false,
    },
  } );

  return (
    <Box>
      <Typography gutterBottom fontSize='1.2rem' fontWeight='bold'>
        {title}
      </Typography>

      <form
        style={{
          width: '100%',
          marginBottom: '1rem',
          minHeight: '10rem',
        }}
      >
        <Grid
          container
          spacing={1}
          direction='column'
          sx={{ maxWidth: '80rem' }}
        >

          <Grid item>
            <Controller
              name='name'
              control={control}
              rules={{
                required: true,
                maxLength: 255,
                pattern: /^[a-zA-Z][a-zA-Z0-9_]+$/,
              }}
              render={( { field } ) => (
                !isCreate
                  ? <TextField
                    {...field}
                    fullWidth
                    margin='dense'
                    label='Name'
                    variant='standard'
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  : <TextField
                    {...field}
                    inputRef={field.ref}
                    margin='dense'
                    label='Name'
                    variant='filled'
                    error={!!errors?.name?.message}
                    helperText={errors?.name?.message}
                    fullWidth
                  />
              )}
            />
          </Grid>

          <Grid item>
            <Controller
              name='lang'
              control={control}
              rules={{
                required: true,
              }}
              render={( { field } ) => (
                <LanguageInput
                  {...field}
                  languages={languages}
                  inputRef={field.ref}
                  margin='dense'
                  label='Language'
                  variant='filled'
                  error={!!errors?.lang?.message}
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item>
            <Controller
              name='value'
              control={control}
              rules={{
                required: true,
                maxLength: 255,
              }}
              render={( { field } ) => (
                <TextField
                  {...field}
                  inputRef={field.ref}
                  margin='dense'
                  label='Value'
                  variant='filled'
                  error={!!errors?.value?.message}
                  helperText={errors?.value?.message}
                  multiline
                  fullWidth
                />
              )}
            />
          </Grid>

        </Grid>
      </form>

      <Toolbar>
        <Button variant='contained'
          disabled={!isDirty || isValidating || isSubmitting || isSubmitted || !isValid}
          onClick={handleSubmit( onSubmit, ( v ) => { console.log( errors, v ) } )}
          sx={{ fontSize: '90%' }}
        // color='success'
        >
          Submit
        </Button>
        <Box sx={{ flexGrow: 1 }} />
      </Toolbar>

    </Box>
  );
}
