import styled from '@mui/material/styles/styled';
import MuiSvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const SvgIcon = styled( MuiSvgIcon, {
  name: 'AwsIAM',
  shouldForwardProp: ( prop ) => prop !== 'fill',
} )<SvgIconProps>( () => ( {
  // fill: '#f00',
  // stroke: 'currentColor',
  // strokeLinecap: 'round',
  // strokeLinejoin: 'round',
  // strokeWidth: '2.25px',
} ) );

SvgIcon.defaultProps = {
  viewBox: '0 0 40 40',
  focusable: 'false',
  'aria-hidden': 'true',
};

export const AwsIAM: FC<SvgIconProps> = ( props ) => {

  return (
    <SvgIcon {...props}>
      <g id="Icon-Architecture/32/Arch_AWS-Identity-and-Access-Management_32" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icon-Architecture-BG/32/Security-Identity-Compliance" fill="#DD344C">
          <rect id="Rectangle" x="0" y="0" width="40" height="40"></rect>
        </g>
        <path d="M7,30 L33,30 L33,11 L7,11 L7,30 Z M34,10.5 L34,30.5 C34,30.776 33.776,31 33.5,31 L6.5,31 C6.224,31 6,30.776 6,30.5 L6,10.5 C6,10.224 6.224,10 6.5,10 L33.5,10 C33.776,10 34,10.224 34,10.5 L34,10.5 Z M22,24 L30,24 L30,23 L22,23 L22,24 Z M28,21 L31,21 L31,20 L28,20 L28,21 Z M22,21 L26,21 L26,20 L22,20 L22,21 Z M15,23.5 C15,23.224 14.775,23 14.5,23 C14.225,23 14,23.224 14,23.5 C14,23.776 14.225,24 14.5,24 C14.775,24 15,23.776 15,23.5 L15,23.5 Z M16,23.5 C16,24.151 15.581,24.701 15,24.908 L15,26 L14,26 L14,24.908 C13.419,24.701 13,24.151 13,23.5 C13,22.673 13.673,22 14.5,22 C15.327,22 16,22.673 16,23.5 L16,23.5 Z M10,26.996 L18.997,27 L18.998,25 L17,25 L17,24 L18.998,24 L18.999,23 L17,23 L17,22 L18.999,22 L19,20.004 L10.003,20 L10,26.996 Z M11,19 L17.998,19.003 L17.998,15.577 C17.998,14.759 17.332,14.163 16.935,13.881 C16.248,13.393 15.338,13.101 14.5,13.101 L14.499,13.101 C12.734,13.101 11.002,14.326 11.001,15.575 L11,19 Z M9,27.496 L9.003,19.5 C9.003,19.367 9.056,19.24 9.149,19.146 C9.243,19.053 9.37,19 9.503,19 L10,19 L10.001,15.575 C10.002,13.586 12.376,12.101 14.499,12.101 L14.5,12.101 C15.553,12.101 16.651,12.453 17.514,13.065 C18.472,13.746 18.999,14.638 18.998,15.578 L18.998,19.004 L19.5,19.004 C19.776,19.004 20,19.228 20,19.504 L19.997,27.5 C19.997,27.633 19.944,27.76 19.851,27.854 C19.757,27.947 19.63,28 19.497,28 L9.5,27.996 C9.224,27.996 9,27.772 9,27.496 L9,27.496 Z M29,18 L31,18 L31,17 L29,17 L29,18 Z M22,18 L28,18 L28,17 L22,17 L22,18 Z" id="AWS-Identity-and-Access-Management_Icon_32_Squid" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="1"></path>
      </g>
    </SvgIcon >
  );
};

export default AwsIAM;

