import { Alert, Box, Paper, Snackbar } from '@mui/material';
import { FC, useState } from 'react';
import { Outlet, ScrollRestoration, useLoaderData } from 'react-router-dom';
import { AppBar } from './AppBar';
import { AppMenu } from './AppMenu';
import { BaseContext } from './Base';
import { LocBreadcrumbs } from './Breadcrumb';
import { BaseInterface } from './data';
import { TenantSelector } from './TenantSelector';
import { TenantTabSelector } from './TenantTabSelector';
import { SessionAuth } from 'supertokens-auth-react/recipe/session/index.js';

export const App: FC = () => {
  // const { getBase } = useData();
  const base = useLoaderData() as BaseInterface;
  const [ open, setOpen ] = useState( true );
  const [ notify, setNotify ] = useState( '' );
  const [ error, setError ] = useState( '' );
  const { environment } = base || {};
  const timeZoneName = 'America/Chicago';

  if( !base ) return <>No Base</>;

  const context: BaseContext = { base, timeZoneName, setNotify, setError };

  return (
    <SessionAuth>
      <Paper >
        <AppBar environment={environment} {...{ open, setOpen }} />
        <Box sx={{ display: 'flex' }} >
          <AppMenu {...{ open }} />
          <Box
            component='main'
            sx={{
              flexGrow: 1,
              margin: '1rem',
              overflow: 'auto',
            }}
          >
            <ScrollRestoration />

            <LocBreadcrumbs
              middleElement={<><TenantTabSelector /><TenantSelector /></>}
            // endElement={}
            />

            <SimpleConfirmSnackbar
              open={!!notify}
              onClose={() => { setNotify( '' ) }}
              message={notify}
            />
            <SimpleErrorSnackbar
              open={!!error}
              onClose={() => { setError( '' ) }}
              message={error}
            />

            <Outlet context={context} />

          </Box>
        </Box >
      </Paper >
    </SessionAuth>
  );
}

export const SimpleConfirmSnackbar: FC<{ open: boolean, onClose: () => void, message: string }> = ( props ) => {
  const { open, onClose, message } = props;

  return (
    <Snackbar
      key='notify'
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert
        severity='success'
        variant='filled'
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export const SimpleErrorSnackbar: FC<{ open: boolean, onClose: () => void, message: string }> = ( props ) => {
  const { open, onClose, message } = props;

  return (
    <Snackbar
      key='error'
      open={open}
      autoHideDuration={10000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert
        severity='error'
        variant='filled'
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

