import { Autocomplete, Box, Button, Grid, InputLabel, MenuItem, Select, Stack, TextField, Toolbar, Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { BaseContext } from './Base';
import { tagRe, TenantCreateFormData, useData } from './data';


export interface ConfigProp {
  key: string;
  value: string;
}

export interface ConfigPropFormProps {
  title: string;
  data: Record<string, string>;
  fieldKey?: string; // for edit
  possibleKeys?: string[];
  onSubmit: ( data: ConfigProp ) => Promise<void>;
  onCancel: () => void;
}

export const ConfigPropForm: FC<ConfigPropFormProps> = props => {
  const { title, data, onSubmit, onCancel, possibleKeys = [], fieldKey } = props;

  const choices = useMemo<string[]>( () => {
    const existingKeys = Object.keys( data );
    return possibleKeys.filter( k => !existingKeys.includes( k ) );
  }, [ possibleKeys, data ] );

  const { control, handleSubmit, setValue, watch, formState: { errors, isDirty, isValidating, isValid, isSubmitted, isSubmitting } } = useForm<ConfigProp>( {
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    delayError: 100,
    defaultValues: fieldKey ? { key: fieldKey, value: data[ fieldKey ] } : { key: '', value: '' },
  } );

  // watch( ( values, { name, type } ) => {
  //   const { key } = values;
  //   if( key && name == 'key' && type == 'change' ) {
  //     console.log( key.toUpperCase() );
  //     setValue( 'key', key.toUpperCase(), { shouldDirty: true, shouldTouch: true, shouldValidate: true } );
  //   }
  // } );

  return (
    <Box>
      <Typography gutterBottom fontSize='1.2rem' fontWeight='bold'
      >
        {title}
      </Typography>

      <form
        style={{
          width: '100%',
          marginBottom: '1rem',
        }}
      >
        <Grid container spacing={1} direction='column'
          sx={{ maxWidth: '40rem' }}

        >

          <Grid item key='key'>

            <Controller
              name='key'
              control={control}
              rules={{
                pattern: {
                  value: /^[A-Z][_A-Z0-9]{3,64}/,
                  message: 'Must be all-upper, snakecase, letter first.'
                },
                minLength: {
                  value: 4,
                  message: 'Minimum of 4 characters'
                },
                maxLength: {
                  value: 64,
                  message: 'Maximum of 64 characters'
                },
              }}
              render={( { field } ) => (
                <Autocomplete
                  freeSolo
                  readOnly={!!fieldKey}
                  value={fieldKey || ''}
                  options={choices}
                  fullWidth
                  renderInput={( params ) => (
                    <TextField
                      {...params}
                      {...field}
                      // onChange={( e ) => {
                      //   field.onChange( e.target.value.toUpperCase() )
                      // }}
                      inputRef={field.ref}
                      required
                      margin='dense'
                      label='Key'
                      variant='filled'
                      error={!!errors?.key?.message}
                      helperText={errors?.key?.message}
                    />
                  )}
                // sx={{ width: '40rem' }}
                />
              )}
            />

          </Grid>

          <Grid item key='value'>
            <Controller
              name='value'
              control={control}
              render={( { field } ) => (
                <TextField
                  {...field}
                  inputRef={field.ref}
                  margin='dense'
                  // sx={{ width: '40rem' }}
                  fullWidth
                  label='Value'
                  variant='filled'
                  error={!!errors?.value?.message}
                  helperText={errors?.value?.message}
                />
              )}
            />
          </Grid>

        </Grid>
      </form>
      <Toolbar>
        <Button
          variant='contained'
          disabled={!isDirty || isValidating || isSubmitting || isSubmitted} // || !isValid}
          onClick={handleSubmit( onSubmit, ( v ) => { console.log( errors, v ) } )}
          sx={{ fontSize: '90%' }}
        >
          Save
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          variant='outlined'
          onClick={onCancel}
          sx={{ fontSize: '90%' }}

        >
          Cancel
        </Button>
      </Toolbar>

    </Box >
  );
}


