import { Grid } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { TenantStackEvent, useData } from './data';
import { shortDateTime } from './lib/pretty';
import { promisedSleep } from './promised-sleep';
import { PropGrid } from './PropGrid';
import { StackEventStatus } from './StackEventStatus';
import { LabelledStackResource } from './StackResourceType';
import { TenantStackContext } from './TenantStack';

export const TenantStackEvents: FC = () => {
  const { getTenantStackEvents } = useData();
  const { tenant, stack } = useOutletContext() as TenantStackContext;
  // const revalidator = useRevalidator();
  // const fetchers = useFetchers();
  const [ events, setEvents ] = useState<TenantStackEvent[] | undefined>();
  const [ latest, setLatest ] = useState<number>( 0 );
  const [ isLoading, setIsLoading ] = useState( false );
  const navigate = useNavigate();

  const fetchEvents = useCallback( async () => {
    const { tag } = tenant;
    const events = await getTenantStackEvents( tag );
    setEvents( events || [] );
    // const { Timestamp = '' } = events.slice( -1 )[ 0 ] || {};
    const { Timestamp = '', ResourceStatus = '', ResourceType = '', LogicalResourceId = '' } = events[ 0 ] || {};
    const latest = new Date( Timestamp ).getTime();
    setLatest( latest );
    setIsLoading( false );
    if( !Timestamp ) navigate( '.' );
    if( ResourceType == 'AWS::CloudFormation::Stack' && LogicalResourceId == tag && stack?.status != ResourceStatus ) navigate( '.' );
  }, [ tenant, setEvents, setLatest, setIsLoading ] );

  useEffect( () => {
    ( async () => {
      setIsLoading( true );
      await fetchEvents();
    } )()
  }, [ fetchEvents ] );

  useEffect( () => {
    const diff = Date.now() - latest;
    if( isLoading || diff > 600000 ) return;
    ( async () => {
      setIsLoading( true );
      await promisedSleep( 10000 );
      await fetchEvents();
    } )()
  }, [ latest, isLoading ] );

  const records = useMemo( () => {
    if( !events ) return;
    return events.map( e => {
      const { Timestamp, LogicalResourceId: LogicalId, ResourceStatus: Status, ResourceType: Type, ResourceStatusReason: StatusReason = '' } = e;
      return {
        Timestamp: shortDateTime( Timestamp, 'America/Chicago' ),
        // Type,
        LogicalId: <LabelledStackResource type={Type} label={LogicalId} />,
        Status: <StackEventStatus Status={Status} />,
        StatusReason,
      }
    } );
  }, [ events ] );


  return (
    <Grid item >
      <PropGrid label='Events' records={records} unsort />
    </Grid>
  );
}


