import { Box, Button, Toolbar } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { ContentBackupContext } from './ContentBackupContainer';
import { ContentBackup as ContentBackupType, useData } from './data';
import { LanguageSelect } from './LanguageSelect';
import { PropGrid } from './PropGrid';
import { PropTableRecord } from './PropTable';

export const ContentBackup: FC = () => {
  const { backup } = useOutletContext() as ContentBackupContext;
  const navigate = useNavigate();
  const collections = [
    'Languages',
    // 'Texts',
    'Snippets',
    'HtmlComponents',
    'MessageTemplates',
    'Twimls',
  ];

  if( !backup ) return null;

  return (
    <Toolbar>
      {collections.map( t => (
        <Button
          key={t}
          onClick={() => { navigate( t ); }}
        >
          {t}
        </Button>
      ) )}
    </Toolbar>
  );
}


export const ContentBackupCollection: FC = () => {
  const { collection } = useParams();
  const { backup, languages = [] } = useOutletContext() as ContentBackupContext;
  const { getContentBackupCollection } = useData();
  const [ data, setData ] = useState<PropTableRecord[] | undefined>();
  const [ lang, setLang ] = useState( 'en' );



  useEffect( () => {
    ( async () => {
      if( !backup?.id || !collection ) return;
      const data = await getContentBackupCollection( backup.id, collection );
      setData( data );
    } )();
  }, [ backup, collection ] );

  const records = useMemo( () => {
    if( !data ) return;
    return data
      .filter( r => collection != 'Texts' || r.lang == lang )
      .map( record => {
        if( 'value' in record && typeof record.value == 'string' ) {
          record.value = <Box component='pre' mt='0' pt='0' >{record.value}</Box>;
        }
        return record;
      } );

  }, [ data, lang ] );


  return (
    <Box>
      {collection == 'Texts' &&
        <Toolbar>
          <LanguageSelect languages={languages} lang={lang || ''} onChange={( e ) => setLang( e.target.value )} />
        </Toolbar>
      }
      <PropGrid label='Label' records={records} unsort />
    </Box>
  );
}
