import styled from '@mui/material/styles/styled';
import MuiSvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const SvgIcon = styled( MuiSvgIcon, {
  name: 'LogoBlank',
  shouldForwardProp: ( prop ) => prop !== 'fill',
} )<SvgIconProps>( () => ( {
  // fill: '#f00',
  // stroke: 'currentColor',
  // strokeLinecap: 'round',
  // strokeLinejoin: 'round',
  // strokeWidth: '2.25px',
} ) );

SvgIcon.defaultProps = {
  viewBox: '0 0 114 103.5',
  focusable: 'false',
  'aria-hidden': 'true',
};


export const LogoSupertokens: FC<SvgIconProps> = ( props ) => {

  const { fill = 'rgb(248, 153, 29)', ...rest } = props;

  return (
    <SvgIcon {...rest}>
      <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1">
        <path fill="#f93" d="M30.84,69.12l4.85-58.21L29.9,13.33a4.57,4.57,0,0,0-2.69,3.17C18.19,54,17.47,61.08.8,85.61a4.57,4.57,0,0,0,2.89,7.06c9.56,1.88,15.34,14.48,12.51,8a8.29,8.29,0,0,1-.76-3.07c-.13-3.83,3.5-5.94,9.18-6.89,8.56-1.45,21.48-3.23,33-4.61,2.53.31,5.13.63,7.74,1l-19.55-3Z" />
        <path fill="#f93" d="M113.2,86.75C96.53,62.22,97,54,87.94,16.5a4.56,4.56,0,0,0-2.7-3.17l-8.63-3.6a4.57,4.57,0,0,0-5.11,1.1l-.37.4,9.47,3.16,5,24.88-5,34.83L66.85,87.24c8.71,1.13,17.38,2.37,23.68,3.44,5.67.95,9.31,3.06,9.18,6.89a8.49,8.49,0,0,1-.76,3.07c-2.83,6.51,1.8-4.94,11.36-6.82A4.57,4.57,0,0,0,113.2,86.75Z" />
        <path fill="#f93" d="M66.82,87.27l0,0L65.31,87Z" />
        <path fill="#292e37" d="M60.56,7.34h-6C46,7.34,39.88,9,36.3,12.29,32.89,15.4,31.16,21,31.16,29V61.52c0,7.94,1.73,13.55,5.13,16.67,3.58,3.28,9.74,4.94,18.3,4.94h6c8.56,0,14.71-1.66,18.29-4.94C82.25,75.07,84,69.46,84,61.52V29C84,21,82.25,15.4,78.85,12.29,75.26,9,69.11,7.34,60.56,7.34ZM76.64,61.52c0,5.55-1,9.66-2.75,11.26-1,.9-4.27,3-13.33,3h-6c-9.06,0-12.35-2.12-13.34-3-1.75-1.59-2.75-5.7-2.75-11.25V29c0-5.55,1-9.65,2.75-11.25,1-.91,4.28-3,13.34-3h6c9.06,0,12.34,2.11,13.33,3,1.75,1.6,2.75,5.7,2.75,11.25Z" />
        <path fill="#f93" d="M12.7,96c.19-.49,1.59-1.11,2-1.36a19.83,19.83,0,0,1,2-1.21,11.65,11.65,0,0,1,4.56-.73,19,19,0,0,1,9.27,2.2A85.08,85.08,0,0,1,38.09,100a19.39,19.39,0,0,0,9.55,3.48,15.4,15.4,0,0,0,9.26-2.7c2.79-1.76,5.54-3.89,8.71-4.45a13.46,13.46,0,0,1,7.48,1.22c2.76,1.17,6.7,4.16,9.25,1,.67-.82,1.11-1.8,1.75-2.65,1.78-2.34,4.73-2.94,7.51-3,3.44-.12,6.81.78,10.1,1.64-2.27-1.74-4.52-3.52-6.78-5.28l-3.4-2.65-1.63-1.28c-.29-.23-1.3-1.29-1.64-1.28l-71.91,3C15.13,90.06,13.88,93,12.7,96Z" />
        <path fill="#db902e" d="M61.89,97.57l.42-7.1,6.4-1.85,2.61,8.27A12.33,12.33,0,0,0,61.89,97.57Z" />
        <path fill="#db902e" d="M24.6,90.11c0,.17,0,2.65,0,2.85-5.13-.91-9.32.78-9.18,4.61h0v-1.1c-.21-7.4,3.44-13.6,5.49-20.45,1.68-5.65,2.45-22.87,3.82-17.18,1.18,4.93.63,9.23-.1,14.6-.38,2.81,0,5.47,0,8.32Z" />
        <path fill="#db902e" d="M90.57,90.11c0,.17,0,2.65,0,2.85,4.48.24,9.31.78,9.18,4.61h0v-1.1c.21-7.4-3.44-13.6-5.49-20.45-1.68-5.65-2.45-22.87-3.81-17.18-1.18,4.93-.63,9.23.1,14.6.38,2.81,0,5.47,0,8.32Z" />
        <path fill="#222" d="M63.93,46.24a6.36,6.36,0,0,0-12.72,0,6.26,6.26,0,0,0,3,5.27l-1,9.79a1.36,1.36,0,0,0,1.34,1.48h5.94a1.35,1.35,0,0,0,1.34-1.48l-1-9.79A6.27,6.27,0,0,0,63.93,46.24Z" />
        <path fill="#222" d="M83.81,6.88Q76.31,0,60.56,0h-6Q38.84,0,31.34,6.88T23.82,29V61.52q0,15.21,7.52,22.08t23.25,6.87h6q15.75,0,23.25-6.87t7.51-22.08V29Q91.32,13.76,83.81,6.88ZM76.64,61.52c0,5.55-1,9.66-2.75,11.26-1,.9-4.27,3-13.33,3h-6c-9.06,0-12.35-2.12-13.34-3-1.75-1.59-2.75-5.7-2.75-11.25V29c0-5.55,1-9.65,2.75-11.25,1-.91,4.28-3,13.34-3h6c9.06,0,12.34,2.11,13.33,3,1.75,1.6,2.75,5.7,2.75,11.25Z" />
      </g></g>
    </SvgIcon >
  );
};

export default LogoSupertokens;

