import { Input as UpdateIconAlt } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, ButtonGroup, ButtonProps, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import { FC, ReactNode, useRef, useState } from 'react';

export interface UpdateCodeButtonOption {
  value: string;
  label: ReactNode;
  disabled?: boolean;
}

export interface UpdateCodeButtonProps extends Pick<ButtonProps, 'disabled' | 'startIcon' | 'children'> {
  onClick: ( value: string ) => void;
  options: UpdateCodeButtonOption[];
  disabledFully?: boolean;
}

export const UpdateCodeButton: FC<UpdateCodeButtonProps> = props => {
  const { onClick, disabled, disabledFully, options, startIcon = <UpdateIconAlt />, children = 'Update latest' } = props;
  const [ open, setOpen ] = useState( false );
  const anchorRef = useRef<HTMLDivElement>( null );

  const handleToggle = () => {
    setOpen( ( prevOpen ) => !prevOpen );
  };

  const handleClick = ( value: string ) => {
    setOpen( false );
    onClick( value );
  };

  const handleClose = ( event: Event ) => {
    if( anchorRef.current && anchorRef.current.contains( event.target as HTMLElement ) ) {
      return;
    }
    setOpen( false );
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
      >
        <Button
          startIcon={startIcon}
          color='primary'
          onClick={() => handleClick( options[ 0 ].value )}
          disabled={disabled || disabledFully}
        >
          {children}
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          disabled={disabledFully}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement='bottom-end'
        disablePortal
      >
        {( { TransitionProps, placement } ) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom',
            }}
          >
            <Paper
              sx={{
                maxHeight: '20rem',
                overflow: 'scroll',
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem
                >
                  {options.map( option => (
                    <MenuItem
                      key={option.value}
                      disabled={option.disabled}
                      onClick={() => handleClick( option.value )}
                    >
                      {option.label}
                    </MenuItem>
                  ) )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>

  )
}

