import { Box, Button, CircularProgress, Grid, Typography, useTheme } from '@mui/material';
import { default as _, without } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useOutletContext, useRouteLoaderData } from 'react-router-dom';
import { UpdateCodeButton } from './components/UpdateCodeButton';
import { ArrayElement, BaseInterface, TenantFunctionsCodes, useData } from './data';
import { PropGrid } from './PropGrid';
import { formatValue } from './PropTable';
import { TenantContext } from './TenantContainer';
import { colorPalettes } from './color-palettes';
import { ConfirmDialog } from './components/ConfirmDialog';

type CodeFunction = ArrayElement<TenantFunctionsCodes[ 'functions' ]>;
type CodeVersion = ArrayElement<TenantFunctionsCodes[ 'versions' ]>;

export const TenantFunctionCode: FC = () => {
  const base = useRouteLoaderData( 'app' ) as BaseInterface;
  const { environment } = base;
  const [ tags, setTags ] = useState<string[]>( [] );
  const [ functions, setFunctions ] = useState<CodeFunction[] | undefined>();
  const [ versions, setVersions ] = useState<CodeVersion[] | undefined>();
  const [ deployeds, setDeployeds ] = useState<CodeVersion[] | undefined>();
  const [ isLoading, setIsLoading ] = useState( false );
  const [ inProgress, setInProgress ] = useState<string[]>( [] );
  const [ openConfirmDeploy, setOpenConfirmDeploy ] = useState<string>( '' );
  const [ inDeploy, setInDeploy ] = useState( false );
  const { getTenants, getTenantFunctionCode, getTenantStackFunction, updateTenantStackFunction, deployFunction } = useData();
  const { setNotify } = useOutletContext() as TenantContext;
  const theme = useTheme();
  const primary = theme.palette.primary.main;
  const deployPrefix = 'dorp'; // 'prod';

  const loadFunction = useCallback( async ( tag: string ) => {
    const func = await getTenantStackFunction( tag );
    if( !func?.FunctionName ) return;
    setFunctions( prev => {
      if( !prev ) return [ func ];
      const index = prev.findIndex( f => f.FunctionName?.split( '-' )[ 0 ] == func.FunctionName?.split( '-' )[ 0 ] );
      return index == -1 ? [ ...prev, func ] : [ ...prev.slice( 0, index ), func, ...prev.slice( index + 1 ) ];
    } );
  }, [] );

  const loadFunctions = useCallback( async () => {
    if( isLoading || !tags.length || !setFunctions || !getTenantStackFunction ) return;
    console.log( tags );
    setFunctions( tags.map( FunctionName => ( { FunctionName } ) ) );
    for( const tag of tags ) {
      loadFunction( tag );
    }
  }, [ tags, setFunctions, getTenantStackFunction, isLoading ] );

  useEffect( () => {
    ( async () => {
      if( tags.length ) return;
      setIsLoading( true );
      setTags( await getTenants() );
      setIsLoading( false );
    } )();
  }, [] );

  useEffect( () => {
    ( async () => {
      if( !tags || versions ) return;
      const [ v, d ] = await Promise.all( [
        getTenantFunctionCode(),
        environment != 'aic-dev' ? undefined : getTenantFunctionCode( { overridePrefix: deployPrefix } ),
      ] );
      setVersions( v );
      setDeployeds( d );
    } )();
  }, [ tags, getTenantFunctionCode ] );

  useEffect( () => {
    ( async () => {
      if( !tags.length ) return;
      await loadFunctions();
    } )();
  }, [ tags ] );

  const finishUpdate = useCallback( ( tag: string ) => {
    ( async () => {
      setInProgress( without( inProgress, tag ) );
      setNotify( `Updated function code for "${ tag }"` );
      await loadFunction( tag );
    } )();
  }, [ inProgress ] );

  const onUpdate = useCallback( async ( tag: string, versionId?: string ) => {
    setInProgress( [ ...inProgress, tag ] );
    await updateTenantStackFunction( tag, versionId );
    finishUpdate( tag );
  }, [ updateTenantStackFunction, inProgress ] )

  const deploy = useCallback( async ( func: string, versionId?: string ): Promise<void> => {
    setInDeploy( true );
    await deployFunction( func, versionId );
    await loadFunctions();
    setInDeploy( false );
  }, [] );

  useEffect( () => {
    const interval = setInterval( () => {
      if( !functions ) return;
      const functionsToUpdate = functions.filter( f => f.State == 'Pending' )
        .map( f => f.FunctionName?.split( '-' )[ 0 ] )
        .filter( name => name && !inProgress.includes( name ) );
      for( const tag of functionsToUpdate ) {
        if( !tag ) continue;
        loadFunction( tag );
      }
    }, 5_000 );
    return () => clearInterval( interval );
  }, [ functions ] );

  const latestVersion = useMemo<string | undefined>( () => {
    if( !versions ) return;
    return ( versions.find( v => v.IsLatest ) || {} ).ChecksumSHA256;
  }, [ versions ] );

  const hashColors = useMemo( () => {
    if( !functions ) return {};
    const topHashes = _( functions )
      .countBy( f => f.CodeSha256 )
      .entries()
      .sortBy( pair => pair[ 1 ] )
      .reverse()
      .map( pair => pair[ 0 ] )
      .value();
    const { discretePrimary: palette } = colorPalettes;
    const hashColors: Record<string, string | undefined> = {};
    const available = [ ...palette ];
    for( const hash of topHashes ) {
      if( hash == latestVersion ) continue;
      hashColors[ hash ] = available.shift();
    }
    for( const version of( versions || [] ) ) {
      const { ChecksumSHA256: hash } = version;
      if( hash == latestVersion ) continue;
      if( !hash || hashColors[ hash ] ) continue;
      hashColors[ hash ] = available.shift();
    }

    return hashColors;
  }, [ functions, versions, latestVersion ] )

  const versionRecords = useMemo( () => {
    if( !versions ) return;
    return versions.map( ( v, idx ) => {
      const { IsLatest, LastModified, Key, ChecksumSHA256: CodeSha256, VersionId = '' } = v;
      const hideDeploy = environment != 'aic-dev';
      const allowDeploy = !( deployeds || [] ).find( d => d.ChecksumSHA256 == CodeSha256 )
        && ( functions || [] ).find( f => f.CodeSha256 == CodeSha256 );
      return {
        CodeSha256: (
          < Typography
            sx={{
              color: IsLatest ? primary : ( CodeSha256 && hashColors[ CodeSha256 ] || undefined ),
              fontWeight: IsLatest ? 'bold' : undefined,
            }}
          >
            {CodeSha256}
          </Typography >
        ),
        Key, LastModified, VersionId,
        ...( hideDeploy
          ? {}
          : {
            deploy: allowDeploy
              ? (
                <Button
                  color='error'
                  sx={{ mt: '-8px' }
                  }
                  onClick={() => setOpenConfirmDeploy( VersionId )}
                  disabled={idx > 0 || inDeploy}
                >
                  {inDeploy ? 'Deploying...' : 'Deploy to prod'}
                </Button >
              )
              : undefined
          } ),
      };
    } );
  }, [ versions, hashColors ] );

  const versionChoices = useMemo( () => {
    return ( versions || [] )
      .map( v => {
        const { IsLatest, LastModified, Key, ChecksumSHA256: CodeSha256 = '', VersionId = '' } = v;
        return {
          IsLatest, LastModified, Key, CodeSha256, VersionId,
          value: VersionId,
          label: (
            <Box>
              <Typography> {formatValue( LastModified )}</Typography>
              <Typography
                sx={{
                  color: IsLatest ? primary : ( CodeSha256 && hashColors[ CodeSha256 ] || undefined ),
                  fontWeight: IsLatest ? 'bold' : undefined,
                }}
              >{formatValue( CodeSha256 )}</Typography>
            </Box >
          )
        };
      } )
  }, [ versionRecords, hashColors ] );

  const functionRecords = useMemo( () => {
    if( !functions ) return;
    return functions.map( stackFunction => {
      const { FunctionName = '', LastModified, CodeSha256, State } = stackFunction || {};
      const [ tag ] = FunctionName.split( '-' );
      const isLatest = CodeSha256 == latestVersion;
      const options = versionChoices.map( v => ( { ...v, disabled: v.CodeSha256 == CodeSha256 } ) );
      const update = (
        <UpdateCodeButton
          onClick={( versionId ) => onUpdate( tag, versionId )}
          options={options}
          startIcon={inProgress.includes( tag ) ? <CircularProgress size='1rem' /> : undefined}
          disabled={isLatest}
          disabledFully={inProgress.includes( tag ) || !State}
        />
      );
      return {
        CodeSha256: (
          <Typography sx={{
            color: isLatest ? primary : ( CodeSha256 && hashColors[ CodeSha256 ] || undefined ),
            fontWeight: isLatest ? 'bold' : undefined,
          }}
          >
            {CodeSha256}
          </Typography>
        ),
        tag: <Link
          to={`../${ tag }/manage/server`
          }
          style={{
            color: primary,
            textDecorationLine: 'none',
          }}
        >
          {tag}
        </Link>,
        State,
        LastModified,
        update
      };
    } );
  }, [ functions, latestVersion, theme, inProgress, hashColors ] );

  return (
    <Box>
      <Grid key='functions' item xs={'auto'} mb={3}>
        <PropGrid label='Lambda functions' records={functionRecords}
          unsort
          inProgress={isLoading}
        />
      </Grid>

      <Grid key='versions' item xs={'auto'} mb={3}>
        <PropGrid label='Lambda code versions' records={versionRecords}
          unsort
          inProgress={versionRecords === undefined}
        />
      </Grid>

      <ConfirmDialog
        open={!!openConfirmDeploy}
        title={`Deploy Lamba code to Prod`}
        message={`Are you sure you want to deploy version "${ openConfirmDeploy }" of Lambda code to production?`}
        confirmButton='Deploy to Prod'
        onClose={( confirmed ) => {
          if( confirmed ) {
            deploy( 'code', openConfirmDeploy );
          }
          setOpenConfirmDeploy( '' );
        }}
      // fullScreen={isXSmall}
      />
    </Box>
  );
}
