import { Box, CircularProgress, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { sortBy } from 'lodash';
import { FC, isValidElement, ReactElement, ReactNode, useMemo } from 'react';
import { default as humanFormat } from 'human-format';
import { prettyShortDateTime } from './lib/pretty';
import { titleize } from 'inflection';
import { underscore } from 'inflection';
import { dump } from 'js-yaml';

export type PropTableValue = boolean | string | string[] | number | Date | ReactElement | Record<string, string | number> | null | undefined;
export type PropTableRecord = Record<string, PropTableValue>;
export interface PropTableProps {
  record?: PropTableRecord;
  label: string;
  unsort?: boolean;
  inProgress?: boolean;
}


export const formatKey = ( key: string ): string => {
  if( key.match( /^[A-Z]+$/ ) ) return key;   // "EMR"
  return titleize( underscore( key ) );
}

export const formatValue = ( value: PropTableValue ): ReactNode | string => (
  value === undefined
    ? ''
    : value === null
      ? 'null'
      : Array.isArray( value ) && typeof value[ 0 ] == 'string'
        ? value.join( ', ' )
        : typeof value == 'object' && value instanceof Date && !Array.isArray( value ) && !isValidElement( value )
          ? ( value.getTime() === 0 ? '' : prettyShortDateTime( value.toISOString(), 'America/Chicago' ) )
          : typeof value == 'string' && value.match( /\d\d\d\d-\d\d-\d\dT/ )
            ? prettyShortDateTime( value, 'America/Chicago' )
            : typeof value == 'boolean'
              ? value.toString()
              : typeof value == 'number'
                ? humanFormat( value, {
                  maxDecimals: 2,
                } )
                : typeof value == 'string' && value.match( /https.*(png|jpg|jpeg|googleusercontent)/ )
                  ? <img src={value} width='32' height='32' />
                  : isValidElement( value )
                    ? value
                    : typeof value == 'object'
                      ? <Typography component='pre' >{dump( value )}</Typography>
                      : value.toString()
);

export const PropTable: FC<PropTableProps> = ( { record, label, unsort, inProgress } ) => {

  const entries = useMemo( () => !record ? record : ( unsort ? Object.entries( record ) : sortBy( Object.entries( record ), p => p[ 0 ] ) ), [ record, unsort ] );

  return (
    <Box    >
      <Typography
        sx={{
          fontWeight: 'bold',
          fontSize: '110%',
          marginBottom: '1rem',
        }}
      >
        {label}
      </Typography>

      <Box
        sx={{
          position: 'relative',
          minHeight: inProgress ? '12rem' : undefined,
        }}
      >
        {inProgress &&
          <Box sx={{
            textAlign: 'center', padding: '3rem',
            position: 'absolute', zIndex: 10,
            top: '30%',
            left: '30%',
          }}>
            <CircularProgress />
          </Box>
        }

        <Table size='small' padding='none'
          sx={{
            '& .MuiTableCell-body, & .MuiTableCell-body .MuiTypography-root': {
              fontSize: '1rem',
              opacity: inProgress ? 0.2 : undefined,
              borderBottomWidth: 0,
              paddingBottom: '1rem',
              paddingRight: '1rem',
              fontWeight: 'bold',
              verticalAlign: 'top',
              // maxWidth: '10rem',
              overflowX: 'hidden',
              '&:first-of-type': {
                fontWeight: 'inherit',
                // maxWidth: '10rem',
                // width: '6rem',
                // color: 'red',
              },
            },
          }}
        >
          <TableBody>
            {!entries && inProgress === undefined
              ? <TableRow><TableCell><CircularProgress size='2rem' /></TableCell></TableRow>
              : ( entries || [] ).map( ( [ key, value ] ) => (
                key[ 0 ] == '_'
                  ? null
                  : (
                    <TableRow key={key}>
                      <TableCell>{formatKey( key )}</TableCell>
                      <TableCell>{formatValue( value )}</TableCell>
                    </TableRow>
                  )

              ) )}
          </TableBody>
        </Table>

      </Box>
    </Box>
  )
}
