import { FC, useCallback } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { BaseContext } from './Base';
import { TenantCore, TenantCreateFormData, useData } from './data';
import { TenantContext } from './TenantContainer';
import { TenantForm } from './TenantForm';

export const TenantEdit: FC = () => {
  const { tenant } = useOutletContext() as TenantContext;
  const { updateTenant } = useData();
  const navigate = useNavigate();

  const onSubmit = useCallback( async ( data: TenantCreateFormData ) => {
    const { tag } = tenant;
    await updateTenant( tag, data );
    navigate( '..' );
  }, [ updateTenant ] );

  return (
    <TenantForm
      title='Edit tenant'
      data={tenant}
      onSubmit={onSubmit}
    />
  );

}


