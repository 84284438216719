import { Box, CircularProgress, Paper } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { BaseContext } from './Base';
import { Tenant, useData } from './data';
import { NavTabs } from './NavTabs';

export interface TenantContext extends BaseContext {
  tenant: Omit<Tenant, 'auth' | 'database' | 'stack'>;
}

export const TenantContainer: FC = () => {
  const { id } = useParams();
  const baseContext = useOutletContext() as BaseContext;
  // const tenant = useLoaderData() as Tenant;
  const { getTenant } = useData();
  const [ tenant, setTenant ] = useState<Tenant | undefined>();

  useEffect( () => {
    ( async () => {
      setTenant( undefined );
      const t = await getTenant( id );
      if( !t ) return;
      setTenant( t as Tenant );
    } )();
  }, [ id, setTenant, getTenant ] );

  const tabs = [
    { label: 'Create', to: 'create' },
    { label: 'Setup', to: 'setup' },
    { label: 'Deploy', to: 'stack' },
    { label: 'Launch', to: 'launch' },
    { label: 'Manage', to: 'manage' },
    { label: 'Summary', to: 'summary' },
  ];

  // if( !tenant ) return <CircularProgress />;

  // const context: TenantContext = { ...baseContext, tenant };

  return (
    <Box>
      <NavTabs tabs={tabs} />

      <Paper
        sx={{
          padding: '1rem',
        }}
      >
        {!tenant
          ? <CircularProgress />
          : <Outlet
            context={{ ...baseContext, tenant }}
          />
        }
      </Paper>
    </Box>
  );

}


