import { Grid } from '@mui/material';
import { FC, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { PropGrid } from './PropGrid';
import { StackEventStatus } from './StackEventStatus';
import { LabelledStackResource } from './StackResourceType';
import { TenantStackContext } from './TenantStack';

export const TenantStackResources: FC = () => {
  const { stack } = useOutletContext() as TenantStackContext;
  const { resources = [] } = stack || {};

  const records = useMemo( () => {
    return resources.map( e => {
      const { LogicalResourceId: LogicalId, PhysicalResourceId: PhysicalId, ResourceStatus: Status, ResourceType: Type } = e;
      return {
        LogicalId,
        // PhysicalId,
        Type: <LabelledStackResource type={Type} label={Type} />,
        Status: <StackEventStatus Status={Status} />,
      }
    } );
  }, [ resources ] );

  return (
    <Grid item >
      <PropGrid label='Resources' records={records} />
    </Grid>
  );
}


