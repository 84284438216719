import { Check, Close, Edit as EditIcon } from '@mui/icons-material';
import { Box, Button, capitalize, Chip, Toolbar, useTheme } from '@mui/material';
import { FC, useMemo } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ContentLanguageContext } from './ContentLanguageContainer';
import { PropTable, PropTableRecord } from './PropTable';

export const ContentLanguage: FC = () => {
  const { language, inProgress } = useOutletContext() as ContentLanguageContext;
  const navigate = useNavigate();
  const theme = useTheme();

  const record: PropTableRecord|undefined = useMemo( () => {
    if( !language ) return undefined;
    const { nativeName: native, flag, svg, rtl, isSupported, isEnabled, translators, updatedAt:updated } = language;
    return ( {
      native,
      flag: <span style={{ fontSize: '2.5rem' }}>{ flag }</span>,
      SVG: svg ?
        <img
          src={ svg }
          style={ { height: '2rem', border: `1px solid ${ theme.palette.divider }` } }
          alt="missing"
        /> :
        undefined,
      RTL: rtl ? <Check color='primary' /> : <></>,
      supported: isSupported ? <Check color='primary' /> : <Close color='error' />,
      enabled: isEnabled ? <Check color='primary' /> : <Close color='error' />,
      translators: <>{ translators?.map( t => <Chip key={ t } label={ capitalize( t ) } style={{ margin: '2px' }} /> ) }</>,
      updated,
    } );
  }, [ language ] );

  return (
    <Box>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          startIcon={<EditIcon />}
          onClick={() => navigate( 'edit' )}
          disabled={!!inProgress}
        >
          edit
        </Button>
      </Toolbar>
      <PropTable label={language?.name || ''} record={record} inProgress={ inProgress } />
    </Box>
  );
}
