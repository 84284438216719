import { CircularProgress } from '@mui/material';
import { FC, useCallback } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ContentTextContext } from './ContentTextContainer';
import { ContentTextForm, ContentTextFormData } from './ContentTextForm';
import { useData } from './data';

export const ContentTextEdit: FC = () => {
  const { text, inProgress, setText, languages } = useOutletContext() as ContentTextContext;
  const { updateContentText } = useData();
  const navigate = useNavigate();

  const onSubmit = useCallback( async ( data: ContentTextFormData ) => {
    if( !data?.id ) return;
    const update = await updateContentText( `${ data.id }`, data );
    if( update && setText ) setText( update );
    navigate( '..' );
  }, [ updateContentText ] )

  if( inProgress ) return <CircularProgress />;
  return (
    <ContentTextForm
      title='Edit Text'
      data={text}
      onSubmit={onSubmit}
      languages={languages}
    />
  )
}
