import { CertificateDetail } from '@aws-sdk/client-acm';
import { StackResource } from '@aws-sdk/client-cloudformation';
import { Rule } from '@aws-sdk/client-eventbridge';
import { WebACL } from '@aws-sdk/client-wafv2';
import { FunctionConfiguration } from '@aws-sdk/client-lambda';
import { ResourceRecordSet } from '@aws-sdk/client-route-53';
import { GetObjectAttributesOutput, ObjectVersion as S3Version, ListObjectsOutput } from '@aws-sdk/client-s3';
import { createContext, FC, ReactElement, useCallback, useContext, useMemo } from 'react';
import { fetchApi, useFetch, getUrl, QueryParams } from './fetch';
import { PropTableRecord } from './PropTable';

const {
  REACT_APP_API_SERVER: apiServer = '', // 'http://localhost:3400',
} = process.env;

const apiPath = `${ apiServer }/api/v1`;

export type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly ( infer ElementType )[] ? ElementType : never;
export type CopyWithPartial<T, K extends keyof T> = Omit<T, K> & Partial<T>;
export type CopyWithRequired<T, K extends keyof T> = Omit<T, K> & Required<T>;

export interface Stack {
  stackName: string;
  description: string;
  stackId: string;
  parameters: Record<string, string>;
  outputs: Record<string, string>;
  resources: StackResource[];
  tags: Record<string, string>;
  status: string;
  statusReason: string;
  templateSha256?: string;
  createdAt: string;
  updatedAt: string;
  // events: {time, logicalId, status, statusReason}
  // resources: {logicalId, physicalId, type, status}
}

export interface BaseInterface extends Stack {
  environment: string; // stack-env
}

export interface TenantEnvironment {
  config: Record<string, string>;
  secret: Record<string, string>;
}

export interface DatabaseOperation {
  stats: Record<string, string | number>;
  // Object.fromEntries( db.getCollectionNames().map( c => { const {wiredTiger, ...stats } = db.getCollection( c ).stats(); return [ c, stats ]; } ) )
  collections?: Record<string, Record<string, boolean | string | number | Record<string, number>>>
}

export interface AuthUser {
  [ index: string ]: unknown;
  id: string;
  timeJoined: number;
  isPrimaryUser: boolean;
  tenantIds: string[];
  emails: string[];
  phoneNumbers: string[];
  thirdParty: {
    id: string;
    userId: string;
  }[];
  loginMethods: {
    tenantIds: string[];
    timeJoined: number;
    recipeId: string;
    recipeUserId: { recipeUserId: string; };
    email?: string;
    phoneNumber?: string;
    thirdParty?: {
      id: string;
      userId: string;
    };
    verified: boolean;
  }[];
  metadata?: Record<string, string>;
}

export interface AuthUserCreate {
  name: string;
  email: string;
  type?: string; // ~ tenantId:  aic or ops for now
}
export type AuthUserUpdate = Pick<AuthUserCreate, 'name' | 'type'>;
export type AuthUserDelete = Pick<AuthUserCreate, 'type'>;

export interface AuthConfig {
  // appClientId: string;
  // adminClientId: string;
  // api: Record<string, any>; // TODO
  // app: Record<string, any>; // TODO
  roles: Record<string, {
    id: string;
    name: string;
    description: string;
    users: AuthUser[];
  }>,
}

export interface TenantCore {
  tag: string;
  name: string;
  description: string;
}

// this is misnamed now
export interface Tenant extends TenantCore {
  emrBrand?: string;
  emrHostPath?: string;
  emrUsername?: string;
  updatedAt?: string;
  versionId?: string;

  environment: TenantEnvironment;
  stack?: Partial<Stack>;
  database?: DatabaseOperation;
  auth?: AuthConfig;
}
export type TenantSummary = Pick<Tenant, 'tag' | 'name' | 'description'>;

// https://www.mongodb.com/docs/atlas/reference/api-resources-spec/v2/#tag/Database-Users/operation/getDatabaseUser
export interface TenantDatabaseUser {
  awsIAMType: 'NONE' | 'USER' | 'ROLE';
  databaseName: string;
  groupId: string;
  labels: { key: string; value: string }[];
  ldapAuthType: 'NONE' | 'USER' | 'GROUP';
  links: { href: string; rel: string }[];
  oidcAuthType: 'NONE' | 'IDP_GROUP';
  roles: { roleName: string; databaseName: string; collectionName?: string }[];
  scopes: { name: string; type: 'CLUSTER' | 'DATA_LAKE'; }[];
  username: string;
  x509Type: 'NONE' | 'CUSTOMER' | 'MANAGED';
}


export type TenantStackEvent = Record<string, string>;

export interface ServerError {
  type: string;
  message: string;
}

export const stackTimerKeys = 'AppointmentsUpdate Pathology Reports SmsSend'.split( ' ' );
export const stackTimerStates = 'ENABLED DISABLED'.split( ' ' );
export type StackTimerKey = 'AppointmentsUpdate' | 'Pathology' | 'Reports' | 'SmsSend';
export type StackTimerState = 'ENABLED' | 'DISABLED';

export type S3HashVersion = ( S3Version & GetObjectAttributesOutput[ 'Checksum' ] );

export interface TenantSiteCode {
  deployed?: string;
  versions: S3HashVersion[];
  overrides?: Record<string, string>;
}

export interface TenantStacksTemplates {
  stacks: Stack[];
  versions: S3HashVersion[];
}

export interface TenantFunctionsCodes {
  functions: FunctionConfiguration[];
  versions: S3HashVersion[];
}

export type TenantCreateFormDataHandlerResponse = undefined | Record<keyof TenantCreateFormData, ServerError>;
export interface TenantCreateFormData {
  tag: string;
  name?: string;
  description?: string;
  // emrBrand?: string;
  // emrHostPath?: string;
  // emrUsername?: string;
  // emrPassword?: string;
}

export interface TenantEmr extends PropTableRecord {
  brand: string;
  username?: string;
  password?: string;
  tenantId?: string;
  tenantSecret?: string;
  fhirPath?: string;
  organizationIds?: string;
  bulkIds?: string;
  scopes?: string;
  updatedAt?: string;
}

export type TenantEmrCreateFormDataHandlerResponse = undefined | Record<keyof TenantEmrCreateFormData, ServerError>;
export type TenantEmrCreateFormData = Omit<TenantEmr, 'updatedAt'>;

export type TenantsCosts = Record<string, Record<string, number>>; // { [tenant]: { [date]: number }

export type FormData<T extends ContentBase = ContentBase> = Omit<T, 'updatedAt'>;
export type FormProps<T extends FormData = FormData> = {
  data?: T;
  onSubmit: ( data: T ) => Promise<void>;
  title: string;
}

export interface ContentBase extends PropTableRecord {
  id: string;
  createdAt?: string;
  updatedAt?: string;
}
export type LanguageTranslator = 'aws' | 'google' | 'manual' | 'preset';
export interface ContentLanguage extends ContentBase {
  name: string;
  nativeName?: string;
  flag?: string;
  svg?: string;
  translators?: LanguageTranslator[];
  isEnabled?: boolean;
  isSupported?: boolean;
}
export interface ContentKeyValue extends ContentBase {
  value: string;
}
export interface ContentText extends ContentKeyValue {
  lang: string;
  name: string;
  enHash?: string;
  translator?: LanguageTranslator;
  locked?: boolean;
}
export interface ContentTextTranslations extends ContentKeyValue {
  enHash: string;
  name: string;
  available: number;
  current: number;
  total: number;
}
export interface ContentSnippet extends ContentKeyValue {
  comment?: string;
}
export interface ContentTwiml extends ContentKeyValue {
  comment?: string;
}
export interface ContentHtmlComponent extends ContentKeyValue {
  comment?: string;
  isParent?: boolean;
  type: 'section' | 'presentation';
}
export interface ContentMessageTemplate extends ContentBase {
  name: string;
  smsMessage?: string;
  smsShortMessage?: string;
  emailSubjectLine?: string;
}

export interface ContentBackup extends ContentBase {
  bucket?: string;
  eTag?: string;
  metadata: Record<string, string>;
  size?: number;
  url?: string;
  versionId?: string;
  preview?: string;
}

export interface ContentQueryResult<T> {
  total: number;
  data: T[];
}

export interface SessionInfo {
  metadata?: Record<string, unknown>;
}

export const tagRe = /^[a-z][a-z0-9]{2,31}$/;

export interface DataContextInterface {
  getSessionInfo: () => Promise<SessionInfo | undefined>;
  getBase: () => Promise<BaseInterface | undefined>;
  getCertificates: () => Promise<Record<string, unknown>[] | undefined>;
  getRedirectDomains: () => Promise<Record<string, unknown>[] | undefined>;
  getUsers: () => Promise<AuthConfig | undefined>;
  getUser: ( id: string ) => Promise<AuthUser | undefined>;
  createUser: ( data: AuthUserCreate ) => Promise<AuthUser | undefined>;
  updateUser: ( id: string, data: AuthUserUpdate ) => Promise<AuthUser | undefined>;
  deleteUser: ( id: string, data?: AuthUserDelete ) => Promise<void>;
  getTenants: () => Promise<string[]>;
  getTenantsCosts: () => Promise<TenantsCosts>;
  getTenant: ( tag?: string ) => Promise<TenantCore | undefined>;
  createTenant: ( data: TenantCreateFormData ) => Promise<Tenant | undefined>;
  deleteTenant: ( tag: string ) => Promise<ServerError | undefined>;
  updateTenant: ( tag: string, data: TenantCreateFormData ) => Promise<TenantCore | undefined>;
  getTenantAuth: ( tag?: string ) => Promise<AuthConfig | undefined>;
  createTenantAuth: ( tag?: string ) => Promise<AuthConfig | undefined>;
  deleteTenantAuth: ( tag?: string ) => Promise<void>;
  resetTenantAuth: ( tag?: string ) => Promise<AuthConfig | undefined>;
  getTenantCertificate: ( tag?: string ) => Promise<CertificateDetail | undefined>;
  createTenantCertificate: ( tag?: string ) => Promise<CertificateDetail | undefined>;
  deleteTenantCertificate: ( tag?: string ) => Promise<void>;
  validateTenantCertificate: ( tag?: string ) => Promise<CertificateDetail | undefined>;
  getTenantDns: ( tag?: string ) => Promise<ResourceRecordSet[] | undefined>;
  createTenantDns: ( tag?: string ) => Promise<void>;
  deleteTenantDns: ( tag?: string ) => Promise<void>;

  initTenantDatabase: ( tag?: string ) => Promise<void>;
  getTenantDatabaseOrg: ( tag?: string ) => Promise<PropTableRecord | undefined>;
  getTenantDatabaseTags: ( tag?: string ) => Promise<PropTableRecord[]>;

  getTenantDatabaseUser: ( tag?: string ) => Promise<TenantDatabaseUser | undefined>;
  createTenantDatabaseUser: ( tag?: string ) => Promise<TenantDatabaseUser | undefined>;
  resetTenantDatabaseUser: ( tag?: string ) => Promise<TenantDatabaseUser | undefined>;
  deleteTenantDatabaseUser: ( tag?: string ) => Promise<void>;
  getTenantEmrs: ( tag?: string ) => Promise<TenantEmr[] | undefined>;
  createTenantEmr: ( tag: string, data: TenantEmrCreateFormData ) => Promise<void>;
  getTenantEmr: ( tag?: string, emrId?: string ) => Promise<TenantEmr | undefined>;
  updateTenantEmr: ( tag: string, emrId: string, data: Partial<TenantEmr> ) => Promise<void>;
  deleteTenantEmr: ( tag: string, emrId: string ) => Promise<void>;
  testTenantEmr: ( tag: string, emrId: string, testId: string ) => Promise<unknown>;
  getTenantEnvConfig: ( tag?: string ) => Promise<TenantEnvironment[ 'config' ] | undefined>;
  updateTenantEnvConfig: ( tag: string, data: TenantEnvironment[ 'config' ] ) => Promise<TenantEnvironment[ 'config' ] | undefined>;
  deleteTenantEnvConfig: ( tag: string, key: string ) => Promise<TenantEnvironment[ 'config' ] | undefined>;
  getTenantEnvSecret: ( tag?: string ) => Promise<TenantEnvironment[ 'secret' ] | undefined>;
  initTenantEnvSecret: ( tag: string ) => Promise<TenantEnvironment[ 'secret' ] | undefined>;
  updateTenantEnvSecret: ( tag: string, data: TenantEnvironment[ 'secret' ] ) => Promise<TenantEnvironment[ 'secret' ] | undefined>;
  deleteTenantEnvSecret: ( tag: string, key: string ) => Promise<TenantEnvironment[ 'secret' ] | undefined>;
  getTenantStack: ( tag?: string ) => Promise<Stack | undefined>;
  createTenantStack: ( tag?: string, versionId?: string ) => Promise<Stack | undefined>;
  deleteTenantStack: ( tag?: string ) => Promise<void>;
  getTenantStackEvents: ( tag?: string ) => Promise<TenantStackEvent[]>;
  getTenantStackFunction: ( tag?: string ) => Promise<FunctionConfiguration | undefined>;
  reloadTenantStackFunction: ( tag?: string ) => Promise<void>;
  updateTenantStackFunction: ( tag?: string, versionId?: string ) => Promise<void>;
  getTenantStackOutputs: ( tag?: string ) => Promise<Stack[ 'outputs' ] | undefined>;
  getTenantStackTimers: ( tag?: string ) => Promise<Rule[]>;
  setTenantStackTimerState: ( tag: string, key: StackTimerKey | string, state: StackTimerState | string ) => Promise<void>;
  getTenantStackWaf: ( tag?: string ) => Promise<WebACL | undefined>;
  getTenantFunctionCode: ( params?: QueryParams ) => Promise<S3HashVersion[] | undefined>;
  getTenantSitesAdminCode: ( params?: QueryParams ) => Promise<TenantSiteCode | undefined>;
  getTenantSitesAppCode: ( params?: QueryParams ) => Promise<TenantSiteCode | undefined>;
  getTenantSitesOpsCode: ( params?: QueryParams ) => Promise<TenantSiteCode | undefined>;
  getTenantStackTemplates: ( params?: QueryParams ) => Promise<S3HashVersion[] | undefined>;
  setTenantSitesAdminCode: ( tag: string, data: { path?: string } ) => Promise<void>;
  setTenantSitesAppCode: ( tag: string, data: { path?: string } ) => Promise<void>;
  updateSharedSitesCode: ( originId: string, versionId: string ) => Promise<void>;
  deployFunction: ( func: string, versionId?: string ) => Promise<void>;
  deploySite: ( site: string, versionId?: string ) => Promise<void>;
  invalidateSitesCaches: () => Promise<void>;

  // Content APIs
  getContentBackups: ( params?: QueryParams ) => Promise<ContentQueryResult<ContentBackup>>;
  getContentBackup: ( id?: string ) => Promise<ContentBackup | undefined>;
  getContentBackupCollection: ( id?: string, collection?: string ) => Promise<ContentBase[] | undefined>;
  createContentBackup: () => Promise<ContentBackup | undefined>;

  getContentLanguages: ( params?: QueryParams ) => Promise<ContentQueryResult<ContentLanguage>>;
  getContentLanguage: ( id?: string ) => Promise<ContentLanguage | undefined>;
  createContentLanguage: ( data: Partial<ContentLanguage> ) => Promise<ContentLanguage | undefined>;
  updateContentLanguage: ( id: string, data: Partial<ContentLanguage> ) => Promise<ContentLanguage | undefined>;

  getContentTenantsTexts: ( params?: QueryParams ) => Promise<Record<string, ContentText[]>>;
  getContentTexts: ( params?: QueryParams ) => Promise<ContentQueryResult<ContentText>>;
  getContentTextsTranslations: () => Promise<ContentTextTranslations[]>;
  getContentText: ( id?: string, params?: QueryParams ) => Promise<ContentText | undefined>;
  getContentTextTranslations: ( id?: string ) => Promise<ContentTextTranslations | undefined>;
  createContentText: ( data: Partial<ContentText> ) => Promise<ContentText | undefined>;
  translateContentText: ( id?: string ) => Promise<ContentText | undefined>;
  updateContentText: ( id: string, data: Partial<ContentText> ) => Promise<ContentText | undefined>;

  getContentTenantsSnippets: ( params?: QueryParams ) => Promise<Record<string, ContentSnippet[]>>;
  getContentSnippets: ( params?: QueryParams ) => Promise<ContentQueryResult<ContentSnippet>>;
  getContentSnippet: ( id?: string, params?: QueryParams ) => Promise<ContentSnippet | undefined>;
  createContentSnippet: ( data: Partial<ContentSnippet> ) => Promise<ContentSnippet | undefined>;
  updateContentSnippet: ( id: string, data: Partial<ContentSnippet> ) => Promise<ContentSnippet | undefined>;

  getContentTenantsTwimls: ( params?: QueryParams ) => Promise<Record<string, ContentTwiml[]>>;
  getContentTwimls: ( params?: QueryParams ) => Promise<ContentQueryResult<ContentTwiml>>;
  getContentTwiml: ( id?: string, params?: QueryParams ) => Promise<ContentTwiml | undefined>;
  createContentTwiml: ( data: Partial<ContentTwiml> ) => Promise<ContentTwiml | undefined>;
  updateContentTwiml: ( id: string, data: Partial<ContentTwiml> ) => Promise<ContentTwiml | undefined>;

  getContentTenantsHtmlComponents: ( params?: QueryParams ) => Promise<Record<string, ContentHtmlComponent[]>>;
  getContentHtmlComponents: ( params?: QueryParams ) => Promise<ContentQueryResult<ContentHtmlComponent>>;
  getContentHtmlComponent: ( id?: string, params?: QueryParams ) => Promise<ContentHtmlComponent | undefined>;
  createContentHtmlComponent: ( data: Partial<ContentHtmlComponent> ) => Promise<ContentHtmlComponent | undefined>;
  updateContentHtmlComponent: ( id: string, data: Partial<ContentHtmlComponent> ) => Promise<ContentHtmlComponent | undefined>;

  getContentTenantsMessageTemplates: ( params?: QueryParams ) => Promise<Record<string, ContentMessageTemplate[]>>;
  getContentMessageTemplates: ( params?: QueryParams ) => Promise<ContentQueryResult<ContentMessageTemplate>>;
  getContentMessageTemplate: ( id?: string, params?: QueryParams ) => Promise<ContentMessageTemplate | undefined>;
  createContentMessageTemplate: ( data: Partial<ContentMessageTemplate> ) => Promise<ContentMessageTemplate | undefined>;
  updateContentMessageTemplate: ( id: string, data: Partial<ContentMessageTemplate> ) => Promise<ContentMessageTemplate | undefined>;

}

const stub = (): never => {
  throw new Error( 'You forgot to wrap your component in <FetchProvider>.' );
};

const DataContext = createContext<DataContextInterface>( {
  getSessionInfo: stub,
  getBase: stub,
  getCertificates: stub,
  getRedirectDomains: stub,
  getUsers: stub,
  getUser: stub,
  createUser: stub,
  updateUser: stub,
  deleteUser: stub,
  getTenants: stub,
  getTenantsCosts: stub,
  getTenant: stub,
  createTenant: stub,
  deleteTenant: stub,
  updateTenant: stub,
  getTenantAuth: stub,
  createTenantAuth: stub,
  deleteTenantAuth: stub,
  resetTenantAuth: stub,
  getTenantCertificate: stub,
  createTenantCertificate: stub,
  deleteTenantCertificate: stub,
  validateTenantCertificate: stub,
  initTenantDatabase: stub,
  getTenantDatabaseOrg: stub,
  getTenantDatabaseTags: stub,
  getTenantDatabaseUser: stub,
  createTenantDatabaseUser: stub,
  resetTenantDatabaseUser: stub,
  deleteTenantDatabaseUser: stub,
  getTenantDns: stub,
  createTenantDns: stub,
  deleteTenantDns: stub,
  getTenantEmrs: stub,
  createTenantEmr: stub,
  getTenantEmr: stub,
  updateTenantEmr: stub,
  deleteTenantEmr: stub,
  testTenantEmr: stub,
  getTenantEnvConfig: stub,
  updateTenantEnvConfig: stub,
  deleteTenantEnvConfig: stub,
  getTenantEnvSecret: stub,
  initTenantEnvSecret: stub,
  updateTenantEnvSecret: stub,
  deleteTenantEnvSecret: stub,
  getTenantStack: stub,
  createTenantStack: stub,
  deleteTenantStack: stub,
  getTenantStackEvents: stub,
  getTenantStackFunction: stub,
  reloadTenantStackFunction: stub,
  updateTenantStackFunction: stub,
  getTenantStackOutputs: stub,
  getTenantStackTimers: stub,
  setTenantStackTimerState: stub,
  getTenantStackWaf: stub,
  getTenantFunctionCode: stub,
  getTenantSitesAdminCode: stub,
  getTenantSitesAppCode: stub,
  getTenantSitesOpsCode: stub,
  getTenantStackTemplates: stub,
  setTenantSitesAdminCode: stub,
  setTenantSitesAppCode: stub,
  updateSharedSitesCode: stub,
  deployFunction: stub,
  deploySite: stub,
  invalidateSitesCaches: stub,

  getContentBackups: stub,
  getContentBackup: stub,
  getContentBackupCollection: stub,
  createContentBackup: stub,

  getContentLanguages: stub,
  getContentLanguage: stub,
  createContentLanguage: stub,
  updateContentLanguage: stub,

  getContentTenantsTexts: stub,
  getContentTexts: stub,
  getContentTextsTranslations: stub,
  getContentText: stub,
  getContentTextTranslations: stub,
  createContentText: stub,
  translateContentText: stub,
  updateContentText: stub,

  getContentTenantsSnippets: stub,
  getContentSnippets: stub,
  getContentSnippet: stub,
  createContentSnippet: stub,
  updateContentSnippet: stub,

  getContentTenantsTwimls: stub,
  getContentTwimls: stub,
  getContentTwiml: stub,
  createContentTwiml: stub,
  updateContentTwiml: stub,

  getContentTenantsHtmlComponents: stub,
  getContentHtmlComponents: stub,
  getContentHtmlComponent: stub,
  createContentHtmlComponent: stub,
  updateContentHtmlComponent: stub,

  getContentTenantsMessageTemplates: stub,
  getContentMessageTemplates: stub,
  getContentMessageTemplate: stub,
  createContentMessageTemplate: stub,
  updateContentMessageTemplate: stub,

} );

export interface DataProviderProps {
  children: ReactElement;
  // context?: Context<DataContextInterface>;
}

export const getBase = async (): Promise<BaseInterface | null> => {
  const response = await fetchApi( `${ apiPath }/base`, { cache: 'no-cache' } );
  if( !response.ok ) return null;
  return await response.json();
};


export const DataProvider: FC<DataProviderProps> = props => {
  const { children } = props;
  const { fetchApi } = useFetch();

  const getSessionInfo = useCallback( async (): Promise<SessionInfo | undefined> => {
    if( !fetchApi ) return;
    const response = await fetchApi( `${ apiPath }/auth/sessioninfo`, { cache: 'no-cache' } );
    if( !response.ok ) return;
    return await response.json();
  }, [ fetchApi ] );

  const getBase = useCallback( async (): Promise<BaseInterface | undefined> => {
    if( !fetchApi ) return;
    const response = await fetchApi( `${ apiPath }/base`, { cache: 'no-cache' } );
    if( !response.ok ) return;
    return await response.json();
  }, [ fetchApi ] );

  const getCertificates = useCallback( async (): Promise<Record<string, unknown>[] | undefined> => {
    if( !fetchApi ) return;
    const response = await fetchApi( `${ apiPath }/base/certificates`, { cache: 'no-cache' } );
    if( !response.ok ) return;
    return await response.json();
  }, [ fetchApi ] );

  const getRedirectDomains = useCallback( async (): Promise<Record<string, unknown>[] | undefined> => {
    if( !fetchApi ) return;
    const response = await fetchApi( `${ apiPath }/base/redirect-domains`, { cache: 'no-cache' } );
    if( !response.ok ) return;
    return await response.json();
  }, [ fetchApi ] );

  const getUsers = useCallback( async (): Promise<AuthConfig | undefined> => {
    if( !fetchApi ) return;
    const response = await fetchApi( `${ apiPath }/users`, { cache: 'no-cache' } );
    return await response.json();
  }, [ fetchApi ] );

  const getUser = useCallback( async ( id: string ): Promise<AuthUser | undefined> => {
    if( !fetchApi ) return;
    if( !id ) return;
    const response = await fetchApi( `${ apiPath }/users/${ id }`, { cache: 'no-cache' } );
    return await response.json();
  }, [ fetchApi ] );

  const createUser = useCallback( async ( data: AuthUserCreate ): Promise<AuthUser | undefined> => {
    if( !fetchApi ) return;
    if( !data ) return;
    const result = await fetchApi( `${ apiPath }/users`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( data ),
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
    return body;
  }, [ fetchApi ] );

  const updateUser = useCallback( async ( id: string, data: AuthUserUpdate ): Promise<AuthUser | undefined> => {
    if( !fetchApi ) return;
    if( !id || !data ) return;
    const result = await fetchApi( `${ apiPath }/users/${ id }`, {
      method: 'PATCH',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( data ),
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
    return body;
  }, [ fetchApi ] );

  const deleteUser = useCallback( async ( id: string, data?: AuthUserDelete ): Promise<void> => {
    if( !fetchApi ) return;
    if( !id ) return;
    const result = await fetchApi( `${ apiPath }/users/${ id }`, {
      method: 'DELETE',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( data ),
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
    return body;
  }, [ fetchApi ] );

  const getTenants = useCallback( async (): Promise<string[]> => {
    if( !fetchApi ) return [];
    const response = await fetchApi( `${ apiPath }/tenants`, { cache: 'no-cache' } );
    if( !response.ok ) return [];
    return await response.json();
    // return tenants.map( t => pick( t, [ 'tag', 'name', 'description' ] ) );
  }, [ fetchApi ] );

  const getTenantsCosts = useCallback( async (): Promise<TenantsCosts> => {
    if( !fetchApi ) return {};
    const response = await fetchApi( `${ apiPath }/tenants/costs`, { cache: 'no-cache' } );
    if( !response.ok ) return {};
    return await response.json();
  }, [ fetchApi ] );

  const getTenant = useCallback( async ( tag?: string ): Promise<TenantCore | undefined> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    const response = await fetchApi( `${ apiPath }/tenants/${ tag }`, { cache: 'no-cache' } );
    return await response.json();
    // return tenants.find( t => t.tag == tag );
  }, [ fetchApi ] );

  const createTenant = useCallback( async ( data: TenantCreateFormData ): Promise<Tenant | undefined> => {
    if( !fetchApi ) return;
    if( !data ) return;
    const result = await fetchApi( `${ apiPath }/tenants`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( data ),
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
  }, [ fetchApi ] );

  const deleteTenant = useCallback( async ( tag: string ): Promise<ServerError | undefined> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    const result = await fetchApi( `${ apiPath }/tenants/${ tag }`, {
      method: 'DELETE',
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors[ 0 ];
  }, [ fetchApi ] );

  const updateTenant = useCallback( async ( tag: string, data: TenantCreateFormData ): Promise<Tenant | undefined> => {
    if( !fetchApi ) return;
    // if( !tag || !data ) return;
    const result = await fetchApi( `${ apiPath }/tenants/${ tag }`, {
      method: 'PATCH',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( data ),
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
  }, [ fetchApi ] );

  const getTenantAuth = useCallback( async ( tag?: string ): Promise<AuthConfig | undefined> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    const response = await fetchApi( `${ apiPath }/tenants/${ tag }/auth`, { cache: 'no-cache' } );
    return await response.json();
  }, [ fetchApi ] );

  const createTenantAuth = useCallback( async ( tag?: string ): Promise<AuthConfig | undefined> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    const response = await fetchApi( `${ apiPath }/tenants/${ tag }/auth`, { method: 'POST' } );
    return await response.json();
  }, [ fetchApi ] );

  const resetTenantAuth = useCallback( async ( tag?: string ): Promise<AuthConfig | undefined> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    const response = await fetchApi( `${ apiPath }/tenants/${ tag }/auth`, { method: 'PATCH' } );
    return await response.json();
  }, [ fetchApi ] );

  const deleteTenantAuth = useCallback( async ( tag?: string ): Promise<void> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    await fetchApi( `${ apiPath }/tenants/${ tag }/auth`, { method: 'DELETE' } );
  }, [ fetchApi ] );

  const getTenantCertificate = useCallback( async ( tag?: string ): Promise<CertificateDetail | undefined> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    const response = await fetchApi( `${ apiPath }/tenants/${ tag }/certificate`, { cache: 'no-cache' } );
    if( !response?.ok ) return;
    return await response.json();
  }, [ fetchApi ] );

  const createTenantCertificate = useCallback( async ( tag?: string ): Promise<CertificateDetail | undefined> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    const response = await fetchApi( `${ apiPath }/tenants/${ tag }/certificate`, { method: 'POST' } );
    if( !response?.ok ) return;
    return await response.json();
  }, [ fetchApi ] );

  const deleteTenantCertificate = useCallback( async ( tag?: string ): Promise<void> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    await fetchApi( `${ apiPath }/tenants/${ tag }/certificate`, { method: 'DELETE' } );
  }, [ fetchApi ] );

  const validateTenantCertificate = useCallback( async ( tag?: string ): Promise<CertificateDetail | undefined> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    const response = await fetchApi( `${ apiPath }/tenants/${ tag }/certificate/validate`, { method: 'POST' } );
    if( !response?.ok ) return;
    return await response.json();
  }, [ fetchApi ] );

  const getTenantDns = useCallback( async ( tag?: string ): Promise<ResourceRecordSet[] | undefined> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    const response = await fetchApi( `${ apiPath }/tenants/${ tag }/dns`, { cache: 'no-cache' } );
    if( !response?.ok ) return;
    return await response.json();
  }, [ fetchApi ] );

  const createTenantDns = useCallback( async ( tag?: string ): Promise<void> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    const response = await fetchApi( `${ apiPath }/tenants/${ tag }/dns`, { method: 'POST' } );
    if( !response?.ok ) return;
    // return await response.json();
  }, [ fetchApi ] );

  const deleteTenantDns = useCallback( async ( tag?: string ): Promise<void> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    await fetchApi( `${ apiPath }/tenants/${ tag }/dns`, { method: 'DELETE' } );
  }, [ fetchApi ] );

  const initTenantDatabase = useCallback( async ( tag?: string ): Promise<void> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    const response = await fetchApi( `${ apiPath }/tenants/${ tag }/stack/database/init`, { method: 'POST' } );
    if( !response?.ok ) return;
    // return await response.json();
  }, [ fetchApi ] );

  const getTenantDatabaseOrg = useCallback( async ( tag?: string ): Promise<PropTableRecord | undefined> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    const response = await fetchApi( `${ apiPath }/tenants/${ tag }/stack/database/org`, { cache: 'no-cache' } );
    if( !response?.ok ) return;
    return await response.json();
  }, [ fetchApi ] );

  const getTenantDatabaseTags = useCallback( async ( tag?: string ): Promise<PropTableRecord[]> => {
    if( !fetchApi ) return [];
    if( !tag ) return [];
    const response = await fetchApi( `${ apiPath }/tenants/${ tag }/stack/database/tags`, { cache: 'no-cache' } );
    if( !response?.ok ) return [];
    return await response.json();
  }, [ fetchApi ] );

  const getTenantDatabaseUser = useCallback( async ( tag?: string ): Promise<TenantDatabaseUser | undefined> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    const response = await fetchApi( `${ apiPath }/tenants/${ tag }/database/user`, { cache: 'no-cache' } );
    if( !response?.ok ) return;
    return await response.json();
  }, [ fetchApi ] );

  const createTenantDatabaseUser = useCallback( async ( tag?: string ): Promise<TenantDatabaseUser | undefined> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    const response = await fetchApi( `${ apiPath }/tenants/${ tag }/database/user`, { method: 'POST' } );
    if( !response?.ok ) return;
    return await response.json();
  }, [ fetchApi ] );

  const resetTenantDatabaseUser = useCallback( async ( tag?: string ): Promise<TenantDatabaseUser | undefined> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    const response = await fetchApi( `${ apiPath }/tenants/${ tag }/database/user`, { method: 'PATCH' } );
    if( !response?.ok ) return;
    return await response.json();
  }, [ fetchApi ] );

  const deleteTenantDatabaseUser = useCallback( async ( tag?: string ): Promise<void> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    await fetchApi( `${ apiPath }/tenants/${ tag }/database/user`, { method: 'DELETE' } );
  }, [ fetchApi ] );

  const getTenantEmrs = useCallback( async ( tag?: string ): Promise<TenantEmr[]> => {
    if( !fetchApi ) return [];
    if( !tag ) return [];
    const response = await fetchApi( `${ apiPath }/tenants/${ tag }/emrs`, { cache: 'no-cache' } );
    if( !response.ok ) return [];
    return await response.json();
  }, [ fetchApi ] );

  const createTenantEmr = useCallback( async ( tag: string, data: TenantEmrCreateFormData ): Promise<void> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    const result = await fetchApi( `${ apiPath }/tenants/${ tag }/emrs`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( data ),
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
  }, [ fetchApi ] );

  const getTenantEmr = useCallback( async ( tag?: string, emrId?: string ): Promise<TenantEmr | undefined> => {
    if( !fetchApi ) return;
    if( !tag || !emrId ) return;
    const response = await fetchApi( `${ apiPath }/tenants/${ tag }/emrs/${ emrId }`, { cache: 'no-cache' } );
    if( !response.ok ) return;
    return await response.json();
  }, [ fetchApi ] );

  const updateTenantEmr = useCallback( async ( tag: string, emrId: string, data: Partial<TenantEmr> ): Promise<void> => {
    if( !fetchApi ) return;
    if( !tag || !emrId || !data ) return;
    await fetchApi( `${ apiPath }/tenants/${ tag }/emrs/${ emrId }`, {
      method: 'PATCH',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( data ),
    } );
  }, [ fetchApi ] );

  const deleteTenantEmr = useCallback( async ( tag: string, emrId: string ): Promise<void> => {
    if( !fetchApi ) return;
    if( !tag || !emrId ) return;
    await fetchApi( `${ apiPath }/tenants/${ tag }/emrs/${ emrId }`, { method: 'DELETE' } );
  }, [ fetchApi ] );

  const testTenantEmr = useCallback( async ( tag: string, emrId: string, testId: string ): Promise<unknown> => {
    if( !fetchApi ) return;
    if( !tag || !emrId || !testId ) return;
    const response = await fetchApi( `${ apiPath }/tenants/${ tag }/emrs/${ emrId }/tests/${ testId }`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
    } );
    if( !response.ok ) {
      const error = await response.json()
      return { error };
    }
    return await response.json();
  }, [ fetchApi ] );


  const getTenantEnvConfig = useCallback( async ( tag?: string ): Promise<TenantEnvironment[ 'config' ] | undefined> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    const response = await fetchApi( `${ apiPath }/tenants/${ tag }/env/config`, { cache: 'no-cache' } );
    if( !response?.ok ) return;
    return await response.json();
  }, [ fetchApi ] );

  const updateTenantEnvConfig = useCallback( async ( tag: string, data: TenantEnvironment[ 'config' ] ): Promise<TenantEnvironment[ 'config' ] | undefined> => {
    if( !fetchApi ) return;
    const result = await fetchApi( `${ apiPath }/tenants/${ tag }/env/config`, {
      method: 'PATCH',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( data ),
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
  }, [ fetchApi ] );

  const deleteTenantEnvConfig = useCallback( async ( tag: string, key: string ): Promise<TenantEnvironment[ 'config' ] | undefined> => {
    if( !fetchApi ) return;
    const result = await fetchApi( `${ apiPath }/tenants/${ tag }/env/config/${ key }`, { method: 'DELETE' } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
  }, [ fetchApi ] );

  const getTenantEnvSecret = useCallback( async ( tag?: string ): Promise<TenantEnvironment[ 'secret' ] | undefined> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    const response = await fetchApi( `${ apiPath }/tenants/${ tag }/env/secret`, { cache: 'no-cache' } );
    if( !response?.ok ) return;
    return await response.json();
  }, [ fetchApi ] );

  const initTenantEnvSecret = useCallback( async ( tag: string ): Promise<TenantEnvironment[ 'secret' ] | undefined> => {
    if( !fetchApi ) return;
    const result = await fetchApi( `${ apiPath }/tenants/${ tag }/env/secret/init`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' }
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
  }, [ fetchApi ] );

  const updateTenantEnvSecret = useCallback( async ( tag: string, data: TenantEnvironment[ 'secret' ] ): Promise<TenantEnvironment[ 'secret' ] | undefined> => {
    if( !fetchApi ) return;
    const result = await fetchApi( `${ apiPath }/tenants/${ tag }/env/secret`, {
      method: 'PATCH',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( data ),
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
  }, [ fetchApi ] );

  const deleteTenantEnvSecret = useCallback( async ( tag: string, key: string ): Promise<TenantEnvironment[ 'secret' ] | undefined> => {
    if( !fetchApi ) return;
    const result = await fetchApi( `${ apiPath }/tenants/${ tag }/env/secret/${ key }`, { method: 'DELETE' } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
  }, [ fetchApi ] );

  const getTenantStack = useCallback( async ( tag?: string ): Promise<Stack | undefined> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    const response = await fetchApi( `${ apiPath }/tenants/${ tag }/stack`, { cache: 'no-cache' } );
    if( !response?.ok ) return;
    return await response.json();
    // return tenants.find( t => t.tag == tag );
  }, [ fetchApi ] );

  const createTenantStack = useCallback( async ( tag?: string, versionId?: string ): Promise<Stack | undefined> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    const response = await fetchApi( `${ apiPath }/tenants/${ tag }/stack`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: versionId ? JSON.stringify( { versionId } ) : undefined,
    } );
    // return await response.json();
  }, [ fetchApi ] );

  const deleteTenantStack = useCallback( async ( tag?: string ): Promise<void> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    await fetchApi( `${ apiPath }/tenants/${ tag }/stack`, {
      method: 'DELETE',
      cache: 'no-cache',
    } );
  }, [ fetchApi ] );

  const getTenantStackEvents = useCallback( async ( tag?: string ): Promise<TenantStackEvent[]> => {
    if( !fetchApi ) return [];
    if( !tag ) return [];
    const response = await fetchApi( `${ apiPath }/tenants/${ tag }/stack/events`, { cache: 'no-cache' } );
    if( !response?.ok ) return [];
    return await response.json();
  }, [ fetchApi ] );

  const getTenantStackFunction = useCallback( async ( tag?: string ): Promise<FunctionConfiguration | undefined> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    const response = await fetchApi( `${ apiPath }/tenants/${ tag }/stack/function`, { cache: 'no-cache' } );
    if( !response?.ok ) return;
    return await response.json();
  }, [ fetchApi ] );

  const reloadTenantStackFunction = useCallback( async ( tag?: string ): Promise<void> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    await fetchApi( `${ apiPath }/tenants/${ tag }/stack/function/reload`, { method: 'POST' } );
  }, [ fetchApi ] );

  const updateTenantStackFunction = useCallback( async ( tag?: string, versionId?: string ): Promise<void> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    await fetchApi( `${ apiPath }/tenants/${ tag }/stack/function/update`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: versionId ? JSON.stringify( { versionId } ) : undefined,
    } );
  }, [ fetchApi ] );

  const getTenantStackOutputs = useCallback( async ( tag?: string ): Promise<Stack[ 'outputs' ] | undefined> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    const response = await fetchApi( `${ apiPath }/tenants/${ tag }/stack/outputs`, { cache: 'no-cache' } );
    if( !response?.ok ) return;
    return await response.json();
  }, [ fetchApi ] );

  const getTenantStackTimers = useCallback( async ( tag?: string ): Promise<Rule[]> => {
    if( !fetchApi ) return [];
    if( !tag ) return [];
    const response = await fetchApi( `${ apiPath }/tenants/${ tag }/stack/timers`, { cache: 'no-cache' } );
    if( !response?.ok ) return [];
    return await response.json();
  }, [ fetchApi ] );

  const getTenantStackWaf = useCallback( async ( tag?: string ): Promise<WebACL | undefined> => {
    if( !fetchApi ) return;
    if( !tag ) return;
    const response = await fetchApi( `${ apiPath }/tenants/${ tag }/waf`, { cache: 'no-cache' } );
    if( !response?.ok ) return;
    return await response.json();
  }, [ fetchApi ] );

  const getTenantFunctionCode = useCallback( async ( params?: QueryParams ): Promise<S3HashVersion[] | undefined> => {
    if( !fetchApi ) return;
    const response = await fetchApi( getUrl( `/tenants/function/code`, params ), { cache: 'no-cache' } );
    if( !response?.ok ) return;
    return await response.json();
  }, [ fetchApi ] );

  const getTenantSitesAdminCode = useCallback( async ( params?: QueryParams ): Promise<TenantSiteCode | undefined> => {
    if( !fetchApi ) return;
    const response = await fetchApi( getUrl( `/tenants/sites/admin/code`, params ), { cache: 'no-cache' } );
    if( !response?.ok ) return;
    return await response.json();
  }, [ fetchApi ] );

  const getTenantSitesAppCode = useCallback( async ( params?: QueryParams ): Promise<TenantSiteCode | undefined> => {
    if( !fetchApi ) return;
    const response = await fetchApi( getUrl( `/tenants/sites/app/code`, params ), { cache: 'no-cache' } );
    if( !response?.ok ) return;
    return await response.json();
  }, [ fetchApi ] );

  const getTenantSitesOpsCode = useCallback( async ( params?: QueryParams ): Promise<TenantSiteCode | undefined> => {
    if( !fetchApi ) return;
    const response = await fetchApi( getUrl( `/tenants/sites/ops/code`, params ), { cache: 'no-cache' } );
    if( !response?.ok ) return;
    return await response.json();
  }, [ fetchApi ] );

  const setTenantSitesAdminCode = useCallback( async ( tag: string, data: { path?: string } ): Promise<void> => {
    if( !fetchApi ) return;
    const result = await fetchApi( `${ apiPath }/tenants/${ tag }/sites/admin/code`, {
      method: 'PATCH',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( data ),
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
    return body;
  }, [ fetchApi ] );

  const setTenantSitesAppCode = useCallback( async ( tag: string, data: { path?: string } ): Promise<void> => {
    if( !fetchApi ) return;
    const result = await fetchApi( `${ apiPath }/tenants/${ tag }/sites/webapp/code`, {
      method: 'PATCH',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( data ),
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
    return body;
  }, [ fetchApi ] );

  const updateSharedSitesCode = useCallback( async ( originId: string, versionId: string ): Promise<void> => {
    if( !fetchApi ) return;
    const result = await fetchApi( `${ apiPath }/tenants/sites/${ originId }/code`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( { versionId } ),
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
    return body;
  }, [ fetchApi ] );

  const deployFunction = useCallback( async ( func: string, versionId?: string ): Promise<void> => {
    if( !fetchApi ) return;
    const result = await fetchApi( `${ apiPath }/tenants/deploy/functions/${ func }`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( { versionId } ),
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
    return body;
  }, [ fetchApi ] );

  const deploySite = useCallback( async ( site: string, versionId?: string ): Promise<void> => {
    if( !fetchApi ) return;
    const result = await fetchApi( `${ apiPath }/tenants/deploy/sites/${ site }`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( { versionId } ),
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
    return body;
  }, [ fetchApi ] );

  const invalidateSitesCaches = useCallback( async (): Promise<void> => {
    if( !fetchApi ) return;
    const result = await fetchApi( `${ apiPath }/tenants/sites/invalidateCaches`, {
      method: 'POST',
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
    return body;
  }, [ fetchApi ] );



  const getTenantStackTemplates = useCallback( async (): Promise<S3HashVersion[] | undefined> => {
    if( !fetchApi ) return;
    const response = await fetchApi( `${ apiPath }/tenants/stack/template`, { cache: 'no-cache' } );
    if( !response?.ok ) return;
    return await response.json();
  }, [ fetchApi ] );

  const setTenantStackTimerState = useCallback( async ( tag: string, key: StackTimerKey | string, state: StackTimerState | string ): Promise<void> => {
    if( !fetchApi ) return;
    if( !tag || !key || !state ) return;
    await fetchApi( `${ apiPath }/tenants/${ tag }/stack/timers/${ key }/state/${ state }`, { method: 'POST' } );
  }, [ fetchApi ] );

  //////////////////
  // Content API's
  //////////////////

  // Backups

  const getContentBackups = useCallback( async ( params?: QueryParams ): Promise<ContentQueryResult<ContentBackup>> => {
    if( !fetchApi ) return { total: 0, data: [] };
    const response = await fetchApi( getUrl( `/content/backups`, params ), { cache: 'no-cache' } );
    if( !response.ok ) return { total: 0, data: [] };
    return await response.json() || []
  }, [ fetchApi ] );

  const getContentBackup = useCallback( async ( id?: string ): Promise<ContentBackup | undefined> => {
    if( !fetchApi ) return;
    if( !id ) return;
    const response = await fetchApi( `${ apiPath }/content/backups/${ id }`, { cache: 'no-cache' } );
    return await response.json();
  }, [ fetchApi ] );

  const getContentBackupCollection = useCallback( async ( id?: string, collection?: string ): Promise<ContentBase[] | undefined> => {
    if( !fetchApi ) return;
    if( !id || !collection ) return;
    const response = await fetchApi( `${ apiPath }/content/backups/${ id }/${ collection }`, { cache: 'no-cache' } );
    return await response.json();
  }, [ fetchApi ] );

  const createContentBackup = useCallback( async (): Promise<ContentBackup | undefined> => {
    if( !fetchApi ) return;
    const result = await fetchApi( `${ apiPath }/content/backups`, {
      method: 'POST',
      // headers: { 'content-type': 'application/json' },
      // body: JSON.stringify( data ),
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
    return body;
  }, [ fetchApi ] );

  // Languages

  const getContentLanguages = useCallback( async ( params?: QueryParams ): Promise<ContentQueryResult<ContentLanguage>> => {
    if( !fetchApi ) return { total: 0, data: [] };
    const response = await fetchApi( getUrl( `/content/languages`, params ), { cache: 'no-cache' } );
    if( !response.ok ) return { total: 0, data: [] };
    return await response.json();
  }, [ fetchApi ] );

  const getContentLanguage = useCallback( async ( id?: string ): Promise<ContentLanguage | undefined> => {
    if( !fetchApi ) return;
    if( !id ) return;
    const response = await fetchApi( `${ apiPath }/content/languages/${ id }`, { cache: 'no-cache' } );
    return await response.json();
  }, [ fetchApi ] );

  const createContentLanguage = useCallback( async ( data: Partial<ContentLanguage> ): Promise<ContentLanguage | undefined> => {
    if( !fetchApi ) return;
    if( !data ) return;
    const result = await fetchApi( `${ apiPath }/content/languages`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( data ),
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
    return body;
  }, [ fetchApi ] );

  const updateContentLanguage = useCallback( async ( id: string, data: Partial<ContentLanguage> ): Promise<ContentLanguage | undefined> => {
    if( !fetchApi ) return;
    const result = await fetchApi( `${ apiPath }/content/languages/${ id }`, {
      method: 'PATCH',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( data ),
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
    return body;
  }, [ fetchApi ] );

  // Texts

  const getContentTenantsTexts = useCallback( async ( params?: QueryParams ): Promise<Record<string, ContentText[]>> => {
    if( !fetchApi ) return {};
    const response = await fetchApi( getUrl( `/content/tenants/texts`, params ), { cache: 'no-cache' } );
    if( !response.ok ) return {};
    return await response.json();
  }, [ fetchApi ] );

  const getContentTextsTranslations = useCallback( async (): Promise<ContentTextTranslations[]> => {
    if( !fetchApi ) return [];
    const response = await fetchApi( getUrl( `/content/texts/translations` ), { cache: 'no-cache' } );
    return ( await response.json() ) || []; // not found returns null;
  }, [ fetchApi ] );

  const getContentTexts = useCallback( async ( params?: QueryParams ): Promise<ContentQueryResult<ContentText>> => {
    if( !fetchApi ) return { total: 0, data: [] };
    const response = await fetchApi( getUrl( `/content/texts`, params ), { cache: 'no-cache' } );
    if( !response.ok ) return { total: 0, data: [] };
    return await response.json();
  }, [ fetchApi ] );

  const getContentText = useCallback( async ( id?: string, params?: QueryParams ): Promise<ContentText | undefined> => {
    if( !fetchApi ) return;
    if( !id ) return;
    const response = await fetchApi( getUrl( `/content/texts/${ encodeURIComponent( id ) }`, params ), { cache: 'no-cache' } );
    return ( await response.json() ) || undefined; // not found returns null;
  }, [ fetchApi ] );

  const getContentTextTranslations = useCallback( async ( id?: string ): Promise<ContentTextTranslations | undefined> => {
    if( !fetchApi ) return;
    if( !id ) return;
    const response = await fetchApi( getUrl( `/content/texts/${ encodeURIComponent( id ) }/translations` ), { cache: 'no-cache' } );
    return ( await response.json() ) || undefined; // not found returns null;
  }, [ fetchApi ] );

  const createContentText = useCallback( async ( data: Partial<ContentText> ): Promise<ContentText | undefined> => {
    if( !fetchApi ) return;
    if( !data ) return;
    const result = await fetchApi( getUrl( `/content/texts` ), {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( data ),
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
    return body;
  }, [ fetchApi ] );

  const translateContentText = useCallback( async ( id?: string ): Promise<ContentText | undefined> => {
    if( !fetchApi ) return;
    if( !id ) return;
    const result = await fetchApi( getUrl( `/content/texts/${ id }/translate` ), {
      method: 'POST',
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
    return body;
  }, [ fetchApi ] );

  const updateContentText = useCallback( async ( id: string, data: Partial<ContentText> ): Promise<ContentText | undefined> => {
    if( !fetchApi ) return;
    const result = await fetchApi( getUrl( `/content/texts/${ encodeURIComponent( id ) }` ), {
      method: 'PATCH',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( data ),
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
    return body;
  }, [ fetchApi ] );

  // Snippets

  const getContentTenantsSnippets = useCallback( async ( params?: QueryParams ): Promise<Record<string, ContentSnippet[]>> => {
    if( !fetchApi ) return {};
    const response = await fetchApi( getUrl( `/content/tenants/snippets`, params ), { cache: 'no-cache' } );
    if( !response.ok ) return {};
    return await response.json();
  }, [ fetchApi ] );

  const getContentSnippets = useCallback( async ( params?: QueryParams ): Promise<ContentQueryResult<ContentSnippet>> => {
    if( !fetchApi ) return { total: 0, data: [] };
    const response = await fetchApi( getUrl( `/content/snippets`, params ), { cache: 'no-cache' } );
    if( !response.ok ) return { total: 0, data: [] };
    return await response.json();
  }, [ fetchApi ] );

  const getContentSnippet = useCallback( async ( id?: string, params?: QueryParams ): Promise<ContentSnippet | undefined> => {
    if( !fetchApi ) return;
    if( !id ) return;
    const response = await fetchApi( getUrl( `/content/snippets/${ id }`, params ), { cache: 'no-cache' } );
    return ( await response.json() ) || undefined; // not found returns null;
  }, [ fetchApi ] );

  const createContentSnippet = useCallback( async ( data: Partial<ContentSnippet> ): Promise<ContentSnippet | undefined> => {
    if( !fetchApi ) return;
    if( !data ) return;
    const result = await fetchApi( getUrl( `/content/snippets` ), {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( data ),
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
    return body;
  }, [ fetchApi ] );

  const updateContentSnippet = useCallback( async ( id: string, data: Partial<ContentSnippet> ): Promise<ContentSnippet | undefined> => {
    if( !fetchApi ) return;
    const result = await fetchApi( getUrl( `/content/snippets/${ id }` ), {
      method: 'PATCH',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( data ),
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
    return body;
  }, [ fetchApi ] );

  // Twimls

  const getContentTenantsTwimls = useCallback( async ( params?: QueryParams ): Promise<Record<string, ContentTwiml[]>> => {
    if( !fetchApi ) return {};
    const response = await fetchApi( getUrl( `/content/tenants/twimls`, params ), { cache: 'no-cache' } );
    if( !response.ok ) return {};
    return await response.json();
  }, [ fetchApi ] );

  const getContentTwimls = useCallback( async ( params?: QueryParams ): Promise<ContentQueryResult<ContentTwiml>> => {
    if( !fetchApi ) return { total: 0, data: [] };
    const response = await fetchApi( getUrl( `/content/twimls`, params ), { cache: 'no-cache' } );
    if( !response.ok ) return { total: 0, data: [] };
    return await response.json();
  }, [ fetchApi ] );

  const getContentTwiml = useCallback( async ( id?: string, params?: QueryParams ): Promise<ContentTwiml | undefined> => {
    if( !fetchApi ) return;
    if( !id ) return;
    const response = await fetchApi( getUrl( `content/twimls/${ id }`, params ), { cache: 'no-cache' } );
    return ( await response.json() ) || undefined; // not found returns null;
  }, [ fetchApi ] );

  const createContentTwiml = useCallback( async ( data: Partial<ContentTwiml> ): Promise<ContentTwiml | undefined> => {
    if( !fetchApi ) return;
    if( !data ) return;
    const result = await fetchApi( getUrl( `/content/twimls` ), {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( data ),
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
    return body;
  }, [ fetchApi ] );

  const updateContentTwiml = useCallback( async ( id: string, data: Partial<ContentTwiml> ): Promise<ContentTwiml | undefined> => {
    if( !fetchApi ) return;
    const result = await fetchApi( getUrl( `/content/twimls/${ id }` ), {
      method: 'PATCH',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( data ),
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
    return body;
  }, [ fetchApi ] );

  // HtmlComponents

  const getContentTenantsHtmlComponents = useCallback( async ( params?: QueryParams ): Promise<Record<string, ContentHtmlComponent[]>> => {
    if( !fetchApi ) return {};
    const response = await fetchApi( getUrl( `/content/tenants/htmlComponents`, params ), { cache: 'no-cache' } );
    if( !response.ok ) return {};
    return await response.json();
  }, [ fetchApi ] );

  const getContentHtmlComponents = useCallback( async ( params?: QueryParams ): Promise<ContentQueryResult<ContentHtmlComponent>> => {
    if( !fetchApi ) return { total: 0, data: [] };
    const response = await fetchApi( getUrl( `/content/htmlComponents`, params ), { cache: 'no-cache' } );
    if( !response.ok ) return { total: 0, data: [] };
    return await response.json();
  }, [ fetchApi ] );

  const getContentHtmlComponent = useCallback( async ( id?: string, params?: QueryParams ): Promise<ContentHtmlComponent | undefined> => {
    if( !fetchApi ) return;
    if( !id ) return;
    const response = await fetchApi( getUrl( `/content/htmlComponents/${ id }`, params ), { cache: 'no-cache' } );
    return ( await response.json() ) || undefined; // not found returns null;
  }, [ fetchApi ] );

  const createContentHtmlComponent = useCallback( async ( data: Partial<ContentHtmlComponent> ): Promise<ContentHtmlComponent | undefined> => {
    if( !fetchApi ) return;
    if( !data ) return;
    const result = await fetchApi( getUrl( `/content/htmlComponents` ), {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( data ),
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
    return body;
  }, [ fetchApi ] );

  const updateContentHtmlComponent = useCallback( async ( id: string, data: Partial<ContentHtmlComponent> ): Promise<ContentHtmlComponent | undefined> => {
    if( !fetchApi ) return;
    const result = await fetchApi( getUrl( `/content/htmlComponents/${ id }` ), {
      method: 'PATCH',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( data ),
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
    return body;
  }, [ fetchApi ] );

  // MessageTemplates

  const getContentTenantsMessageTemplates = useCallback( async ( params?: QueryParams ): Promise<Record<string, ContentMessageTemplate[]>> => {
    if( !fetchApi ) return {};
    const response = await fetchApi( getUrl( `/content/tenants/messageTemplates`, params ), { cache: 'no-cache' } );
    if( !response.ok ) return {};
    return await response.json();
  }, [ fetchApi ] );

  const getContentMessageTemplates = useCallback( async ( params?: QueryParams ): Promise<ContentQueryResult<ContentMessageTemplate>> => {
    if( !fetchApi ) return { total: 0, data: [] };
    const response = await fetchApi( getUrl( `/content/messageTemplates`, params ), { cache: 'no-cache' } );
    if( !response.ok ) return { total: 0, data: [] };
    return await response.json();
  }, [ fetchApi ] );

  const getContentMessageTemplate = useCallback( async ( id?: string, params?: QueryParams ): Promise<ContentMessageTemplate | undefined> => {
    if( !fetchApi ) return;
    if( !id ) return;
    const response = await fetchApi( getUrl( `/content/messageTemplates/${ id }`, params ), { cache: 'no-cache' } );
    return ( await response.json() ) || undefined; // not found returns null;
  }, [ fetchApi ] );

  const createContentMessageTemplate = useCallback( async ( data: Partial<ContentMessageTemplate> ): Promise<ContentMessageTemplate | undefined> => {
    if( !fetchApi ) return;
    if( !data ) return;
    const result = await fetchApi( getUrl( `/content/messageTemplates` ), {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( data ),
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
    return body;
  }, [ fetchApi ] );

  const updateContentMessageTemplate = useCallback( async ( id: string, data: Partial<ContentMessageTemplate> ): Promise<ContentMessageTemplate | undefined> => {
    if( !fetchApi ) return;
    const result = await fetchApi( getUrl( `/content/messageTemplates/${ id }` ), {
      method: 'PATCH',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( data ),
    } );
    if( result.headers.get( 'content-length' ) == '0' ) return;
    const body = await result.json();
    if( body.errors ) return body.errors;
    return body;
  }, [ fetchApi ] );


  const value = useMemo( () => ( {
    getSessionInfo,
    getBase,
    getCertificates,
    getRedirectDomains,
    getUsers,
    getUser,
    createUser,
    updateUser,
    deleteUser,
    getTenants,
    getTenantsCosts,
    getTenant,
    createTenant,
    deleteTenant,
    updateTenant,
    getTenantAuth,
    createTenantAuth,
    deleteTenantAuth,
    resetTenantAuth,
    getTenantCertificate,
    createTenantCertificate,
    deleteTenantCertificate,
    validateTenantCertificate,
    initTenantDatabase,
    getTenantDatabaseOrg,
    getTenantDatabaseTags,
    getTenantDatabaseUser,
    createTenantDatabaseUser,
    resetTenantDatabaseUser,
    deleteTenantDatabaseUser,
    getTenantDns,
    createTenantDns,
    deleteTenantDns,
    getTenantEmrs,
    createTenantEmr,
    getTenantEmr,
    updateTenantEmr,
    deleteTenantEmr,
    testTenantEmr,
    getTenantEnvConfig,
    updateTenantEnvConfig,
    deleteTenantEnvConfig,
    getTenantEnvSecret,
    initTenantEnvSecret,
    updateTenantEnvSecret,
    deleteTenantEnvSecret,
    getTenantStack,
    createTenantStack,
    deleteTenantStack,
    getTenantStackEvents,
    getTenantStackFunction,
    reloadTenantStackFunction,
    updateTenantStackFunction,
    getTenantStackOutputs,
    getTenantStackTimers,
    setTenantStackTimerState,
    getTenantStackWaf,
    getTenantFunctionCode,
    getTenantSitesAdminCode,
    getTenantSitesAppCode,
    getTenantSitesOpsCode,
    getTenantStackTemplates,
    setTenantSitesAdminCode,
    setTenantSitesAppCode,
    updateSharedSitesCode,
    deployFunction,
    deploySite,
    invalidateSitesCaches,

    getContentBackups,
    getContentBackup,
    getContentBackupCollection,
    createContentBackup,

    getContentLanguages,
    getContentLanguage,
    createContentLanguage,
    updateContentLanguage,

    getContentTenantsTexts,
    getContentTexts,
    getContentTextsTranslations,
    getContentText,
    getContentTextTranslations,
    createContentText,
    translateContentText,
    updateContentText,

    getContentTenantsSnippets,
    getContentSnippets,
    getContentSnippet,
    createContentSnippet,
    updateContentSnippet,

    getContentTenantsTwimls,
    getContentTwimls,
    getContentTwiml,
    createContentTwiml,
    updateContentTwiml,

    getContentTenantsHtmlComponents,
    getContentHtmlComponents,
    getContentHtmlComponent,
    createContentHtmlComponent,
    updateContentHtmlComponent,

    getContentTenantsMessageTemplates,
    getContentMessageTemplates,
    getContentMessageTemplate,
    createContentMessageTemplate,
    updateContentMessageTemplate,
  } ), [
    getSessionInfo,
    getBase,
    getCertificates,
    getRedirectDomains,
    getUsers,
    getUser,
    createUser,
    updateUser,
    deleteUser,
    getTenants,
    getTenantsCosts,
    getTenant,
    createTenant,
    deleteTenant,
    updateTenant,
    getTenantAuth,
    createTenantAuth,
    deleteTenantAuth,
    resetTenantAuth,
    getTenantCertificate,
    createTenantCertificate,
    deleteTenantCertificate,
    validateTenantCertificate,
    initTenantDatabase,
    getTenantDatabaseOrg,
    getTenantDatabaseTags,
    getTenantDatabaseUser,
    createTenantDatabaseUser,
    resetTenantDatabaseUser,
    deleteTenantDatabaseUser,
    getTenantDns,
    createTenantDns,
    deleteTenantDns,
    getTenantEmrs,
    createTenantEmr,
    getTenantEmr,
    updateTenantEmr,
    deleteTenantEmr,
    testTenantEmr,
    getTenantEnvConfig,
    updateTenantEnvConfig,
    deleteTenantEnvConfig,
    getTenantEnvSecret,
    initTenantEnvSecret,
    updateTenantEnvSecret,
    deleteTenantEnvSecret,
    getTenantStack,
    createTenantStack,
    deleteTenantStack,
    getTenantStackEvents,
    getTenantStackFunction,
    reloadTenantStackFunction,
    updateTenantStackFunction,
    getTenantStackOutputs,
    getTenantStackTimers,
    setTenantStackTimerState,
    getTenantStackWaf,
    getTenantFunctionCode,
    getTenantSitesAdminCode,
    getTenantSitesAppCode,
    getTenantSitesOpsCode,
    getTenantStackTemplates,
    setTenantSitesAdminCode,
    setTenantSitesAppCode,
    updateSharedSitesCode,
    deployFunction,
    deploySite,
    invalidateSitesCaches,

    getContentBackups,
    getContentBackup,
    getContentBackupCollection,
    createContentBackup,

    getContentLanguages,
    getContentLanguage,
    createContentLanguage,
    updateContentLanguage,

    getContentTenantsTexts,
    getContentTexts,
    getContentTextsTranslations,
    getContentText,
    getContentTextTranslations,
    createContentText,
    translateContentText,
    updateContentText,

    getContentTenantsSnippets,
    getContentSnippets,
    getContentSnippet,
    createContentSnippet,
    updateContentSnippet,

    getContentTenantsTwimls,
    getContentTwimls,
    getContentTwiml,
    createContentTwiml,
    updateContentTwiml,

    getContentTenantsHtmlComponents,
    getContentHtmlComponents,
    getContentHtmlComponent,
    createContentHtmlComponent,
    updateContentHtmlComponent,

    getContentTenantsMessageTemplates,
    getContentMessageTemplates,
    getContentMessageTemplate,
    createContentMessageTemplate,
    updateContentMessageTemplate,
  ] );

  return (
    <DataContext.Provider value={value} >
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext<DataContextInterface>( DataContext );

