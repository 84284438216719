import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { Avatar, Box, Button, Checkbox, Chip, Toolbar } from '@mui/material';
import { default as _ } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfirmDialog } from './components/ConfirmDialog';
import { AuthUser, useData } from './data';
import { PropTable } from './PropTable';


export const UsersOpsUser: FC = () => {
  const { id } = useParams();
  const { getUser, deleteUser } = useData();
  const [ data, setData ] = useState<AuthUser | undefined>();
  const [ openConfirm, setOpenConfirm ] = useState( false );
  const navigate = useNavigate();

  useEffect( () => {
    ( async () => {
      if( !id || data ) return;
      const user = await getUser( id );
      setData( user );
    } )();
  }, [ id, setData ] );


  const onDelete = useCallback( async () => {
    if( !id ) return;
    await deleteUser( id, { type: 'ops' } );
    navigate( `..` );
  }, [] );

  const onEdit = useCallback( async () => {
    navigate( 'edit' );
  }, [] );

  if( !data ) return null;

  const { id: userId, emails, tenantIds, timeJoined, metadata, loginMethods } = data || {};
  const { name = '', pictureUrl = '' } = metadata || {};
  const picture = pictureUrl ? <Avatar alt={name} src={pictureUrl} /> : <></>;
  const loginMethod = loginMethods.find( m => m.recipeId == 'emailpassword' ) || loginMethods[ 0 ];
  const { email = emails[ 0 ], verified } = loginMethod || {}
  const joined = new Date( timeJoined );
  const tenants = <>{_( tenantIds ).sort().map( t => <Chip key={t} label={t} size='small' sx={{ marginRight: 1, marginBottom: 1 }} /> ).value()}</>;
  const record = { userId, name, email, verified: <Checkbox checked={verified} sx={{ padding: 0 }} />, joined, metadata, tenants, picture };

  return (
    <Box>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }} />

        <Button
          startIcon={<DeleteIcon />}
          onClick={() => setOpenConfirm( true )}
          color='error'
        >
          Delete
        </Button>

        <Button
          startIcon={<EditIcon />}
          onClick={onEdit}
        >
          Edit
        </Button>

      </Toolbar>

      <ConfirmDialog
        open={!!openConfirm}
        title={`Delete ${ record.email }`}
        message={`Are you sure you want to delete ${ record.name || record.email }?`}
        confirmButton='Delete'
        onClose={( confirmed ) => {
          if( confirmed ) {
            onDelete();
          }
          setOpenConfirm( false );
        }}
      />

      <PropTable label='' record={record} unsort />

    </Box>
  );
}


