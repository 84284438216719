import styled from '@mui/material/styles/styled';
import MuiSvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const SvgIcon = styled( MuiSvgIcon, {
  name: 'LogoCerner',
  shouldForwardProp: ( prop ) => prop !== 'fill',
} )<SvgIconProps>( () => ( {
  // fill: '#f00',
  // stroke: 'currentColor',
  // strokeLinecap: 'round',
  // strokeLinejoin: 'round',
  // strokeWidth: '2.25px',
} ) );

SvgIcon.defaultProps = {
  viewBox: '0 0 761 648',
  focusable: 'false',
  'aria-hidden': 'true',
};
//       <style type="text/css">
//         .st0{fill:#FFFFFF;}
//         .st1{fill:#582D82;}
//         .st2{fill:#FFC425;}
//         .st3{fill:#C1C5C8;}
//         .st4{fill:#A3AAAD;}
// </style>

export const LogoCerner: FC<SvgIconProps> = ( props ) => {

  const { fill = 'rgb(248, 153, 29)', ...rest } = props;

  return (
    <SvgIcon {...rest}>
      <g><path fill="#fefefe" d="M -0.5,-0.5 C 253.167,-0.5 506.833,-0.5 760.5,-0.5C 760.5,215.5 760.5,431.5 760.5,647.5C 506.833,647.5 253.167,647.5 -0.5,647.5C -0.5,431.5 -0.5,215.5 -0.5,-0.5 Z" /></g>
      <g><path fill="#468acc" d="M 538.5,180.5 C 536.983,180.489 535.816,179.823 535,178.5C 519.03,151.547 499.197,128.047 475.5,108C 399.791,48.2535 314.791,30.2535 220.5,54C 210.552,56.818 200.886,60.318 191.5,64.5C 188.829,62.7741 186.163,60.9408 183.5,59C 184.056,58.6174 184.389,58.1174 184.5,57.5C 272.958,23.1001 358.625,29.2667 441.5,76C 484.072,102.072 518.072,136.572 543.5,179.5C 541.955,180.452 540.288,180.785 538.5,180.5 Z" /></g>
      <g><path fill="#428ccc" d="M 538.5,180.5 C 530.019,181.645 521.352,182.311 512.5,182.5C 510.143,182.663 507.81,182.497 505.5,182C 454.421,111.202 384.921,74.3682 297,71.5C 264.657,71.5355 233.49,77.5355 203.5,89.5C 202.833,89.1667 202.167,88.8333 201.5,88.5C 199.547,84.0909 197.213,79.9242 194.5,76C 189.102,72.1015 183.435,68.7682 177.5,66C 175.866,65.5065 174.199,65.3398 172.5,65.5C 172.5,64.5 172.5,63.5 172.5,62.5C 176.261,60.4625 180.261,58.7958 184.5,57.5C 184.389,58.1174 184.056,58.6174 183.5,59C 186.163,60.9408 188.829,62.7741 191.5,64.5C 200.886,60.318 210.552,56.818 220.5,54C 314.791,30.2535 399.791,48.2535 475.5,108C 499.197,128.047 519.03,151.547 535,178.5C 535.816,179.823 536.983,180.489 538.5,180.5 Z" /></g>
      <g><path fill="#4691ce" d="M 172.5,62.5 C 172.5,63.5 172.5,64.5 172.5,65.5C 174.199,65.3398 175.866,65.5065 177.5,66C 183.435,68.7682 189.102,72.1015 194.5,76C 197.213,79.9242 199.547,84.0909 201.5,88.5C 202.167,88.8333 202.833,89.1667 203.5,89.5C 233.49,77.5355 264.657,71.5355 297,71.5C 384.921,74.3682 454.421,111.202 505.5,182C 507.81,182.497 510.143,182.663 512.5,182.5C 505.371,183.815 498.037,184.482 490.5,184.5C 438.446,114.65 368.446,81.4832 280.5,85C 255.262,86.6948 230.929,92.1948 207.5,101.5C 206.069,99.563 204.402,97.7297 202.5,96C 201.101,95.4054 199.768,95.5721 198.5,96.5C 195.701,93.8444 192.701,92.1777 189.5,91.5C 186.47,94.0232 183.47,93.8565 180.5,91C 178.402,88.146 176.069,85.4794 173.5,83C 174.167,82.6667 174.833,82.3333 175.5,82C 173.714,81.2155 172.214,80.0488 171,78.5C 167.629,79.1552 164.629,78.1552 162,75.5C 161,75.8333 160,76.1667 159,76.5C 157.603,74.9297 156.103,73.5964 154.5,72.5C 160.17,68.663 166.17,65.3296 172.5,62.5 Z" /></g>
      <g><path fill="#4893cf" d="M 490.5,184.5 C 484.862,185.22 479.196,185.553 473.5,185.5C 421.215,122.554 353.882,94.3877 271.5,101C 251.84,103.276 232.84,108.11 214.5,115.5C 211.617,111.068 209.284,106.401 207.5,101.5C 230.929,92.1948 255.262,86.6948 280.5,85C 368.446,81.4832 438.446,114.65 490.5,184.5 Z" /></g>
      <g><path fill="#4e98d0" d="M 154.5,72.5 C 156.103,73.5964 157.603,74.9297 159,76.5C 160,76.1667 161,75.8333 162,75.5C 164.629,78.1552 167.629,79.1552 171,78.5C 172.214,80.0488 173.714,81.2155 175.5,82C 174.833,82.3333 174.167,82.6667 173.5,83C 176.069,85.4794 178.402,88.146 180.5,91C 183.47,93.8565 186.47,94.0232 189.5,91.5C 192.701,92.1777 195.701,93.8444 198.5,96.5C 199.768,95.5721 201.101,95.4054 202.5,96C 204.402,97.7297 206.069,99.563 207.5,101.5C 209.284,106.401 211.617,111.068 214.5,115.5C 232.84,108.11 251.84,103.276 271.5,101C 353.882,94.3877 421.215,122.554 473.5,185.5C 462.024,187.501 450.358,188.667 438.5,189C 387.174,136.673 325.174,117.007 252.5,130C 242.79,132.737 233.123,135.571 223.5,138.5C 223.293,135.759 222.293,133.426 220.5,131.5C 218.081,124.829 214.748,118.662 210.5,113C 206.87,113.423 203.203,112.09 199.5,109C 193.367,106.085 187.034,104.085 180.5,103C 179.646,101.978 178.646,101.145 177.5,100.5C 175.167,100.5 172.833,100.5 170.5,100.5C 166.105,99.3661 161.605,97.6994 157,95.5C 154.618,96.8663 152.452,96.533 150.5,94.5C 151.811,93.7425 152.311,92.7425 152,91.5C 148.619,87.625 145.453,83.625 142.5,79.5C 145.835,76.1611 149.835,73.8278 154.5,72.5 Z" /></g>
      <g><path fill="#5498ca" d="M 142.5,79.5 C 145.453,83.625 148.619,87.625 152,91.5C 152.311,92.7425 151.811,93.7425 150.5,94.5C 152.452,96.533 154.618,96.8663 157,95.5C 161.605,97.6994 166.105,99.3661 170.5,100.5C 172.833,100.5 175.167,100.5 177.5,100.5C 178.646,101.145 179.646,101.978 180.5,103C 187.034,104.085 193.367,106.085 199.5,109C 203.203,112.09 206.87,113.423 210.5,113C 214.748,118.662 218.081,124.829 220.5,131.5C 215.332,128.894 209.832,126.894 204,125.5C 203.228,125.645 202.561,125.978 202,126.5C 200.287,124.807 198.287,123.474 196,122.5C 194.984,123.634 194.317,123.301 194,121.5C 192.318,121.807 190.652,122.14 189,122.5C 186.901,120.347 184.567,119.68 182,120.5C 180.102,117.87 178.269,117.537 176.5,119.5C 175.5,118.167 174.5,118.167 173.5,119.5C 172.522,119.023 171.689,118.357 171,117.5C 170.722,118.416 170.222,119.082 169.5,119.5C 166.683,116.574 163.849,113.574 161,110.5C 160.667,109.167 160.333,107.833 160,106.5C 157.957,104.792 156.29,102.792 155,100.5C 154.08,100.93 153.247,101.264 152.5,101.5C 153.889,104.342 153.222,106.675 150.5,108.5C 151.939,110.343 151.772,112.01 150,113.5C 143.62,105.242 137.12,97.0755 130.5,89C 129.906,88.5357 129.239,88.369 128.5,88.5C 132.624,84.7191 137.291,81.7191 142.5,79.5 Z" /></g>
      <g><path fill="#568eb5" d="M 163.5,150.5 C 167.702,155.691 171.035,161.358 173.5,167.5C 169.5,170.833 165.5,174.167 161.5,177.5C 160.783,172.402 158.283,168.402 154,165.5C 148.5,158.667 143,151.833 137.5,145C 138.28,143.927 138.113,142.76 137,141.5C 131,134.167 125,126.833 119,119.5C 118.383,118.551 118.549,117.718 119.5,117C 115.79,113.158 112.123,109.325 108.5,105.5C 106.736,107.278 104.736,108.611 102.5,109.5C 107.891,103.776 113.891,98.7763 120.5,94.5C 121.496,94.5858 122.329,94.2524 123,93.5C 124.584,97.4195 127.25,100.419 131,102.5C 132.11,104.397 132.276,106.23 131.5,108C 138.5,116.333 145.5,124.667 152.5,133C 151.833,133.667 151.167,134.333 150.5,135C 155.408,139.761 159.741,144.928 163.5,150.5 Z" /></g>
      <g><path fill="#5b9fcc" d="M 220.5,131.5 C 222.293,133.426 223.293,135.759 223.5,138.5C 213.028,143.444 202.695,148.777 192.5,154.5C 190.343,151.529 188.176,148.529 186,145.5C 184.871,143.859 183.371,142.859 181.5,142.5C 180.862,140.54 180.862,138.707 181.5,137C 177.147,132.596 172.98,128.096 169,123.5C 168.805,122.181 168.972,120.847 169.5,119.5C 170.222,119.082 170.722,118.416 171,117.5C 171.689,118.357 172.522,119.023 173.5,119.5C 174.5,118.167 175.5,118.167 176.5,119.5C 178.269,117.537 180.102,117.87 182,120.5C 184.567,119.68 186.901,120.347 189,122.5C 190.652,122.14 192.318,121.807 194,121.5C 194.317,123.301 194.984,123.634 196,122.5C 198.287,123.474 200.287,124.807 202,126.5C 202.561,125.978 203.228,125.645 204,125.5C 209.832,126.894 215.332,128.894 220.5,131.5 Z" /></g>
      <g><path fill="#5487a9" d="M 161.5,177.5 C 157.833,181.833 153.833,185.833 149.5,189.5C 149.822,187.278 149.155,185.444 147.5,184C 148.308,183.692 148.975,183.192 149.5,182.5C 148.6,176.602 145.767,171.935 141,168.5C 126.812,150.094 112.646,131.761 98.5,113.5C 99.5,111.833 100.833,110.5 102.5,109.5C 104.736,108.611 106.736,107.278 108.5,105.5C 112.123,109.325 115.79,113.158 119.5,117C 118.549,117.718 118.383,118.551 119,119.5C 125,126.833 131,134.167 137,141.5C 138.113,142.76 138.28,143.927 137.5,145C 143,151.833 148.5,158.667 154,165.5C 158.283,168.402 160.783,172.402 161.5,177.5 Z" /></g>
      <g><path fill="#5896c0" d="M 128.5,88.5 C 129.239,88.369 129.906,88.5357 130.5,89C 137.12,97.0755 143.62,105.242 150,113.5C 151.772,112.01 151.939,110.343 150.5,108.5C 153.222,106.675 153.889,104.342 152.5,101.5C 153.247,101.264 154.08,100.93 155,100.5C 156.29,102.792 157.957,104.792 160,106.5C 160.333,107.833 160.667,109.167 161,110.5C 163.849,113.574 166.683,116.574 169.5,119.5C 168.972,120.847 168.805,122.181 169,123.5C 172.98,128.096 177.147,132.596 181.5,137C 180.862,138.707 180.862,140.54 181.5,142.5C 179.956,142.297 178.622,141.63 177.5,140.5C 176.5,141 175.5,141.5 174.5,142C 175.863,144.129 175.529,144.629 173.5,143.5C 172.304,143.846 172.304,144.346 173.5,145C 172.065,144.689 170.732,144.855 169.5,145.5C 168.915,147.865 167.748,149.865 166,151.5C 165.329,150.748 164.496,150.414 163.5,150.5C 159.741,144.928 155.408,139.761 150.5,135C 151.167,134.333 151.833,133.667 152.5,133C 145.5,124.667 138.5,116.333 131.5,108C 132.276,106.23 132.11,104.397 131,102.5C 127.25,100.419 124.584,97.4195 123,93.5C 122.329,94.2524 121.496,94.5858 120.5,94.5C 122.86,92.1468 125.527,90.1468 128.5,88.5 Z" /></g>
      <g><path fill="#619cc3" d="M 181.5,142.5 C 183.371,142.859 184.871,143.859 186,145.5C 188.176,148.529 190.343,151.529 192.5,154.5C 185.892,158.443 179.559,162.776 173.5,167.5C 171.035,161.358 167.702,155.691 163.5,150.5C 164.496,150.414 165.329,150.748 166,151.5C 167.748,149.865 168.915,147.865 169.5,145.5C 170.732,144.855 172.065,144.689 173.5,145C 172.304,144.346 172.304,143.846 173.5,143.5C 175.529,144.629 175.863,144.129 174.5,142C 175.5,141.5 176.5,141 177.5,140.5C 178.622,141.63 179.956,142.297 181.5,142.5 Z" /></g>
      <g><path fill="#3a5669" d="M 124.5,222.5 C 124.443,223.609 124.11,224.609 123.5,225.5C 122.167,225.167 120.833,224.833 119.5,224.5C 117.167,223.167 114.833,221.833 112.5,220.5C 113.552,220.649 114.552,220.483 115.5,220C 110.372,211.574 104.872,203.408 99,195.5C 97.3333,194.167 95.6667,192.833 94,191.5C 89.3767,185.208 84.3767,179.208 79,173.5C 75.879,165.591 71.0457,158.925 64.5,153.5C 63.4822,154.193 62.4822,154.859 61.5,155.5C 63.016,152.467 65.016,149.8 67.5,147.5C 68.5754,146.438 69.5754,146.438 70.5,147.5C 82.1606,162.654 94.3273,177.32 107,191.5C 109.333,196.5 111.667,201.5 114,206.5C 118.767,211.044 122.267,216.377 124.5,222.5 Z" /></g>
      <g><path fill="#354f5f" d="M 112.5,220.5 C 107.333,218.084 102.333,215.418 97.5,212.5C 98.2389,212.631 98.9056,212.464 99.5,212C 98.7877,210.575 98.621,209.075 99,207.5C 90.0923,196.682 81.4256,185.682 73,174.5C 72.6401,172.392 71.9734,170.392 71,168.5C 67.5134,164.858 64.5134,160.858 62,156.5C 61.1482,157.571 60.3149,158.571 59.5,159.5C 59.5,158.833 59.5,158.167 59.5,157.5C 59.8333,156.5 60.5,155.833 61.5,155.5C 62.4822,154.859 63.4822,154.193 64.5,153.5C 71.0457,158.925 75.879,165.591 79,173.5C 84.3767,179.208 89.3767,185.208 94,191.5C 95.6667,192.833 97.3333,194.167 99,195.5C 104.872,203.408 110.372,211.574 115.5,220C 114.552,220.483 113.552,220.649 112.5,220.5 Z" /></g>
      <g><path fill="#4c748e" d="M 88.5,123.5 C 92.532,127.159 96.3654,131.159 100,135.5C 110.333,148.5 120.667,161.5 131,174.5C 134.334,181.179 137.667,187.846 141,194.5C 141.49,195.793 141.657,197.127 141.5,198.5C 139.518,201.81 137.185,204.81 134.5,207.5C 133.074,202.661 130.574,198.328 127,194.5C 126.333,192.5 125.667,190.5 125,188.5C 120.302,180.427 115.636,172.427 111,164.5C 100.971,153.798 91.6378,142.465 83,130.5C 82.3292,131.252 81.4959,131.586 80.5,131.5C 82.8333,128.5 85.5,125.833 88.5,123.5 Z" /></g>
      <g><path fill="#466981" d="M 134.5,207.5 C 132.745,210.68 130.745,213.68 128.5,216.5C 128.657,215.127 128.49,213.793 128,212.5C 124.421,207.021 121.087,201.354 118,195.5C 117,194.833 116,194.167 115,193.5C 107.809,184.326 101.476,174.659 96,164.5C 89.2859,156.788 82.9526,148.788 77,140.5C 75.9887,139.663 74.8221,139.33 73.5,139.5C 75.8623,136.808 78.1956,134.141 80.5,131.5C 81.4959,131.586 82.3292,131.252 83,130.5C 91.6378,142.465 100.971,153.798 111,164.5C 115.636,172.427 120.302,180.427 125,188.5C 125.667,190.5 126.333,192.5 127,194.5C 130.574,198.328 133.074,202.661 134.5,207.5 Z" /></g>
      <g><path fill="#80bb40" d="M 59.5,157.5 C 59.5,158.167 59.5,158.833 59.5,159.5C 60.9474,173.517 66.614,185.517 76.5,195.5C 75.5041,195.586 74.6708,195.252 74,194.5C 73.5,226.498 73.3334,258.498 73.5,290.5C 45.1634,274.5 31.9967,250.167 34,217.5C 36.261,194.666 44.761,174.666 59.5,157.5 Z" /></g>
      <g><path fill="#527e9b" d="M 98.5,113.5 C 112.646,131.761 126.812,150.094 141,168.5C 145.767,171.935 148.6,176.602 149.5,182.5C 148.975,183.192 148.308,183.692 147.5,184C 149.155,185.444 149.822,187.278 149.5,189.5C 146.805,192.526 144.138,195.526 141.5,198.5C 141.657,197.127 141.49,195.793 141,194.5C 137.667,187.846 134.334,181.179 131,174.5C 120.667,161.5 110.333,148.5 100,135.5C 96.3654,131.159 92.532,127.159 88.5,123.5C 90.7842,119.216 94.1175,115.882 98.5,113.5 Z" /></g>
      <g><path fill="#2f4654" d="M 97.5,212.5 C 97.1667,212.5 96.8333,212.5 96.5,212.5C 89.2207,207.554 82.5541,201.887 76.5,195.5C 66.614,185.517 60.9474,173.517 59.5,159.5C 60.3149,158.571 61.1482,157.571 62,156.5C 64.5134,160.858 67.5134,164.858 71,168.5C 71.9734,170.392 72.6401,172.392 73,174.5C 81.4256,185.682 90.0923,196.682 99,207.5C 98.621,209.075 98.7877,210.575 99.5,212C 98.9056,212.464 98.2389,212.631 97.5,212.5 Z" /></g>
      <g><path fill="#406075" d="M 73.5,139.5 C 74.8221,139.33 75.9887,139.663 77,140.5C 82.9526,148.788 89.2859,156.788 96,164.5C 101.476,174.659 107.809,184.326 115,193.5C 116,194.167 117,194.833 118,195.5C 121.087,201.354 124.421,207.021 128,212.5C 128.49,213.793 128.657,215.127 128.5,216.5C 127.66,218.853 126.326,220.853 124.5,222.5C 122.267,216.377 118.767,211.044 114,206.5C 111.667,201.5 109.333,196.5 107,191.5C 94.3273,177.32 82.1606,162.654 70.5,147.5C 69.5754,146.438 68.5754,146.438 67.5,147.5C 69.1223,144.545 71.1223,141.878 73.5,139.5 Z" /></g>
      <g><path fill="#86bd3e" d="M 76.5,195.5 C 82.5541,201.887 89.2207,207.554 96.5,212.5C 95.5,212.5 94.5,212.5 93.5,212.5C 93.8287,241.74 93.4953,270.907 92.5,300C 94.3197,300.2 95.653,301.034 96.5,302.5C 88.3846,299.276 80.7179,295.276 73.5,290.5C 73.3334,258.498 73.5,226.498 74,194.5C 74.6708,195.252 75.5041,195.586 76.5,195.5 Z" /></g>
      <g><path fill="#8cc03d" d="M 96.5,212.5 C 96.8333,212.5 97.1667,212.5 97.5,212.5C 102.333,215.418 107.333,218.084 112.5,220.5C 114.833,221.833 117.167,223.167 119.5,224.5C 118.178,224.67 117.011,224.337 116,223.5C 115.176,252.572 115.342,281.572 116.5,310.5C 109.359,308.791 102.692,306.125 96.5,302.5C 95.653,301.034 94.3197,300.2 92.5,300C 93.4953,270.907 93.8287,241.74 93.5,212.5C 94.5,212.5 95.5,212.5 96.5,212.5 Z" /></g>
      <g><path fill="#82bc41" d="M 678.5,299.5 C 678.5,274.167 678.5,248.833 678.5,223.5C 675.833,223.5 673.167,223.5 670.5,223.5C 690.028,221.223 709.694,218.889 729.5,216.5C 730.5,216.833 731.5,217.167 732.5,217.5C 714.476,244.899 696.476,272.232 678.5,299.5 Z" /></g>
      <g><path fill="#93c33b" d="M 119.5,224.5 C 120.833,224.833 122.167,225.167 123.5,225.5C 128.676,227.261 133.676,229.261 138.5,231.5C 138.333,259.835 138.5,288.169 139,316.5C 141.294,317.422 143.461,318.422 145.5,319.5C 135.596,316.946 125.929,313.946 116.5,310.5C 115.342,281.572 115.176,252.572 116,223.5C 117.011,224.337 118.178,224.67 119.5,224.5 Z" /></g>
      <g><path fill="#86be3e" d="M 670.5,223.5 C 673.167,223.5 675.833,223.5 678.5,223.5C 678.5,248.833 678.5,274.167 678.5,299.5C 677.513,301.473 676.513,303.473 675.5,305.5C 667.817,307.007 660.151,308.341 652.5,309.5C 651.841,281.402 651.175,253.402 650.5,225.5C 656.97,224.304 663.637,223.638 670.5,223.5 Z" /></g>
      <g><path fill="#8cc03d" d="M 650.5,225.5 C 651.175,253.402 651.841,281.402 652.5,309.5C 642.513,310.927 632.513,312.26 622.5,313.5C 621.509,285.574 621.176,257.574 621.5,229.5C 617.5,229.5 613.5,229.5 609.5,229.5C 612.287,228.523 615.287,228.19 618.5,228.5C 623.731,228.556 628.731,227.889 633.5,226.5C 639.152,225.968 644.819,225.635 650.5,225.5 Z" /></g>
      <g><path fill="#bbda89" d="M 633.5,226.5 C 628.731,227.889 623.731,228.556 618.5,228.5C 623.269,227.111 628.269,226.444 633.5,226.5 Z" /></g>
      <g><path fill="#92c33b" d="M 609.5,229.5 C 613.5,229.5 617.5,229.5 621.5,229.5C 621.176,257.574 621.509,285.574 622.5,313.5C 612.191,314.94 601.857,316.273 591.5,317.5C 591.177,288.554 590.177,259.887 588.5,231.5C 595.27,229.925 602.27,229.259 609.5,229.5 Z" /></g>
      <g><path fill="#9ac63a" d="M 138.5,231.5 C 149.615,234.185 160.615,236.851 171.5,239.5C 169.5,239.5 167.5,239.5 165.5,239.5C 165.167,267.508 165.5,295.508 166.5,323.5C 169.793,323.976 172.793,324.976 175.5,326.5C 165.399,324.679 155.399,322.346 145.5,319.5C 143.461,318.422 141.294,317.422 139,316.5C 138.5,288.169 138.333,259.835 138.5,231.5 Z" /></g>
      <g><path fill="#99c53a" d="M 588.5,231.5 C 590.177,259.887 591.177,288.554 591.5,317.5C 581.048,319.473 570.382,320.806 559.5,321.5C 559.831,292.329 559.498,263.329 558.5,234.5C 568.287,232.784 578.287,231.784 588.5,231.5 Z" /></g>
      <g><path fill="#9fc839" d="M 558.5,234.5 C 559.498,263.329 559.831,292.329 559.5,321.5C 550.013,323.154 540.347,324.487 530.5,325.5C 529.833,325.5 529.167,325.5 528.5,325.5C 529.492,295.907 529.825,266.24 529.5,236.5C 539.078,234.912 548.745,234.245 558.5,234.5 Z" /></g>
      <g><path fill="#a5ca37" d="M 529.5,236.5 C 529.825,266.24 529.492,295.907 528.5,325.5C 523.951,325.624 519.618,326.291 515.5,327.5C 511.131,327.467 506.798,327.8 502.5,328.5C 502.5,298.833 502.5,269.167 502.5,239.5C 511.273,237.73 520.273,236.73 529.5,236.5 Z" /></g>
      <g><path fill="#a0c838" d="M 171.5,239.5 C 175.975,240.392 180.308,241.392 184.5,242.5C 183.502,270.662 183.169,298.995 183.5,327.5C 184.791,327.263 185.791,327.596 186.5,328.5C 182.779,328.089 179.112,327.422 175.5,326.5C 172.793,324.976 169.793,323.976 166.5,323.5C 165.5,295.508 165.167,267.508 165.5,239.5C 167.5,239.5 169.5,239.5 171.5,239.5 Z" /></g>
      <g><path fill="#abcd37" d="M 502.5,239.5 C 502.5,269.167 502.5,298.833 502.5,328.5C 490.188,329.979 477.855,331.312 465.5,332.5C 467.607,331.532 469.941,331.198 472.5,331.5C 472.5,301.833 472.5,272.167 472.5,242.5C 467.5,242.5 462.5,242.5 457.5,242.5C 472.31,240.911 487.31,239.911 502.5,239.5 Z" /></g>
      <g><path fill="#a5ca38" d="M 184.5,242.5 C 191.337,243.222 198.004,244.222 204.5,245.5C 202.508,273.48 201.842,301.814 202.5,330.5C 203.791,330.263 204.791,330.596 205.5,331.5C 199.01,331.291 192.677,330.291 186.5,328.5C 185.791,327.596 184.791,327.263 183.5,327.5C 183.169,298.995 183.502,270.662 184.5,242.5 Z" /></g>
      <g><path fill="#b2cf35" d="M 457.5,242.5 C 462.5,242.5 467.5,242.5 472.5,242.5C 472.5,272.167 472.5,301.833 472.5,331.5C 469.941,331.198 467.607,331.532 465.5,332.5C 457.466,332.8 449.466,333.467 441.5,334.5C 441.5,304.5 441.5,274.5 441.5,244.5C 440.167,244.5 438.833,244.5 437.5,244.5C 443.937,242.961 450.604,242.294 457.5,242.5 Z" /></g>
      <g><path fill="#b8d235" d="M 437.5,244.5 C 438.833,244.5 440.167,244.5 441.5,244.5C 441.5,274.5 441.5,304.5 441.5,334.5C 432.902,335.386 424.235,336.053 415.5,336.5C 415.5,306.5 415.5,276.5 415.5,246.5C 412.167,246.5 408.833,246.5 405.5,246.5C 415.97,245.099 426.636,244.433 437.5,244.5 Z" /></g>
      <g><path fill="#abcd37" d="M 204.5,245.5 C 214.66,246.304 224.66,247.304 234.5,248.5C 231.5,248.5 228.5,248.5 225.5,248.5C 225.175,277.24 225.509,305.907 226.5,334.5C 219.387,334.155 212.387,333.155 205.5,331.5C 204.791,330.596 203.791,330.263 202.5,330.5C 201.842,301.814 202.508,273.48 204.5,245.5 Z" /></g>
      <g><path fill="#bfd534" d="M 405.5,246.5 C 408.833,246.5 412.167,246.5 415.5,246.5C 415.5,276.5 415.5,306.5 415.5,336.5C 413.708,336.366 412.042,336.699 410.5,337.5C 402.483,337.501 394.483,337.834 386.5,338.5C 388.48,308.724 389.147,278.724 388.5,248.5C 384.167,248.5 379.833,248.5 375.5,248.5C 385.311,247.261 395.311,246.594 405.5,246.5 Z" /></g>
      <g><path fill="#b2d036" d="M 234.5,248.5 C 238.898,248.294 243.232,248.627 247.5,249.5C 247.333,278.169 247.5,306.835 248,335.5C 250.939,336.128 253.772,336.795 256.5,337.5C 246.393,337.097 236.393,336.097 226.5,334.5C 225.509,305.907 225.175,277.24 225.5,248.5C 228.5,248.5 231.5,248.5 234.5,248.5 Z" /></g>
      <g><path fill="#c5d732" d="M 375.5,248.5 C 379.833,248.5 384.167,248.5 388.5,248.5C 389.147,278.724 388.48,308.724 386.5,338.5C 383.941,338.198 381.607,338.532 379.5,339.5C 368.146,339.34 356.813,339.674 345.5,340.5C 347.265,339.539 349.265,339.205 351.5,339.5C 351.5,309.5 351.5,279.5 351.5,249.5C 359.316,248.508 367.316,248.174 375.5,248.5 Z" /></g>
      <g><path fill="#b8d234" d="M 247.5,249.5 C 253.845,249.653 260.178,249.986 266.5,250.5C 266.5,279.5 266.5,308.5 266.5,337.5C 268.735,337.205 270.735,337.539 272.5,338.5C 266.974,338.821 261.64,338.488 256.5,337.5C 253.772,336.795 250.939,336.128 248,335.5C 247.5,306.835 247.333,278.169 247.5,249.5 Z" /></g>
      <g><path fill="#bfd534" d="M 266.5,250.5 C 281.5,250.5 296.5,250.5 311.5,250.5C 310.081,251.451 308.415,251.784 306.5,251.5C 299.833,251.5 293.167,251.5 286.5,251.5C 286.5,280.833 286.5,310.167 286.5,339.5C 281.737,339.774 277.07,339.441 272.5,338.5C 270.735,337.539 268.735,337.205 266.5,337.5C 266.5,308.5 266.5,279.5 266.5,250.5 Z" /></g>
      <g><path fill="#ccda31" d="M 351.5,249.5 C 351.5,279.5 351.5,309.5 351.5,339.5C 349.265,339.205 347.265,339.539 345.5,340.5C 332.5,340.5 319.5,340.5 306.5,340.5C 306.5,310.833 306.5,281.167 306.5,251.5C 308.415,251.784 310.081,251.451 311.5,250.5C 324.833,250.167 338.167,249.833 351.5,249.5 Z" /></g>
      <g><path fill="#c4d633" d="M 306.5,251.5 C 306.5,281.167 306.5,310.833 306.5,340.5C 299.776,340.756 293.109,340.423 286.5,339.5C 286.5,310.167 286.5,280.833 286.5,251.5C 293.167,251.5 299.833,251.5 306.5,251.5 Z" /></g>
      <g><path fill="#e1edbc" d="M 528.5,325.5 C 529.167,325.5 529.833,325.5 530.5,325.5C 525.731,326.889 520.731,327.556 515.5,327.5C 519.618,326.291 523.951,325.624 528.5,325.5 Z" /></g>
      <g><path fill="#f4f7db" d="M 410.5,337.5 C 400.372,339.009 390.038,339.675 379.5,339.5C 381.607,338.532 383.941,338.198 386.5,338.5C 394.483,337.834 402.483,337.501 410.5,337.5 Z" /></g>
      <g><path fill="#82bc41" d="M 607.5,411.5 C 607.5,390.167 607.5,368.833 607.5,347.5C 606.167,347.5 604.833,347.5 603.5,347.5C 619.552,344.631 635.718,341.631 652,338.5C 652.833,338.833 653.667,339.167 654.5,339.5C 638.774,363.54 623.107,387.54 607.5,411.5 Z" /></g>
      <g><path fill="#87be3e" d="M 603.5,347.5 C 604.833,347.5 606.167,347.5 607.5,347.5C 607.5,368.833 607.5,390.167 607.5,411.5C 604.216,417.229 600.549,422.729 596.5,428C 594.527,428.495 592.527,428.662 590.5,428.5C 587.848,428.41 585.515,429.076 583.5,430.5C 582.833,430.5 582.167,430.5 581.5,430.5C 582.49,404.241 582.824,377.908 582.5,351.5C 578.881,352.244 575.215,352.577 571.5,352.5C 581.998,350.606 592.664,348.94 603.5,347.5 Z" /></g>
      <g><path fill="#8cc03d" d="M 122.5,350.5 C 117.167,350.5 111.833,350.5 106.5,350.5C 106.5,377.5 106.5,404.5 106.5,431.5C 104.972,432.309 103.306,432.976 101.5,433.5C 97.3038,434.785 93.3038,436.452 89.5,438.5C 87.5082,410.186 86.8415,381.519 87.5,352.5C 89.6365,351.213 91.9699,350.379 94.5,350C 104.009,349.176 113.343,349.342 122.5,350.5 Z" /></g>
      <g><path fill="#92c33b" d="M 122.5,350.5 C 125.689,350.858 128.689,351.525 131.5,352.5C 129.833,352.5 128.167,352.5 126.5,352.5C 126.5,379.167 126.5,405.833 126.5,432.5C 126.167,432.5 125.833,432.5 125.5,432.5C 119.77,430.621 114.104,430.287 108.5,431.5C 107.833,431.5 107.167,431.5 106.5,431.5C 106.5,404.5 106.5,377.5 106.5,350.5C 111.833,350.5 117.167,350.5 122.5,350.5 Z" /></g>
      <g><path fill="#8cc03c" d="M 581.5,430.5 C 573.834,431.833 566.168,433.166 558.5,434.5C 557.833,434.5 557.167,434.5 556.5,434.5C 556.178,407.886 555.178,381.553 553.5,355.5C 559.291,354.021 565.291,353.021 571.5,352.5C 575.215,352.577 578.881,352.244 582.5,351.5C 582.824,377.908 582.49,404.241 581.5,430.5 Z" /></g>
      <g><path fill="#86bd3e" d="M 87.5,352.5 C 86.8415,381.519 87.5082,410.186 89.5,438.5C 82.5604,443.473 76.2271,449.14 70.5,455.5C 70.1646,423.653 69.1646,391.987 67.5,360.5C 73.5556,356.478 80.2222,353.811 87.5,352.5 Z" /></g>
      <g><path fill="#99c53a" d="M 131.5,352.5 C 137.02,353.547 142.354,354.88 147.5,356.5C 147.5,383.833 147.5,411.167 147.5,438.5C 142.835,437.501 138.169,436.501 133.5,435.5C 133.167,435.5 132.833,435.5 132.5,435.5C 130.426,434.486 128.426,433.486 126.5,432.5C 126.5,405.833 126.5,379.167 126.5,352.5C 128.167,352.5 129.833,352.5 131.5,352.5 Z" /></g>
      <g><path fill="#92c33b" d="M 551.5,355.5 C 552.167,355.5 552.833,355.5 553.5,355.5C 555.178,381.553 556.178,407.886 556.5,434.5C 553.311,434.858 550.311,435.525 547.5,436.5C 541.148,437.392 534.815,438.392 528.5,439.5C 528.5,412.833 528.5,386.167 528.5,359.5C 536.349,358.471 544.016,357.137 551.5,355.5 Z" /></g>
      <g><path fill="#a2c938" d="M 147.5,356.5 C 162.03,358.723 176.364,361.39 190.5,364.5C 188.5,364.5 186.5,364.5 184.5,364.5C 184.5,391.5 184.5,418.5 184.5,445.5C 171.817,444.216 159.483,441.882 147.5,438.5C 147.5,411.167 147.5,383.833 147.5,356.5 Z" /></g>
      <g><path fill="#acd168" d="M 551.5,355.5 C 544.016,357.137 536.349,358.471 528.5,359.5C 526.5,359.5 524.5,359.5 522.5,359.5C 531.933,357.439 541.599,356.105 551.5,355.5 Z" /></g>
      <g><path fill="#99c53a" d="M 522.5,359.5 C 524.5,359.5 526.5,359.5 528.5,359.5C 528.5,386.167 528.5,412.833 528.5,439.5C 519.488,440.835 510.488,442.168 501.5,443.5C 501.5,416.5 501.5,389.5 501.5,362.5C 499.833,362.5 498.167,362.5 496.5,362.5C 505.006,361.336 513.673,360.336 522.5,359.5 Z" /></g>
      <g><path fill="#a0c839" d="M 496.5,362.5 C 498.167,362.5 499.833,362.5 501.5,362.5C 501.5,389.5 501.5,416.5 501.5,443.5C 491.195,445.02 480.862,446.353 470.5,447.5C 471.568,446.566 472.901,446.232 474.5,446.5C 474.5,419.5 474.5,392.5 474.5,365.5C 473.5,365.5 472.5,365.5 471.5,365.5C 479.609,363.779 487.943,362.779 496.5,362.5 Z" /></g>
      <g><path fill="#abcd37" d="M 190.5,364.5 C 195.82,365.26 201.153,365.927 206.5,366.5C 206.5,366.833 206.5,367.167 206.5,367.5C 205.5,367.5 204.5,367.5 203.5,367.5C 203.5,394.5 203.5,421.5 203.5,448.5C 197.032,448.089 190.699,447.089 184.5,445.5C 184.5,418.5 184.5,391.5 184.5,364.5C 186.5,364.5 188.5,364.5 190.5,364.5 Z" /></g>
      <g><path fill="#a5ca38" d="M 471.5,365.5 C 472.5,365.5 473.5,365.5 474.5,365.5C 474.5,392.5 474.5,419.5 474.5,446.5C 472.901,446.232 471.568,446.566 470.5,447.5C 463.851,448.331 457.185,448.998 450.5,449.5C 450.5,422.5 450.5,395.5 450.5,368.5C 446.833,368.5 443.167,368.5 439.5,368.5C 449.997,367.223 460.664,366.223 471.5,365.5 Z" /></g>
      <g><path fill="#b2d036" d="M 206.5,367.5 C 211.152,367.998 215.819,368.331 220.5,368.5C 223.33,368.963 225.997,369.629 228.5,370.5C 226.833,370.5 225.167,370.5 223.5,370.5C 223.5,397.167 223.5,423.833 223.5,450.5C 216.776,450.326 210.109,449.659 203.5,448.5C 203.5,421.5 203.5,394.5 203.5,367.5C 204.5,367.5 205.5,367.5 206.5,367.5 Z" /></g>
      <g><path fill="#eaf1c6" d="M 206.5,367.5 C 206.5,367.167 206.5,366.833 206.5,366.5C 211.414,366.362 216.081,367.028 220.5,368.5C 215.819,368.331 211.152,367.998 206.5,367.5 Z" /></g>
      <g><path fill="#accd37" d="M 439.5,368.5 C 443.167,368.5 446.833,368.5 450.5,368.5C 450.5,395.5 450.5,422.5 450.5,449.5C 440.948,451.157 431.281,452.157 421.5,452.5C 422.816,438.888 423.483,425.055 423.5,411C 423.167,397.5 422.833,384 422.5,370.5C 415.892,371.462 409.226,371.795 402.5,371.5C 414.636,369.894 426.97,368.894 439.5,368.5 Z" /></g>
      <g><path fill="#7fbb41" d="M 67.5,360.5 C 69.1646,391.987 70.1646,423.653 70.5,455.5C 64.3623,463.449 60.6957,472.449 59.5,482.5C 59.5,484.167 59.5,485.833 59.5,487.5C 36.4828,458.75 29.3161,426.416 38,390.5C 44.6615,377.339 54.4949,367.339 67.5,360.5 Z" /></g>
      <g><path fill="#b2cf35" d="M 421.5,452.5 C 418.904,453.265 416.237,453.599 413.5,453.5C 407.828,453.76 402.161,454.093 396.5,454.5C 396.5,427.167 396.5,399.833 396.5,372.5C 394.833,372.5 393.167,372.5 391.5,372.5C 394.962,371.518 398.629,371.185 402.5,371.5C 409.226,371.795 415.892,371.462 422.5,370.5C 422.833,384 423.167,397.5 423.5,411C 423.483,425.055 422.816,438.888 421.5,452.5 Z" /></g>
      <g><path fill="#bcd434" d="M 228.5,370.5 C 239.686,371.053 250.686,372.053 261.5,373.5C 260.5,373.5 259.5,373.5 258.5,373.5C 258.5,400.167 258.5,426.833 258.5,453.5C 261.067,453.721 263.401,454.388 265.5,455.5C 258.833,454.833 252.167,454.167 245.5,453.5C 240.402,452.043 235.069,451.376 229.5,451.5C 227.435,451.483 225.435,451.15 223.5,450.5C 223.5,423.833 223.5,397.167 223.5,370.5C 225.167,370.5 226.833,370.5 228.5,370.5 Z" /></g>
      <g><path fill="#b8d235" d="M 370.5,373.5 C 377.313,372.509 384.313,372.175 391.5,372.5C 393.167,372.5 394.833,372.5 396.5,372.5C 396.5,399.833 396.5,427.167 396.5,454.5C 394.265,454.205 392.265,454.539 390.5,455.5C 384.536,456.307 378.536,456.64 372.5,456.5C 373.158,428.48 372.492,400.813 370.5,373.5 Z" /></g>
      <g><path fill="#c4d733" d="M 289.5,374.5 C 309.5,374.5 329.5,374.5 349.5,374.5C 349.5,402.167 349.5,429.833 349.5,457.5C 331.341,458.689 313.341,458.689 295.5,457.5C 305.5,457.5 315.5,457.5 325.5,457.5C 325.5,430.167 325.5,402.833 325.5,375.5C 313.322,375.828 301.322,375.495 289.5,374.5 Z" /></g>
      <g><path fill="#bed433" d="M 370.5,373.5 C 372.492,400.813 373.158,428.48 372.5,456.5C 364.876,457.398 357.209,457.731 349.5,457.5C 349.5,429.833 349.5,402.167 349.5,374.5C 356.496,374.106 363.496,373.773 370.5,373.5 Z" /></g>
      <g><path fill="#cad931" d="M 261.5,373.5 C 270.823,374.068 280.156,374.401 289.5,374.5C 301.322,375.495 313.322,375.828 325.5,375.5C 325.5,402.833 325.5,430.167 325.5,457.5C 315.5,457.5 305.5,457.5 295.5,457.5C 285.469,457.255 275.469,456.588 265.5,455.5C 263.401,454.388 261.067,453.721 258.5,453.5C 258.5,426.833 258.5,400.167 258.5,373.5C 259.5,373.5 260.5,373.5 261.5,373.5 Z" /></g>
      <g><path fill="#c8e1a5" d="M 590.5,428.5 C 588.485,429.924 586.152,430.59 583.5,430.5C 585.515,429.076 587.848,428.41 590.5,428.5 Z" /></g>
      <g><path fill="#d4e7b3" d="M 556.5,434.5 C 557.167,434.5 557.833,434.5 558.5,434.5C 555.155,436.217 551.489,436.884 547.5,436.5C 550.311,435.525 553.311,434.858 556.5,434.5 Z" /></g>
      <g><path fill="#2c414c" d="M 101.5,433.5 C 88.3393,449.812 75.1726,466.145 62,482.5C 61.2303,483.598 60.397,483.598 59.5,482.5C 60.6957,472.449 64.3623,463.449 70.5,455.5C 76.2271,449.14 82.5604,443.473 89.5,438.5C 93.3038,436.452 97.3038,434.785 101.5,433.5 Z" /></g>
      <g><path fill="#3d5c70" d="M 125.5,432.5 C 125.833,432.5 126.167,432.5 126.5,432.5C 128.426,433.486 130.426,434.486 132.5,435.5C 132.414,436.496 132.748,437.329 133.5,438C 115.247,461.261 96.5807,484.261 77.5,507C 76.552,507.483 75.552,507.649 74.5,507.5C 72.4202,505.427 70.7536,503.093 69.5,500.5C 82.7497,486.391 95.2497,471.391 107,455.5C 111.017,451.414 115.017,447.414 119,443.5C 121.694,440.108 123.86,436.441 125.5,432.5 Z" /></g>
      <g><path fill="#385364" d="M 108.5,431.5 C 114.104,430.287 119.77,430.621 125.5,432.5C 123.86,436.441 121.694,440.108 119,443.5C 115.017,447.414 111.017,451.414 107,455.5C 95.2497,471.391 82.7497,486.391 69.5,500.5C 66.3637,497.571 63.697,494.238 61.5,490.5C 62.5832,490.539 63.5832,490.873 64.5,491.5C 66.4829,490.018 68.3163,488.351 70,486.5C 84.8712,468.302 99.7045,450.136 114.5,432C 112.527,431.505 110.527,431.338 108.5,431.5 Z" /></g>
      <g><path fill="#d6e485" d="M 229.5,451.5 C 235.069,451.376 240.402,452.043 245.5,453.5C 239.949,453.473 234.616,452.806 229.5,451.5 Z" /></g>
      <g><path fill="#e8f0c1" d="M 413.5,453.5 C 412.048,454.302 410.382,454.802 408.5,455C 402.509,455.499 396.509,455.666 390.5,455.5C 392.265,454.539 394.265,454.205 396.5,454.5C 402.161,454.093 407.828,453.76 413.5,453.5 Z" /></g>
      <g><path fill="#45687f" d="M 132.5,435.5 C 132.833,435.5 133.167,435.5 133.5,435.5C 136.585,439.246 139.251,443.246 141.5,447.5C 141.539,448.583 141.873,449.583 142.5,450.5C 131.174,467.523 118.674,483.523 105,498.5C 99.744,506.097 94.0773,513.43 88,520.5C 87.3292,519.748 86.4959,519.414 85.5,519.5C 81.3765,515.921 77.7099,511.921 74.5,507.5C 75.552,507.649 76.552,507.483 77.5,507C 96.5807,484.261 115.247,461.261 133.5,438C 132.748,437.329 132.414,436.496 132.5,435.5 Z" /></g>
      <g><path fill="#304858" d="M 106.5,431.5 C 107.167,431.5 107.833,431.5 108.5,431.5C 110.527,431.338 112.527,431.505 114.5,432C 99.7045,450.136 84.8712,468.302 70,486.5C 68.3163,488.351 66.4829,490.018 64.5,491.5C 63.5832,490.873 62.5832,490.539 61.5,490.5C 60.0937,490.027 59.427,489.027 59.5,487.5C 59.5,485.833 59.5,484.167 59.5,482.5C 60.397,483.598 61.2303,483.598 62,482.5C 75.1726,466.145 88.3393,449.812 101.5,433.5C 103.306,432.976 104.972,432.309 106.5,431.5 Z" /></g>
      <g><path fill="#4f7d9c" d="M 145.5,451.5 C 149.138,455.474 152.805,459.474 156.5,463.5C 156.939,472.222 153.772,479.222 147,484.5C 146.667,485.5 146.333,486.5 146,487.5C 133.644,502.631 121.644,517.964 110,533.5C 108.429,535.923 106.263,537.256 103.5,537.5C 99.8333,534.5 96.5,531.167 93.5,527.5C 94.8471,528.171 96.0138,528.171 97,527.5C 112.736,508.426 127.736,488.759 142,468.5C 142.219,463.775 144.052,459.775 147.5,456.5C 146.544,454.925 145.877,453.258 145.5,451.5 Z" /></g>
      <g><path fill="#448acb" d="M 201.5,594.5 C 200.922,592.309 201.922,590.642 204.5,589.5C 205.386,588.675 205.719,587.675 205.5,586.5C 213.032,587.383 220.365,589.216 227.5,592C 331.334,614.764 421.167,589.598 497,516.5C 508.902,503.622 519.568,489.955 529,475.5C 530.302,474.915 531.469,474.249 532.5,473.5C 534.686,472.035 537.019,471.702 539.5,472.5C 482.108,560.754 400.441,606.754 294.5,610.5C 262.358,610.913 231.358,605.579 201.5,594.5 Z" /></g>
      <g><path fill="#428ccc" d="M 532.5,473.5 C 531.469,474.249 530.302,474.915 529,475.5C 519.568,489.955 508.902,503.622 497,516.5C 421.167,589.598 331.334,614.764 227.5,592C 220.365,589.216 213.032,587.383 205.5,586.5C 205.719,587.675 205.386,588.675 204.5,589.5C 201.922,590.642 200.922,592.309 201.5,594.5C 196.233,593.411 191.233,591.744 186.5,589.5C 191.329,586.399 196.329,583.232 201.5,580C 203.908,580.068 206.075,578.901 208,576.5C 210.544,570.703 212.377,564.703 213.5,558.5C 303.294,586.784 384.961,571.951 458.5,514C 470.202,503.966 480.868,492.966 490.5,481C 493.952,480.115 497.286,479.282 500.5,478.5C 510.982,476.421 521.648,474.754 532.5,473.5 Z" /></g>
      <g><path fill="#4691ce" d="M 500.5,478.5 C 497.286,479.282 493.952,480.115 490.5,481C 480.868,492.966 470.202,503.966 458.5,514C 384.961,571.951 303.294,586.784 213.5,558.5C 212.377,564.703 210.544,570.703 208,576.5C 206.075,578.901 203.908,580.068 201.5,580C 196.329,583.232 191.329,586.399 186.5,589.5C 178.368,586.599 170.702,582.932 163.5,578.5C 164.884,577.199 166.384,577.199 168,578.5C 169.941,576.458 172.108,575.792 174.5,576.5C 177.591,575.023 180.591,573.356 183.5,571.5C 185.123,572.476 186.457,571.976 187.5,570C 191.769,570.212 195.269,568.712 198,565.5C 198.333,565.833 198.667,566.167 199,566.5C 200.167,565.333 201.333,564.167 202.5,563C 201.833,562.667 201.167,562.333 200.5,562C 201.833,561.333 201.833,560.667 200.5,560C 203.465,559.652 205.965,558.485 208,556.5C 209.578,557.547 211.078,557.547 212.5,556.5C 214.375,552.85 216.042,549.183 217.5,545.5C 243.02,555.206 269.52,559.873 297,559.5C 359.948,558.752 414.448,537.252 460.5,495C 464.679,490.654 468.679,486.154 472.5,481.5C 481.813,480.312 491.147,479.312 500.5,478.5 Z" /></g>
      <g><path fill="#4993cf" d="M 472.5,481.5 C 468.679,486.154 464.679,490.654 460.5,495C 414.448,537.252 359.948,558.752 297,559.5C 269.52,559.873 243.02,555.206 217.5,545.5C 218.167,542.167 218.833,538.833 219.5,535.5C 220.404,534.791 220.737,533.791 220.5,532.5C 221.117,532.389 221.617,532.056 222,531.5C 286.568,552.873 348.734,547.373 408.5,515C 422.549,505.973 435.882,495.973 448.5,485C 451.036,484.814 453.369,484.314 455.5,483.5C 461.116,482.543 466.783,481.876 472.5,481.5 Z" /></g>
      <g><path fill="#4f99d0" d="M 455.5,483.5 C 453.369,484.314 451.036,484.814 448.5,485C 435.882,495.973 422.549,505.973 408.5,515C 348.734,547.373 286.568,552.873 222,531.5C 221.617,532.056 221.117,532.389 220.5,532.5C 221.374,529.211 222.041,525.877 222.5,522.5C 224.435,518.105 225.769,513.438 226.5,508.5C 288.15,528.652 346.484,521.819 401.5,488C 419.446,486.003 437.446,484.503 455.5,483.5 Z" /></g>
      <g><path fill="#5588aa" d="M 156.5,463.5 C 160.526,467.195 164.526,470.862 168.5,474.5C 168.414,475.496 168.748,476.329 169.5,477C 166.85,480.207 167.016,480.707 170,478.5C 170.617,479.449 170.451,480.282 169.5,481C 172.943,481.221 173.276,482.221 170.5,484C 171.167,484.667 171.833,485.333 172.5,486C 170.28,490.884 167.28,495.218 163.5,499C 163.833,499.333 164.167,499.667 164.5,500C 162.342,503.656 159.342,506.49 155.5,508.5C 153.962,510.243 152.962,512.243 152.5,514.5C 150.073,513.721 150.073,512.554 152.5,511C 150.606,509.921 150.273,508.587 151.5,507C 150.551,506.383 149.718,506.549 149,507.5C 149.077,506.262 148.911,505.262 148.5,504.5C 141.8,512.697 135.3,521.031 129,529.5C 127.354,531.055 127.021,532.721 128,534.5C 124.333,538.833 120.667,543.167 117,547.5C 116.329,546.748 115.496,546.414 114.5,546.5C 110.518,543.848 106.851,540.848 103.5,537.5C 106.263,537.256 108.429,535.923 110,533.5C 121.644,517.964 133.644,502.631 146,487.5C 146.333,486.5 146.667,485.5 147,484.5C 153.772,479.222 156.939,472.222 156.5,463.5 Z" /></g>
      <g><path fill="#4a7490" d="M 141.5,447.5 C 143.167,448.5 144.5,449.833 145.5,451.5C 145.877,453.258 146.544,454.925 147.5,456.5C 144.052,459.775 142.219,463.775 142,468.5C 127.736,488.759 112.736,508.426 97,527.5C 96.0138,528.171 94.8471,528.171 93.5,527.5C 90.5,525.167 87.8333,522.5 85.5,519.5C 86.4959,519.414 87.3292,519.748 88,520.5C 94.0773,513.43 99.744,506.097 105,498.5C 118.674,483.523 131.174,467.523 142.5,450.5C 141.873,449.583 141.539,448.583 141.5,447.5 Z" /></g>
      <g><path fill="#5b98c0" d="M 168.5,474.5 C 178.188,482.005 188.188,489.005 198.5,495.5C 198.062,495.435 197.728,495.601 197.5,496C 200.624,497.541 200.957,499.375 198.5,501.5C 198.709,502.086 199.043,502.586 199.5,503C 196.833,506.333 194.167,509.667 191.5,513C 192.167,513.333 192.833,513.667 193.5,514C 188.449,518.384 184.116,523.384 180.5,529C 182.373,529.953 182.706,531.12 181.5,532.5C 181.167,532.5 180.833,532.5 180.5,532.5C 179.153,531.829 177.986,531.829 177,532.5C 175,529.833 173,529.833 171,532.5C 170.395,530.519 169.395,530.185 168,531.5C 166.665,529.395 164.998,528.729 163,529.5C 162.935,527.117 162.102,526.45 160.5,527.5C 159.646,526.478 158.646,525.645 157.5,525C 158.712,523.893 158.379,523.393 156.5,523.5C 155.761,523.631 155.094,523.464 154.5,523C 155.293,521.915 155.96,520.748 156.5,519.5C 154.997,519.165 153.664,519.165 152.5,519.5C 153.815,517.801 153.815,516.135 152.5,514.5C 152.962,512.243 153.962,510.243 155.5,508.5C 159.342,506.49 162.342,503.656 164.5,500C 164.167,499.667 163.833,499.333 163.5,499C 167.28,495.218 170.28,490.884 172.5,486C 171.833,485.333 171.167,484.667 170.5,484C 173.276,482.221 172.943,481.221 169.5,481C 170.451,480.282 170.617,479.449 170,478.5C 167.016,480.707 166.85,480.207 169.5,477C 168.748,476.329 168.414,475.496 168.5,474.5 Z" /></g>
      <g><path fill="#5b9ecc" d="M 198.5,495.5 C 207.902,499.87 217.236,504.204 226.5,508.5C 225.769,513.438 224.435,518.105 222.5,522.5C 221.733,523.172 221.066,524.005 220.5,525C 217.607,525.631 214.774,526.464 212,527.5C 211.127,527.265 210.293,526.931 209.5,526.5C 207.312,528.87 204.812,529.537 202,528.5C 200.212,529.358 198.378,530.358 196.5,531.5C 194.549,530.744 192.716,530.411 191,530.5C 190.333,531.167 189.667,531.833 189,532.5C 186.893,531.409 184.393,531.409 181.5,532.5C 182.706,531.12 182.373,529.953 180.5,529C 184.116,523.384 188.449,518.384 193.5,514C 192.833,513.667 192.167,513.333 191.5,513C 194.167,509.667 196.833,506.333 199.5,503C 199.043,502.586 198.709,502.086 198.5,501.5C 200.957,499.375 200.624,497.541 197.5,496C 197.728,495.601 198.062,495.435 198.5,495.5 Z" /></g>
      <g><path fill="#538cb5" d="M 152.5,514.5 C 153.815,516.135 153.815,517.801 152.5,519.5C 153.664,519.165 154.997,519.165 156.5,519.5C 155.96,520.748 155.293,521.915 154.5,523C 155.094,523.464 155.761,523.631 156.5,523.5C 155.432,526.075 153.765,528.242 151.5,530C 152.451,530.718 152.617,531.551 152,532.5C 149.667,535.43 147,538.096 144,540.5C 142.632,541.993 142.465,543.493 143.5,545C 141.759,546.62 139.925,548.12 138,549.5C 137.667,548.167 137.333,546.833 137,545.5C 136.667,546.167 136.333,546.833 136,547.5C 134.938,546.101 133.605,545.101 132,544.5C 128.609,547.07 125.443,549.737 122.5,552.5C 119.545,550.878 116.878,548.878 114.5,546.5C 115.496,546.414 116.329,546.748 117,547.5C 120.667,543.167 124.333,538.833 128,534.5C 127.021,532.721 127.354,531.055 129,529.5C 135.3,521.031 141.8,512.697 148.5,504.5C 148.911,505.262 149.077,506.262 149,507.5C 149.718,506.549 150.551,506.383 151.5,507C 150.273,508.587 150.606,509.921 152.5,511C 150.073,512.554 150.073,513.721 152.5,514.5 Z" /></g>
      <g><path fill="#5392bf" d="M 156.5,523.5 C 158.379,523.393 158.712,523.893 157.5,525C 158.646,525.645 159.646,526.478 160.5,527.5C 162.102,526.45 162.935,527.117 163,529.5C 164.998,528.729 166.665,529.395 168,531.5C 169.395,530.185 170.395,530.519 171,532.5C 173,529.833 175,529.833 177,532.5C 177.986,531.829 179.153,531.829 180.5,532.5C 178.787,535.265 176.62,537.598 174,539.5C 173.027,541.392 172.36,543.392 172,545.5C 169.324,548.206 167.158,551.206 165.5,554.5C 163.43,555.507 161.43,555.174 159.5,553.5C 158.522,553.977 157.689,554.643 157,555.5C 154.116,554.797 150.949,554.63 147.5,555C 146.366,556.016 146.699,556.683 148.5,557C 144.948,558.652 141.614,561.152 138.5,564.5C 132.77,560.975 127.437,556.975 122.5,552.5C 125.443,549.737 128.609,547.07 132,544.5C 133.605,545.101 134.938,546.101 136,547.5C 136.333,546.833 136.667,546.167 137,545.5C 137.333,546.833 137.667,548.167 138,549.5C 139.925,548.12 141.759,546.62 143.5,545C 142.465,543.493 142.632,541.993 144,540.5C 147,538.096 149.667,535.43 152,532.5C 152.617,531.551 152.451,530.718 151.5,530C 153.765,528.242 155.432,526.075 156.5,523.5 Z" /></g>
      <g><path fill="#5499cc" d="M 222.5,522.5 C 222.041,525.877 221.374,529.211 220.5,532.5C 220.737,533.791 220.404,534.791 219.5,535.5C 218.609,537.291 217.609,539.124 216.5,541C 212.068,542.52 207.735,544.186 203.5,546C 204.654,546.914 204.487,547.747 203,548.5C 200.846,547.774 198.346,547.607 195.5,548C 189.975,549.641 184.475,551.474 179,553.5C 177.333,553.5 175.667,553.5 174,553.5C 173.261,554.574 172.261,555.241 171,555.5C 169.186,555.409 167.353,555.075 165.5,554.5C 167.158,551.206 169.324,548.206 172,545.5C 172.36,543.392 173.027,541.392 174,539.5C 176.62,537.598 178.787,535.265 180.5,532.5C 180.833,532.5 181.167,532.5 181.5,532.5C 184.393,531.409 186.893,531.409 189,532.5C 189.667,531.833 190.333,531.167 191,530.5C 192.716,530.411 194.549,530.744 196.5,531.5C 198.378,530.358 200.212,529.358 202,528.5C 204.812,529.537 207.312,528.87 209.5,526.5C 210.293,526.931 211.127,527.265 212,527.5C 214.774,526.464 217.607,525.631 220.5,525C 221.066,524.005 221.733,523.172 222.5,522.5 Z" /></g>
      <g><path fill="#4f94ca" d="M 219.5,535.5 C 218.833,538.833 218.167,542.167 217.5,545.5C 216.042,549.183 214.375,552.85 212.5,556.5C 211.078,557.547 209.578,557.547 208,556.5C 205.965,558.485 203.465,559.652 200.5,560C 201.833,560.667 201.833,561.333 200.5,562C 201.167,562.333 201.833,562.667 202.5,563C 201.333,564.167 200.167,565.333 199,566.5C 198.667,566.167 198.333,565.833 198,565.5C 195.269,568.712 191.769,570.212 187.5,570C 186.457,571.976 185.123,572.476 183.5,571.5C 180.591,573.356 177.591,575.023 174.5,576.5C 172.108,575.792 169.941,576.458 168,578.5C 166.384,577.199 164.884,577.199 163.5,578.5C 154.674,574.59 146.341,569.923 138.5,564.5C 141.614,561.152 144.948,558.652 148.5,557C 146.699,556.683 146.366,556.016 147.5,555C 150.949,554.63 154.116,554.797 157,555.5C 157.689,554.643 158.522,553.977 159.5,553.5C 161.43,555.174 163.43,555.507 165.5,554.5C 167.353,555.075 169.186,555.409 171,555.5C 172.261,555.241 173.261,554.574 174,553.5C 175.667,553.5 177.333,553.5 179,553.5C 184.475,551.474 189.975,549.641 195.5,548C 198.346,547.607 200.846,547.774 203,548.5C 204.487,547.747 204.654,546.914 203.5,546C 207.735,544.186 212.068,542.52 216.5,541C 217.609,539.124 218.609,537.291 219.5,535.5 Z" /></g>


    </SvgIcon >
  );
};

export default LogoCerner;

