import { CircularProgress } from '@mui/material';
import { FC, useCallback } from 'react';
import { useNavigate, useOutletContext, useRevalidator } from 'react-router-dom'
import { ContentSnippetContext } from './ContentSnippetContainer';
import { ContentSnippetForm, ContentSnippetFormData } from './ContentSnippetForm';
import { useData } from './data';

export const ContentSnippetEdit: FC = () => {
  const { snippet, inProgress } = useOutletContext() as ContentSnippetContext;
  const { updateContentSnippet } = useData();
  const navigate = useNavigate();
  const { revalidate } = useRevalidator();

  const onSubmit = useCallback( async ( data: ContentSnippetFormData ) => {
    if( !data?.id ) return;
    await updateContentSnippet( `${data.id}`, data );
    revalidate();
    navigate( '..' );
  }, [ updateContentSnippet ] )

  if( inProgress ) return <CircularProgress />;
  return (
    <ContentSnippetForm
      title='Edit Snippet'
      data={ snippet }
      onSubmit={ onSubmit }
    />
  )
}
