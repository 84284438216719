import { Clear as ClearIcon, Delete as DeleteIcon, Edit as EditIcon, Policy as TestIcon } from '@mui/icons-material';
import { Box, Button, CircularProgress, Paper, Toolbar, Typography } from '@mui/material';
import { titleize } from 'inflection';
import { dump } from 'js-yaml';
import { FC, useCallback, useState } from 'react';
import { useNavigate, useOutletContext, useRevalidator } from 'react-router-dom';
import { ConfirmDialog } from './components/ConfirmDialog';
import { useData } from './data';
import { PropTable } from './PropTable';
import { TenantEmrContext } from './TenantEmrContainer';

export const TenantEmr: FC = () => {
  const { emr, tenant, setNotify, loadEmr, inProgress, setInProgress } = useOutletContext() as TenantEmrContext;
  const { tag } = tenant || {};
  const navigate = useNavigate();
  const { revalidate } = useRevalidator();
  const [ openConfirm, setOpenConfirm ] = useState<'delete' | ''>( '' );
  const { deleteTenantEmr, testTenantEmr } = useData();
  const [ result, setResult ] = useState( '' );

  const onDelete = useCallback( async () => {
    if( !emr?.brand ) return;
    setInProgress( true );
    await deleteTenantEmr( tag, emr.brand );
    setNotify( `Deleted "${ emr.brand }"` )
    navigate( '..' );
    // setInProgress( false );
  }, [ tag, deleteTenantEmr ] )

  const onTest = useCallback( async ( testId: string ) => {
    if( !tag || !emr?.brand || !testId ) return;
    setInProgress( true );
    const result = await testTenantEmr( tag, emr.brand, testId );
    setResult( dump( result || 'Nothing returned' ) );
    setInProgress( false );
  }, [ tag, emr, setResult, testTenantEmr, setInProgress ] );

  return (
    <Box>

      <PropTable label={emr?.brand || 'EMR'} record={emr} unsort />

      {emr &&
        <>
          <Toolbar>
            <Button
              startIcon={<EditIcon />}
              onClick={() => navigate( 'edit' )}
              disabled={!!inProgress}
            >
              edit
            </Button>
            <Box sx={{ flexGrow: 1 }} />
            <>
              <Button
                startIcon={<DeleteIcon />}
                color='error'
                onClick={() => setOpenConfirm( 'delete' )}
                disabled={!!inProgress}
              >
                Delete EMR
              </Button>

            </>
          </Toolbar>

          <ConfirmDialog
            open={!!openConfirm}
            title={`${ titleize( openConfirm ) } EMR`}
            message={`Are you sure you want to ${ openConfirm } "${ emr?.brand }"?`}
            confirmButton={titleize( openConfirm )}
            onClose={( confirmed ) => {
              if( confirmed ) {
                openConfirm == 'delete' ? onDelete() : undefined;
              }
              setOpenConfirm( '' );
            }}
          // fullScreen={isXSmall}
          />


          <Box>
            <Paper elevation={2} sx={{ p: '1rem' }}>
              <Typography component='h6'>
                EMR Tests
              </Typography>
              <Toolbar
                sx={{
                  '& .MuiButton-root': {
                    marginRight: '1rem',
                  }
                }}
              >
                <Button
                  startIcon={<TestIcon />}
                  disabled={!!inProgress}
                  onClick={() => onTest( '1' )}
                >
                  Metadata
                </Button>
                <Button
                  startIcon={<TestIcon />}
                  disabled={!!inProgress}
                  onClick={() => onTest( '2' )}
                >
                  Capabilities
                </Button>
                <Button
                  startIcon={<TestIcon />}
                  disabled={!!inProgress}
                  onClick={() => onTest( '3' )}
                >
                  Get patient
                </Button>

                <Button
                  startIcon={<TestIcon />}
                  disabled={!!inProgress}
                  onClick={() => onTest( '4' )}
                >
                  Get patient error
                </Button>

                <Button
                  startIcon={<TestIcon />}
                  disabled={!!inProgress}
                  onClick={() => onTest( '5' )}
                >
                  Get value sets
                </Button>

                <Box sx={{ flexGrow: 1 }} />
                <Button
                  startIcon={<ClearIcon />}
                  disabled={!!inProgress}
                  onClick={() => setResult( '' )}
                >
                  Clear
                </Button>
              </Toolbar>
              <Box component='pre'>
                {!inProgress &&
                  result
                }
                {inProgress &&
                  <Box sx={{ textAlign: 'center', padding: '3rem' }}>
                    <CircularProgress />
                  </Box>
                }
              </Box>
            </Paper>
          </Box>
        </>
      }
    </Box>
  );
}


