import { FC, useCallback } from 'react';
import { useNavigate, useOutletContext, useRevalidator } from 'react-router-dom';
import { BaseContext } from './Base';
import { TenantEmrCreateFormData, useData } from './data';
import { TenantEmrsContext } from './TenantEmrsContainer';
import { TenantEmrForm } from './TenantEmrForm';

export const TenantEmrCreate: FC = () => {
  const { createTenantEmr } = useData();
  const { tenant, emrs } = useOutletContext() as TenantEmrsContext;
  const { tag } = tenant;
  const navigate = useNavigate();
  const { revalidate } = useRevalidator();

  const onSubmit = useCallback( async ( data: TenantEmrCreateFormData ) => {
    const { brand } = data;
    await createTenantEmr( tag, data );
    revalidate();
    navigate( `..` );
  }, [ createTenantEmr ] );

  return (
    <TenantEmrForm
      title='Add EMR'
      onSubmit={onSubmit}
      existing={emrs}
    />
  );

}


