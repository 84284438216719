import { Add as AddIcon, Delete as DeleteIcon, RestartAlt } from '@mui/icons-material';
import { Box, Button, CircularProgress, Toolbar } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { ConfirmDialog } from './components/ConfirmDialog';
import { Stack, useData } from './data';
import { NavTabs } from './NavTabs';
import { PropTable } from './PropTable';
import { StackEventStatus } from './StackEventStatus';
import { TenantContext } from './TenantContainer';

export interface TenantStackContext extends TenantContext {
  stack?: Stack;
}

export const TenantStack: FC = () => {
  const { id } = useParams();
  const { getTenantStack } = useData();
  const [ stack, setStack ] = useState<Partial<Stack> | undefined>();
  const tenantContext = useOutletContext() as TenantContext;
  const { tenant } = tenantContext;
  const { tag } = tenant;
  const { stackName = '', status = '', stackId = '', templateSha256, createdAt, updatedAt } = stack || {};
  const [ inProgress, setInProgress ] = useState<'create' | 'update' | 'delete' | ''>( '' );
  const [ openConfirmDelete, setOpenConfirmDelete ] = useState( false );
  const { createTenantStack, deleteTenantStack } = useData();

  useEffect( () => {
    ( async () => {
      const s = await getTenantStack( id );
      setStack( s || {} );
    } )();
  }, [ id, setStack, getTenantStack ] );

  const onCreateStack = useCallback( async () => {
    setInProgress( 'create' );
    await createTenantStack( tag );
    setInProgress( '' );
  }, [ tag, createTenantStack ] )

  const onDeleteStack = useCallback( async () => {
    setInProgress( 'delete' );
    await deleteTenantStack( tag );
    setInProgress( '' );
  }, [ tag, deleteTenantStack ] )

  const onUpdateStack = useCallback( async () => {
    setInProgress( 'update' );
    await createTenantStack( tag );                      // TODO if nothing changed, this ends silently
    setInProgress( '' );
  }, [ tag, createTenantStack ] )

  useEffect( () => { setInProgress( '' ); }, [ stack ] ); // fixes wrong data flash before revalidate kicks in

  const record = useMemo( () => !stack ? stack : { stackName, stackId, status: <StackEventStatus Status={status} />, templateSha256, createdAt, updatedAt }, [ stack ] );

  const context = { ...tenantContext, stack };


  const tabs = [
    { label: 'Events', to: 'events' },
    { label: 'Resources', to: 'resources' },
    { label: 'Outputs', to: 'outputs' },
    { label: 'Parameters', to: 'parameters' },
    { label: 'Tags', to: 'tags' },
  ];

  return (
    <Box>

      {!inProgress &&
        <PropTable label='Stack' record={record} unsort />
      }
      <Toolbar>
        {!stack?.stackId && !inProgress &&
          <Button
            startIcon={<AddIcon />}
            onClick={onCreateStack}
            disabled={!!inProgress}
            variant='contained'
          >
            Create stack
          </Button>
        }
        <Box sx={{ flexGrow: 1 }} />
        {stack?.stackId && !inProgress &&
          <Button
            startIcon={<DeleteIcon />}
            color='error'
            onClick={() => setOpenConfirmDelete( true )}
          >
            Delete stack
          </Button>
        }
        {stack?.stackId && !inProgress &&
          <Button
            startIcon={<RestartAlt />}
            onClick={onUpdateStack}
            disabled={!!inProgress}
          >
            Update stack
          </Button>
        }
        <ConfirmDialog
          open={openConfirmDelete}
          title='Delete stack'
          message='Are you sure you want to delete this stack?'
          confirmButton='Delete'
          onClose={( confirmed ) => {
            if( confirmed ) {
              onDeleteStack();
            }
            setOpenConfirmDelete( false );
          }}
        // fullScreen={isXSmall}
        />
      </Toolbar>

      {stack && !inProgress &&
        <>
          <NavTabs tabs={tabs} />

          <Box
            sx={{
              padding: '1rem',
            }}
          >
            <Outlet
              context={context}
            />
          </Box>
        </>
      }

      {inProgress &&
        <Box sx={{ textAlign: 'center', padding: '3rem' }}>
          <CircularProgress />
        </Box>
      }

    </Box>
  );

}


