import styled from '@mui/material/styles/styled';
import MuiSvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const SvgIcon = styled( MuiSvgIcon, {
  name: 'LogoOpenEMR',
  shouldForwardProp: ( prop ) => prop !== 'fill',
} )<SvgIconProps>( () => ( {
  // fill: '#f00',
  // stroke: 'currentColor',
  // strokeLinecap: 'round',
  // strokeLinejoin: 'round',
  // strokeWidth: '2.25px',
} ) );

SvgIcon.defaultProps = {
  viewBox: '0 0 287 287',
  focusable: 'false',
  'aria-hidden': 'true',
};

export const LogoOpenEMR: FC<SvgIconProps> = ( props ) => {

  const { fill = 'rgb(248, 153, 29)', ...rest } = props;

  return (
    <SvgIcon {...rest}>

      <g><path style={{ opacity: 0.974 }} fill="#2d9bd5" d="M 163.5,1.5 C 204.968,-0.629942 239.468,13.7034 267,44.5C 275.277,54.9441 281.111,66.6108 284.5,79.5C 281.623,96.5374 271.623,103.704 254.5,101C 250.131,99.7334 246.297,97.5667 243,94.5C 236.186,79.7089 227.019,66.5422 215.5,55C 185.417,30.9857 152.083,25.319 115.5,38C 72.9435,57.4376 53.6102,90.6043 57.5,137.5C 48.8954,83.8827 67.8954,43.3827 114.5,16C 130.029,7.98579 146.362,3.15246 163.5,1.5 Z" /></g>

      <g><path style={{ opacity: 0.974 }} fill="#2d9bd6" d="M 26.5,58.5 C 37.0341,57.6888 45.5341,61.3555 52,69.5C 54.2854,74.9718 54.9521,80.6385 54,86.5C 38.0675,123.506 43.5675,157.006 70.5,187C 100.735,214.71 135.402,222.044 174.5,209C 190.347,203.152 203.347,193.485 213.5,180C 214.662,179.173 215.829,178.34 217,177.5C 217.5,177.667 218,177.833 218.5,178C 188.55,216.332 149.217,232.665 100.5,227C 68.417,221.719 42.2503,206.552 22,181.5C -1.9586,145.253 -5.29193,107.253 12,67.5C 15.7517,62.551 20.585,59.551 26.5,58.5 Z" /></g>

      <g><path style={{ opacity: 0.974 }} fill="#2d9ad6" d="M 214.5,77.5 C 214.56,76.9569 214.893,76.6236 215.5,76.5C 244.837,95.6992 262.337,122.699 268,157.5C 270.54,214.934 245.374,254.767 192.5,277C 160.933,287.784 129.933,286.451 99.5,273C 89.9332,264.797 87.9332,254.964 93.5,243.5C 100.851,234.66 110.185,231.494 121.5,234C 170.124,251.059 209.958,239.226 241,198.5C 259,167.167 259,135.833 241,104.5C 233.499,94.1399 224.666,85.1399 214.5,77.5 Z" /></g>


    </SvgIcon >
  );
};

export default LogoOpenEMR;

