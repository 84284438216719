import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { Box, Button, Toolbar } from '@mui/material';
import { titleize } from 'inflection';
import { FC, useCallback, useState } from 'react';
import { useNavigate, useOutletContext, useRevalidator } from 'react-router-dom';
import { ConfirmDialog } from './components/ConfirmDialog';
import { useData } from './data';
import { PropTable, PropTableRecord } from './PropTable';
import { TenantContext } from './TenantContainer';

export const TenantIndex: FC = () => {
  const { tenant, setNotify, setError } = useOutletContext() as TenantContext;
  const { tag, name, description } = tenant || {};
  const navigate = useNavigate();
  const { revalidate } = useRevalidator();
  const [ openConfirm, setOpenConfirm ] = useState<'delete' | ''>( '' );
  const { deleteTenant } = useData();

  const onDelete = useCallback( async () => {
    setNotify( `Deleting tenant "${ tag }"` )
    const error = await deleteTenant( tag );
    if( error ) {
      setNotify( '' );
      setError( error.message );
      return;
    }
    setNotify( `Deleted tenant "${ tag }"` )
    navigate( '..' );
  }, [ tag ] ); // , deleteTenant ] )

  const record: PropTableRecord = {
    '   tag': tag,
    '  name': name,
    ' description': description,
    // 'emrBrand': emrBrand,
    // 'emrUsername': emrUsername,
  };

  return (
    <Box>

      <PropTable label='' record={record} />
      <Toolbar>
        <Button
          startIcon={<EditIcon />}
          onClick={() => navigate( 'edit' )}
        >
          edit
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <>
          <Button
            startIcon={<DeleteIcon />}
            color='error'
            onClick={() => setOpenConfirm( 'delete' )}
          >
            Delete tenant
          </Button>

        </>
      </Toolbar>
      <ConfirmDialog
        open={!!openConfirm}
        title={`${ titleize( openConfirm ) } Tenant`}
        message={`Are you sure you want to ${ openConfirm } the tenant? Before doing so, make sure all other assets have been deleted first.`}
        confirmButton={titleize( openConfirm )}
        onClose={( confirmed ) => {
          if( confirmed ) {
            openConfirm == 'delete' ? onDelete() : undefined;
          }
          setOpenConfirm( '' );
        }}
      // fullScreen={isXSmall}
      />

    </Box>
  );
}

