import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FC, useState } from 'react';


export interface PickOptionDialogProps {
  choices: { value: string, label: string }[];
  open: boolean;
  onClose: ( id?: string ) => void;
  title: string;
  message: string;
  label: string;
  fullScreen?: boolean;
  confirmButton?: string;
  cancelButton?: string;
}

export const PickOptionDialog: FC<PickOptionDialogProps> = ( props ) => {
  const { choices, onClose, open, label, message, title, confirmButton = 'Yes', cancelButton = 'Cancel', fullScreen = false, ...other } = props;
  const [ value, setValue ] = useState<string>( '' );

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose( value );
  };

  const handleChange = ( event: SelectChangeEvent ) => {
    setValue( event.target.value as string );
  };

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': fullScreen
          ? {}
          : {
            width: '80%',
            // maxHeight: 435
          }
      }}
      maxWidth="xs"
      open={open}
      fullScreen={fullScreen}
      onClose={handleCancel}
      {...other}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers={false}>
        <Box sx={{ minWidth: 120, paddingTop: '1rem' }}>
          <FormControl fullWidth>
            <InputLabel id="select-label">{label}</InputLabel>
            <Select
              value={value}
              onChange={handleChange}
              label={label}
            >
              <MenuItem key='default' value={''}>&nbsp;</MenuItem>
              {choices.map( c => (
                <MenuItem key={c.value} value={c.value}>{c.label}</MenuItem>
              ) )}
            </Select>
          </FormControl>
        </Box>

      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          {cancelButton}
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        {confirmButton !== '' &&
          <Button onClick={handleOk} disabled={value == ''}>{confirmButton}</Button>
        }
      </DialogActions>
    </Dialog >
  );
}


