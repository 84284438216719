import { Paper, useTheme } from '@mui/material';
import { FC } from 'react';
import { useOutletContext } from 'react-router-dom';
import { BaseContext } from './Base';


export const Dashboard: FC = () => {
  const context = useOutletContext() as BaseContext;


  return (
    <Paper
      sx={{
        padding: '1rem',
      }}
    >

      Dashboard
    </Paper>
  );
}


