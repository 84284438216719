import { Rule } from '@aws-sdk/client-eventbridge';
import { ToggleOff, ToggleOn } from '@mui/icons-material';
import { Box, Button, Tooltip } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useOutletContext, useParams, useRevalidator } from 'react-router-dom';
import { useData } from './data';
import { PropGrid } from './PropGrid';
import { TenantContext } from './TenantContainer';

export const TenantTimers: FC = () => {
  const { id } = useParams();
  const { getTenantStackTimers } = useData();
  const [ timers, setTimers ] = useState<Rule[] | undefined>();
  const { tenant, setNotify } = useOutletContext() as TenantContext;
  const { tag } = tenant;
  const [ inProgress, setInProgress ] = useState( '' );
  const { setTenantStackTimerState } = useData();
  const { revalidate } = useRevalidator();

  const loadData = useCallback( async () => {
    setTimers( await getTenantStackTimers( id ) );
  }, [ id ] );

  useEffect( () => {
    ( async () => {
      setInProgress( 'load' );
      await loadData();
      setInProgress( '' );
    } )();
  }, [ id, loadData ] );

  const onClick = useCallback( ( name: string, state: string ) => async () => {
    setInProgress( name );
    await setTenantStackTimerState( tag, name, state );
    setNotify( `${ state == 'ENABLED' ? 'Enabled' : 'Disabled' } timer "${ name }"` )
    await loadData();
    setInProgress( '' );
  }, [ tag, setTenantStackTimerState, setInProgress, setNotify, revalidate ] )


  const records = useMemo( () => !timers
    ? timers
    : timers.map( t => {
      const { Name = '', ScheduleExpression: schedule, State } = t;
      const [ , , name ] = Name.split( '-' );
      const isDisabled = State == 'DISABLED';
      const notState = isDisabled ? 'ENABLED' : 'DISABLED';
      const state = (
        <Tooltip
          title={`Click to ${ isDisabled ? 'ENABLE' : 'DISABLE' }`}
          placement='right'
        >
          <Button
            startIcon={isDisabled ? <ToggleOff /> : <ToggleOn />}
            onClick={onClick( name, notState )}
            color={isDisabled ? 'error' : 'success'}
            size='large'
            disabled={inProgress == name}
          >
            {State}
          </Button>
        </Tooltip >
      );
      return { state, name, schedule };
    } ), [ timers, inProgress ] );


  return (
    <Box>
      <PropGrid label='Timers' records={records} inProgress={!!inProgress} />
    </Box>
  );
}


