import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthUserCreate, useData } from './data';
import { UsersOpsForm } from './UsersOpsForm';

export const UsersOpsCreate: FC = () => {
  const { createUser } = useData();
  const navigate = useNavigate();

  const onSubmit = useCallback( async ( data: AuthUserCreate ) => {
    await createUser( { ...data, type: 'ops' } );
    navigate( `..` );
  }, [ createUser ] );


  return (
    <UsersOpsForm
      title='Create Ops user'
      onSubmit={onSubmit}
    />
  );

}


