import { Grid, useTheme } from '@mui/material';
import { FC } from 'react';
import { useOutletContext } from 'react-router-dom';
import { BaseContext } from './Base';
import { ConfigTable } from './ConfigTable';
import { PropTable } from './PropTable';


export const BaseParameters: FC = () => {
  const { base, timeZoneName } = useOutletContext() as BaseContext;
  const { environment, stackName, status, stackId, tags = {}, createdAt, updatedAt, outputs = {}, parameters = {} } = base;
  const theme = useTheme();

  return (
    <Grid item xs={'auto'}>
      <ConfigTable label='Parameters' record={parameters} />
    </Grid>


  );
}


