import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Avatar, Box, Button, Checkbox, Chip, Toolbar } from '@mui/material';
import { default as _, get, sortBy } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthConfig, useData } from './data';
import { PropGrid } from './PropGrid';
import { PropTableRecord } from './PropTable';

export const UsersOps: FC = () => {
  const id = 'aic';
  const { getUsers } = useData();
  const [ auth, setAuth ] = useState<AuthConfig | undefined>();
  const [ inProgress, setInProgress ] = useState<'load' | 'create' | 'reset' | 'delete' | ''>( '' );
  const navigate = useNavigate();

  const loadAuth = useCallback( async () => {
    setAuth( undefined );
    const a = await getUsers();
    if( !a ) return;
    setAuth( a );
  }, [ id ] );

  useEffect( () => {
    ( async () => {
      setInProgress( 'load' );
      await loadAuth();
      setInProgress( '' );
    } )();
  }, [ id, setAuth, getUsers ] );

  useEffect( () => { setInProgress( '' ); }, [ auth ] ); // fixes wrong data flash before revalidate kicks in

  const onCreate = useCallback( async () => {
    navigate( 'create' );
  }, [] );

  const users = useMemo<PropTableRecord[] | undefined>( () => {
    if( !auth ) return;
    const admins = get( auth, 'roles.aicOps.users', [] ).map( user => {
      const { id: userId, emails, tenantIds, timeJoined, metadata, loginMethods } = user || {};
      const { name = '', pictureUrl = '' } = metadata || {};
      const picture = pictureUrl ? <Avatar alt={name} src={pictureUrl} /> : <></>;
      const loginMethod = loginMethods.find( m => m.recipeId == 'emailpassword' ) || loginMethods[ 0 ];
      const { email = emails[ 0 ], verified } = loginMethod || {}
      const joined = new Date( timeJoined );
      const tenants = <>{_( tenantIds ).sort().map( t => <Chip key={t} label={t} size='small' sx={{ marginRight: 1, marginBottom: 1 }} /> ).value()}</>;
      return { picture, name, email, verified: <Checkbox checked={verified} />, tenants, userId, joined };
    } );
    return sortBy( admins, a => a.name.toLowerCase() );
  }, [ auth ] );

  const rowClick = useCallback( async ( record: PropTableRecord ) => {
    const { userId } = record;
    if( !userId || typeof userId != 'string' ) return;
    navigate( userId );
  }, [] );

  return (
    <Box>

      <Toolbar>
        <Box sx={{ flexGrow: 1 }} />

        <Button
          startIcon={<AddIcon />}
          onClick={onCreate}
          disabled={!!inProgress}
        >
          Create
        </Button>

      </Toolbar>

      <PropGrid label={''} records={users} inProgress={!!inProgress} rowClick={rowClick} />

    </Box>
  );
}


