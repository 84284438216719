import { createContext, FC, useCallback, useContext } from 'react';

export type QueryParams = ConstructorParameters<typeof URLSearchParams>[ 0 ];

const {
  REACT_APP_API_SERVER: apiServer = '', // 'http://localhost:3400',
} = process.env;

export const apiPath = `${ apiServer }/api/v1`;

const credentials = 'include';                                                            // TODO    'same-origin',

export type Fetch = typeof fetch;

export const fetchApi: Fetch = async ( input, options ) => {
  const { headers = {}, ...opts } = options || {};
  const res = await fetch( input, {
    headers: {
      ...headers,
    },
    credentials,
    ...opts,
  } );
  if( !res.ok ) {
    if( [ 401 ].includes( res.status ) ) {
      throw new Response( res.statusText, { status: res.status } );
    }
  }
  return res;
};


export const getUrl = ( apiResource: string, params?: QueryParams ) => {
  const url = new URL( `${ apiPath }/${ apiResource.replace( /^\//, '' ) }`, location.origin )
  if( params ) {
    const search = new URLSearchParams( params ).toString();
    url.search = search;
  }
  return url;
}




export interface FetchContext {
  fetchApi?: Fetch;
}

export const fetchContext = createContext<FetchContext>( {} );

export const FetchProvider: FC<{ children?: React.ReactNode }> = props => {
  const { children } = props;

  const fetchApi = useCallback<Fetch>( async ( input, options ) => {
    const { headers = {}, ...opts } = options || {};
    try {
      return await fetch( input, {
        headers: {
          ...headers,
          // authorization: `Bearer ${ apiToken }`
        },
        credentials,
        ...opts,
      } );
    } catch( e ) {
      return new Response( '""', { status: 404, headers: { 'Content-Type': 'application/json' } } );
    }
  }, [] );

  return (
    <fetchContext.Provider value={{ fetchApi }}>
      {children}
    </fetchContext.Provider>
  );

};

export const useFetch = () => useContext<FetchContext>( fetchContext );

