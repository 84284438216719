import { Box, Paper } from '@mui/material';
import { FC } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { NavTabs } from './NavTabs';
import { TenantContext } from './TenantContainer';

export interface TenantSetupContext extends TenantContext {
  // stack?: Stack;
  maybe?: string;
}

export const TenantSetup: FC = () => {
  // const tenant = useLoaderData() as Tenant;
  const tenantContext = useOutletContext() as TenantContext;



  const tabs = [
    { label: 'Summary', to: 'summary' },
    { label: 'Database', to: 'database' },
    { label: 'Auth', to: 'auth' },
    { label: 'Cert', to: 'certificate' },
    { label: 'Messaging', to: 'messaging' },
    { label: 'Config', to: 'config' },
  ];

  const context: TenantSetupContext = { ...tenantContext };

  return (
    <Box>
      <NavTabs tabs={tabs} />

      <Outlet
        context={context}
      />
    </Box>
  );

}


