import styled from '@mui/material/styles/styled';
import MuiSvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const SvgIcon = styled( MuiSvgIcon, {
  name: 'AwsS3',
  shouldForwardProp: ( prop ) => prop !== 'fill',
} )<SvgIconProps>( () => ( {
  // fill: '#f00',
  // stroke: 'currentColor',
  // strokeLinecap: 'round',
  // strokeLinejoin: 'round',
  // strokeWidth: '2.25px',
} ) );

SvgIcon.defaultProps = {
  viewBox: '0 0 40 40',
  focusable: 'false',
  'aria-hidden': 'true',
};

export const AwsS3: FC<SvgIconProps> = ( props ) => {

  return (
    <SvgIcon {...props}>
      <g id="Icon-Architecture/32/Arch_Amazon-Simple-Storage-Service_32" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icon-Architecture-BG/32/Storage" fill="#7AA116">
          <rect id="Rectangle" x="0" y="0" width="40" height="40"></rect>
        </g>
        <g id="Icon-Service/32/Amazon-Simple-Storage-Service_32" transform="translate(7.000200, 6.000100)" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="1">
          <path d="M23.532,15.391 L23.729,13.932 C25.821,15.131 25.864,15.626 25.8630149,15.64 C25.859,15.643 25.509,15.923 23.532,15.391 L23.532,15.391 Z M22.564,15.103 C19.722,14.206 15.904,12.531 13.723,11.504 C13.723,11.497 13.727,11.491 13.727,11.484 C13.727,10.821 13.187,10.281 12.524,10.281 C11.861,10.281 11.321,10.821 11.321,11.484 C11.321,12.148 11.861,12.687 12.524,12.687 C12.814,12.687 13.07,12.571 13.278,12.4 C14.547,12.994 19.075,15.076 22.428,16.113 L21.249,24.85 C21.249,25.716 17.575,27 12.5,27 C7.425,27 3.751,25.716 3.746,24.784 L1.283,6.563 C3.458,8.155 8.074,9 12.5,9 C16.926,9 21.543,8.155 23.718,6.563 L22.564,15.103 Z M1.003,4.489 C1.032,3.609 5.104,1 12.5,1 C19.895,1 23.97,3.609 24,4.489 L24,4.603 C23.81,6.231 19.137,8 12.5,8 C5.863,8 1.209,6.231 1.018,4.604 L1.003,4.489 Z M25,4.5 C25,2.665 20.131,0 12.5,0 C4.869,0 0,2.665 0,4.5 L0,4.567 L2.751,24.85 C2.751,26.896 7.773,28 12.5,28 C17.226,28 22.249,26.896 22.244,24.917 L23.396,16.39 C24.217,16.604 24.881,16.718 25.392,16.718 C25.985,16.718 26.386,16.574 26.628,16.285 C26.827,16.047 26.903,15.76 26.845,15.454 C26.708,14.716 25.797,13.919 23.89,12.87 L23.869,12.896 L25,4.567 L25,4.5 Z" id="Amazon-Simple-Storage-Service-Icon_32_Squid"></path>
        </g>
      </g>
    </SvgIcon >
  );
};

export default AwsS3;

