import { Divider, FormControl, InputLabel, ListSubheader, MenuItem, Select, SelectProps } from '@mui/material';
import { groupBy, sortBy } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useData } from './data';

const tabMap = {
  EMR: 'launch/emr',
  Database: 'setup/database',
  Config: 'setup/config',
  Auth: 'setup/auth',
  Cert: 'setup/certificate',
  Messaging: 'setup/messaging',
  // Stack: 'stack/resources',
  Secret: 'launch/secret',
  DNS: 'launch/dns',
  Init: 'launch/init',
  // App: 'manage/app',
  // Admin: 'manage/admin',
  // Timers: 'manage/timers',
  // Server: 'manage/server',
};

const groupMap: Record<string, string> = {
  setup: 'Setup',
  stack: 'Deploy',
  launch: 'Launch',
  manage: 'Manage',
};

export const TenantTabSelector: FC = () => {
  const match = useMatch( { path: '/tenants/:id', end: false } );
  const loc = useLocation();
  const { pathname } = match || {};
  const { pathname: currentPath } = loc;
  const navigate = useNavigate();

  const tabs = useMemo<{ id: string; name: string }[]>( () => {
    return sortBy( Object.entries( tabMap ), ( [ s ] ) => s ).map( ( [ name, id ] ) => ( { id, name } ) )
  }, [ tabMap ] );

  const grouped = useMemo<Record<string, typeof tabs>>( () => {
    const unsorted = groupBy( tabs, ( { id } ) => groupMap[ id.split( '/' )[ 0 ] ] );
    return Object.fromEntries(
      Object.values( groupMap )
        .map( g => [ g, unsorted[ g ] || [] ] )
        .filter( ( [ , v ] ) => v.length )
    );
  }, [ tabMap, tabs ] );

  const value = useMemo<string>( () => {
    return tabs && ( tabs.find( t => currentPath.includes( t.id ) ) || {} ).id || '';
  }, [ tabs, currentPath ] )

  const onChange = useCallback<NonNullable<SelectProps[ 'onChange' ]>>( ( e ) => {
    if( !match || !loc ) return;
    const { value } = e.target;
    if( typeof value != 'string' ) return;
    const newPath = `${ pathname }/${ value }`;
    navigate( newPath );
  }, [ match, loc ] );

  if( !match || !grouped ) return null;

  return (
    <FormControl variant='standard' margin='none'
      sx={{
        marginTop: '-16px',
        minWidth: '10rem',
      }}

    >
      <InputLabel id="select-label">Switch tab</InputLabel>
      <Select
        value={value}
        label='Tab'
        labelId='select-label'
        placeholder='Tenant tab'
        variant='standard'
        autoWidth
        autoFocus={false}

        sx={{
          minWidth: '5rem',
          '& .MuiSelect-select:focus': {
            backgroundColor: 'initial',
          },
          '&:after, &:before,&:hover': {
            borderBottomWidth: 0,
          },
        }}
        onChange={onChange}
      >
        {Object.entries( grouped ).map( ( [ group, groupTabs ] ) => [
          <ListSubheader
            color='primary'
          >
            {group.toUpperCase()}
          </ListSubheader>,
          ...groupTabs.map( ( { id, name } ) => (
            <MenuItem value={id} key={id}>
              {name}
            </MenuItem>
          ) )
        ] )}
      </Select>
    </FormControl >
  )
}

