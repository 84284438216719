import styled from '@mui/material/styles/styled';
import MuiSvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const SvgIcon = styled( MuiSvgIcon, {
  name: 'LogoBlank',
  shouldForwardProp: ( prop ) => prop !== 'fill',
} )<SvgIconProps>( () => ( {
  // fill: '#f00',
  // stroke: 'currentColor',
  // strokeLinecap: 'round',
  // strokeLinejoin: 'round',
  // strokeWidth: '2.25px',
} ) );

SvgIcon.defaultProps = {
  viewBox: '0 0 40 40',
  focusable: 'false',
  'aria-hidden': 'true',
};


// https://png2svg.com


export const LogoBlank: FC<SvgIconProps> = ( props ) => {

  const { fill = 'rgb(248, 153, 29)', ...rest } = props;

  return (
    <SvgIcon {...rest}>
      <g id="Icon-Architecture/32/Arch_Amazon-Virtual-Private-Cloud_32" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icon-Architecture-BG/32/Networking-Content-Delivery" fill="#FFFFFF">
          <rect id="Rectangle" x="0" y="0" width="40" height="40"></rect>
        </g>
      </g>
    </SvgIcon >
  );
};

export default LogoBlank;

