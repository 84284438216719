import { Box } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { TenantEmr, useData } from './data';
import { TenantContext } from './TenantContainer';
import { TenantEmrsContext } from './TenantEmrsContainer';

export interface TenantEmrContext extends TenantEmrsContext {
  emr?: TenantEmr;
  loadEmr: () => void;
  inProgress: boolean;
  setInProgress: ( b: boolean ) => void;
}

export const TenantEmrContainer: FC = () => {
  const { id, emr: emrId } = useParams();
  const { getTenantEmr } = useData();
  const [ inProgress, setInProgress ] = useState( false );
  const [ emr, setEmr ] = useState<TenantEmr | undefined>();
  const tenantEmrsContext = useOutletContext() as TenantEmrsContext;

  const loadEmr = useCallback( async () => {
    setEmr( await getTenantEmr( id, emrId ) );
  }, [ id, emrId ] );

  useEffect( () => {
    ( async () => {
      setInProgress( true );
      await loadEmr();
      setInProgress( false );
    } )();
  }, [ id, emrId, setEmr, getTenantEmr ] );

  const context: TenantEmrContext = {
    ...tenantEmrsContext,
    emr, loadEmr, inProgress, setInProgress,
  };

  return (
    <Box>

      <Outlet
        context={context}
      />
    </Box>
  );

}


