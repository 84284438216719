import { Box, Tooltip, Typography } from '@mui/material';
import { FC, ReactElement } from 'react';
import { LogoAthena, LogoBlank, LogoCerner, LogoEpic, LogoEyeMD, LogoModMed, LogoNextech, LogoNextgen, LogoOpenEHR, LogoOpenEMR, LogoPointClickCare } from './Logos';

export const emrImage: Record<string, ReactElement> = {
  Athena: <LogoAthena />,
  Cerner: <LogoCerner />,
  Epic: <LogoEpic />,
  EyeMD: <LogoEyeMD />,
  ModMed: <LogoModMed />,
  OpenEmr: <LogoOpenEMR />,
  OpenEhr: <LogoOpenEHR />,
  PointClickCare: <LogoPointClickCare />,
  Nextgen: <LogoNextgen />,
  Nextech: <LogoNextech />,
};


export interface EmrLogoProps {
  brand?: string;
}

export const EmrLogo: FC<EmrLogoProps> = ( { brand = '' } ) => {
  const svg = emrImage[ brand ];

  return svg || <LogoBlank />;
}

export const EmrWithLogo: FC<{ brand?: string; label?: string }> = ( { label = '', brand = '' } ) => (
  <Tooltip
    title={brand}
    placement='left-end'
  // sx={{
  //   fontSize: '110%',
  // }}
  >
    <Box
      sx={{
        display: 'flex',
        '& .MuiSvgIcon-root': {
          marginRight: '0.5rem',
          marginBottom: '0.5rem',
        },
      }} >
      <EmrLogo brand={brand} />
      <Typography>
        {label || brand}
      </Typography>
    </Box>
  </Tooltip>
)



