import { Add as AddIcon, ArrowCircleUp as UpIcon } from '@mui/icons-material';
import { Box, Button, IconButton, Toolbar, Tooltip } from '@mui/material';
import { inflect, titleize } from 'inflection';
import { FC, useCallback, useState } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { ConfirmDialog } from './components/ConfirmDialog';
import { EmrWithLogo } from './EmrWithLogo';
import { PropGrid } from './PropGrid';
import { PropTableRecord } from './PropTable';
import { TenantEmrsContext } from './TenantEmrsContainer';

export const TenantEmrs: FC = () => {
  const { emrs, setNotify, setError, loadEmrs, inProgress, setInProgress } = useOutletContext() as TenantEmrsContext;
  const navigate = useNavigate();
  const [ openConfirm, setOpenConfirm ] = useState<string>( '' );
  // const { deleteTenant } = useData();

  const onDelete = useCallback( async ( brand: string ) => {
    setInProgress( true );
    // await deleteTenantEmr( brand );
    setNotify( `Removed "${ brand }"` )
    loadEmrs();
    setInProgress( false );
  }, [] ); // , deleteTenantEmr ] )

  const onPromote = useCallback( async ( brand: string ) => {
    // await deleteTenantEmr( brand );
    setError( `Promoting "${ brand }" is not implemented` )
    loadEmrs();
    setInProgress( false );
  }, [] ); // , deleteTenantEmr ] )

  const records: PropTableRecord[] | undefined = !emrs
    ? emrs
    : emrs.map( ( emr, idx ) => {
      const { brand } = emr;
      return {
        brand: (
          <Link
            to={brand}
            key={brand}
            style={{
              textDecorationLine: 'none',
            }}
          >
            <EmrWithLogo brand={brand} />
          </Link>
        ),
        priority: idx + 1,

        '': idx > 0
          ? (
            <>
              <Tooltip
                key={`${ brand } promote`}
                title={`Promote ${ brand }`}
              >
                <IconButton
                  onClick={() => onPromote( brand )}
                >
                  <UpIcon />
                </IconButton>
              </Tooltip>
              <Box sx={{ flexGrow: 1 }} />
            </>
          )
          : <></>,

      };
    } );

  return (
    <Box>

      <Toolbar>
        {emrs && !inProgress && !emrs.length &&
          <Button
            startIcon={<AddIcon />}
            variant='contained'
            onClick={() => navigate( 'create' )}
          >
            Add EMR
          </Button>
        }
        <Box sx={{ flexGrow: 1 }} />
        {!!emrs?.length &&
          <Button
            startIcon={<AddIcon />}
            onClick={() => navigate( 'create' )}
            disabled={inProgress}
          >
            Add
          </Button>
        }
      </Toolbar>

      <PropGrid label={inflect( 'EMR', emrs?.length || 1 )} records={records} unsort inProgress={inProgress} />

      <ConfirmDialog
        open={!!openConfirm}
        title={`Remove ${ openConfirm }`}
        message={`Are you sure you want to remove ${ openConfirm }?`}
        confirmButton={titleize( openConfirm )}
        onClose={( confirmed ) => {
          if( confirmed ) {
            // onDelete( openConfirm );
          }
          setOpenConfirm( '' );
        }}
      // fullScreen={isXSmall}
      />

    </Box>
  );
}


