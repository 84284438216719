import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useData } from './data';


export const TenantSelector: FC = () => {
  const match = useMatch( { path: '/tenants/:id', end: false } );
  const loc = useLocation();
  const { params } = match || {};
  const { id } = params || {};
  const { getTenants } = useData();
  const [ ids, setIds ] = useState<string[]>( [] );
  const navigate = useNavigate();

  useEffect( () => {
    ( async () => {
      if( !match || ids.length ) return;
      setIds( await getTenants() );
    } )();
  }, [ match, setIds ] );

  const onChange = useCallback<NonNullable<SelectProps[ 'onChange' ]>>( ( e ) => {
    if( !match || !loc ) return;
    const { value } = e.target;
    if( typeof value != 'string' ) return;
    // match?.pathname
    const { pathname: origPath } = loc;
    const { pathname: previous, pattern: { path: template } } = match;
    const newPath = template.replace( ':id', value ) + origPath.slice( previous.length );
    navigate( newPath );
  }, [ match, loc ] );

  if( !match ) return null;

  return (
    <FormControl variant='standard' margin='none'
      sx={{
        // m: 1,
        marginTop: '-16px',
        minWidth: '10rem',
        // '& .MuiInput-root': {
        //   marginTop: 0,
        // }
      }}

    >
      <InputLabel id="select-label">Switch tenant</InputLabel>
      <Select
        value={id && ids.includes( id ) ? id : ''}
        label='Tenant'
        labelId='select-label'
        placeholder='Tenant'
        variant='standard'
        autoWidth
        autoFocus={false}

        sx={{
          minWidth: '5rem',
          '& .MuiSelect-select:focus': {
            backgroundColor: 'initial',
          },
          '&:after, &:before,&:hover': {
            borderBottomWidth: 0,
            // borderBottomColor: 'red',
          },
        }}
        onChange={onChange}
      >
        {ids.map( id => ( <MenuItem value={id} key={id}>{id}</MenuItem> ) )}
      </Select>
    </FormControl>
  )
}

